import Parse from "parse";

export const GetFirstObjectById = async (
  dbTable: string,
  id: string,
  includeString?: string
) => {
  const object = Parse.Object.extend(dbTable);
  const objectQuery = includeString
    ? new Parse.Query(object)
        .equalTo("objectId", id)
        .include(includeString)
        .limit(10000)
    : new Parse.Query(object).equalTo("objectId", id).limit(10000);
  return await objectQuery.first();
};

export const GetFirstObjectByPropertyName = async (
  dbTable: string,
  propertyName: string,
  value: any
) => {
  const object = Parse.Object.extend(dbTable);
  const objectQuery = new Parse.Query(object)
    .equalTo(propertyName, value)
    .limit(10000);
  return await objectQuery.first();
};

export const GetAllObjectsByPropertyName = async (
  dbTable: string,
  propertyName: string,
  value: any,
  pointerName?: string
) => {
  const object = Parse.Object.extend(dbTable);
  const objectQuery = new Parse.Query(object)
    .equalTo(propertyName, value)
    .limit(10000);
  if (pointerName) {
    objectQuery.include(pointerName);
  }
  return await objectQuery.find();
};

export const GetAllObjectsById = async (dbTable: string, id: string) => {
  const object = Parse.Object.extend(dbTable);
  const objectQuery = new Parse.Query(object)
    .equalTo("objectId", id)
    .limit(10000);
  return await objectQuery.find();
};

export const GetAllObjectsByTableName = async (
  dbTable: string,
  pointerName?: string
) => {
  const object = Parse.Object.extend(dbTable);
  const query = new Parse.Query(object).limit(10000);
  if (pointerName) {
    query.include(pointerName);
  }

  return await query.find();
};

export const GetRelationByName = async (query: any, name: string) => {
  const relationQuery = query.relation(name);
  return await relationQuery.query().find();
};
