import React, { ReactElement, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { DataGrid } from "../../components/dataGrid";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { ProjectTemplateBaseModel } from "../../models/ProjectTemplateBaseModel";
import { Language, ParseClassName } from "../../settings/Enums";
import {
  GetProjectTemplates,
  saveProjectTemplate,
} from "../../services/ProjectTemplateServices";
import { useAuthDataContext } from "../../components/authDataProvider";
import { translations } from "../../settings/translation";

interface Props {}

export const TemplatesProjects: React.FC<Props> = (
  props: Props
): ReactElement => {
  const [templateProjects, setTemplateProjects] = useState<
    ProjectTemplateBaseModel[]
  >([]);
  const { language } = useAuthDataContext();

  const history = useHistory();

  useEffect(() => {
    fetchProjectTemplate();
  }, []);

  const fetchProjectTemplate = async (): Promise<void> => {
    const results = await GetProjectTemplates();

    setTemplateProjects(results);
  };

  const createUpdateProjectTemplate = async (
    projectTemplate: ProjectTemplateBaseModel
  ) => {
    const response = await saveProjectTemplate(projectTemplate);

    if (response) {
      await fetchProjectTemplate();
    }
  };

  const columns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].title,
      validationRules: {
        required: true,
      },
    },
    {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 200,
      template: (props): ReactElement => {
        return (
          <div>
            <div
              className="e-btn"
              onClick={(): void =>
                history.push(`/templates/projects/${props.id}/tasks`)
              }
            >
              {translations[language || Language.Hr].tasks}
            </div>
            <div
              className="e-btn"
              style={{ marginLeft: 10 }}
              onClick={(): void =>
                history.push(`/templates/projects/${props.id}/documents`)
              }
            >
              {translations[language || Language.Hr].documents}
            </div>
          </div>
        );
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "title", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    mode: "Dialog",
  };

  return (
    <section id="projectTemplate">
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={templateProjects}
            columnProperties={columns}
            useDialogTemplate={ParseClassName.ProjectTemplate}
            updateItem={createUpdateProjectTemplate}
            createItem={createUpdateProjectTemplate}
            fetchDataAfterCatchError={fetchProjectTemplate}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
    </section>
  );
};
