import React from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Parse from "parse";
import {
  loadCldr,
  L10n,
  setCulture,
  setCurrencyCode,
} from "@syncfusion/ej2-base";
import { NavBar } from "./components/navBar";
import AuthDataProvider from "./components/authDataProvider";
import { Router } from "./components/router";

Parse.initialize(
  process.env.REACT_APP_PARSE_APPLICATION_ID || "",
  process.env.REACT_APP_PARSE_JAVASCRIPT_KEY || ""
);
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL || "";

/* Ovi importi moraju tak biti prema njihovoj upiti da bi datepickeri radili:
    https://www.syncfusion.com/forums/149232/uncaught-typeerror-with-datepicker-localization
    Sureshkumar P [Syncfusion]
    Replied On November 22, 2019 11:09 AM UTC
*/
declare var require: any;
loadCldr(
  require("cldr-data/main/hr/ca-gregorian.json"),
  require("cldr-data/main/hr/numbers.json"),
  require("cldr-data/main/hr/timeZoneNames.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/supplemental/weekData.json"),
  require("cldr-data/main/hr/currencies.json")
);

setCurrencyCode("HRK");
L10n.load({
  hr: {
    grid: {
      EmptyRecord: "Nema podataka",
      Item: "Redak",
      Items: "Redak",
      Add: "Novi",
      Edit: "Uredi",
      SaveButton: "Spremi",
      CancelButton: "Odustani",
      EditFormTitle: "Uredi ",
      AddFormTitle: "Novi",
      EditOperationAlert: "Niste odabrali niti jedan redak.",
      FilterButton: "Primjeni",
      ClearButton: "Poništi",
      StartsWith: "Počinje",
      EndsWith: "Završava",
      Contains: "Sadrži",
      Equal: "Jednako",
      NotEqual: "Različito",
      LessThan: "Manje od",
      LessThanOrEqual: "Manje ili jednako od",
      GreaterThan: "Veće od",
      GreaterThanOrEqual: "Veće ili jednako od",
      ChooseDate: "Odaberi datum",
      EnterValue: "Unesi vrijednost",
      Search: "Pretraži",
      SelectAll: "Označi sve",
      Columnchooser: "Kolone",
      ChooseColumns: "Odaberi kolone",
      SearchColumns: "Pretraži kolone",
      Print: "Ispis",
      Pdfexport: "PDF",
      Excelexport: "XLS",
    },
    pager: {
      currentPageInfo: "Stranica {0} od {1}",
      totalItemsInfo: "(Ukupno redaka: {0})",
      firstPageTooltip: "Idi na prvu stranicu",
      lastPageTooltip: "Idi na posljednju stranicu",
      nextPageTooltip: "Idi na sljedeću stranicu",
      previousPageTooltip: "Idi na prethodnu stranicu",
      pagerDropDown: "Redaka po stranici",
    },
    formValidator: {
      required: "Obavezno polje",
      email: "Unesite ispravnu elektroničku poštu",
      url: "Unesite ispravni URL",
      date: "Unesite ispravni datum",
      number: "Obavezno unesite broj",
      min: "Unesite broj u rasponu od 0 do 100",
      max: "Unesite broj u rasponu od 0 do 100",
      maxLength: "Duljina ne smije biti veća od {0} znakova",
      minLength: "Duljina ne smije biti manja od {0} znakova",
    },
    filemanager: {
      NewFolder: "Novi folder",
      Upload: "Učitaj",
      Delete: "Obriši",
      Rename: "Preimenuj",
      Download: "Preuzmi",
      Cut: "Izreži",
      Copy: "Kopiraj",
      Paste: "Zalijepi",
      SortBy: "Poredaj po",
      Refresh: "Osvježi",
      "Item-Selection": "Odabrana stavka",
      "Items-Selection": "Odabrane stavke",
      View: "Pregled",
      Details: "Detalji",
      SelectAll: "Označi sve",
      Open: "Otvori",
      "Tooltip-NewFolder": "Novi folder",
      "Tooltip-Upload": "Učitaj",
      "Tooltip-Delete": "Obriši",
      "Tooltip-Rename": "Preimenuj",
      "Tooltip-Download": "Preuzmi",
      "Tooltip-Cut": "Izreži",
      "Tooltip-Copy": "Kopiraj",
      "Tooltip-Paste": "Zalijepi",
      "Tooltip-SortBy": "Poredaj po",
      "Tooltip-Refresh": "Osvježi",
      "Tooltip-Selection": "Makni odabir",
      "Tooltip-View": "Pregled",
      "Tooltip-Details": "Detalji",
      "Tooltip-SelectAll": "Označi sve",
      Name: "Ime",
      Size: "Veličina",
      DateModified: "Modificirano",
      DateCreated: "Kreirano",
      Path: "Putanja",
      Modified: "Modificirano",
      Created: "Kreirano",
      Location: "Lokacija",
      Type: "Tip",
      Ascending: "Uzlazno",
      Descending: "Silazno",
      None: "Ništa",
      "View-LargeIcons": "Velike ikone",
      "View-Details": "Detalji",
      Search: "Pretraga",
      "Button-Ok": "OK",
      "Button-Cancel": "Odustani",
      "Button-Yes": "Da",
      "Button-No": "Ne",
      "Button-Create": "Spremi",
      "Button-Save": "Spremi",
      "Header-NewFolder": "Novi folder",
      "Content-NewFolder": "Unesi ime foldera",
      "Header-Rename": "Preimenuj",
      "Content-Rename": "Unesi novo ime",
      "Header-Rename-Confirmation": "Potvrdi promjenu",
      "Header-Delete": "Izbriši",
      "Content-Delete": "Jeste sigurni da želite izbrisati?",
      "Header-Multiple-Delete": "Izbriši",
      "Content-Multiple-Delete": "Jeste sigurni da želite izbrisati?",
      Error: "Greška",
      "Folder-Empty": "Prazni folder",
      "File-Upload": "Dovucite datoteke za učitavanje",
      "Search-Empty": "Nema rezultata",
      "Search-Key": "Pokušajte drugi unos",
      "Access-Denied": "Zabranjen pristup",
      "Access-Details": "Nemate pravo pristupa ovom folderu.",
      "Header-Retry": "Datoteka već postoji",
      "Button-Keep-Both": "Zadrži oboje",
      "Button-Replace": "Zamijeni",
      "Button-Skip": "Preskoči",
      "ApplyAll-Label": "Primjeni za sve stavke",
    },
    richtexteditor: {
      bold: "Podebljano",
      italic: "Kurziv",
      underline: "Podcrtano",
      strikethrough: "Prekiženo",
      orderedList: "Popis s rednim brojevima",
      unorderedList: "Popis s grafičkim oznakama",
      insertLink: "Nova poveznica",
      fontColor: "Boja fonta",
      backgroundColor: "Boja pozadine",
      linkHeader: "Nova poveznica",
      linkOpenInNewWindow: "Otvori poveznicu u novom prozoru",
      linkText: "Tekst poveznice",
      linkTooltipLabel: "Tekst iznad pokazivača miša",
      linkWebUrl: "Web adresa",
    },
    datepicker: {
      today: "Danas",
    },
    dropdowns: {
      noRecordsTemplate: "Nema opcija koje je moguće odabrati",
    },
  },
  en: {
    grid: {
      EmptyRecord: "No data",
      Item: "Item",
      Items: "Items",
      Add: "Add",
      Edit: "Edit",
      SaveButton: "Save",
      CancelButton: "Cancel",
      EditFormTitle: "Edit ",
      AddFormTitle: "New",
      EditOperationAlert: "Niste odabrali niti jedan redak.",
      FilterButton: "Apply",
      ClearButton: "Clear",
      StartsWith: "Starts with",
      EndsWith: "End with",
      Contains: "Contains",
      Equal: "Equal",
      NotEqual: "Not equal",
      LessThan: "Less than",
      LessThanOrEqual: "Less than or equal to",
      GreaterThan: "Greater than",
      GreaterThanOrEqual: "Greater than or equal",
      ChooseDate: "Choose date",
      EnterValue: "Enter value",
      Search: "Search",
      SelectAll: "Tick all",
      Columnchooser: "Columns",
      ChooseColumns: "Choose columns",
      SearchColumns: "Search columns",
      Print: "Print",
      Pdfexport: "PDF",
      Excelexport: "XLS",
    },
    pager: {
      currentPageInfo: "Page {0} od {1}",
      totalItemsInfo: "(Total rows: {0})",
      firstPageTooltip: "Go to first page",
      lastPageTooltip: "Go to last page",
      nextPageTooltip: "Go to next page",
      previousPageTooltip: "Go to previous page",
      pagerDropDown: "Rows per page",
    },
    formValidator: {
      required: "Required field",
      email: "Please enter a valid email address",
      url: "Please enter a valid URL",
      date: "Please enter a valid date",
      number: "Number is required",
      min: "Enter a number between 0 and 100",
      max: "Enter a number between 0 and 100",
      maxLength: "The length must not exceed {0} characters",
      minLength: "The length must not be less than {0} characters",
    },
    filemanager: {
      NewFolder: "New folder",
      Upload: "Upload",
      Delete: "Delete",
      Rename: "Rename",
      Download: "Download",
      Cut: "Cut",
      Copy: "Copy",
      Paste: "Paste",
      SortBy: "Sort by",
      Refresh: "Refresh",
      "Item-Selection": "Selected item",
      "Items-Selection": "Selected items",
      View: "View",
      Details: "Details",
      SelectAll: "Select all",
      Open: "Open",
      "Tooltip-NewFolder": "New folder",
      "Tooltip-Upload": "Upload",
      "Tooltip-Delete": "Delete",
      "Tooltip-Rename": "Rename",
      "Tooltip-Download": "Download",
      "Tooltip-Cut": "Cut",
      "Tooltip-Copy": "Copy",
      "Tooltip-Paste": "Paste",
      "Tooltip-SortBy": "Sort by",
      "Tooltip-Refresh": "Refresh",
      "Tooltip-Selection": "Remove selection",
      "Tooltip-View": "View",
      "Tooltip-Details": "Details",
      "Tooltip-SelectAll": "Select all",
      Name: "Name",
      Size: "Size",
      DateModified: "Modified",
      DateCreated: "Created",
      Path: "Path",
      Modified: "Modified",
      Created: "Created",
      Location: "Location",
      Type: "Type",
      Ascending: "Ascending",
      Descending: "Descending",
      None: "None",
      "View-LargeIcons": "Large icons",
      "View-Details": "Details",
      Search: "Search",
      "Button-Ok": "OK",
      "Button-Cancel": "Cancel",
      "Button-Yes": "Yes",
      "Button-No": "No",
      "Button-Create": "Save",
      "Button-Save": "Save",
      "Header-NewFolder": "New folder",
      "Content-NewFolder": "Enter folder name",
      "Header-Rename": "Rename",
      "Content-Rename": "Enter new name",
      "Header-Rename-Confirmation": "Confirm change",
      "Header-Delete": "Delete",
      "Content-Delete": "Are you sure you want to delete?",
      "Header-Multiple-Delete": "Delete",
      "Content-Multiple-Delete": "Are you sure you want to delete?",
      Error: "Error",
      "Folder-Empty": "Empty folder",
      "File-Upload": "Drag the files to upload",
      "Search-Empty": "No results",
      "Search-Key": "Try another input",
      "Access-Denied": "Access denied",
      "Access-Details": "You do not have permission to access this folder.",
      "Header-Retry": "File already exists",
      "Button-Keep-Both": "Keep both",
      "Button-Replace": "Replace",
      "Button-Skip": "Skip",
      "ApplyAll-Label": "Apply to all items",
    },
    richtexteditor: {
      bold: "Bold",
      italic: "Italic",
      underline: "Underline",
      strikethrough: "Strikethrough",
      orderedList: "List with ordinal numbers",
      unorderedList: "Bulleted list",
      insertLink: "New link",
      fontColor: "Font color",
      backgroundColor: "Background color",
      linkHeader: "New link",
      linkOpenInNewWindow: "Open the link in a new window",
      linkText: "Link text",
      linkTooltipLabel: "Text above the mouse pointer",
      linkWebUrl: "Web address",
    },
    datepicker: {
      today: "Today",
    },
    dropdowns: {
      noRecordsTemplate: "There are no options that can be selected",
    },
  },
});

const App = () => {
  return (
    <BrowserRouter>
      <AuthDataProvider>
        <div className="App">
          <Container fluid>
            <NavBar />
            <Router />
          </Container>
        </div>
      </AuthDataProvider>
    </BrowserRouter>
  );
};

export default App;
