import React, { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import Parse from "parse";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-react-popups";
import { PASSWORD_RESET_ROUTE } from "../../settings/Routes";
import { translations } from "../../settings/translation";
import { Language } from "../../settings/Enums";

interface FormValidation {
  usernameError?: boolean;
}

interface Props {
  dialogState: boolean;
  language?: Language;
  handleDialogState: (newState: boolean) => void;
}

export const ForgottenPasswordDialog: React.FC<Props> = (
  props: Props
): ReactElement => {
  const history = useHistory();
  const { dialogState, language, handleDialogState } = props;
  const [selectedMail, setSelectedMail] = useState<string | undefined>(
    undefined
  );
  const [errors, setErrors] = useState<FormValidation>({});

  const handleForgottenPassword = async (): Promise<void> => {
    if (selectedMail) {
      Parse.User.requestPasswordReset(selectedMail)
        .then(() => {
          history.push(PASSWORD_RESET_ROUTE);
        })
        .catch(() => {
          setErrors({ usernameError: true });
        });
    }
  };

  const buttons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].sendEmail,
        cssClass: "e-flat",
        isPrimary: true,
        disabled: !(selectedMail && selectedMail !== ""),
      },
      click: () => handleForgottenPassword(),
    },
    {
      buttonModel: {
        content: translations[language || Language.Hr].cancel,
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => handleDialogState(false),
    },
  ];

  return (
    <DialogComponent
      isModal={true}
      width={450}
      minHeight={400}
      visible={dialogState}
      header={translations[language || Language.Hr].forgotPassword}
      close={(): void => handleDialogState(false)}
      showCloseIcon={true}
      closeOnEscape={true}
      buttons={buttons}
    >
      <div className="dialog-content">
        <Row className={"box-form"}>
          <Col>
            <Form onSubmit={handleForgottenPassword} className={"formLogin"}>
              <Form.Group>
                <Form.Label htmlFor="username">{translations[language || Language.Hr].email}</Form.Label>
                <Form.Control
                  required
                  id="username"
                  type="text"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    setSelectedMail(event.target.value);
                  }}
                />
                <Form.Control.Feedback
                  className={errors.usernameError ? "displayErrors" : "noError"}
                >
                  {translations[language || Language.Hr].checkEnteredEmail}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </div>
    </DialogComponent>
  );
};
