import {
  EditSettingsModel,
  SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import Parse from "parse";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuthDataContext } from "../../components/authDataProvider";
import { BreadCrumbsNavigation } from "../../components/breadCrumbsNavigation";
import { DataGrid } from "../../components/dataGrid";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { ProjectBaseModel, ProjectNumberModel } from "../../models/ProjectBaseModel";
import { GetRelationByName } from "../../services/GenericServices";
import { applyProjectDocumentTemplate } from "../../services/ProjectDocumentServices";
import { getNumberOfProjectsByOrganization } from "../../services/ProjectServices";
import { GetProjectTemplateById } from "../../services/ProjectTemplateServices";
import { createTasksFromTemplate } from "../../services/TaskServices";
import { Language, ParseClassName, Roles } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {}

export const Projects: React.FC<Props> = (props: Props): ReactElement => {
  const [client, setClient] = useState<any>(undefined);
  const [columns, setColumns] = useState<DataGridColumnModel[]>([]);
  const [editSettings, setEditSettings] = useState<EditSettingsModel>({
    allowEditing: false,
    allowAdding: false,
    mode: "Dialog",
  });
  const { language } = useAuthDataContext();
  const [projects, setProjects] = useState<ProjectBaseModel[]>([]);
  const [numberOfProjects, setNumberOfProjects] = useState<
    ProjectNumberModel | undefined
  >();

  const history = useHistory();
  const { selectedOrganization } = useAuthDataContext();

  useEffect(() => {
    getProjectsCount();
  }, [selectedOrganization]);

  const getProjectsCount = async (): Promise<void> => {
    const numbers = await getNumberOfProjectsByOrganization(
      selectedOrganization
    );
    setNumberOfProjects(numbers);
  };

  const getClientIdFromRoute = (): string | undefined => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 4 &&
      urlParts[1] === "clients" &&
      urlParts[3] === "projects"
    ) {
      return urlParts[2];
    }
  };

  const clientId = getClientIdFromRoute();

  const defaultColumns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].title,
      validationRules: {
        required: true,
      },
    },
    {
      field: "code",
      headerText: translations[language || Language.Hr].projectCode,
    },
    {
      field: "shortCode",
      headerText: translations[language || Language.Hr].shortCode,
      validationRules: {
        required: true,
      },
    },
    {
      field: "signDate",
      headerText: translations[language || Language.Hr].signDate,
      validationRules: {
        required: true,
      },
      type: "date",
      format: "dd.MM.yyyy.",
      filter: {
        type: "Menu",
        params: {
          format: "dd.MM.yyyy.",
        },
      },
    },
    {
      field: "startDate",
      headerText: translations[language || Language.Hr].startOfImplementation,
      validationRules: {
        required: true,
      },
      type: "date",
      format: "dd.MM.yyyy.",
      filter: {
        type: "Menu",
        params: {
          format: "dd.MM.yyyy.",
        },
      },
    },
    {
      field: "endDate",
      headerText: translations[language || Language.Hr].endOfimplementation,
      validationRules: {
        required: true,
      },
      type: "date",
      format: "dd.MM.yyyy.",
      filter: {
        type: "Menu",
        params: {
          format: "dd.MM.yyyy.",
        },
      },
    },
    {
      field: "totalCost",
      headerText: translations[language || Language.Hr].granted,
      format: "N2",
    },
    {
      field: "totalCostContract",
      headerText: translations[language || Language.Hr].agreed,
      format: "N2",
    },
    {
      field: "sumDifference",
      headerText: translations[language || Language.Hr].difference,
      format: "N2",
    },
    {
      field: "totalSubvention",
      headerText: translations[language || Language.Hr].subvention,
      format: "N2",
    },
    {
      field: "totalPayment",
      headerText: translations[language || Language.Hr].paid,
      format: "N2",
    },
    {
      field: "isRegistratedForVatDisplay",
      headerText: translations[language || Language.Hr].inTheVatSystem,
      filter: {
        type: "CheckBox",
      },
    },
  ];

  const getDetailsColumn = (clientId?: string): DataGridColumnModel => {
    return {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      // this has to have clientId check because otherwise it won't update details column
      width: clientId ? 390 : 300,
      template: (props): ReactElement => (
        <div>
          <div
            className="e-btn"
            onClick={(): void =>
              history.push(`/projects/${props.id}/documents`)
            }
          >
            {translations[language || Language.Hr].documents}
          </div>
          <div
            className="e-btn"
            style={{ marginLeft: 15 }}
            onClick={(): void => history.push(`/projects/${props.id}/tasks`)}
          >
            {translations[language || Language.Hr].tasks}
          </div>
          <div
            className="e-btn"
            style={{ marginLeft: 15 }}
            onClick={(): void =>
              history.push(`/projects/${props.id}/activities`)
            }
          >
            {translations[language || Language.Hr].activities}
          </div>
          {clientId && (
            <div
              className="e-btn"
              style={{ marginLeft: 15 }}
              onClick={(): void =>
                history.push(`/clients/${clientId}/projects/${props.id}/users`)
              }
            >
              {translations[language || Language.Hr].users}
            </div>
          )}
        </div>
      ),
    };
  };

  const getEditSettings = (clientId?: string): EditSettingsModel => {
    return {
      allowEditing: !!clientId,
      allowAdding:
        !!clientId &&
        numberOfProjects &&
        numberOfProjects.currentProjectNumber <
          numberOfProjects.maxProjectNumber,
      mode: "Dialog",
    };
  };

  useEffect(() => {
    setColumns([...defaultColumns, getDetailsColumn(clientId)]);
    setEditSettings(getEditSettings(clientId));
    fetchProjects();
  }, [clientId, numberOfProjects]);

  useEffect(() => {
    fetchProjects();
  }, [selectedOrganization]);

  const fetchProjects = async (): Promise<void> => {
    if (!selectedOrganization) return;
    const projectsList: ProjectBaseModel[] = [];

    const parseProject = Parse.Object.extend("Project");
    const projectQuery = new Parse.Query(parseProject)
      .include("client.organization")
      .limit(10000);

    if (clientId) {
      const parseClient = Parse.Object.extend("Client");
      const clientQuery = new Parse.Query(parseClient).limit(10000);

      const client = await clientQuery.get(clientId);

      if (!client) return;

      setClient(client);
      projectQuery.equalTo("client", client);
    }

    const projects = await projectQuery.find();

    if (!(projects && projects.length > 0)) return;

    projects.forEach((project) => {
      if (
        project.attributes &&
        project.id &&
        project.attributes.shortCode &&
        project.attributes.startDate &&
        project.attributes.endDate &&
        project.attributes.signDate &&
        project.attributes.shortTitle &&
        project.attributes.code &&
        project.attributes.role &&
        project.attributes.title &&
        project.attributes.client &&
        project.attributes.client.attributes &&
        project.attributes.client.attributes.organization &&
        project.attributes.client.attributes.organization.id ===
          selectedOrganization.id
      ) {
        projectsList.push({
          id: project.id,
          shortCode: project.attributes.shortCode,
          startDate: project.attributes.startDate,
          endDate: project.attributes.endDate,
          signDate: project.attributes.signDate,
          shortTitle: project.attributes.shortTitle,
          code: project.attributes.code,
          role: project.attributes.role.id,
          title: project.attributes.title,
          client: project.attributes.client.id,
          roleModel: project.attributes.role,
          clientModel: project.attributes.client,
          totalCost: project.attributes.totalCost || 0,
          totalSubvention: project.attributes.totalSubvention || 0,
          totalCostContract: project.attributes.totalCostContract || 0,
          totalPayment: project.attributes.totalPayment || 0,
          sumDifference:
            (project.attributes.totalCost || 0) -
            (project.attributes.totalCostContract || 0),
          isRegistratedForVat: project.attributes.isRegistratedForVat,
          isRegistratedForVatDisplay: project.attributes.isRegistratedForVat
            ? translations[language || Language.Hr].yes
            : translations[language || Language.Hr].no,
        });
      }
    });

    setProjects(projectsList);
  };

  const updateProject = async (updatedProject: ProjectBaseModel) => {
    const project = setPropertyToProject(updatedProject);
    project.set("role", updatedProject.roleModel);
    project.set("client", updatedProject.clientModel);

    const response = await project.save();

    if (response) {
      await fetchProjects();
    }
  };

  const createProject = async (newProject: ProjectBaseModel): Promise<void> => {
    if (!selectedOrganization) return;

    const project = setPropertyToProject(newProject);
    project.set("client", newProject.clientModel || client);

    const role = await createRole(newProject);

    if (!role) return;

    project.set("role", role);

    const projectRoleName = `PROJECT_${project.attributes.shortCode}`;
    const employeeRoleName = `${selectedOrganization.attributes.shortTitle}_EMPLOYEE`;
    const adminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;

    const acl = new Parse.ACL();
    acl.setRoleReadAccess(Roles.Admin, true);
    acl.setRoleWriteAccess(Roles.Admin, true);
    acl.setRoleReadAccess(adminRoleName, true);
    acl.setRoleWriteAccess(adminRoleName, true);
    acl.setRoleReadAccess(projectRoleName, true);
    acl.setRoleWriteAccess(projectRoleName, false);
    acl.setRoleReadAccess(employeeRoleName, true);
    acl.setRoleWriteAccess(employeeRoleName, true);

    project.setACL(acl);

    const response = await project.save();

    if (response && response.attributes && newProject.projectTemplate) {
      const projectTemplate = await GetProjectTemplateById(
        newProject.projectTemplate
      );
      const taskTemplates = await GetRelationByName(
        projectTemplate,
        "taskTemplates"
      );
      const projectFileTemplates = await GetRelationByName(
        projectTemplate,
        "projectFileTemplates"
      );

      if (taskTemplates && taskTemplates.length > 0) {
        createTasksFromTemplate(taskTemplates, response, selectedOrganization);
      }

      if (projectFileTemplates && projectFileTemplates.length > 0) {
        projectFileTemplates.forEach(async (template: any) => {
          await applyProjectDocumentTemplate(
            [template.id],
            response.id,
            response.attributes.shortCode
          );
        });
      }
    }

    if (response) {
      if (numberOfProjects) {
        setNumberOfProjects({
          ...numberOfProjects,
          currentProjectNumber: numberOfProjects.currentProjectNumber + 1,
        });
      }
      await fetchProjects();
    }
  };

  const setPropertyToProject = (projectValue: ProjectBaseModel) => {
    const projectObject = Parse.Object.extend("Project");
    const project = new projectObject();

    project.set("id", projectValue.id);
    project.set("title", projectValue.title);
    //this is due to shortTitle is removed from form, this way we don't have to refactor the whole stack
    project.set("shortTitle", projectValue.shortCode);
    project.set("code", projectValue.code);
    project.set("shortCode", projectValue.shortCode);
    project.set("startDate", projectValue.startDate);
    project.set("endDate", projectValue.endDate);
    project.set("signDate", projectValue.signDate);
    project.set("isRegistratedForVat", projectValue.isRegistratedForVat);

    return project;
  };

  const createRole = async (project: ProjectBaseModel): Promise<any> => {
    const roleName = `PROJECT_${project.shortCode}`;
    const adminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;

    const roleACL = new Parse.ACL();

    roleACL.setRoleReadAccess(Roles.Admin, true);
    roleACL.setRoleWriteAccess(Roles.Admin, true);
    roleACL.setRoleReadAccess(adminRoleName, true);
    roleACL.setRoleWriteAccess(adminRoleName, true);
    roleACL.setRoleReadAccess(roleName, true);
    roleACL.setRoleWriteAccess(roleName, false);

    const role = new Parse.Role(roleName, roleACL);
    return await role.save();
  };

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "title", direction: "Ascending" }],
  };

  return (
    <section id="project">
      {clientId && (
        <BreadCrumbsNavigation
          breadCrumbTemplates={[
            {
              url: "clients",
              title: translations[language || Language.Hr].clients,
            },
          ]}
        />
      )}
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={projects}
            columnProperties={columns}
            useDialogTemplate={ParseClassName.Project}
            updateItem={updateProject}
            createItem={createProject}
            fetchDataAfterCatchError={fetchProjects}
            showOnlyCustomToolbarOption={!clientId}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
            autoFitColumns={true}
          />
        </Col>
      </Row>
    </section>
  );
};
