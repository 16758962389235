import Parse from "parse";
import { PersonBaseModel } from "../models/PersonBaseModel";

export const GetPersonByUserId = async (
  userId: string
): Promise<PersonBaseModel | undefined> => {
  const userPointer = {
    __type: "Pointer",
    className: "_User",
    objectId: userId,
  };
  const parsePerson = new Parse.Query("Person").limit(10000);
  const query = parsePerson.equalTo("parseUser", userPointer);
  const response = await query.first();
  if (!response) return;
  const person = {
    id: response.id,
    ime: response.attributes.firstName,
    lastName: response.attributes.lastName,
    elektronickaPosta: response.attributes.email,
    phone: response.attributes.phone,
    parseUser: response.attributes.parseUser,
  };
  return person;
};

export const CreatePerson = async (
  personModel: PersonBaseModel,
  acl: Parse.ACL
): Promise<any> => {
  const personObject = Parse.Object.extend("Person");
  const person = new personObject();

  person.set("firstName", personModel.ime);
  person.set("lastName", personModel.lastName);
  person.set("phone", personModel.phone);
  person.set("email", personModel.elektronickaPosta);
  person.set("parseUser", personModel.parseUser);
  person.setACL(acl);

  const personResponse = await person.save();

  return personResponse;
};

export const UpdatePerson = async (
  personModel: PersonBaseModel
): Promise<any | undefined> => {
  const parsePerson = new Parse.Query("Person").limit(10000);
  const query = parsePerson.equalTo("objectId", personModel.id);

  const person = await query.first();

  if (!person) return;

  person.set("firstName", personModel.ime);
  person.set("lastName", personModel.lastName);
  person.set("phone", personModel.phone);
  person.set("email", personModel.elektronickaPosta);
  person.set("parseUser", personModel.parseUser);

  const personResponse = person.save();
  return personResponse;
};

export const UpdatePersonByUser = async (
  personModel: PersonBaseModel
): Promise<any | undefined> => {
  const parsePerson = new Parse.Query("Person").limit(10000);
  const query = parsePerson.equalTo("parseUser", personModel.parseUser);

  const person = await query.first();

  if (!person) return;

  person.set("firstName", personModel.ime);
  person.set("lastName", personModel.lastName);
  person.set("phone", personModel.phone);
  person.set("email", personModel.elektronickaPosta);
  person.set("parseUser", personModel.parseUser);

  const personResponse = person.save();
  return personResponse;
};
