import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { AlarmTemplateBaseModel } from "../../models/AlarmTemplateBaseModel";
import { GetTaskMilestones } from "../../services/TaskMilestoneServices";
import { GetTimeUnits } from "../../services/TimeUnitServices";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  data: AlarmTemplateBaseModel;
  language?: Language;
}

export const AlarmTemplateDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const [taskMilestones, setTaskMilestones] = useState<
    { [key: string]: Object }[]
  >([]);
  const [selectedTaskMilestone, setSelectedTaskMilestone] = useState<
    string | undefined
  >(undefined);
  const [timeUnits, setTimeUnits] = useState<{ [key: string]: Object }[]>([]);
  const [selectedTimeUnit, setSelectedTimeUnit] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    fetchTaskMilestones();
    fetchTimeUnits();
  }, []);

  const fetchTaskMilestones = async (): Promise<void> => {
    const taskMilestones = await GetTaskMilestones();

    if (!taskMilestones) return;

    const mappedTaskMilestones: { [key: string]: Object }[] = [];

    taskMilestones.forEach((taskMilestone) => {
      mappedTaskMilestones.push({
        text: language && language !== Language.Hr ? taskMilestone.title_en : taskMilestone.title,
        value: taskMilestone.id,
      });
    });

    setTaskMilestones(mappedTaskMilestones);

    setSelectedTaskMilestone(
      data.taskMilestone && data.taskMilestone.id
        ? data.taskMilestone.id
        : taskMilestones[0].id
    );
  };

  const fetchTimeUnits = async (): Promise<void> => {
    const timeUnits = await GetTimeUnits();

    if (!timeUnits) return;

    const mappedTimeUnits: { [key: string]: Object }[] = [];

    timeUnits.forEach((timeUnit) => {
      mappedTimeUnits.push({
        text:
          language && language !== Language.Hr
            ? timeUnit.title_en
            : timeUnit.title,
        value: timeUnit.id,
      });
    });

    setTimeUnits(mappedTimeUnits);

    setSelectedTimeUnit(
      data.timeUnit && data.timeUnit.id ? data.timeUnit.id : timeUnits[0].id
    );
  };

  const { data, language } = props;
  const fields: object = { text: "text", value: "value" };

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="timeQuantity">
          <Form.Label>
            {translations[language || Language.Hr].number}
          </Form.Label>
          <Form.Control
            type="number"
            name="timeQuantity"
            defaultValue={data.timeQuantity}
            autoComplete="off"
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="timeUnit">
          <Form.Label>
            {translations[language || Language.Hr].timeUnit}
          </Form.Label>
          <DropDownListComponent
            name="timeUnit"
            fields={fields}
            dataSource={timeUnits}
            value={selectedTimeUnit}
            select={(item) => setSelectedTimeUnit(item?.itemData.value)}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          controlId="isBeforeTaskMilestoneValidation"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: "18px",
          }}
        >
          <Form.Check
            label={translations[language || Language.Hr].beforeReferentDate}
            type="checkbox"
            name="isBeforeTaskMilestone"
            defaultChecked={!!data.isBeforeTaskMilestone}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="taskMilestone">
          <Form.Label>
            {translations[language || Language.Hr].referentDate}
          </Form.Label>
          <DropDownListComponent
            name="taskMilestone"
            fields={fields}
            dataSource={taskMilestones}
            value={selectedTaskMilestone}
            select={(item) => setSelectedTaskMilestone(item?.itemData.value)}
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
