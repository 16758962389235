import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { ProjectCostContractBaseModel } from "../../models/ProjectCostContractBaseModel";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import {
  fetchTags,
  mapTagsToDropdown,
} from "../fetching/CostContractsServiceHelper";

interface Props {
  data: ProjectCostContractBaseModel;
  language?: Language;
}

export const ProjectCostContractDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const { data, language } = props;
  const [tags, setTags] = useState<{ [key: string]: Object }[]>([]);
  const [unitPrice, setUnitPrice] = useState<number>(data.unitPrice || 0);
  const [numberOfUnits, setNumberOfUnits] = useState<number>(
    data.numberOfUnits || 0
  );
  const [vatRate, setVateRate] = useState<number>((data.vatRate || 0) * 100);
  const [tagId, setTagId] = useState<string | undefined>(data.tagId);

  useEffect(() => {
    getTags();
  }, []);

  const getTags = async (): Promise<void> => {
    const types = await fetchTags();
    const mappedTypes = mapTagsToDropdown(types);
    setTags(mappedTypes);
  };

  const fields: object = { text: "text", value: "value" };

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="contractorValidation">
          <Form.Label>
            {translations[language || Language.Hr].suppllier}
          </Form.Label>
          <Form.Control
            type="text"
            name="contractor"
            defaultValue={data.contractor}
            autoComplete="off"
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="tagIdValidation">
          <Form.Label>
            {translations[language || Language.Hr].designationProcurmentPlan}
          </Form.Label>
          <DropDownListComponent
            fields={fields}
            dataSource={tags}
            value={tagId}
            placeholder={
              translations[language || Language.Hr].chooseDesignation
            }
            select={(value) => setTagId(value?.itemData?.value)}
          />
          {/* Ovo je za sad quickfix da imamo validaciju na komponenti koja nije defaultno od react forme control */}
          <Form.Control
            type="text"
            name="tagId"
            value={tagId?.toString() || ""}
            hidden
            autoComplete="off"
            required
          />
          <Form.Control.Feedback type="invalid">
            {translations[language || Language.Hr].requiredField}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="unitPriceValidation">
          <Form.Label>
            {translations[language || Language.Hr].unitPrice}
          </Form.Label>
          <Form.Control
            type="number"
            name="unitPrice"
            defaultValue={data.unitPrice}
            onBlur={(e: React.FocusEvent<HTMLInputElement>): void =>
              setUnitPrice(e?.target?.value ? parseFloat(e.target.value) : 0)
            }
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="numberOfUnitsValidation">
          <Form.Label>
            {translations[language || Language.Hr].quantity}
          </Form.Label>
          <Form.Control
            type="number"
            name="numberOfUnits"
            defaultValue={data.numberOfUnits}
            onBlur={(e: React.FocusEvent<HTMLInputElement>): void =>
              setNumberOfUnits(
                e?.target?.value ? parseFloat(e.target.value) : 0
              )
            }
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="realTotalNetPriceValidation">
          <Form.Label>
            {translations[language || Language.Hr].overallAcceptable}
          </Form.Label>
          <Form.Control
            type="number"
            name="realTotalNetPrice"
            value={(unitPrice * numberOfUnits).toFixed(2)}
            disabled
            autoComplete="off"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="vatRateValidation">
          <Form.Label>
            {translations[language || Language.Hr].vatRate}
          </Form.Label>
          <Form.Control
            type="number"
            name="vatRate"
            min={0}
            max={100}
            defaultValue={vatRate}
            onBlur={(e: React.FocusEvent<HTMLInputElement>): void =>
              setVateRate(e?.target?.value ? parseFloat(e.target.value) : 0)
            }
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="vatAmountValidation">
          <Form.Label>
            {translations[language || Language.Hr].vatAmount}
          </Form.Label>
          <Form.Control
            type="number"
            name="vatAmount"
            value={((unitPrice * numberOfUnits * vatRate) / 100).toFixed(2)}
            disabled
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="realTotalVatPriceValidation">
          <Form.Label>
            {translations[language || Language.Hr].inTotalWithVAT.toUpperCase()}
          </Form.Label>
          <Form.Control
            type="number"
            name="realTotalVatPrice"
            value={(unitPrice * numberOfUnits * (1 + vatRate / 100)).toFixed(2)}
            disabled
            autoComplete="off"
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
