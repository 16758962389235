import React, { ReactElement, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import Parse from "parse";
import { DataGrid } from "../../components/dataGrid";
import { TagBaseModel } from "../../models/TagBaseModel";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { Language, ParseClassName } from "../../settings/Enums";
import { useAuthDataContext } from "../../components/authDataProvider";
import { translations } from "../../settings/translation";

interface Props {}

export const Tags: React.FC<Props> = (props: Props): ReactElement => {
  const { language } = useAuthDataContext();
  const [tags, setTags] = useState<TagBaseModel[]>([]);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async (): Promise<void> => {
    const tagsList: TagBaseModel[] = [];

    const tag = Parse.Object.extend("Tag");
    const tagListQuery = new Parse.Query(tag).limit(10000);

    const results = await tagListQuery.find();
    if (results && results.length > 0) {
      results.forEach((result) => {
        if (result.attributes && result.id && result.attributes.title) {
          tagsList.push({
            id: result.id,
            title: result.attributes.title,
          });
        }
      });
    }
    setTags(tagsList);
  };

  const updateTag = async (updatedTag: TagBaseModel) => {
    const tagObject = Parse.Object.extend("Tag");
    const tag = new tagObject();

    tag.set("id", updatedTag.id);
    tag.set("title", updatedTag.title);

    const response = await tag.save();

    if (response) {
      await fetchTags();
    }
  };

  const createTag = async (newTag: TagBaseModel): Promise<void> => {
    const tagObject = Parse.Object.extend("Tag");
    const tag = new tagObject();

    tag.set("title", newTag.title);

    const response = await tag.save();

    if (response) {
      await fetchTags();
    }
  };

  const columnProperties: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].title,
      validationRules: {
        required: true,
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "title", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    mode: "Dialog",
  };

  return (
    <section id="tag">
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={tags}
            columnProperties={columnProperties}
            updateItem={updateTag}
            createItem={createTag}
            fetchDataAfterCatchError={fetchTags}
            useDialogTemplate={ParseClassName.Tag}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
    </section>
  );
};
