import { KanbanColumnModel } from "../../models/KanbanColumnModel";
import { UserBaseModel } from "../../models/UserBaseModel";
import { GetFirstObjectById } from "../../services/GenericServices";
import {
  GetRoleRelationByName,
  GetRoleById,
} from "../../services/RoleServices";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

export const fetchProjectsUsersHelper = async (
  projectId: string
): Promise<UserBaseModel[] | undefined> => {
  if (projectId === "") return;

  const usersList: UserBaseModel[] = [];
  const projectResult = await GetFirstObjectById("Project", projectId);

  if (
    !(
      projectResult &&
      projectResult.attributes &&
      projectResult.attributes.role &&
      projectResult.attributes.role.id
    )
  )
    return;

  const roleResult = await GetRoleById(projectResult.attributes.role.id);

  if (!(roleResult && roleResult.attributes)) return;
  const roleUsers = await GetRoleRelationByName(roleResult, "users");

  if (!(roleUsers && roleUsers.length > 0)) return;

  roleUsers.forEach((roleUser) => {
    if (roleUser.attributes && roleUser.id) {
      usersList.push({
        id: roleUser.id,
        // TODO: check if this is true, if Parse really returns email undefined if equals to username
        email: roleUser.attributes.email || roleUser.attributes.username,
        username: roleUser.attributes.username,
      });
    }
  });

  return usersList;
};

export const fetchDaysOfTheWeekHelper = (
  language?: Language
): KanbanColumnModel[] => {
  return [
    {
      keyField: "1",
      headerText: translations[language || Language.Hr].monday,
    },
    {
      keyField: "2",
      headerText: translations[language || Language.Hr].tuesday,
    },
    {
      keyField: "3",
      headerText: translations[language || Language.Hr].wednesday,
    },
    {
      keyField: "4",
      headerText: translations[language || Language.Hr].thursday,
    },
    {
      keyField: "5",
      headerText: translations[language || Language.Hr].friday,
    },
    {
      keyField: "6",
      headerText: translations[language || Language.Hr].saturday,
    },
    {
      keyField: "0",
      headerText: translations[language || Language.Hr].sunday,
    },
  ];
};
