import React, {
  ReactElement,
  useState,
  useEffect,
  forwardRef,
  useRef,
} from "react";
import { Col, Form } from "react-bootstrap";
import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject,
} from "@syncfusion/ej2-react-dropdowns";
import {
  HtmlEditor,
  Inject as InjectRTE,
  Link,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { TaskBaseModel, TaskFormBaseModel } from "../../models/TaskBaseModel";
import { KanbanColumnModel } from "../../models/KanbanColumnModel";
import { Language, TaskColumnNames } from "../../settings/Enums";
import { toolbarSettings } from "../../settings/Constants";
import { fetchProjectsUsersHelper } from "../fetching/FetchingHelper";
import "./KanbanDialogTemplate.css";
import { translations } from "../../settings/translation";

interface Props {
  data: TaskBaseModel;
  language?: Language;
  formData: TaskFormBaseModel;
  taskStatusesList: KanbanColumnModel[];
  updateTask: (task: TaskFormBaseModel) => void;
  startValidation: boolean;
  onValidationReset: (result: boolean) => void;
}

export const KanbanDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const [users, setUsers] = React.useState<{ [key: string]: Object }[]>([]);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    fetchUsers();
    if (props.startValidation) {
      triggerValidation();
    }
  }, [props.startValidation]);

  const { data, language, formData } = props;

  const fetchUsers = async (): Promise<void> => {
    const dropdownItems: { [key: string]: Object }[] = [];

    const result = await fetchProjectsUsersHelper(data.projectId);

    if (!(result && result.length > 0)) return;

    result.forEach((user) => {
      dropdownItems.push({
        value: user.id,
        text: `${user.email}`,
      });
    });

    setUsers(dropdownItems);
  };

  const updateFormData = (fieldId: string, args?: any): void => {
    const { updateTask } = props;
    const argsValue = args && args.value;
    const argsItemDataValue = args && args.itemData && args.itemData.value;
    const argsModelValue = args && args.model && args.model.value;
    const argsTargetValue = (args && args.target && args.target.value) || "";
    switch (fieldId) {
      case TaskColumnNames.Title:
        if (argsTargetValue || argsTargetValue == "") {
          updateTask({ ...formData, title: argsTargetValue });
        }
        break;
      case TaskColumnNames.Desciption:
        if (argsTargetValue || argsTargetValue == "") {
          updateTask({ ...formData, description: argsTargetValue });
        }
        break;
      case TaskColumnNames.AvailableSince:
        if (argsModelValue) {
          updateTask({
            ...formData,
            availableSince: new Date(argsModelValue),
          });
        }
        break;
      case TaskColumnNames.Deadline:
        if (argsModelValue) {
          updateTask({ ...formData, deadline: new Date(argsModelValue) });
        }
        break;
      case TaskColumnNames.Assignees:
        if (argsValue) {
          updateTask({ ...formData, assignees: argsValue });
        }
        break;
      case TaskColumnNames.RTE:
        updateTask({ ...formData, rteBody: argsValue });
        break;
      case TaskColumnNames.TaskStatus:
        if (argsItemDataValue) {
          updateTask({ ...formData, taskStateId: argsItemDataValue });
        }
        break;
      default:
        break;
    }
  };

  const triggerValidation = (): void => {
    const form: any = formRef.current;
    setValidated(true);
    const result = form.checkValidity();
    props.onValidationReset(result);
  };

  const mapStatuses = (
    taskStatusesList: KanbanColumnModel[]
  ): { [key: string]: Object }[] => {
    const dropdownItems: { [key: string]: Object }[] = [];
    taskStatusesList.map((taskStatus: KanbanColumnModel) => {
      if (!taskStatus.keyField || !taskStatus.headerText) return;
      dropdownItems.push({
        value: taskStatus.keyField,
        text: taskStatus.headerText,
      });
    });
    return dropdownItems;
  };

  const fields: object = { text: "text", value: "value" };
  const statuses: { [key: string]: Object }[] = mapStatuses(
    props.taskStatusesList
  );

  const formRef = useRef(null);

  return (
    <Form noValidate validated={validated} ref={formRef}>
      <Form.Row>
        <Form.Group as={Col} controlId="titleValidation">
          <Form.Label>{translations[language || Language.Hr].title}</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={formData.title}
            onChange={(value: any) => updateFormData("title", value)}
            autoComplete="off"
            required
          />
          <Form.Control.Feedback type="invalid">
            {translations[language || Language.Hr].requiredField}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>
            {translations[language || Language.Hr].project}
          </Form.Label>
          <Form.Control
            type="text"
            disabled
            defaultValue={data.projectShortTitle}
            autoComplete="off"
          />
          <Form.Control
            type="text"
            name="project"
            disabled
            hidden
            defaultValue={data.projectShortCode}
            autoComplete="off"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="descriptionValidation">
          <Form.Label>
            {translations[language || Language.Hr].description}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            value={formData.description}
            onChange={(value: any) => updateFormData("description", value)}
            autoComplete="off"
            required
          />
          <Form.Control.Feedback type="invalid">
            {translations[language || Language.Hr].requiredField}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="availableSinceValidation">
          <Form.Label>
            {translations[language || Language.Hr].availableFrom}
          </Form.Label>
          <DatePickerComponent
            name="availableSince"
            format="dd.MM.yyyy"
            allowEdit={false}
            showClearButton={false}
            value={formData.availableSince}
            blur={(value) => updateFormData("availableSince", value)}
          />
          {/* Ovo je za sad quickfix da imamo validaciju na komponenti koja nije defaultno od react forme control */}
          <Form.Control
            type="text"
            value={formData.availableSince?.toString() || ""}
            hidden
            autoComplete="off"
            required
          />
          <Form.Control.Feedback type="invalid">
            {translations[language || Language.Hr].requiredField}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="deadlineValidation">
          <Form.Label>
            {translations[language || Language.Hr].deadline}
          </Form.Label>
          <DatePickerComponent
            name="deadline"
            format="dd.MM.yyyy"
            allowEdit={false}
            showClearButton={false}
            value={formData.deadline}
            blur={(value) => updateFormData("deadline", value)}
          />
          {/* Ovo je za sad quickfix da imamo validaciju na komponenti koja nije defaultno od react forme control */}
          <Form.Control
            type="text"
            value={formData.deadline?.toString() || ""}
            hidden
            autoComplete="off"
            required
          />
          <Form.Control.Feedback type="invalid">
            {translations[language || Language.Hr].requiredField}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="selectedStatusValidation">
          <Form.Label>
            {translations[language || Language.Hr].status}
          </Form.Label>
          <DropDownListComponent
            fields={fields}
            dataSource={statuses}
            value={formData.taskStateId}
            placeholder={translations[language || Language.Hr].chooseStatus}
            select={(value) => updateFormData("taskStatus", value)}
          />
          {/* Ovo je za sad quickfix da imamo validaciju na komponenti koja nije defaultno od react forme control */}
          <Form.Control
            type="text"
            value={formData.taskStateId?.toString() || ""}
            hidden
            autoComplete="off"
            required
          />
          <Form.Control.Feedback type="invalid">
            {translations[language || Language.Hr].requiredField}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="assigneesValidation">
          <Form.Label>
            {translations[language || Language.Hr].responsible}
          </Form.Label>
          <MultiSelectComponent
            dataSource={users}
            value={formData.assignees}
            placeholder={
              translations[language || Language.Hr].chooseResponsibleUsers
            }
            change={(value) => updateFormData("assignees", value)}
            closePopupOnSelect={false}
            mode="Box"
            name="assignees"
          >
            <Inject services={[CheckBoxSelection]} />
          </MultiSelectComponent>
          {/* Ovo je za sad quickfix da imamo validaciju na komponenti koja nije defaultno od react forme control */}
          <Form.Control
            type="text"
            value={formData.assignees?.toString() || ""}
            hidden
            autoComplete="off"
            // required
          />
          {/* <Form.Control.Feedback type="invalid">
            Odaberite barem jednog zaduženog korisnika
          </Form.Control.Feedback> */}
        </Form.Group>
      </Form.Row>
      {!formData.id && (
        <Form.Row>
          <Form.Group as={Col} controlId="assigneesValidation">
            <Form.Label>
              {translations[language || Language.Hr].firstMessage}
            </Form.Label>
            <RichTextEditorComponent
              height={420}
              toolbarSettings={toolbarSettings}
              change={(value) => updateFormData("rte", value)}
            >
              <InjectRTE services={[Toolbar, Link, HtmlEditor]} />
            </RichTextEditorComponent>
          </Form.Group>
        </Form.Row>
      )}
    </Form>
  );
};
