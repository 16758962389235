export const translations = {
    hr: {
        accordingToTheContract: "Sukladno ugovoru o financiranju",
        activities: "Aktivnosti",
        activity: "Aktivnost",
        addDocument: "Dodaj dokument",
        addDocumentTemplateToProject: "Dodaj predložak dokumenta na predložak projekta",
        addExisting: "Dodaj postojeći",
        addExistingContact: "Dodaj postojeći kontakt drugih partnera ovom partneru",
        addExistingUserToOrganization: "Dodaj postojećeg korisnika ovoj organizaciji",
        addTask: "Dodaj zadatak",
        addTaskTemplateToProject: "Dodaj predložak zadatka na predložak projekta",
        addUser: "Dodaj korisnika",
        addUserToProject: "Dodaj korisnika na projekt",
        administrator: "Administrator",
        administrators: "Administratori",
        after: "Nakon",
        afterReferentDate: "Nakon referentnog datuma",
        agreed: "Ugovoreno",
        alarm: "Alarm",
        alarms: "Alarmi",
        alarmTemplate: "Predložak alarma",
        allow: "Dozvoli",
        alreadyExists: "već postoji",
        amountOfCoFinancing: "Iznos sufinanciranja",
        amountWithoutVat: "Iznos bez PDV-a",
        applicationUser: "Korisnik aplikacije",
        apply: "Primjeni",
        areYouSure: "Jeste li sigurni?",
        automaticallyCreateTask: "Automatski kreiraj zadatak",
        automaticRenameNote: "NAPOMENA: Sve datoteke koje učitate u sustav bit će automatski preimenovane u naziv poddirektorija u koji datoteku postavljate. Ukoliko želite zadržati izvorni naziv datoteke s Vašeg računala tada datoteku morate postaviti u glavni direktorij projekta, a ne u specifičan poddirektorij! Automatizirano preimenovanje postoji zbog zadržavanja predefinirane strukture naziva datoteka.",
        availableFrom: "Raspoloživo od",
        before: "Prije",
        beforeAfter: "Prije / poslije",
        beforeReferentDate: "Prije referentnog datuma",
        byProjects: "Po projektima",
        byStatuses: "Po statusima",
        byWeeks: "Po tjednima",
        cancel: "Odustani",
        checkEnteredEmail: "Molimo provjerite upisanu e-mail adresu!",
        chooseAdministrator: "Odaberite administratora",
        chooseAdministratorToAdd: "Odaberite administratora kojeg želite dodati",
        chooseContact: "Odaberite kontakt",
        chooseDesignation: "Odaberite oznaku",
        chooseFinancingCategory: "Odaberite kategoriju financiranja",
        chooseOrganization: "Odaberi organizaciju",
        chooseProject: "Odaberite projekt",
        chooseProjectToShowTasks: "Odaberite projekt za kojeg želite prikazati sve zadatke.",
        chooseResponsibleUsers: "Odaberite zadužene korisnike",
        chooseStatus: "Odaberite status",
        chooseTags: "Odaberite oznake",
        chooseTask: "Odaberite zadatak",
        chooseTaskTemplate: "Odaberi predložak zadatka",
        chooseTaskTemplateToAdd: "Odaberi predložak zadatka kojeg želiš dodati",
        chooseTemplate: "Odaberite predložak",
        chooseUser: "Odaberite korisnika",
        chooseUserToAdd: "Odaberite korisnika kojeg želite dodati",
        client: "Partner",
        clientCanEdit: "Partner može uređivati",
        clientCanEditFolders: "Partner može uređivati folder",
        clientCannotEditFolders: "Partner ne može uređivati folder",
        clients: "Partneri",
        close: "Zatvori",
        code: "Šifra",
        codeBooks: "Šifrarnici",
        coFinancingRate: "Stopa sufinanciranja",
        contact: "Kontakt",
        contacts: "Kontakti",
        contract: "Ugovor",
        contracts: "Ugovori",
        cost: "Trošak",
        costs: "Troškovi",
        croatian: "Hrvatski",
        deadline: "Rok",
        delete: "Obriši",
        description: "Opis",
        designationProcurmentPlan: "Oznaka iz plana nabave",
        details: "Detalji",
        difference: "Razlika",
        documents: "Dokumenti",
        download: "Preuzmi",
        downloadByTags: "Preuzimanje po oznakama",
        edit: "Uredi",
        editingTags: "Uređivanje tagova",
        editTags: "Uredi oznake",
        editTask: "Uredi zadatak",
        email: "Elektronička pošta",
        employee: "Zaposlenik",
        employees: "Zaposlenici",
        endDate: "Datum završetka",
        endOfimplementation: "Kraj provedbe",
        english: "Engleski",
        enterFolderName: "Unesi naziv foldera",
        enterNotificationTime: "Unesite vrijeme obavijesti",
        error: "Greška",
        file: "Datoteka",
        financingCategories: "Kategorije financiranja",
        financingCategory: "Kategorija financiranja",
        firstMessage: "Prva poruka",
        folderIsCreatedAutomaticallyTask: "Folder se kreira uz automatski generirani zadatak",
        folderIsNotCreatedAutomaticallyTask: "Folder se ne kreira uz automatski generirani zadatak",
        folderName: "Naziv foldera",
        forbid: "Zabrani",
        forgotPassword: "Zaboravio si lozinku?",
        friday: "Petak",
        fullName: "Naziv",
        goToLoginScreen: "Idi na ekran za prijavu",
        granted: "Odobreno",
        hidePassword: "Sakrij lozinku",
        inTheVatSystem: "U sustavu PDV-a",
        inTotal: "Ukupno",
        inTotalWithVAT: "Ukupno (s PDV-om)",
        invoiceDate: "Datum računa",
        joinExistingTask: "Pridruži postojeći zadatak",
        loading: "Učitavanje",
        login: "Prijava",
        logout: "Odjava",
        maxProjectNumber: "Maksimalan broj projekata",
        maxProjectsCount: "Maks. broj projekata",
        maxUsersCount: "Maks. broj korisnika",
        maxUsersNumber: "Maksimalan broj korisnika",
        maxUsersUsed: "Maksimalan broj korisnika u ovoj organizaciji je iskorišten, molimo Vas kontaktirajte administratora za proširenje slobodnih mjesta.",
        modified: "Modificirano",
        monday: "Ponedjeljak",
        name: "Ime",
        new: "Novi",
        newFolder: "Novi folder",
        newTask: "Novi zadatak",
        no: "Ne",
        noCreateFolderAccessMessage: "Nemate prava za kreiranje novog foldera u izabranom folderu!",
        noRightsToDeleteFile: "Nemate prava za brisanje jedne od odabranih datoteka!",
        noRightsToRenameFile: "Nemate prava za izmjenu imena odabrane datoteke!",
        notEditableFolderNote: "Nakon što otvorite specifični direktorij bit će moguće izraditi zadatak ili pridužiti mu postojeći!",
        notificationTime: "Vrijeme obavijesti",
        number: "Broj",
        numberOfDays: "Broj dana",
        ok: "OK",
        organization: "Organizacija",
        organizationAlreadyExists: "Organizacija s tim skraćenim nazivom već postoji:",
        organizations: "Organizacije",
        organizationShortTitleFormat: "Format: DIO_NAZIVA",
        overallAcceptable: "Ukupno prihvatljivo",
        overallAcceptableShort: "Uk. prihvatljivo",
        overview: "Pregled",
        overviewByStatus: "Pregled po statusima",
        overviewByWeeks: "Pregled po tjednima",
        paid: "Plaćeno",
        password: "Lozinka",
        payment: "Plaćanje",
        paymentDate: "Datum plaćanja",
        payments: "Plaćanja",
        phone: "Telefon",
        pin: "OIB",
        pleaseCheckMail: "Molimo Vas provjerite svoju poštu.",
        project: "Projekt",
        projectCode: "Kod projekta",
        projects: "Projekti",
        projectTemplate: "Predložak projekta",
        projectTemplates: "Predlošci projekata",
        projectWithThat: "Projekt s tom",
        quantity: "Količina",
        referentDate: "Referentni datum",
        removeJoinedTask: "Ukloni pridruženi zadatak",
        removeTemplate: "Ukloni predložak",
        rename: "Preimenuj",
        renameAfterAutomaticRename: "Datoteku nakon automatiziranog preimenovanja možete preimenovati.",
        requiredField: "Obavezno polje",
        resetPasswordSent: "Na Vašu e-mail adresu je poslan link za resetiranje lozinke.",
        responsible: "Zaduženi",
        responsiblePersons: "Zadužene osobe",
        saturday: "Subota",
        save: "Spremi",
        saveAndSend: "Spremi i pošalji",
        saving: "Spremanje",
        selectedFolderNoTags: "Odabrani folder nema niti jednu oznaku.",
        selectedFolderNoTask: "Odabrani folder nema zadatka.",
        selectExistingContact: "Odaberi postojeći kontakt drugih klijenata za ovog partnera",
        sendEmail: "Pošalji e-mail",
        shortCode: "Kratka šifra",
        shortCodeFormat: "Format: PARTNER_POZIV_GODINA",
        shortName: "Skr. naziv",
        shortTitle: "Organizacija s tim skraćenim nazivom već postoji:",
        showedTasksDaysPart1: "Prikazani su zadaci kojima je rok unutar sljedećih",
        showedTasksDaysPart2: "odabranih dana.",
        showPassword: "Prikaži lozinku",
        signDate: "Potpis ugovora",
        size: "Veličina",
        startDate: "Datum početka",
        startOfImplementation: "Početak provedbe",
        state: "Stanje",
        status: "Status",
        subvention: "Subvencija",
        subventionAmount: "Iznos subvencije",
        sunday: "Nedjelja",
        suppllier: "Dobavljač",
        surname: "Prezime",
        tag: "Oznaka",
        tags: "Oznake",
        task: "Zadatak",
        taskDetails: "Detalji zadatka",
        tasks: "Zadaci",
        taskTemplate: "Predložak zadatka",
        template: "Predložak",
        templates: "Predlošci",
        thursday: "Četvrtak",
        time: "Vrijeme",
        timeUnit: "Vremenska jedinica",
        title: "Naslov",
        tuesday: "Utorak",
        unitPrice: "Jedinična cijena",
        unitPriceShort: "Jed. cijena",
        unknownError: "Nepoznata greška",
        user: "Korisnik",
        users: "Korisnici",
        VAT: "PDV",
        vatAmount: "Iznos PDV-a",
        vatEligibleCost: "PDV prihvatljiv trošak",
        vatRate: "Stopa PDV-a",
        wednesday: "Srijeda",
        weekTasksDisplayed: "Tjedan za koji se prikazuju zadaci",
        wrongLogin: "Upisali ste neispravnu elektroničku poštu ili lozinku, molimo Vas pokušajte ponovno.",
        yes: "Da"
    },
    en: {
        accordingToTheContract: "According to the financing agreement",
        activities: "Activities",
        activity: "Activity",
        addDocument: "Add document",
        addDocumentTemplateToProject: "Add a document template to the project template",
        addExisting: "Add existing",
        addExistingContact: "Add an existing contact from other partners for this partner",
        addExistingUserToOrganization: "Add an existing user to this organization",
        addTask: "Add task",
        addTaskTemplateToProject: "Add a task template to a project template",
        addUser: "Add user",
        addUserToProject: "Add user to project",
        administrator: "Administrator",
        administrators: "Administrators",
        after: "After",
        afterReferentDate: "After referent date",
        agreed: "Agreed",
        alarm: "Alarm",
        alarms: "Alarms",
        alarmTemplate: "Alarm template",
        allow: "Allow",
        alreadyExists: "already exists",
        amountOfCoFinancing: "Amount of co-financing",
        amountWithoutVat: "Amount without VAT",
        applicationUser: "Application user",
        apply: "Apply",
        areYouSure: "Are you sure?",
        automaticallyCreateTask: "Automatically create task",
        automaticRenameNote: "NOTE: All files you upload to the system will be automatically renamed to the name of the subdirectory where you place the file. If you want to keep the original file name from your computer, then you must place the file in the main project directory, not in a specific subdirectory! Automated renaming exists to maintain a predefined file name structure.",
        availableFrom: "Available from",
        before: "Before",
        beforeAfter: "Before / after",
        beforeReferentDate: "Before referent date",
        byProjects: "By projects",
        byStatuses: "By statuses",
        byWeeks: "By weeks",
        cancel: "Cancel",
        checkEnteredEmail: "Please check the entered e-mail address!",
        chooseAdministrator: "Choose administrator",
        chooseAdministratorToAdd: "Choose the administrator you want to add",
        chooseContact: "Choose contact",
        chooseDesignation: "Select designation",
        chooseFinancingCategory: "Select financing category",
        chooseOrganization: "Choose organization",
        chooseProject: "Choose project",
        chooseProjectToShowTasks: "Select the project for which you want to display all tasks.",
        chooseResponsibleUsers: "Select responsible users",
        chooseStatus: "Select status",
        chooseTags: "Choose marks",
        chooseTask: "Select task",
        chooseTaskTemplate: "Select a task template",
        chooseTaskTemplateToAdd: "Select the task template you want to add",
        chooseTemplate: "Choose template",
        chooseUser: "Choose user",
        chooseUserToAdd: "Select the user you want to add",
        client: "Partner",
        clientCanEdit: "Partner can edit",
        clientCanEditFolders: "The partner can edit folders",
        clientCannotEditFolders: "The partner cannot edit folders",
        clients: "Partners",
        close: "Close",
        code: "Code",
        codeBooks: "Code books",
        coFinancingRate: "Co-financing rate",
        contact: "Contact",
        contacts: "Contacts",
        contract: "Contract",
        contracts: "Contracts",
        cost: "Cost",
        costs: "Costs",
        croatian: "Croatian",
        deadline: "Deadline",
        delete: "Delete",
        description: "Description",
        designationProcurmentPlan: "Designation from the procurement plan",
        details: "Details",
        difference: "Difference",
        documents: "Documents",
        download: "Download",
        downloadByTags: "Download by marks",
        edit: "Edit",
        editingTags: "Editing tags",
        editTags: "Edit tags",
        editTask: "Edit task",
        email: "Email",
        employee: "Employee",
        employees: "Employees",
        endDate: "End date",
        endOfimplementation: "End of implementation",
        english: "English",
        enterFolderName: "Enter folder name",
        enterNotificationTime: "Enter notification time",
        error: "Error",
        file: "File",
        financingCategories: "Financing categories",
        financingCategory: "Financing category",
        firstMessage: "First message",
        folderIsCreatedAutomaticallyTask: "The folder is created with the automatically generated task",
        folderIsNotCreatedAutomaticallyTask: "The folder is not created with the automatically generated task",
        folderName: "Folder name",
        forbid: "Forbid",
        forgotPassword: "Forgot password?",
        friday: "Friday",
        fullName: "Name",
        goToLoginScreen: "Go to login screen",
        granted: "Granted",
        hidePassword: "Hide password",
        inTheVatSystem: "In the VAT system",
        inTotal: "In total",
        inTotalWithVAT: "In total (with VAT)",
        invoiceDate: "Invoice date",
        joinExistingTask: "Join an existing task",
        loading: "Loading",
        login: "Login",
        logout: "Log out",
        maxProjectNumber: "Maximum number of projects",
        maxProjectsCount: "Max projects count",
        maxUsersCount: "Max users count",
        maxUsersNumber: "Maximum number of users",
        maxUsersUsed: "The maximum number of users in this organization has been used, please contact the administrator to expand the vacancies.",
        modified: "Modified",
        monday: "Monday",
        name: "Name",
        new: "New",
        newFolder: "New folder",
        newTask: "New task",
        no: "No",
        noCreateFolderAccessMessage: "You do not have rights to create a new folder in the selected folder!",
        noRightsToDeleteFile: "You do not have the right to delete one of the selected files!",
        noRightsToRenameFile: "You do not have the right to change the name of the selected file!",
        notEditableFolderNote: "Once you open a specific directory it will be possible to create a task or attach an existing one to it!",
        notificationTime: "Notification time",
        number: "Number",
        numberOfDays: "Number of days",
        ok: "OK",
        organization: "Organization",
        organizationAlreadyExists: "Organization with that short title already exists:",
        organizations: "Organizations",
        organizationShortTitleFormat: "Format: PART_OF_TITLE",
        overallAcceptable: "Overall acceptable",
        overallAcceptableShort: "Ov. acceptable",
        overview: "Overview",
        overviewByStatus: "Overview by status",
        overviewByWeeks: "Overview by weeks",
        paid: "Paid",
        password: "Password",
        payment: "Payment",
        paymentDate: "Payment date",
        payments: "Payments",
        phone: "Phone",
        pin: "Personal Identification Number",
        pleaseCheckMail: "Please check your mail.",
        project: "Project",
        projectCode: "Project code",
        projects: "Projects",
        projectTemplate: "Project template",
        projectTemplates: "Project templates",
        projectWithThat: "Project with that",
        quantity: "Quantity",
        referentDate: "Referent date",
        removeJoinedTask: "Remove the joined task",
        removeTemplate: "Remove template",
        rename: "Rename",
        renameAfterAutomaticRename: "You can rename the file after automatic renaming.",
        requiredField: "Required field",
        resetPasswordSent: "A password reset link has been sent to your e-mail address.",
        responsible: "Responsible",
        responsiblePersons: "Responsible persons",
        saturday: "Saturday",
        save: "Save",
        saveAndSend: "Save and send",
        saving: "Saving",
        selectedFolderNoTags: "The selected folder has no tags.",
        selectedFolderNoTask: "The selected folder has no task.",
        selectExistingContact: "Select an existing contact from other partners for this partner",
        sendEmail: "Send e-mail",
        shortCode: "Short code",
        shortCodeFormat: "Format: PARTNER_CALL_YEAR",
        shortName: "Short name",
        shortTitle: "Short title",
        showedTasksDaysPart1: "Tasks due within the next",
        showedTasksDaysPart2: "are displayed.",
        showPassword: "Show password",
        signDate: "Sign date",
        size: "Size",
        startDate: "Start date",
        startOfImplementation: "Start of implementation",
        state: "State",
        status: "Status",
        subvention: "Subvention",
        subventionAmount: "Subvention amount",
        sunday: "Sunday",
        suppllier: "Suppllier",
        surname: "Surname",
        tag: "Mark",
        tags: "Marks",
        task: "Task",
        taskDetails: "Task details",
        tasks: "Tasks",
        taskTemplate: "Task template",
        template: "Template",
        templates: "Templates",
        thursday: "Thursday",
        time: "Time",
        timeUnit: "Time unit",
        title: "Title",
        tuesday: "Tuesday",
        unitPrice: "Unit price",
        unitPriceShort: "Unit price",
        unknownError: "Unknown error",
        user: "User",
        users: "Users",
        VAT: "Vat",
        vatAmount: "VAT amount",
        vatEligibleCost: "VAT eligible cost",
        vatRate: "VAT rate",
        wednesday: "Wednesday",
        weekTasksDisplayed: "Week for which tasks are displayed",
        wrongLogin: "You entered an incorrect email or password, please try again.",
        yes: "Yes"
    }
}