import {
  CheckBoxSelection,
  DropDownListComponent,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { EditSettingsModel } from "@syncfusion/ej2-react-grids";
import {
  ButtonPropsModel,
  DialogComponent,
  hideSpinner,
  showSpinner,
} from "@syncfusion/ej2-react-popups";
import moment from "moment";
import Parse from "parse";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { KanbanDialogFormTemplate } from "../../helpers/dialogTemplates/KanbanDialogTemplate";
import KanbanReadDialogFormTemplate from "../../helpers/dialogTemplates/KanbanReadDialogTemplate";
import {
  generateLinkToPaymentsTable,
  getProjectCostContractByTags,
} from "../../helpers/fetching/CostContractsServiceHelper";
import {
  removeProjectFileTask,
  saveProjectFileTags,
  saveProjectFileTask,
  saveProjectFileTaskAfterCreate,
  updateIsEditableForClientProperty,
} from "../../helpers/fetching/ProjectFileFetchingHelper";
import {
  fetchTaskStates,
  saveTask,
} from "../../helpers/fetching/TaskFetchingHelper";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import { refreshFileManager } from "../../helpers/refreshing/RefreshingHelper";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { FooterAggregateSettingModel } from "../../models/FooterAggregateSettingModel";
import { KanbanColumnModel } from "../../models/KanbanColumnModel";
import { ProjectCostContractBaseModel } from "../../models/ProjectCostContractBaseModel";
import { TaskBaseModel, TaskFormBaseModel } from "../../models/TaskBaseModel";
import {
  GetFirstObjectById,
  GetRelationByName,
} from "../../services/GenericServices";
import { calculateDeadline } from "../../services/TaskServices";
import { GetTaskTemplateById } from "../../services/TaskTemplateServices";
import { roleListAdminAndAdminOrganizationAndEmployee } from "../../settings/Constants";
import { Language, TaskStatus } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import CustomSpinner from "../custom-spinner";
import { DataGrid } from "../dataGrid";
import { TaskTemplatePicker } from "../taskTemplatePicker";
import "./styles.css";

interface OwnProps {
  language: Language;
  folderId?: string;
  userRoles?: string[];
  selectedOrganization?: any;
  selectedProjectId?: string;
}

interface State {
  isLoading: boolean;
  isScheduledToNotify: boolean;
  isDialogVisible: boolean;
  isTaskTemplateDialogVisible: boolean;
  isNewTaskDialogVisible: boolean;
  isConfirmDialogVisible: boolean;
  isValidatingForm: boolean;
  isTaskDialogVisible: boolean;
  isNotEditableFolder: boolean;
  tagOptions: { [key: string]: Object }[];
  tagOptionsForSearch: any[];
  taskOptions: { [key: string]: Object }[];
  taskOptionsForSearch: any[];
  task?: TaskBaseModel;
  concatenatedTags?: string[];
  selectedTags?: string[];
  selectedProjectCostContracts: ProjectCostContractBaseModel[];
  selectedTagIds?: string[];
  selectedTask?: string;
  selectedTaskTemplate?: string;
  folderId?: string;
  spinnerElement: HTMLElement | null;
  taskFormData?: TaskFormBaseModel;
  columnsList: KanbanColumnModel[];
  editCardButtons: ButtonPropsModel[];
  taskTemplateDialogButtons: ButtonPropsModel[];
  defaultEditCardButtons: ButtonPropsModel[];
  templateTaskButton: ButtonPropsModel;
  removeTemplateTaskButton: ButtonPropsModel;
  isEditableForClient: boolean;
  wrappedComponentRef?: any;
}

type Props = RouteComponentProps & OwnProps;

class FolderDetails extends React.Component<Props, State> {
  private customSpinnerRef: any;

  constructor(props: any) {
    super(props);

    this.customSpinnerRef = React.createRef();

    this.state = {
      isScheduledToNotify: false,
      isDialogVisible: false,
      isLoading: false,
      isTaskTemplateDialogVisible: false,
      isValidatingForm: false,
      isNewTaskDialogVisible: false,
      isConfirmDialogVisible: false,
      isTaskDialogVisible: false,
      isNotEditableFolder: false,
      isEditableForClient: false,
      spinnerElement: null,
      tagOptions: [],
      tagOptionsForSearch: [],
      selectedProjectCostContracts: [],
      taskOptions: [],
      taskOptionsForSearch: [],
      columnsList: [],
      editCardButtons: [
        {
          buttonModel: {
            content:
              translations[
                (props.language as Language | undefined) || Language.Hr
              ].save,
            cssClass: "e-flat",
            isPrimary: true,
          },
          click: () => this.onSubmitTaskClick(false),
        },
        {
          buttonModel: {
            content:
              translations[
                (props.language as Language | undefined) || Language.Hr
              ].saveAndSend,
            cssClass: "e-flat",
            isPrimary: true,
          },
          click: () => this.onSubmitTaskClick(true),
        },
        {
          buttonModel: {
            content:
              translations[
                (props.language as Language | undefined) || Language.Hr
              ].cancel,
            cssClass: "e-flat",
            isPrimary: false,
          },
          click: () => this.setState({ isTaskDialogVisible: false }),
        },
      ],
      templateTaskButton: {
        buttonModel: {
          content:
            translations[
              (props.language as Language | undefined) || Language.Hr
            ].template,
          cssClass: "e-flat float-left",
        },
        click: () => this.setState({ isTaskTemplateDialogVisible: true }),
      },
      removeTemplateTaskButton: {
        buttonModel: {
          content:
            translations[
              (props.language as Language | undefined) || Language.Hr
            ].removeTemplate,
          cssClass: "e-flat e-danger float-left",
        },
        click: () => this.removeSelectedTaskTemplate(),
      },
      defaultEditCardButtons: [
        {
          buttonModel: {
            content:
              translations[
                (props.language as Language | undefined) || Language.Hr
              ].save,
            cssClass: "e-flat",
            isPrimary: true,
          },
          click: () => this.onSubmitTaskClick(false),
        },
        {
          buttonModel: {
            content:
              translations[
                (props.language as Language | undefined) || Language.Hr
              ].saveAndSend,
            cssClass: "e-flat",
            isPrimary: true,
          },
          click: () => this.onSubmitTaskClick(true),
        },
        {
          buttonModel: {
            content:
              translations[
                (props.language as Language | undefined) || Language.Hr
              ].cancel,
            cssClass: "e-flat",
            isPrimary: false,
          },
          click: () => this.updateTaskFormAfterCancel(),
        },
      ],
      taskTemplateDialogButtons: [
        {
          buttonModel: {
            content:
              translations[
                (props.language as Language | undefined) || Language.Hr
              ].apply,
            cssClass: "e-flat",
            isPrimary: true,
          },
          click: () => this.updateTaskInitialValues(),
        },
        {
          buttonModel: {
            content:
              translations[
                (props.language as Language | undefined) || Language.Hr
              ].cancel,
            cssClass: "e-flat",
            isPrimary: false,
          },
          click: () => this.setState({ isTaskTemplateDialogVisible: false }),
        },
      ],
    };
  }

  public componentDidMount() {
    const { folderId } = this.props;

    this.fetchAllTags();
    this.fetchProjectFiles(folderId);
    this.fetchTaskStates();
    this.fetchAllTasks();
  }

  private fetchAllTags = async (): Promise<void> => {
    const dropdownItems: { [key: string]: Object }[] = [];
    const dropdownItemsForSearch: any[] = [];

    const object = Parse.Object.extend("Tag");
    const query = new Parse.Query(object).limit(10000);

    const result = await query.find();

    if (!(result && result.length > 0)) return;

    result.forEach((tag) => {
      if (tag.id && tag.attributes) {
        dropdownItems.push({
          value: tag.id,
          text: `${tag.attributes.title}`,
        });
        dropdownItemsForSearch.push(tag);
      }
    });

    this.setState({
      tagOptions: dropdownItems,
      tagOptionsForSearch: dropdownItemsForSearch,
    });
  };

  private fetchAllTasks = async (): Promise<void> => {
    const { selectedProjectId } = this.props;
    const dropdownItems: { [key: string]: Object }[] = [];
    const dropdownItemsForSearch: any[] = [];

    if (selectedProjectId) {
      const parseProject = Parse.Object.extend("Project");
      const projectQuery = new Parse.Query(parseProject)
        .equalTo("objectId", selectedProjectId)
        .limit(10000);

      const projectResults = await projectQuery.find();

      if (!(projectResults && projectResults.length > 0)) return;

      const object = Parse.Object.extend("Task");
      const query = new Parse.Query(object)
        .equalTo("project", projectResults[0])
        .limit(10000);

      const result = await query.find();

      if (!(result && result.length > 0)) return;

      result.forEach((task) => {
        if (task.id && task.attributes && !task.attributes.projectFile) {
          dropdownItems.push({
            value: task.id,
            text: `${task.attributes.title}`,
          });
          dropdownItemsForSearch.push(task);
        }
      });

      this.setState({
        taskOptions: dropdownItems,
        taskOptionsForSearch: dropdownItemsForSearch,
      });
    }
  };

  private fetchProjectFiles = async (
    folderIdFromRef?: string
  ): Promise<void> => {
    const { selectedProjectId } = this.props;
    this.setState({ isLoading: true });
    if (folderIdFromRef) {
      const projectFile = Parse.Object.extend("ProjectFile");
      const objectQuery = new Parse.Query(projectFile)
        .equalTo("objectId", folderIdFromRef)
        .include("task")
        .limit(10000);

      const result = await objectQuery.first();
      if (result && result.attributes && result.id) {
        const tags = await GetRelationByName(result, "tags");
        const mappedTagTitles: string[] = [];
        const mappedTagIds: string[] = [];

        if (tags) {
          tags.forEach((tag: any) => {
            if (tag && tag.id && tag.attributes) {
              mappedTagTitles.push(tag.attributes.title);
              mappedTagIds.push(tag.id);
            }
          });
        }

        this.setState({
          concatenatedTags: mappedTagTitles,
          selectedTags: mappedTagTitles,
          selectedTagIds: mappedTagIds,
          isNotEditableFolder: false,
          isEditableForClient: result.attributes.isEditableForClient,
        });

        if (
          result.attributes.task &&
          result.attributes.task.id &&
          result.attributes.task.attributes
        ) {
          const assignees = await GetRelationByName(
            result.attributes.task,
            "assignees"
          );
          const assigneesMails: string[] = [];

          if (assignees) {
            assignees.forEach((assignee: any) => {
              if (assignee && assignee.attributes) {
                assigneesMails.push(
                  assignee.attributes.email || assignee.attributes.username
                );
              }
            });
          }
          const taskModel = {
            id: result.attributes.task.id,
            projectActivity: result.attributes.task.attributes.projectActivity,
            assignees: assigneesMails,
            taskState: result.attributes.task.attributes.taskState,
            availableSince: result.attributes.task.attributes.availableSince,
            title: result.attributes.task.attributes.title,
            deadline: result.attributes.task.attributes.deadline,
            project: result.attributes.task.attributes.project,
            description: result.attributes.task.attributes.description,
            projectId: result.attributes.task.attributes.project.id,
            taskStateId: result.attributes.task.attributes.taskState.id,
            className:
              new Date() >= result.attributes.task.attributes.deadline
                ? "highlighted-card"
                : "",
            deadlineCroFormat: moment(
              result.attributes.task.attributes.deadline
            ).format("DD.MM.YYYY."),
            projectShortCode:
              result.attributes.task.attributes.project.attributes.shortCode,
            projectShortTitle:
              result.attributes.task.attributes.project.attributes.shortTitle,
            projectFile: result.attributes.task.attributes.projectFile,
          };
          this.setState({
            task: taskModel,
            taskFormData: { ...taskModel },
          });
        } else if (selectedProjectId) {
          const projectObject = await GetFirstObjectById(
            "Project",
            selectedProjectId
          );

          const taskModel = {
            assignees: [],
            taskStateId: TaskStatus.ToDo,
            projectId: selectedProjectId,
            project: projectObject,
            projectShortCode: projectObject?.attributes.shortCode,
            projectShortTitle: projectObject?.attributes.shortTitle,
          };

          this.setState({
            task: taskModel as TaskBaseModel,
            taskFormData: { ...(taskModel as TaskBaseModel) },
          });
        }
      } else {
        this.setState({
          task: undefined,
          taskFormData: undefined,
          concatenatedTags: undefined,
          selectedTags: undefined,
          selectedTagIds: undefined,
          isNotEditableFolder: true,
          selectedTaskTemplate: undefined,
          selectedTask: undefined,
        });
      }
    } else {
      this.setState({
        task: undefined,
        taskFormData: undefined,
        concatenatedTags: undefined,
        selectedTags: undefined,
        selectedTagIds: undefined,
        isNotEditableFolder: true,
        selectedTask: undefined,
        selectedTaskTemplate: undefined,
      });
    }
    this.setState({
      folderId: folderIdFromRef,
      isLoading: false,
    });
    this.fetchProjectCostContractsByTags();
  };

  private fetchProjectCostContractsByTags = async (): Promise<void> => {
    const { selectedTagIds } = this.state;
    const { selectedProjectId } = this.props;

    const projectCostContracts = await getProjectCostContractByTags(
      selectedTagIds,
      selectedProjectId
    );
    this.setState({
      selectedProjectCostContracts: projectCostContracts,
    });
  };

  private saveTags = async (): Promise<void> => {
    const { folderId, selectedTags, tagOptionsForSearch } = this.state;

    if (!folderId) return;
    const result = await saveProjectFileTags(
      selectedTags,
      tagOptionsForSearch,
      folderId
    );

    if (result) {
      this.fetchProjectFiles(folderId);
      this.setState({ isDialogVisible: false });
    }
  };

  private onSubmitTaskClick = (localIsScheduledToNotify: boolean): void => {
    const spinnerElement = document.getElementById("editDialogComponent");
    if (spinnerElement) {
      showSpinner(spinnerElement);
    }
    this.setState({
      isValidatingForm: true,
      spinnerElement,
      isScheduledToNotify: localIsScheduledToNotify,
    });
  };

  private onValidationEnd = (result: boolean): void => {
    const { spinnerElement } = this.state;
    this.setState({ isValidatingForm: false });
    if (!result) {
      if (spinnerElement) {
        hideSpinner(spinnerElement);
      }
      return;
    }
    this.onSaveAction();
  };

  private onSaveAction = async (): Promise<void> => {
    const {
      taskFormData,
      folderId,
      selectedTaskTemplate,
      isScheduledToNotify,
    } = this.state;
    const { selectedOrganization, language } = this.props;

    if (!taskFormData) return;

    this.setSpinnerState(true);

    const response = await saveTask(
      { ...taskFormData, isScheduledToNotify: isScheduledToNotify },
      selectedTaskTemplate,
      folderId,
      selectedOrganization,
      language
    );

    if (response) {
      if (!taskFormData.id && folderId) {
        const saveProjectFileTaskResponse =
          await saveProjectFileTaskAfterCreate(response, folderId);
        if (!saveProjectFileTaskResponse) {
          this.setSpinnerState(false);
          return;
        }
      }
      this.setState({
        task: undefined,
        taskFormData: undefined,
        isTaskDialogVisible: false,
      });
      this.fetchProjectFiles(folderId);
    }
    this.setSpinnerState(false);
  };

  private fetchTaskStates = async (): Promise<void> => {
    const { language } = this.props;

    const columnsList = await fetchTaskStates(language);
    this.setState({
      columnsList,
    });
  };

  private saveExistingTask = async (): Promise<void> => {
    const { folderId, selectedTask, taskOptionsForSearch } = this.state;

    if (!folderId) return;

    this.setSpinnerState(true);

    const response = await saveProjectFileTask(
      selectedTask,
      taskOptionsForSearch,
      folderId
    );

    if (response) {
      this.clearTaskFormData();
      this.fetchAllTasks();
    }

    this.setSpinnerState(false);
  };

  private removeExistingTask = async (): Promise<void> => {
    const { folderId } = this.state;

    if (!folderId) return;

    this.setSpinnerState(true);

    const response = await removeProjectFileTask(folderId);

    if (response) {
      this.clearTaskFormData();
      this.fetchAllTasks();
    }
    this.handleDialogConfirmChange(false);
    this.setSpinnerState(false);
  };

  private clearTaskFormData = (): void => {
    const { folderId } = this.state;

    this.setState({
      task: undefined,
      taskFormData: undefined,
      isNewTaskDialogVisible: false,
      selectedTask: undefined,
    });
    this.fetchProjectFiles(folderId);
  };

  private setSpinnerState = (newState: boolean): void => {
    const spinner = this.customSpinnerRef && this.customSpinnerRef.current;

    if (spinner) {
      (spinner as any).handleSpinnerState(newState);
    }
  };

  private removeSelectedTaskTemplate = (): void => {
    const { templateTaskButton, defaultEditCardButtons } = this.state;
    this.setState({
      selectedTaskTemplate: undefined,
      editCardButtons: [templateTaskButton, ...defaultEditCardButtons],
    });
  };

  private updateTaskInitialValues = async (): Promise<void> => {
    const { selectedTaskTemplate } = this.state;
    const { language } = this.props;

    if (!selectedTaskTemplate) return;
    const {
      taskFormData,
      task,
      templateTaskButton,
      defaultEditCardButtons,
      removeTemplateTaskButton,
    } = this.state;

    this.setState({
      editCardButtons: [
        templateTaskButton,
        removeTemplateTaskButton,
        ...defaultEditCardButtons,
      ],
    });

    const result = await GetTaskTemplateById(selectedTaskTemplate, language);

    if (
      !(
        result &&
        task &&
        task.project &&
        task.project.attributes &&
        taskFormData
      )
    )
      return;

    const deadline: Date = calculateDeadline(
      task.project.attributes[result.projectMilestone.id],
      result.timeUnit.id,
      result.timeQuantity,
      result.isAfterProjectMilestone
    );

    const newSelectedCard: TaskBaseModel = {
      ...task,
      description: result.description,
      title: result.title,
      deadline,
    };

    const newSelectedTaskFormData: TaskFormBaseModel = {
      ...taskFormData,
      description: result.description,
      title: result.title,
      deadline,
    };

    this.setState({
      task: newSelectedCard,
      taskFormData: newSelectedTaskFormData,
      isTaskTemplateDialogVisible: false,
    });
  };

  private updateTaskFormAfterCancel = (): void => {
    const { folderId } = this.state;
    this.setState({
      isTaskDialogVisible: false,
      selectedTaskTemplate: undefined,
    });
    if (folderId) {
      this.fetchProjectFiles(folderId);
    }
  };

  private handleDialogConfirmChange = (isDialogVisible: boolean): void => {
    this.setState({
      isConfirmDialogVisible: isDialogVisible,
    });
  };

  private updateIsEditableForClient = async (
    isEditable: boolean
  ): Promise<void> => {
    const { folderId } = this.state;
    const { selectedOrganization } = this.props;
    this.setState({ isEditableForClient: isEditable });
    updateIsEditableForClientProperty(
      isEditable,
      folderId,
      selectedOrganization
    );
    refreshFileManager();
  };

  private openPaymentTable = async (id: string): Promise<void> => {
    const { history } = this.props;
    const generatedLink = await generateLinkToPaymentsTable(id);

    if (generatedLink) {
      history.push(generatedLink);
    }
  };

  public render(): any {
    const {
      concatenatedTags,
      tagOptions,
      taskOptions,
      task,
      isLoading,
      isDialogVisible,
      isNewTaskDialogVisible,
      isTaskTemplateDialogVisible,
      isConfirmDialogVisible,
      isTaskDialogVisible,
      isNotEditableFolder,
      isValidatingForm,
      selectedTags,
      selectedTask,
      selectedProjectCostContracts,
      taskFormData,
      columnsList,
      selectedTaskTemplate,
      editCardButtons,
      templateTaskButton,
      defaultEditCardButtons,
      taskTemplateDialogButtons,
      folderId,
      isEditableForClient,
    } = this.state;
    const { userRoles, selectedOrganization, language } = this.props;

    const buttons: ButtonPropsModel[] = [
      {
        buttonModel: {
          content: translations[language || Language.Hr].save,
          cssClass: "e-flat",
          isPrimary: true,
        },
        click: () => this.saveTags(),
      },
      {
        buttonModel: {
          content: translations[language || Language.Hr].save,
          cssClass: "e-flat",
          isPrimary: true,
        },
        click: () => this.saveExistingTask(),
      },
      {
        buttonModel: {
          content: translations[language || Language.Hr].cancel,
          cssClass: "e-flat",
          isPrimary: false,
        },
        click: () =>
          this.setState({
            isDialogVisible: false,
            isNewTaskDialogVisible: false,
            isTaskDialogVisible: false,
            isTaskTemplateDialogVisible: false,
            isConfirmDialogVisible: false,
          }),
      },
    ];

    const fields: object = { groupBy: "groupBy", text: "text", value: "value" };

    const editSettings: EditSettingsModel = {
      allowEditing: false,
      allowAdding: false,
    };

    const columns: DataGridColumnModel[] = [
      {
        field: "id",
        visible: false,
        isPrimaryKey: true,
        allowEditing: false,
      },
      {
        field: "projectCostCode",
        headerText: translations[language || Language.Hr].cost,
      },
      {
        field: "contractor",
        headerText: translations[language || Language.Hr].suppllier,
      },
      {
        field: "realTotalNetPrice",
        headerText: translations[language || Language.Hr].agreed,
        format: "N2",
      },
      {
        field: "totalPayment",
        headerText: translations[language || Language.Hr].paid,
        format: "N2",
      },
      {
        field: "details",
        headerText: translations[language || Language.Hr].details,
        allowEditing: false,
        allowSorting: false,
        allowFiltering: false,
        width: 110,
        template: (props): ReactElement => {
          return (
            <div>
              <div
                className="e-btn"
                onClick={(): Promise<void> => this.openPaymentTable(props.id)}
              >
                {translations[language || Language.Hr].payment}
              </div>
            </div>
          );
        },
      },
    ];

    const footerAggregateSettings: FooterAggregateSettingModel[] = [
      {
        column: "realTotalNetPrice",
        type: "Sum",
      },
      {
        column: "totalPayment",
        type: "Sum",
      },
    ];

    return (
      <div>
        <CustomSpinner
          spinnerClass="custom-spinner-wrapper-folder-details"
          ref={this.customSpinnerRef}
          language={language}
        />
        {!isNotEditableFolder && (
          <div className="main-folder-details-wrapper">
            {userRoles &&
              isAnyCommonString(
                userRoles,
                roleListAdminAndAdminOrganizationAndEmployee,
                selectedOrganization?.attributes?.shortTitle
              ) && (
                <div className="tags-wrapper">
                  <div className="tags-header">
                    <div className="title-header-wrapper">
                      <div
                        className={`details-folder-background-${!!isEditableForClient}`}
                      />
                      {isEditableForClient
                        ? translations[language || Language.Hr]
                            .clientCanEditFolders
                        : translations[language || Language.Hr]
                            .clientCannotEditFolders}
                    </div>
                    <Button
                      className="add-button-tags"
                      onClick={(): Promise<void> =>
                        this.updateIsEditableForClient(!isEditableForClient)
                      }
                    >
                      {isEditableForClient
                        ? translations[language || Language.Hr].forbid
                        : translations[language || Language.Hr].allow}
                    </Button>
                  </div>
                </div>
              )}
            <div className="tags-wrapper">
              <div className="tags-header">
                <div className="title-header-wrapper">
                  {translations[language || Language.Hr].tags}
                </div>
                {userRoles &&
                  isAnyCommonString(
                    userRoles,
                    roleListAdminAndAdminOrganizationAndEmployee,
                    selectedOrganization?.attributes?.shortTitle
                  ) && (
                    <Button
                      className="add-button-tags"
                      onClick={(): void =>
                        this.setState({ isDialogVisible: true })
                      }
                    >
                      {translations[language || Language.Hr].editTags}
                    </Button>
                  )}
              </div>
              <div style={{ padding: "5px 10px 5px" }}>
                {concatenatedTags && concatenatedTags.length > 0 ? (
                  concatenatedTags.map(
                    (cT: string): ReactElement => (
                      <span
                        className="badge badge-secondary"
                        style={{
                          fontSize: "1rem",
                          marginRight: 3,
                          marginBottom: 3,
                        }}
                      >
                        {cT}
                      </span>
                    )
                  )
                ) : (
                  <span>
                    {translations[language || Language.Hr].selectedFolderNoTags}
                  </span>
                )}
              </div>
            </div>
            <div className="tags-wrapper">
              <div className="tags-header" style={{ marginBottom: "-40px" }}>
                <div
                  className="title-header-wrapper"
                  style={{
                    marginLeft: 0,
                    paddingLeft: 10,
                    width: 180,
                    marginTop: 1,
                    marginBottom: -1,
                  }}
                >
                  {translations[language || Language.Hr].contracts}
                </div>
              </div>
              <DataGrid
                data={selectedProjectCostContracts}
                columnProperties={columns}
                footerAggregateSettings={footerAggregateSettings}
                sortSettings={{}}
                editSettings={editSettings}
                allowPaging={false}
                autoFitColumns={true}
              />
            </div>
            <div className="task-read-dialog-border">
              <div className="tags-header">
                <div className="title-header-wrapper">
                  {translations[language || Language.Hr].task}
                </div>
                {userRoles &&
                  isAnyCommonString(
                    userRoles,
                    roleListAdminAndAdminOrganizationAndEmployee,
                    selectedOrganization?.attributes?.shortTitle
                  ) &&
                  !(task && task.id) && (
                    <Button
                      className="add-button-tags"
                      style={{ marginRight: -10 }}
                      onClick={(): void =>
                        this.setState({
                          isNewTaskDialogVisible: true,
                        })
                      }
                    >
                      {translations[language || Language.Hr].joinExistingTask}
                    </Button>
                  )}
                {userRoles &&
                  isAnyCommonString(
                    userRoles,
                    roleListAdminAndAdminOrganizationAndEmployee,
                    selectedOrganization?.attributes?.shortTitle
                  ) &&
                  task &&
                  task.id && (
                    <Button
                      className="add-button-tags"
                      style={{ marginRight: -10 }}
                      onClick={(): void => this.handleDialogConfirmChange(true)}
                    >
                      {translations[language || Language.Hr].removeJoinedTask}
                    </Button>
                  )}
                {userRoles &&
                  isAnyCommonString(
                    userRoles,
                    roleListAdminAndAdminOrganizationAndEmployee,
                    selectedOrganization?.attributes?.shortTitle
                  ) && (
                    <Button
                      className="add-button-tags"
                      onClick={(): void =>
                        this.setState({
                          isTaskDialogVisible: true,
                          editCardButtons: [
                            templateTaskButton,
                            ...defaultEditCardButtons,
                          ],
                        })
                      }
                    >
                      {task && task.id
                        ? translations[language || Language.Hr].editTask
                        : translations[language || Language.Hr].newTask}
                    </Button>
                  )}
              </div>
              {task && task.id && !isLoading ? (
                <div className="task-read-dialog-padding">
                  <KanbanReadDialogFormTemplate
                    data={task}
                    isDialog={true}
                    showTitle={true}
                    showAlarmButton={
                      userRoles &&
                      isAnyCommonString(
                        userRoles,
                        roleListAdminAndAdminOrganizationAndEmployee,
                        selectedOrganization?.attributes?.shortTitle
                      )
                    }
                    projectFileId={folderId}
                    selectedOrganization={selectedOrganization}
                    language={language}
                  />
                </div>
              ) : (
                <div
                  style={{ border: "1px solid #e0e0e0", padding: "10px 10px" }}
                >
                  {translations[language || Language.Hr].selectedFolderNoTask}
                </div>
              )}
            </div>
            <DialogComponent
              isModal={true}
              width="700px"
              close={(): void => this.setState({ isDialogVisible: false })}
              header={translations[language || Language.Hr].editingTags}
              visible={isDialogVisible}
              showCloseIcon={true}
              buttons={[buttons[0], buttons[2]]}
            >
              <div className="dialog-content" style={{ padding: "15px" }}>
                <MultiSelectComponent
                  dataSource={tagOptions}
                  value={selectedTags}
                  placeholder={translations[language || Language.Hr].chooseTags}
                  change={(args) =>
                    this.setState({
                      selectedTags: args && (args.value as string[]),
                    })
                  }
                  closePopupOnSelect={false}
                  mode="Box"
                  name="tags"
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            </DialogComponent>
            {userRoles &&
              isAnyCommonString(
                userRoles,
                roleListAdminAndAdminOrganizationAndEmployee,
                selectedOrganization?.attributes?.shortTitle
              ) && (
                <DialogComponent
                  isModal={true}
                  width="700px"
                  close={(): void =>
                    this.setState({ isNewTaskDialogVisible: false })
                  }
                  header={translations[language || Language.Hr].addTask}
                  visible={isNewTaskDialogVisible}
                  showCloseIcon={true}
                  buttons={[buttons[1], buttons[2]]}
                >
                  <div className="dialog-content" style={{ padding: "15px" }}>
                    <DropDownListComponent
                      fields={fields}
                      dataSource={taskOptions}
                      sortOrder={"Ascending"}
                      value={selectedTask}
                      placeholder={
                        translations[language || Language.Hr].chooseTask
                      }
                      select={(item) =>
                        this.setState({ selectedTask: item?.itemData.value })
                      }
                    />
                  </div>
                </DialogComponent>
              )}
            {userRoles &&
              isAnyCommonString(
                userRoles,
                roleListAdminAndAdminOrganizationAndEmployee,
                selectedOrganization?.attributes?.shortTitle
              ) && (
                <DialogComponent
                  id="editDialogComponent"
                  isModal={true}
                  className="kanban-dialog"
                  header={
                    task?.id
                      ? translations[language || Language.Hr].editTask
                      : translations[language || Language.Hr].newTask
                  }
                  width="700px"
                  visible={isTaskDialogVisible}
                  buttons={editCardButtons}
                >
                  <div className="dialog-content">
                    {task && taskFormData && isTaskDialogVisible && (
                      <KanbanDialogFormTemplate
                        data={task}
                        formData={taskFormData}
                        taskStatusesList={columnsList}
                        updateTask={(task: any): void =>
                          this.setState({ taskFormData: task })
                        }
                        startValidation={isValidatingForm}
                        onValidationReset={this.onValidationEnd}
                        language={language}
                      />
                    )}
                  </div>
                </DialogComponent>
              )}
            {/* this is needed since syncfusion DropDownListComponent won't deselect value
                                even if selectedTaskTemplate === undefined */}
            {userRoles &&
              isAnyCommonString(
                userRoles,
                roleListAdminAndAdminOrganizationAndEmployee,
                selectedOrganization?.attributes?.shortTitle
              ) &&
              isTaskTemplateDialogVisible && (
                <DialogComponent
                  isModal={true}
                  className="kanban-dialog"
                  header={
                    translations[language || Language.Hr].chooseTaskTemplate
                  }
                  width="700px"
                  visible={isTaskTemplateDialogVisible}
                  buttons={taskTemplateDialogButtons}
                >
                  <div className="dialog-content">
                    <TaskTemplatePicker
                      updateSelectedTaskTemplate={(value: string): void =>
                        this.setState({ selectedTaskTemplate: value })
                      }
                      value={selectedTaskTemplate}
                    />
                  </div>
                </DialogComponent>
              )}
            <DialogComponent
              width="240px"
              height="145px"
              header={translations[language || Language.Hr].areYouSure}
              visible={isConfirmDialogVisible}
              showCloseIcon
              close={() => this.handleDialogConfirmChange(false)}
            >
              <div className="confirm-buttons-wrapper">
                <Button
                  style={{ marginRight: 10 }}
                  variant="primary"
                  onClick={(): Promise<void> => this.removeExistingTask()}
                >
                  {translations[language || Language.Hr].yes}
                </Button>
                <Button
                  variant="secondary"
                  onClick={(): void => this.handleDialogConfirmChange(false)}
                >
                  {translations[language || Language.Hr].no}
                </Button>
              </div>
            </DialogComponent>
          </div>
        )}
        {isNotEditableFolder && (
          <div>
            {translations[language || Language.Hr].notEditableFolderNote}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(FolderDetails);
