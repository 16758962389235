import Parse from "parse";
import { TaskMilestoneBaseModel } from "../models/TaskMilestoneBaseModel";

export const GetTaskMilestones = async () => {
    const object = Parse.Object.extend('TaskMilestone');
    const objectQuery = new Parse.Query(object).limit(10000);

    const results = await objectQuery.find();

    if (!results) return [];

    const taskMilestones: TaskMilestoneBaseModel[] = [];

    results.forEach(taskMilestone => {
        taskMilestones.push({
            id: taskMilestone.id,
            title: taskMilestone.attributes.title,
            title_en: taskMilestone.attributes.title_en,
        })
    });

    return taskMilestones; 
}