import Parse from "parse";
import { DocumentTemplateBaseModel } from "../models/DocumentTemplateBaseModel";
import { ProjectTemplateBaseModel } from "../models/ProjectTemplateBaseModel";
import { TaskTemplateBaseModel } from "../models/TaskTemplateBaseModel";
import { Language } from "../settings/Enums";
import { translations } from "../settings/translation";
import { GetFirstObjectById } from "./GenericServices";

export const GetProjectTemplates = async (): Promise<
  ProjectTemplateBaseModel[]
> => {
  const object = Parse.Object.extend("ProjectTemplate");
  const query = new Parse.Query(object).limit(10000);

  const results = await query.find();

  if (!(results && results.length > 0)) return [];

  const projectTemplates: ProjectTemplateBaseModel[] = [];

  results.forEach((template) => {
    projectTemplates.push({
      id: template.id,
      title: template.attributes.title,
    });
  });

  return projectTemplates;
};

export const GetProjectTemplateById = async (id: string): Promise<any> => {
  const object = Parse.Object.extend("ProjectTemplate");
  const query = new Parse.Query(object).equalTo("objectId", id).limit(10000);

  return await query.first();
};

export const AddTaskToProjectTemplateRelation = async (
  taskTemplateId: string,
  projectTemplateId: string
): Promise<any> => {
  const taskTemplate = await GetFirstObjectById("TaskTemplate", taskTemplateId);

  if (!taskTemplate) return;

  const projectTemplateObject = Parse.Object.extend("ProjectTemplate");
  const projectTemplate = new projectTemplateObject();

  projectTemplate.set("id", projectTemplateId);
  var relation = projectTemplate.relation("taskTemplates");
  relation.add(taskTemplate);

  return await projectTemplate.save();
};

export const RemoveTaskToProjectTemplateRelation = async (
  taskTemplateId: string,
  projectTemplateId: string
): Promise<any> => {
  const taskTemplate = await GetFirstObjectById("TaskTemplate", taskTemplateId);

  if (!taskTemplate) return;

  const projectTemplateObject = Parse.Object.extend("ProjectTemplate");
  const projectTemplate = new projectTemplateObject();

  projectTemplate.set("id", projectTemplateId);
  var relation = projectTemplate.relation("taskTemplates");
  relation.remove(taskTemplate);

  return await projectTemplate.save();
};

export const GetProjectTemplateTasks = async (
  projectId: string,
  language?: Language
): Promise<TaskTemplateBaseModel[]> => {
  const object = Parse.Object.extend("ProjectTemplate");
  const query = new Parse.Query(object).limit(10000);
  const projectDetails = await query.get(projectId);
  const relation = projectDetails.relation("taskTemplates");
  const results = await relation.query().find();

  if (!(results && results.length > 0)) return [];

  const taskTemplates: TaskTemplateBaseModel[] = [];

  results.forEach((template) => {
    taskTemplates.push({
      id: template.id,
      isAfterProjectMilestone: template.attributes.isAfterProjectMilestone,
      isAfterProjectMilestoneDisplayTitle: template.attributes
        .isAfterProjectMilestone
        ? translations[language || Language.Hr].after
        : translations[language || Language.Hr].before,
      timeUnit: template.attributes.timeUnit,
      timeUnitDisplayTitle:
        language && language !== Language.Hr
          ? template.attributes.timeUnit.attributes.title_en
          : template.attributes.timeUnit.attributes.title,
      projectMilestone: template.attributes.projectMilestone,
      projectMilestoneDisplayTitle:
        language && language !== Language.Hr
          ? template.attributes.projectMilestone.attributes.title_en
          : template.attributes.projectMilestone.attributes.title,
      title: template.attributes.title,
      timeQuantity: template.attributes.timeQuantity,
      description: template.attributes.description,
    });
  });

  return taskTemplates;
};

export const AddDocumentToProjectTemplateRelation = async (
  documentTemplateIds: string[],
  projectTemplateId: string
): Promise<any> => {
  if (documentTemplateIds.length === 0) return;

  const documentTemplates: any[] = [];

  await Promise.all(
    documentTemplateIds.map(async (id) => {
      const documentTemplate = await GetFirstObjectById(
        "ProjectFileTemplate",
        id
      );
      documentTemplates.push(documentTemplate);
    })
  );

  const projectTemplateObject = Parse.Object.extend("ProjectTemplate");
  const projectTemplate = new projectTemplateObject();

  projectTemplate.set("id", projectTemplateId);
  var relation = projectTemplate.relation("projectFileTemplates");
  documentTemplates.forEach((template: any) => {
    relation.add(template);
  });

  return await projectTemplate.save();
};

export const RemoveDocumentToProjectTemplateRelation = async (
  documentTemplateId: string,
  projectTemplateId: string
): Promise<any> => {
  const documentTemplate = await GetFirstObjectById(
    "ProjectFileTemplate",
    documentTemplateId
  );

  if (!documentTemplate) return;

  const projectTemplateObject = Parse.Object.extend("ProjectTemplate");
  const projectTemplate = new projectTemplateObject();

  projectTemplate.set("id", projectTemplateId);
  var relation = projectTemplate.relation("projectFileTemplates");
  relation.remove(documentTemplate);

  return await projectTemplate.save();
};

export const GetProjectTemplateDocuments = async (
  projectId: string,
  language?: Language
): Promise<DocumentTemplateBaseModel[]> => {
  const object = Parse.Object.extend("ProjectTemplate");
  const query = new Parse.Query(object).limit(10000);
  const projectDetails = await query.get(projectId);
  const relation = projectDetails.relation("projectFileTemplates");
  const results = await relation.query().find();

  if (!(results && results.length > 0)) return [];

  const documentTemplates: DocumentTemplateBaseModel[] = [];

  results.forEach((template) => {
    documentTemplates.push({
      id: template.id,
      isFile: template.attributes.isFile,
      isFileDisplayTitle: template.attributes.isFile
        ? translations[language || Language.Hr].yes
        : translations[language || Language.Hr].no,
      title: template.attributes.title,
      description: template.attributes.description,
    });
  });

  return documentTemplates;
};

export const saveProjectTemplate = async (
  newProjectTemplate: any
): Promise<any> => {
  const projectTemplateObject = Parse.Object.extend("ProjectTemplate");
  const projectTemplate = new projectTemplateObject();

  projectTemplate.set("id", newProjectTemplate.id);
  projectTemplate.set("title", newProjectTemplate.title);

  return await projectTemplate.save();
};
