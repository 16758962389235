import {
  ButtonPropsModel,
  DialogComponent
} from "@syncfusion/ej2-react-popups";
import Parse from "parse";
import React, { ChangeEvent } from "react";
import { Col, Form } from "react-bootstrap";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import { refreshFileManager } from "../../helpers/refreshing/RefreshingHelper";
import { roleListAdminAndAdminOrganizationAndEmployee } from "../../settings/Constants";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  language: Language;
  projectShortCode?: string;
  isTemplate?: boolean;
  userRoles?: string[];
  selectedOrganization?: any;
}

interface State {
  selectedFolderId?: string;
  isDialogVisible: boolean;
  folderName?: string;
  isEditableForClient: boolean;
  isCreatedWithDefaultTask: boolean;
}

const API_HOST = process.env.REACT_APP_API_HOST;

class NewFolderDialog extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isDialogVisible: false,
      isEditableForClient: false,
      isCreatedWithDefaultTask: false,
    };
  }

  // do not delete this, this is used as reference
  private handleModalState = (newState: boolean): void => {
    this.setState({
      isDialogVisible: newState,
    });
  };

  // do not delete this, this is used as reference
  private updateSelectedFolderId = (folderId?: string): void => {
    this.setState({
      selectedFolderId: folderId,
    });
  };

  private saveNewFolderName = async (): Promise<void> => {
    const {
      selectedFolderId,
      folderName,
      isEditableForClient,
      isCreatedWithDefaultTask,
    } = this.state;
    const { projectShortCode, isTemplate, userRoles, selectedOrganization } =
      this.props;

    const sessionToken = Parse.User.current()?.get("sessionToken");
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const action = isTemplate ? "templates" : "actions";
    const actionUrl = `${API_HOST}/filemanager/${action}`;

    const isAdminOrEmployee =
      userRoles &&
      isAnyCommonString(
        userRoles,
        roleListAdminAndAdminOrganizationAndEmployee,
        selectedOrganization?.attributes?.shortTitle
      );

    const req = JSON.stringify({
      action: "create",
      projectShortCode,
      sessionToken,
      data: [
        {
          objectId: selectedFolderId,
        },
      ],
      name: folderName,
      isEditableForClient: isAdminOrEmployee ? isEditableForClient : true,
      isCreatedWithDefaultTask,
    });

    const requestInfo: RequestInit = {
      headers: headers,
      method: "POST",
      body: req,
      redirect: "follow",
    };
    fetch(`${actionUrl}`, requestInfo);
    this.setState({
      folderName: undefined,
      isEditableForClient: false,
      isCreatedWithDefaultTask: false,
    });
    this.handleModalState(false);
    refreshFileManager(isTemplate);
  };

  public render(): any {
    const {
      isDialogVisible,
      folderName,
      isEditableForClient,
      isCreatedWithDefaultTask,
    } = this.state;
    const { userRoles, selectedOrganization, language } = this.props;

    const buttons: ButtonPropsModel[] = [
      {
        buttonModel: {
          content: translations[language || Language.Hr].save,
          cssClass: "e-flat",
          isPrimary: true,
          disabled: !folderName || folderName === "",
        },
        click: () => this.saveNewFolderName(),
      },
      {
        buttonModel: {
          content: translations[language || Language.Hr].cancel,
          cssClass: "e-flat",
          isPrimary: false,
        },
        click: () =>
          this.setState({
            isDialogVisible: false,
            folderName: undefined,
            isEditableForClient: false,
            isCreatedWithDefaultTask: false,
          }),
      },
    ];

    const isAdminOrEmployee =
      userRoles &&
      isAnyCommonString(
        userRoles,
        roleListAdminAndAdminOrganizationAndEmployee,
        selectedOrganization?.attributes?.shortTitle
      );

    return (
      <div>
        {isDialogVisible && (
          <DialogComponent
            isModal={true}
            width="400px"
            close={(): void => this.setState({ isDialogVisible: false })}
            header={translations[language || Language.Hr].newFolder}
            visible={isDialogVisible}
            showCloseIcon={true}
            buttons={buttons}
          >
            <div className="dialog-content" style={{ padding: "15px" }}>
              <Form.Label>
                {translations[language || Language.Hr].folderName}
              </Form.Label>
              <Form.Control
                type="text"
                name="folderName"
                onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  this.setState({ folderName: e?.target?.value })
                }
                placeholder={
                  translations[language || Language.Hr].enterFolderName
                }
                autoComplete="off"
              />
              {isAdminOrEmployee && (
                <div>
                  <Form.Group
                    as={Col}
                    controlId="isEditableForClientValidation"
                    style={{
                      marginTop: "18px",
                      fontSize: "18px",
                      padding: "0px",
                    }}
                  >
                    <Form.Check
                      label={
                        translations[language || Language.Hr].clientCanEdit
                      }
                      type="checkbox"
                      name="isEditableForClient"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void =>
                        this.setState({
                          isEditableForClient: e?.target?.checked || false,
                        })
                      }
                      defaultChecked={isEditableForClient}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    controlId="isCreatedWithDefaultTaskValidation"
                    style={{
                      marginTop: "18px",
                      fontSize: "18px",
                      padding: "0px",
                    }}
                  >
                    <Form.Check
                      label={
                        translations[language || Language.Hr]
                          .automaticallyCreateTask
                      }
                      type="checkbox"
                      name="isCreatedWithDefaultTask"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void =>
                        this.setState({
                          isCreatedWithDefaultTask: e?.target?.checked || false,
                        })
                      }
                      defaultChecked={isCreatedWithDefaultTask}
                    />
                  </Form.Group>
                </div>
              )}
            </div>
          </DialogComponent>
        )}
      </div>
    );
  }
}

export default NewFolderDialog;
