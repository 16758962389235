import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import Parse from "parse";
import { useAuthDataContext } from "../authDataProvider";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";

export const PrivateRoute = ({ component, roles, ...rest }: any) => {
  const currentUser = Parse.User.current();
  const { userRoles, selectedOrganization } = useAuthDataContext();

  const checkUserRoles = (props: any, component: any, roles?: string[]) => {
    if (!roles) return React.createElement(component, props);

    if (userRoles?.length === 0 && props?.history?.location?.pathname) return <Redirect to={{ pathname: props.history.location.pathname }} />;
    
    if (!userRoles) return <Redirect to={{ pathname: "/" }} />;

    const isAnyCommonRole = isAnyCommonString(userRoles, roles, selectedOrganization?.attributes?.shortTitle);

    return isAnyCommonRole ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: "/" }} />
    );
  };

  const routeComponent = (props: any) =>
    !currentUser ? (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: { pathname: window.location.pathname } },
        }}
      />
    ) : (
      checkUserRoles(props, component, roles)
    );

  return <Route {...rest} render={routeComponent} />;
};
