import Parse from "parse";
import { ProjectMilestoneBaseModel } from "../models/ProjectMilestoneBaseModel";

export const GetProjectMilestones = async () => {
    const object = Parse.Object.extend('ProjectMilestone');
    const objectQuery = new Parse.Query(object).limit(10000);

    const results = await objectQuery.find();

    if (!results) return [];

    const projectMilestones: ProjectMilestoneBaseModel[] = [];

    results.forEach(projectMilestone => {
        projectMilestones.push({
            id: projectMilestone.id,
            title: projectMilestone.attributes.title,
            title_en: projectMilestone.attributes.title_en,
        })
    });

    return projectMilestones; 
}