import React, { ReactElement, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { GetTaskById } from "../../services/TaskServices";
import { TaskBaseModel } from "../../models/TaskBaseModel";
import { GetRelationByName } from "../../services/GenericServices";
import KanbanReadDialogFormTemplate from "../../helpers/dialogTemplates/KanbanReadDialogTemplate";
import { useAuthDataContext } from "../../components/authDataProvider";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import { roleListAdminAndEmployee } from "../../settings/Constants";

interface Props {}

export const TaskDetails: React.FC<Props> = (props: Props): ReactElement => {
  const [task, setTask] = useState<TaskBaseModel | undefined>(undefined);
  const { userRoles, selectedOrganization, language } = useAuthDataContext();

  useEffect(() => {
    fetchTask();
  }, []);

  const getTaskIdFromRoute = (): string => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (urlParts && urlParts.length === 3 && urlParts[1] === "tasks") {
      return urlParts[2];
    }
    return "";
  };

  const taskId = getTaskIdFromRoute();

  const fetchTask = async (): Promise<void> => {
    if (!taskId) return;

    const task = await GetTaskById(taskId);

    if (!(task && task.id && task.attributes)) return;

    const assignees = await GetRelationByName(task, "assignees");
    const assigneesMails: string[] = [];

    if (assignees) {
      assignees.forEach((assignee: any) => {
        if (assignee && assignee.attributes) {
          assigneesMails.push(
            assignee.attributes.email || assignee.attributes.username
          );
        }
      });
    }

    const taskModel: TaskBaseModel = {
      id: task.id,
      projectActivity: task.attributes.projectActivity,
      assignees: assigneesMails,
      taskState: task.attributes.taskState,
      availableSince: task.attributes.availableSince,
      title: task.attributes.title,
      deadline: task.attributes.deadline,
      project: task.attributes.project,
      description: task.attributes.description,
      projectId: task.attributes.project.id,
      taskStateId: task.attributes.taskState.id,
      className:
        new Date() >= task.attributes.deadline ? "highlighted-card" : "",
      deadlineCroFormat: moment(task.attributes.deadline).format("DD.MM."),
      projectShortCode: task.attributes.project.attributes.shortCode,
      projectShortTitle: task.attributes.project.attributes.shortTitle,
      projectFile: task.attributes.projectFile,
    };

    setTask(taskModel);
  };

  return (
    <section id="task-details">
      <Row className={"box-form"}>
        <Col>
          <div style={{ padding: "0px 200px" }}>
            {task && (
              <KanbanReadDialogFormTemplate
                data={task}
                showAlarmButton={
                  userRoles &&
                  isAnyCommonString(userRoles, roleListAdminAndEmployee, selectedOrganization?.attributes?.shortTitle)
                }
                language={language}
                selectedOrganization={selectedOrganization}
              />
            )}
          </div>
        </Col>
      </Row>
    </section>
  );
};
