import Parse from "parse";
import React, { ReactElement, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ForgottenPasswordDialog } from "../../components/forgottenPasswordDialog";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import "./Login.css";

interface FormData {
  username: string;
  password: string;
}

const initialValues: FormData = {
  username: "",
  password: "",
};

interface FormValidation {
  usernameError?: boolean;
  passwordError?: boolean;
  authError?: boolean;
}

export const Login: React.FC = (props: any): ReactElement => {
  // const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormValidation>({});
  const [formData, setFormData] = useState<FormData>(initialValues);
  const [
    isForgottenPasswordDialogVisible,
    setIsForgottenPasswordDialogVisible,
  ] = useState<boolean>(false);

  const language =
    (localStorage.getItem("language") as Language | undefined) || Language.Hr;

  const redirectedFrom = props?.location?.state?.from?.pathname || "/";

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event?.target?.getAttribute("id") || "";
    const value: string = event.target.value;
    const newErrors: FormValidation = {
      ...errors,
      [`${name}Error`]: !value || value === "",
      authError: false,
    };
    setErrors(newErrors);
    if (value) {
      const newFormData: FormData = {
        ...formData,
        [name]: value,
      };
      setFormData(newFormData);
    }
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    try {
      if (formData) {
        await Parse.User.logIn(formData.username, formData.password);
        window.location.href = redirectedFrom;
      }
    } catch (e) {
      const newErrors: FormValidation = {
        ...errors,
        authError: true,
      };
      setErrors(newErrors);
    }
  };

  const handleForgottenPasswordDialog = (newState: boolean): void => {
    setIsForgottenPasswordDialogVisible(newState);
  };

  return (
    <section id="login">
      <Row className={"box-form"}>
        <Col>
          <Form onSubmit={handleSubmit} className={"formLogin"}>
            <Form.Group>
              <Form.Label htmlFor="username">
                {translations[language || Language.Hr].email}
              </Form.Label>
              <Form.Control
                required
                id="username"
                type="text"
                onChange={changeHandler}
              />
              <Form.Control.Feedback
                className={errors.usernameError ? "displayErrors" : "noError"}
              >
                {translations[language || Language.Hr].requiredField}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="password">
                {translations[language || Language.Hr].password}
              </Form.Label>
              <Form.Control
                required
                id="password"
                type="password"
                onChange={changeHandler}
              />
              <Form.Control.Feedback
                className={errors.passwordError ? "displayErrors" : "noError"}
              >
                {translations[language || Language.Hr].requiredField}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Row className="justify-content-end">
              <Button
                type="submit"
                className={"btn btn-primary"}
                disabled={
                  (!formData.username || !formData.password ? true : false)
                    ? true
                    : false
                }
              >
                {translations[language || Language.Hr].login}
              </Button>
              <Button
                variant="link"
                className="link-btn"
                style={{ marginLeft: 80 }}
                onClick={(): void => handleForgottenPasswordDialog(true)}
              >
                {translations[language || Language.Hr].forgotPassword}
              </Button>
            </Form.Row>
            <Form.Row className="justify-content-center">
              <Form.Control.Feedback
                className={errors.authError ? "displayErrors" : "noError"}
                style={{ width: "340px" }}
              >
                {translations[language || Language.Hr].wrongLogin}
              </Form.Control.Feedback>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      {isForgottenPasswordDialogVisible && (
        <ForgottenPasswordDialog
          dialogState={isForgottenPasswordDialogVisible}
          handleDialogState={handleForgottenPasswordDialog}
          language={language}
        />
      )}
    </section>
  );
};
