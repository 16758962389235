import {
  createSpinner,
  DialogComponent,
  hideSpinner,
  showSpinner,
} from "@syncfusion/ej2-react-popups";
import {
  HtmlEditor,
  Inject as InjectRTE,
  Link,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import moment from "moment";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Alarm } from "../../components/alarm";
import {
  TaskActivityBaseModel,
  TaskBaseModel,
} from "../../models/TaskBaseModel";
import { GetAllObjectsByPropertyName } from "../../services/GenericServices";
import { createTaskItem, GetTaskById } from "../../services/TaskServices";
import { toolbarSettings } from "../../settings/Constants";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import { sortTaskActivitiesByCreationDate } from "../sorting/SortingHelper";
import "./KanbanDialogTemplate.css";

interface OwnProps {
  data: TaskBaseModel;
  language?: Language;
  isDialog?: boolean;
  showTitle?: boolean;
  showAlarmButton?: boolean;
  projectFileId?: string;
  selectedOrganization?: any;
}

type Props = RouteComponentProps & OwnProps;

interface State {
  isDialogVisible: boolean;
  assignees: string;
  taskActivities: TaskActivityBaseModel[];
  rteText: string;
}

class KanbanReadDialogFormTemplate extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    const { data } = props;

    let assigneesConcatenated = "";

    data.assignees.forEach((assignee: string) => {
      if (assigneesConcatenated === "") {
        assigneesConcatenated = assignee;
      } else {
        assigneesConcatenated = `${assigneesConcatenated}, ${assignee}`;
      }
    });

    this.state = {
      isDialogVisible: false,
      assignees: assigneesConcatenated,
      taskActivities: [],
      rteText: "",
    };
  }

  public componentDidMount() {
    this.fetchTaskHistoryAndTaskItems();
  }

  private fetchTaskHistoryAndTaskItems = async (): Promise<void> => {
    const { data } = this.props;

    const task = await GetTaskById(data.id);

    if (!task) return;

    const taskHistories = await GetAllObjectsByPropertyName(
      "TaskHistory",
      "task",
      task,
      "createdBy"
    );
    const taskItems = await GetAllObjectsByPropertyName(
      "TaskItem",
      "task",
      task,
      "createdBy"
    );

    if (!(taskHistories && taskItems)) return;

    const list: TaskActivityBaseModel[] = [];

    taskItems.forEach((taskItem) => {
      if (
        taskItem.createdAt &&
        taskItem.attributes &&
        taskItem.attributes.body &&
        taskItem.attributes.createdBy &&
        taskItem.attributes.createdBy.attributes &&
        taskItem.attributes.createdBy.attributes.username
      ) {
        list.push({
          creationDate: taskItem.createdAt,
          text: `<b>${moment(taskItem.createdAt).format("DD.MM.")}</b> - <b>${
            taskItem.attributes.createdBy.attributes.username
          }</b> :<div>${taskItem.attributes.body}</div>`,
        });
      }
    });

    taskHistories.forEach((taskHistory) => {
      if (
        taskHistory.createdAt &&
        taskHistory.attributes &&
        taskHistory.attributes.taskState &&
        taskHistory.attributes.taskState.attributes &&
        taskHistory.attributes.taskState.attributes.title &&
        taskHistory.attributes.createdBy &&
        taskHistory.attributes.createdBy.attributes &&
        taskHistory.attributes.createdBy.attributes.username
      ) {
        list.push({
          creationDate: taskHistory.createdAt,
          text: `<b>${moment(taskHistory.createdAt).format(
            "DD.MM."
          )}</b> - <b>${
            taskHistory.attributes.createdBy.attributes.username
          }</b> :<div><p> Promjena statusa iz <b> ${
            taskHistory.attributes.taskState.attributes.title
          }</b></p></div>`,
        });
      }
    });

    const sortedList = await sortTaskActivitiesByCreationDate(list, true);

    this.setState({
      taskActivities: sortedList,
    });
    const spinnerElement = document.getElementById("newTaskItemRow");
    if (spinnerElement) {
      hideSpinner(spinnerElement);
    }
  };

  private updateRTEField = (args: any): void => {
    if (args && args.value) {
      const regex = /<[^>]+>/g;
      const result = args.value.replace(regex, "");

      // TODO: check this, why "" is not equal to "" and length == 1
      this.setState({
        rteText: result && result !== "" ? args.value : "",
      });
    } else {
      this.setState({
        rteText: "",
      });
    }
  };

  private onAddNewTaskItem = async (e: any): Promise<void> => {
    e.preventDefault();
    const { rteText } = this.state;
    const { data, projectFileId, selectedOrganization } = this.props;
    if (!rteText) return;
    const spinnerElement = document.getElementById("newTaskItemRow");
    if (spinnerElement) {
      showSpinner(spinnerElement);
    }

    const task = await GetTaskById(data.id);

    if (!task) return;

    const result = await createTaskItem(
      task,
      rteText,
      projectFileId,
      selectedOrganization
    );

    if (!result) return;

    this.setState({
      rteText: "",
    });
    this.fetchTaskHistoryAndTaskItems();
  };

  private handleDialogChange = (isDialogVisible: boolean): void => {
    this.setState({
      isDialogVisible,
    });
  };

  public render(): any {
    const {
      data,
      language,
      isDialog,
      showAlarmButton,
      history,
      showTitle,
      projectFileId,
    } = this.props;
    const { assignees, taskActivities, rteText, isDialogVisible } = this.state;

    const spinnerElement = document.getElementById("newTaskItemRow");
    if (spinnerElement) {
      createSpinner({
        target: spinnerElement,
        cssClass: "spinner",
      });
    }

    return (
      <div>
        <Row>
          <Col>
            <p>
              {translations[language || Language.Hr].project}:{" "}
              <b>{data.projectShortTitle}</b>
            </p>
          </Col>
          <Col>
            <p className="float-right">
              {translations[language || Language.Hr].status}:{" "}
              <b>
                {language && language !== Language.Hr
                  ? data.taskState?.attributes[`title_${language}`]
                  : data.taskState?.attributes.title}
              </b>
            </p>
          </Col>
          <Col>
            <p className="float-right">
              {translations[language || Language.Hr].availableFrom}:{" "}
              <b>{moment(data.availableSince).format("DD.MM.YYYY.")}</b>
            </p>
          </Col>
        </Row>
        {(!isDialog || (showTitle && isDialog)) && (
          <Row>
            <Col>
              <p>
                {translations[language || Language.Hr].task}:{" "}
                <b>{data.title}</b>
              </p>
            </Col>
            <Col>
              <p className="float-right">
                {translations[language || Language.Hr].deadline}:{" "}
                <b>{moment(data.deadline).format("DD.MM.YYYY.")}</b>
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <p>
              {translations[language || Language.Hr].description}:{" "}
              <b>{data.description}</b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              {translations[language || Language.Hr].responsiblePersons}:{" "}
              <b>{assignees}</b>
            </p>
          </Col>
        </Row>
        <br />
        <hr />
        <Row>
          <Col>
            <h4>{translations[language || Language.Hr].details}</h4>
          </Col>
          <Col>
            {isDialog && (
              <div>
                {showAlarmButton && (
                  <button
                    className="e-control e-btn e-lib e-flat float-right"
                    onClick={() => this.handleDialogChange(true)}
                  >
                    {translations[language || Language.Hr].alarms}
                  </button>
                )}
                <button
                  className="e-control e-btn e-lib e-flat float-right"
                  onClick={(): void => history.push(`/tasks/${data.id}`)}
                  style={{ marginRight: 10 }}
                >
                  {translations[language || Language.Hr].overview}
                </button>
                {data &&
                  data.projectFile &&
                  data.projectFile.id &&
                  !projectFileId && (
                    <button
                      className="e-control e-btn e-lib e-flat float-right"
                      onClick={(): void =>
                        history.push(
                          `/projects/${data.projectId}/documents/${data.projectFile.id}`
                        )
                      }
                      style={{ marginRight: 10 }}
                    >
                      {translations[language || Language.Hr].documents}
                    </button>
                  )}
              </div>
            )}
          </Col>
        </Row>
        <br />
        <Row id="newTaskItemRow">
          <Col>
            <RichTextEditorComponent
              height={200}
              toolbarSettings={toolbarSettings}
              value={rteText}
              change={(value) => this.updateRTEField(value)}
            >
              <InjectRTE services={[Toolbar, Link, HtmlEditor]} />
            </RichTextEditorComponent>
            <div
              className="float-right"
              style={{ float: "right", marginTop: 10 }}
            >
              <button
                className="e-control e-btn e-lib e-flat e-primary"
                onClick={(value) => this.onAddNewTaskItem(value)}
              >
                {translations[language || Language.Hr].save}
              </button>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <div>
          {taskActivities.map((taskActivity) => {
            return (
              <Row>
                <Col>
                  <div
                    dangerouslySetInnerHTML={{ __html: taskActivity.text }}
                  />
                </Col>
              </Row>
            );
          })}
        </div>
        {showAlarmButton && (
          <DialogComponent
            isModal={true}
            header={translations[language || Language.Hr].alarms}
            width="700px"
            minHeight="550px"
            visible={isDialogVisible}
            showCloseIcon
            close={() => this.handleDialogChange(false)}
          >
            <div className="dialog-content">
              <Alarm data={data} />
            </div>
          </DialogComponent>
        )}
      </div>
    );
  }
}

export default withRouter(KanbanReadDialogFormTemplate);
