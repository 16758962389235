import Parse from "parse";
import { KanbanColumnModel } from "../../models/KanbanColumnModel";
import { TaskFormBaseModel } from "../../models/TaskBaseModel";
import { createAlarmsFromTaskTemplate } from "../../services/AlarmServices";
import { setPropertyToTask } from "../../services/ProjectServices";
import { createTaskItem } from "../../services/TaskServices";
import { GetTaskStates } from "../../services/TaskStatusServices";
import { Language, Roles } from "../../settings/Enums";

export const createTaskHistoryRow = async (
    task: TaskFormBaseModel,
    selectedOrganization?: any
): Promise<any> => {
    if (!selectedOrganization) return;
    if (!task.id) return;

    const taskObject = Parse.Object.extend("Task");
    const query = new Parse.Query(taskObject).equalTo('objectId', task.id).limit(10000);
    const includeQuery = query.include('TaskState').include('project.role');

    const response = await includeQuery.first();

    if (
        !(
            response &&
            response.id &&
            response.attributes &&
            response.attributes.taskState
        )
    )
        return;

    //if no change to task state, since no other attributes then state are tracked in history, just skip new history item
    if (task.taskStateId === response?.attributes.taskState.id) return;

    const taskHistoryObject = Parse.Object.extend("TaskHistory");
    const taskHistory = new taskHistoryObject();

    const acl = new Parse.ACL();
    acl.setRoleReadAccess(Roles.Admin, true);
    acl.setRoleReadAccess(response.attributes.project.attributes.role.attributes.name, true);
    acl.setRoleWriteAccess(Roles.Admin, true);
    acl.setRoleWriteAccess(response.attributes.project.attributes.role.attributes.name, true);

    const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;

    acl.setRoleReadAccess(orgAdminRoleName, true);
    acl.setRoleWriteAccess(orgAdminRoleName, true);

    taskHistory.set("task", response);
    taskHistory.set("taskState", response.attributes.taskState);
    taskHistory.set("createdBy", Parse.User.current());
    taskHistory.setACL(acl);

    return await taskHistory.save();
};

export const deleteTaskHistoryRow = async (taskHistoryId: any): Promise<void> => {
    const taskHistoryObject = Parse.Object.extend("TaskHistory");
    const taskHistory = new taskHistoryObject();

    taskHistory.set("id", taskHistoryId);

    await taskHistory.destroy();
};

export const saveTask = async (
    taskModel: TaskFormBaseModel,
    taskTemplateId?: string,
    projectFileId?: string,
    selectedOrganization?: any,
    language?: Language
  ): Promise<any> => {
    if (!selectedOrganization) return;
    
    if (taskModel.id) {
      const taskHistoryResponse = await createTaskHistoryRow(taskModel, selectedOrganization);

      const task = await setPropertyToTask(taskModel, projectFileId);
      const taskResponse = await task.save();

      if (taskResponse) {
        return taskResponse;
      } else {
        if (!(taskHistoryResponse && taskHistoryResponse.id)) return;
        await deleteTaskHistoryRow(taskHistoryResponse.id);
        return;
      }
    } else {
      const task = await setPropertyToTask(taskModel, projectFileId);
      const acl = new Parse.ACL();

      const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;

      acl.setRoleReadAccess(orgAdminRoleName, true);
      acl.setRoleWriteAccess(orgAdminRoleName, true);
      acl.setRoleReadAccess(Roles.Admin, true);
      acl.setRoleReadAccess(taskModel.project.attributes.role.attributes.name, true);
      acl.setRoleWriteAccess(Roles.Admin, true);
      acl.setRoleWriteAccess(taskModel.project.attributes.role.attributes.name, true);
      task.setACL(acl);
      const taskResponse = await task.save();

      if (!taskResponse) return;

      if (taskModel.rteBody) {
        await createTaskItem(taskResponse, taskModel.rteBody, projectFileId, selectedOrganization);
      }

      if (taskTemplateId) {
        await createAlarmsFromTaskTemplate(taskResponse, taskTemplateId, selectedOrganization, language);
      }

      return taskResponse;
    }
  };

  export const fetchTaskStates = async (language?: Language): Promise<KanbanColumnModel[]> => {
    const columnsList: KanbanColumnModel[] = [];

    const results = await GetTaskStates();

    if (results && results.length > 0) {
        results.forEach((result) => {
            if (result.attributes && result.id && result.attributes.title) {
                columnsList.push({
                    keyField: result.id,
                    headerText: language && language !== Language.Hr ? result.attributes[`title_${language}`] :  result.attributes.title,
                });
            }
        });
    }
    return columnsList;
};
