import Parse from "parse";
import { AlarmStateBaseModel } from "../models/AlarmStateBaseModel";

export const GetAlarmStates = async () => {
    const object = Parse.Object.extend('AlarmState');
    const objectQuery = new Parse.Query(object).limit(10000);

    const results = await objectQuery.find();

    if (!results) return [];

    const alarmStates: AlarmStateBaseModel[] = [];

    results.forEach(alarmState => {
        alarmStates.push({
            id: alarmState.id,
            title: alarmState.attributes.title,
            title_en: alarmState.attributes.title_en,
        })
    });

    return alarmStates; 
}