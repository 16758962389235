import React, { ReactElement, useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ProjectActivityBaseModel } from "../../models/ProjectActivityBaseModel";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  data: ProjectActivityBaseModel;
  language?: Language;
}

export const ProjectActivityDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const { data, language } = props;

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="titleValidation">
          <Form.Label>{translations[language || Language.Hr].title}</Form.Label>
          <Form.Control
            type="text"
            name="title"
            defaultValue={data.title}
            autoComplete="off"
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="codeValidation">
          <Form.Label>{translations[language || Language.Hr].code}</Form.Label>
          <Form.Control
            type="text"
            name="code"
            defaultValue={data.code}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="descriptionValidation">
          <Form.Label>
            {translations[language || Language.Hr].description}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            defaultValue={data.description}
            autoComplete="off"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="startDateValidation">
          <Form.Label>
            {translations[language || Language.Hr].startDate}
          </Form.Label>
          <DatePickerComponent
            name="startDate"
            format="dd.MM.yyyy"
            allowEdit={false}
            showClearButton={false}
            value={data.startDate}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="endDateValidation">
          <Form.Label>
            {translations[language || Language.Hr].endDate}
          </Form.Label>
          <DatePickerComponent
            name="endDate"
            format="dd.MM.yyyy"
            allowEdit={false}
            showClearButton={false}
            value={data.endDate}
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
