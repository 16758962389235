import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { ProjectPaymentBaseModel } from "../../models/ProjectPaymentBaseModel";
import { GetFirstObjectById } from "../../services/GenericServices";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  data: ProjectPaymentBaseModel;
  language?: Language;
}

export const ProjectPaymentDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const { data, language } = props;
  const [realTotalNetPrice, setRealTotalNetPrice] = useState<number>(
    data.realTotalNetPrice
  );
  const [vatRateDecimal, setVatRateDecimal] = useState<number>(0);

  useEffect(() => {
    getProjectCostContractIdFromRoute();
  }, []);

  const getProjectCostContractIdFromRoute = async (): Promise<void> => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 10 &&
      urlParts[1] === "projects" &&
      urlParts[3] === "activities" &&
      urlParts[5] === "costs" &&
      urlParts[7] === "contracts" &&
      urlParts[9] === "payments"
    ) {
      const projectCostContractResult = await GetFirstObjectById(
        "ProjectCostContract",
        urlParts[8]
      );

      if (projectCostContractResult?.attributes?.vatRate) {
        setVatRateDecimal(projectCostContractResult.attributes.vatRate);
      }
    }
  };

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="realTotalNetPriceValidation">
          <Form.Label>
            {translations[language || Language.Hr].amountWithoutVat}
          </Form.Label>
          <Form.Control
            type="number"
            name="realTotalNetPrice"
            defaultValue={data.realTotalNetPrice}
            onBlur={(e: React.FocusEvent<HTMLInputElement>): void =>
              setRealTotalNetPrice(
                e?.target?.value ? parseFloat(e.target.value) : 0
              )
            }
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="vatAmountValidation">
          <Form.Label>{translations[language || Language.Hr].VAT}</Form.Label>
          <Form.Control
            type="number"
            name="vatAmount"
            value={(realTotalNetPrice * vatRateDecimal).toFixed(2)}
            disabled
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="realTotalVatPriceValidation">
          <Form.Label>
            {translations[language || Language.Hr].inTotal}
          </Form.Label>
          <Form.Control
            type="number"
            name="realTotalVatPrice"
            value={(realTotalNetPrice * (1 + vatRateDecimal)).toFixed(2)}
            disabled
            autoComplete="off"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          controlId="invoiceDateValidation"
          className="payment-date"
        >
          <Form.Label>
            {translations[language || Language.Hr].invoiceDate}
          </Form.Label>
          <DatePickerComponent
            name="invoiceDate"
            format="dd.MM.yyyy"
            allowEdit={false}
            showClearButton={false}
            value={data.invoiceDate}
            style={{ height: 37 }}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="paymentDateValidation"
          className="payment-date"
        >
          <Form.Label>
            {translations[language || Language.Hr].paymentDate}
          </Form.Label>
          <DatePickerComponent
            name="paymentDate"
            format="dd.MM.yyyy"
            allowEdit={false}
            showClearButton={false}
            value={data.paymentDate}
            style={{ height: 37 }}
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
