import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-react-popups";
import React from "react";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  language: Language;
}

interface State {
  isErrorDialogVisible: boolean;
  errorMessage?: string;
}

class ErrorMessageDialog extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isErrorDialogVisible: false,
    };
  }

  // do not delete this, this is used as reference
  private handleDialogVisibilityState = (
    newState: boolean,
    message?: string
  ): void => {
    this.setState({
      isErrorDialogVisible: newState,
      errorMessage: message,
    });
  };

  public render(): any {
    const { isErrorDialogVisible, errorMessage } = this.state;
    const { language } = this.props;

    const buttons: ButtonPropsModel[] = [
      {
        buttonModel: {
          content: translations[language || Language.Hr].ok,
          cssClass: "e-flat",
          isPrimary: true,
        },
        click: () => this.handleDialogVisibilityState(false),
      },
    ];

    return (
      <DialogComponent
        isModal={true}
        width="500px"
        className="validationErrorDialog"
        header={translations[language || Language.Hr].error}
        allowDragging={true}
        buttons={buttons}
        visible={isErrorDialogVisible && !!errorMessage}
      >
        <div className="dialog-content">
          <p style={{ marginBottom: 20 }}>{errorMessage}</p>
        </div>
      </DialogComponent>
    );
  }
}

export default ErrorMessageDialog;
