import Parse from "parse";
import { ProjectActivityBaseModel } from "../../models/ProjectActivityBaseModel";
import { Roles } from "../../settings/Enums";

export const fetchProjectActivitiesByProjectId = async (project?: any): Promise<ProjectActivityBaseModel[]> => {
  if (!project) return [];

  const projectActivityList: ProjectActivityBaseModel[] = [];

  const projectActivity = Parse.Object.extend("ProjectActivity");
  const projectActivityListQuery = new Parse.Query(projectActivity).equalTo('project', project).limit(10000);

  const results = await projectActivityListQuery.include("Project").find();

  if (results && results.length > 0) {
    await Promise.all(
      results.map(async (result) => {
        if (result.attributes && result.id) {

          projectActivityList.push({
            id: result.id,
            code: result.attributes.code,
            startDate: result.attributes.startDate,
            endDate: result.attributes.endDate,
            title: result.attributes.title,
            project: result.attributes.project,
            description: result.attributes.description,
            projectId: result.attributes.project.id,
            totalCost: result.attributes.totalCost || 0,
            totalSubvention: result.attributes.totalSubvention || 0,
            totalCostContract: result.attributes.totalCostContract || 0,
            totalPayment: result.attributes.totalPayment || 0,
            projectShortTitle: result.attributes.project?.attributes?.shortTitle,
            sumDifference: ((result.attributes.totalCost || 0) - (result.attributes.totalCostContract || 0))
          });
        }
      })
    );
  }

  return projectActivityList;
};

export const setPropertyToProjectActivity = (projectActivityValue: ProjectActivityBaseModel, project: any, selectedOrganization?: any) => {
  if (!selectedOrganization) return;

  const projectActivityObject = Parse.Object.extend("ProjectActivity");
  const projectActivity = new projectActivityObject();

  projectActivity.set("id", projectActivityValue.id);
  projectActivity.set("title", projectActivityValue.title);
  projectActivity.set("description", projectActivityValue.description);
  projectActivity.set("code", projectActivityValue.code);
  projectActivity.set("startDate", projectActivityValue.startDate);
  projectActivity.set("endDate", projectActivityValue.endDate);
  projectActivity.set("project", project);

  if (!projectActivityValue.id) {
    const acl = new Parse.ACL();

    const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;
    const orgEmployeeRoleName = `${selectedOrganization.attributes.shortTitle}_EMPLOYEE`;

    acl.setRoleReadAccess(Roles.Admin, true);
    acl.setRoleWriteAccess(Roles.Admin, true);
    acl.setRoleReadAccess(orgEmployeeRoleName, true);
    acl.setRoleWriteAccess(orgEmployeeRoleName, true);
    acl.setRoleReadAccess(orgAdminRoleName, true);
    acl.setRoleWriteAccess(orgAdminRoleName, true);

    if (project?.attributes?.shortCode) {
        const projectRoleName = `PROJECT_${project.attributes.shortCode}`;

        acl.setRoleReadAccess(projectRoleName, true);
        acl.setRoleWriteAccess(projectRoleName, false);
    }

    projectActivity.setACL(acl);
  }

  return projectActivity;
};
