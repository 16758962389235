import React from "react";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-react-popups";
import { FileManager } from "../fileManager";
import { applyProjectDocumentTemplate } from "../../services/ProjectDocumentServices";
import { FileSelectActionType, Language } from "../../settings/Enums";
import { refreshFileManager } from "../../helpers/refreshing/RefreshingHelper";
import { translations } from "../../settings/translation";

interface Props {
  language: Language;
  selectedProject?: any;
}

interface State {
  selectedFolderId?: string;
  templateModalOpen: boolean;
}

let selectedTemplates: string[] = [];

class ProjectTemplateDialog extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      templateModalOpen: false,
    };
  }

  private handleModalState = (newState: boolean): void => {
    this.setState({
      templateModalOpen: newState,
    });
  };

  private updateSelectedFolderId = (folderId?: string): void => {
    this.setState({
      selectedFolderId: folderId,
    });
  };

  private templateSelect = (event: any): void => {
    if (
      event.action === FileSelectActionType.Select &&
      event.fileDetails &&
      event.fileDetails.objectId
    ) {
      selectedTemplates.push(event.fileDetails.objectId);
    } else if (
      event.action === FileSelectActionType.Unselect &&
      event.fileDetails &&
      event.fileDetails.objectId
    ) {
      const index = selectedTemplates.indexOf(event.fileDetails.objectId);
      if (index !== -1) {
        selectedTemplates.splice(index, 1);
      }
    }
  };

  // do not delete this, this is used as reference
  private updateSelectedTemplates = (newTemplates: string[]): void => {
    selectedTemplates = newTemplates;
  };

  private addProjectTemplateToFolder = async () => {
    const { selectedFolderId } = this.state;
    const { selectedProject } = this.props;

    const result = await applyProjectDocumentTemplate(
      selectedTemplates,
      selectedFolderId,
      selectedProject?.shortCode
    );
    if (result) {
      this.updateSelectedFolderId(undefined);
      selectedTemplates = [];
      this.handleModalState(false);
      refreshFileManager();
    }
  };

  public render(): any {
    const { templateModalOpen } = this.state;
    const { language } = this.props;

    const buttons: ButtonPropsModel[] = [
      {
        buttonModel: {
          content: translations[language || Language.Hr].save,
          cssClass: "e-flat",
          isPrimary: true,
        },
        click: () => this.addProjectTemplateToFolder(),
      },
      {
        buttonModel: {
          content: translations[language || Language.Hr].cancel,
          cssClass: "e-flat",
          isPrimary: false,
        },
        click: () => this.handleModalState(false),
      },
    ];

    return (
      <div>
        {templateModalOpen && (
          <DialogComponent
            isModal={true}
            width="80%"
            minHeight="90vh"
            visible={templateModalOpen}
            header={translations[language || Language.Hr].chooseTemplate}
            close={(): void => this.handleModalState(false)}
            showCloseIcon={true}
            closeOnEscape={true}
            buttons={buttons}
          >
            <div className="dialog-content">
              <FileManager
                id="applyTemplateManager"
                isTemplate={true}
                isTemplatePicker={true}
                fileSelect={(event): void => this.templateSelect(event)}
              />
            </div>
          </DialogComponent>
        )}
      </div>
    );
  }
}

export default ProjectTemplateDialog;
