import {
  EditSettingsModel,
  SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuthDataContext } from "../../components/authDataProvider";
import { BreadCrumbsNavigation } from "../../components/breadCrumbsNavigation";
import { DataGrid } from "../../components/dataGrid";
import {
  fetchCostsByProjectActivityId,
  setPropertyToProjectCost
} from "../../helpers/fetching/ActivityCostsServiceHelper";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import { updateTotalCostInProjectAndProjectActivity } from "../../helpers/updateParent/UpdateParentHelper";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { ProjectCostBaseModel } from "../../models/ProjectCostBaseModel";
import { GetFirstObjectById } from "../../services/GenericServices";
import { roleListAdminAndEmployee } from "../../settings/Constants";
import { Language, ParseClassName } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {}

export const ProjectCosts: React.FC<Props> = (): ReactElement => {
  const [projectCosts, setProjectCosts] = useState<ProjectCostBaseModel[]>([]);
  const [projectActivity, setProjectActivity] = useState<any | undefined>(
    undefined
  );
  const history = useHistory();
  const { userRoles, selectedOrganization, language } = useAuthDataContext();

  useEffect(() => {
    getProjectActivityIdFromRoute();
  }, []);

  let projectId: string;
  let activityId: string;

  const getProjectActivityIdFromRoute = async (): Promise<void> => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 6 &&
      urlParts[1] === "projects" &&
      urlParts[3] === "activities" &&
      urlParts[5] === "costs"
    ) {
      projectId = urlParts[2];
      activityId = urlParts[4];

      const projectActivityResult = await GetFirstObjectById(
        "ProjectActivity",
        activityId,
        "project.role"
      );
      const projectCosts = await fetchCostsByProjectActivityId(
        projectActivityResult,
        language
      );
      setProjectActivity(projectActivityResult);
      setProjectCosts(projectCosts);
    }
  };

  const editSettings: EditSettingsModel = {
    allowEditing:
      userRoles &&
      isAnyCommonString(
        userRoles,
        roleListAdminAndEmployee,
        selectedOrganization?.attributes?.shortTitle
      )
        ? true
        : false,
    allowAdding:
      userRoles &&
      isAnyCommonString(
        userRoles,
        roleListAdminAndEmployee,
        selectedOrganization?.attributes?.shortTitle
      )
        ? true
        : false,
    mode: "Dialog",
  };

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "title", direction: "Ascending" }],
  };

  const columns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].title,
      validationRules: {
        required: true,
      },
    },
    {
      field: "code",
      headerText: translations[language || Language.Hr].code,
      validationRules: {
        required: true,
      },
    },
    {
      field: "subventionRate",
      headerText: translations[language || Language.Hr].coFinancingRate,
      validationRules: {
        required: true,
      },
      format: "P2",
    },
    {
      field: "subventionAmount",
      headerText: translations[language || Language.Hr].amountOfCoFinancing,
      validationRules: {
        required: true,
      },
      format: "N2",
    },
    {
      field: "fundingTypeTitle",
      headerText: translations[language || Language.Hr].financingCategory,
    },
    {
      field: "unitPrice",
      headerText: translations[language || Language.Hr].unitPriceShort,
      validationRules: {
        required: true,
      },
      format: "N2",
    },
    {
      field: "numberOfUnits",
      headerText: translations[language || Language.Hr].quantity,
      validationRules: {
        required: true,
      },
    },
    {
      field: "realTotalNetPrice",
      headerText: translations[language || Language.Hr].overallAcceptableShort,
      validationRules: {
        required: true,
      },
      format: "N2",
    },
    {
      field: "vatRate",
      visible: false,
      headerText: translations[language || Language.Hr].vatRate,
      validationRules: {
        required: true,
      },
      format: "P2",
    },
    {
      field: "vatAmount",
      headerText: translations[language || Language.Hr].vatAmount,
      validationRules: {
        required: true,
      },
      format: "N2",
    },
    {
      field: "realTotalVatPrice",
      headerText:
        translations[language || Language.Hr].inTotalWithVAT.toUpperCase(),
      validationRules: {
        required: true,
      },
      format: "N2",
    },
    {
      field: "isVatEligibleCostDisplay",
      headerText: translations[language || Language.Hr].vatEligibleCost,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "totalCostContract",
      headerText: translations[language || Language.Hr].agreed,
      format: "N2",
    },
    {
      field: "sumDifference",
      headerText: translations[language || Language.Hr].difference,
      format: "N2",
    },
    {
      field: "totalPayment",
      headerText: translations[language || Language.Hr].paid,
      format: "N2",
    },
    {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 120,
      template: (props): ReactElement => {
        return (
          <div>
            {projectId && activityId && (
              <div
                className="e-btn"
                onClick={(): void =>
                  history.push(
                    `/projects/${projectId}/activities/${activityId}/costs/${props.id}/contracts`
                  )
                }
              >
                {translations[language || Language.Hr].contracts}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const createOrUpdateProjectCost = async (
    newProjectCost: ProjectCostBaseModel
  ): Promise<void> => {
    const projectCost = await setPropertyToProjectCost(
      newProjectCost,
      projectActivity,
      selectedOrganization
    );
    const response = await projectCost.save();

    if (response) {
      const projectCosts = await fetchCostsByProjectActivityId(
        projectActivity,
        language
      );
      setProjectCosts(projectCosts);

      await updateTotalCostInProjectAndProjectActivity(projectActivity?.id);
    }
  };

  return (
    <section id="cost">
      <BreadCrumbsNavigation
        breadCrumbTemplates={[
          {
            url: "projects",
            title: translations[language || Language.Hr].projects,
          },
          {
            url: "activities",
            title: translations[language || Language.Hr].activities,
          },
        ]}
      />
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={projectCosts}
            columnProperties={columns}
            useDialogTemplate={ParseClassName.ProjectCost}
            createItem={createOrUpdateProjectCost}
            updateItem={createOrUpdateProjectCost}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
            autoFitColumns={true}
          />
        </Col>
      </Row>
    </section>
  );
};
