import React, { ReactElement, useState } from "react";
import Parse from "parse";
import { Col, Form } from "react-bootstrap";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ProjectBaseModel } from "../../models/ProjectBaseModel";
import { ProjectTemplatePicker } from "../../components/projectTemplatePicker";
import {
  DialogComponent,
  ButtonPropsModel,
} from "@syncfusion/ej2-react-popups";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  data: ProjectBaseModel;
  language?: Language;
}

export const ProjectDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const { data, language } = props;
  const [selectedProjectTemplate, setSelectedProjectTemplate] = useState<
    string | undefined
  >(undefined);
  const [uniqueField, setUniqueField] = useState<string>("");
  const [isErrorDialogVisible, setIsErrorDialogVisible] =
    useState<boolean>(false);

  const checkShortCode = async (event: any): Promise<void> => {
    if (!(event && event.target && event.target.value)) return;
    const element = event.target;

    const project = Parse.Object.extend("Project");
    const projectQuery = new Parse.Query(project).limit(10000);
    const results = await projectQuery
      .equalTo("shortCode", element.value)
      .find();

    if (results && results.length > 0) {
      const result = results[0];
      if (
        result &&
        result.id &&
        result.attributes?.shortCode &&
        result.id !== data.id
      ) {
        setUniqueField(result.attributes?.shortCode);
        setIsErrorDialogVisible(true);
        element.value = "";
        return;
      }
    }
    setUniqueField("");
    setIsErrorDialogVisible(false);
  };

  const checkShortCodeRegex = async (event: any): Promise<void> => {
    if (!(event && event.target && event.target.value)) return;
    const element = event.target;

    const filteredText = element.value
      .replace(/[^0-9A-Z_]/g, "")
      .replace(" ", "");
    element.value = filteredText;
  };

  const updateSelectedProject = (value?: string) => {
    setSelectedProjectTemplate(value);
  };

  const buttons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].ok,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => setIsErrorDialogVisible(false),
    },
  ];

  return (
    <div style={{ padding: "15px" }}>
      {!data.id && (
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              {translations[language || Language.Hr].template}
            </Form.Label>
            <ProjectTemplatePicker
              updateSelectedProjectTemplate={updateSelectedProject}
              value={selectedProjectTemplate}
            />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} controlId="titleValidation">
          <Form.Label>{translations[language || Language.Hr].title}</Form.Label>
          <Form.Control
            type="text"
            name="title"
            defaultValue={data.title}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="codeValidation">
          <Form.Label>
            {translations[language || Language.Hr].projectCode}
            <div style={{ display: "inline", marginLeft: 5, fontSize: 10 }}>
              ({translations[language || Language.Hr].accordingToTheContract})
            </div>
          </Form.Label>
          <Form.Control
            type="text"
            name="code"
            defaultValue={data.code}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="shortCodeValidation">
          <Form.Label>
            {translations[language || Language.Hr].shortCode}
            <div style={{ display: "inline", marginLeft: 5, fontSize: 10 }}>
              ({translations[language || Language.Hr].shortCodeFormat})
            </div>
          </Form.Label>
          <Form.Control
            type="text"
            name="shortCode"
            defaultValue={data.shortCode}
            onBlur={(event: any) => checkShortCode(event)}
            autoComplete="off"
            onChange={(event: any) => checkShortCodeRegex(event)}
            readOnly={data.id !== undefined}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="isRegistratedForVatValidation">
          <Form.Label>
            {translations[language || Language.Hr].inTheVatSystem}
          </Form.Label>
          <Form.Check
            type="checkbox"
            name="isRegistratedForVat"
            defaultChecked={data.isRegistratedForVat}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="signDateValidation">
          <Form.Label>
            {translations[language || Language.Hr].signDate}
          </Form.Label>
          <DatePickerComponent
            name="signDate"
            format="dd.MM.yyyy"
            allowEdit={false}
            showClearButton={false}
            value={data.signDate}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="startDateValidation">
          <Form.Label>
            {translations[language || Language.Hr].startOfImplementation}
          </Form.Label>
          <DatePickerComponent
            name="startDate"
            format="dd.MM.yyyy"
            allowEdit={false}
            showClearButton={false}
            value={data.startDate}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="endDateValidation">
          <Form.Label>
            {translations[language || Language.Hr].endOfimplementation}
          </Form.Label>
          <DatePickerComponent
            name="endDate"
            format="dd.MM.yyyy"
            allowEdit={false}
            showClearButton={false}
            value={data.endDate}
          />
        </Form.Group>
      </Form.Row>
      <DialogComponent
        isModal={true}
        width="500px"
        className="validationErrorDialog"
        header={translations[language || Language.Hr].error}
        allowDragging={true}
        buttons={buttons}
        visible={isErrorDialogVisible}
      >
        <div className="dialog-content">
          <p style={{ marginBottom: 20 }}>
            {translations[language || Language.Hr].projectWithThat}{" "}
            <strong> {translations[language || Language.Hr].shortCode} </strong>{" "}
            {translations[language || Language.Hr].alreadyExists}:{" "}
            {<b style={{ color: "red" }}>{uniqueField}</b>}
          </p>
        </div>
      </DialogComponent>
    </div>
  );
};
