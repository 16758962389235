import React, { ReactElement, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { DataGrid } from "../../components/dataGrid";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { TaskTemplateBaseModel } from "../../models/TaskTemplateBaseModel";
import { Language, ParseClassName } from "../../settings/Enums";
import {
  GetTaskTemplates,
  saveTaskTemplate,
} from "../../services/TaskTemplateServices";
import { translations } from "../../settings/translation";
import { useAuthDataContext } from "../../components/authDataProvider";

interface Props {}

export const TemplatesTasks: React.FC<Props> = (props: Props): ReactElement => {
  const [templateTasks, setTemplateTasks] = useState<TaskTemplateBaseModel[]>(
    []
  );
  const { language } = useAuthDataContext();

  const history = useHistory();

  useEffect(() => {
    fetchTaskTemplate();
  }, []);

  const fetchTaskTemplate = async (): Promise<void> => {
    const results = await GetTaskTemplates(language);

    setTemplateTasks(results);
  };

  const createUpdateTaskTemplate = async (
    taskTemplate: TaskTemplateBaseModel
  ) => {
    const response = await saveTaskTemplate(taskTemplate);

    if (response) {
      await fetchTaskTemplate();
    }
  };

  const columns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].title,
      validationRules: {
        required: true,
      },
    },
    {
      field: "description",
      headerText: translations[language || Language.Hr].description,
      validationRules: {
        required: true,
      },
    },
    {
      field: "timeQuantity",
      headerText: translations[language || Language.Hr].number,
      validationRules: {
        required: true,
      },
      type: "number",
    },
    {
      field: "timeUnitDisplayTitle",
      headerText: translations[language || Language.Hr].timeUnit,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "timeUnit",
      headerText: translations[language || Language.Hr].timeUnit,
      validationRules: {
        required: true,
      },
      visible: false,
    },
    {
      field: "isAfterProjectMilestoneDisplayTitle",
      headerText: translations[language || Language.Hr].beforeAfter,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "isAfterProjectMilestone",
      headerText: translations[language || Language.Hr].beforeAfter,
      validationRules: {
        required: true,
      },
      visible: false,
      type: "boolean",
    },
    {
      field: "projectMilestoneDisplayTitle",
      headerText: translations[language || Language.Hr].referentDate,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "projectMilestone",
      headerText: translations[language || Language.Hr].referentDate,
      validationRules: {
        required: true,
      },
      visible: false,
    },
    {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 200,
      template: (props): ReactElement => {
        return (
          <div
            className="e-btn"
            onClick={(): void =>
              history.push(`/templates/tasks/${props.id}/alarms`)
            }
          >
            {translations[language || Language.Hr].alarms}
          </div>
        );
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "title", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    mode: "Dialog",
  };

  return (
    <section id="taskTemplate">
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={templateTasks}
            columnProperties={columns}
            useDialogTemplate={ParseClassName.TaskTemplate}
            updateItem={createUpdateTaskTemplate}
            createItem={createUpdateTaskTemplate}
            fetchDataAfterCatchError={fetchTaskTemplate}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
    </section>
  );
};
