import {
  EditSettingsModel,
  SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import React, { ReactElement, useEffect, useState } from "react";
import { AlarmBaseModel } from "../../models/AlarmBaseModel";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { TaskBaseModel } from "../../models/TaskBaseModel";
import { GetAlarmsByTask, saveAlarm } from "../../services/AlarmServices";
import { GetTaskById } from "../../services/TaskServices";
import { Language, ParseClassName } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import { useAuthDataContext } from "../authDataProvider";
import { DataGrid } from "../dataGrid";

interface Props {
  data: TaskBaseModel;
}

export const Alarm: React.FC<Props> = (props: Props): ReactElement => {
  const [alarms, setAlarms] = useState<AlarmBaseModel[]>([]);
  const { selectedOrganization, language } = useAuthDataContext();

  useEffect(() => {
    fetchAlarms();
  }, []);

  const fetchAlarms = async (): Promise<void> => {
    const { data } = props;

    const task = await GetTaskById(data.id);

    if (!task) return;

    const alarms = await GetAlarmsByTask(task);
    setAlarms(alarms);
  };

  const updateAlarm = async (updatedAlarm: AlarmBaseModel) => {
    const response = await saveAlarm(updatedAlarm, selectedOrganization);

    if (response) {
      await fetchAlarms();
    }
  };

  const createAlarm = async (updatedAlarm: AlarmBaseModel) => {
    const { data } = props;
    const task = await GetTaskById(data.id);

    updatedAlarm.task = task;

    const response = await saveAlarm(updatedAlarm, selectedOrganization);

    if (response) {
      await fetchAlarms();
    }
  };

  const columnProperties: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "alarmStateDisplayTitle",
      headerText: translations[language || Language.Hr].state,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "alarmState",
      headerText: translations[language || Language.Hr].state,
      visible: false,
      validationRules: {
        required: true,
      },
    },
    {
      field: "deliveryTime",
      headerText: translations[language || Language.Hr].time,
      validationRules: {
        required: true,
      },
      type: "datetime",
      format: "dd.MM.yyyy. HH:mm",
      filter: {
        type: "Menu",
        params: {
          format: "dd.MM.yyyy. HH:mm",
        },
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "alarmStateDisplayTitle", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    mode: "Dialog",
  };

  return (
    <div>
      <DataGrid
        data={alarms}
        columnProperties={columnProperties}
        sortSettings={sortSettings}
        editSettings={editSettings}
        useDialogTemplate={ParseClassName.Alarm}
        updateItem={updateAlarm}
        createItem={createAlarm}
        fetchDataAfterCatchError={fetchAlarms}
        allowPaging={false}
      />
    </div>
  );
};
