import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { ReactElement, useEffect, useState } from "react";
import { GetAllObjectsByTableName } from "../../services/GenericServices";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import { useAuthDataContext } from "../authDataProvider";

interface Props {
  updateSelectedProject: (projectId?: string) => void;
}

export const ProjectPicker: React.FC<Props> = (props: Props): ReactElement => {
  const { updateSelectedProject } = props;
  const { selectedOrganization, language } = useAuthDataContext();
  const [dropdownValues, setDropdownValues] = useState<
    { [key: string]: Object }[]
  >([]);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    fetchProjects();
  }, [selectedOrganization]);

  const fetchProjects = async (): Promise<void> => {
    if (!selectedOrganization) return;
    const dropdownItems: { [key: string]: Object }[] = [];
    const results = await GetAllObjectsByTableName("Project", "client");

    if (!(results && results.length > 0)) return;

    results.forEach((result) => {
      if (
        result.attributes &&
        result.id &&
        result.attributes.shortTitle &&
        result.attributes.client &&
        result.attributes.client.attributes.organization &&
        result.attributes.client.attributes.organization.id ===
          selectedOrganization.id
      ) {
        dropdownItems.push({
          groupBy: result.attributes.client.attributes.name,
          text: result.attributes.shortTitle,
          value: result.id,
        });
      }
    });
    setDropdownValues(dropdownItems);
    if (dropdownItems.length === 1) {
      setSelectedProject(dropdownItems[0].value);
    } else {
      setSelectedProject(undefined);
    }
  };

  const setSelectedProject = (value: any): void => {
    setSelectedValue(value);
    updateSelectedProject(value);
  };

  const fields: object = { groupBy: "groupBy", text: "text", value: "value" };

  return (
    <DropDownListComponent
      fields={fields}
      dataSource={dropdownValues}
      sortOrder={"Ascending"}
      placeholder={translations[language || Language.Hr].chooseProject}
      value={selectedValue}
      select={(item) => setSelectedProject(item?.itemData.value)}
    />
  );
};
