import Parse from "parse";
import { UserBaseModel, UserNumberModel } from "../models/UserBaseModel";
import { Roles } from "../settings/Enums";

export const GetFirstUserByPropertyName = async (
  value: string,
  name?: string
) => {
  const parseUser = new Parse.Query(Parse.User).limit(10000);
  const query = parseUser.equalTo(name || "objectId", value);
  return await query.first();
};

export const CreateUser = async (
  userModel: UserBaseModel,
  organizationShortTitle: string,
  isOrgAdmin: boolean
): Promise<Parse.User> => {
  const userObject = Parse.Object.extend("User");
  const user = new userObject();

  user.set("username", userModel.email);
  user.set("email", userModel.email);
  user.set("password", userModel.password);
  user.set("firstName", userModel.firstName);
  user.set("lastName", userModel.lastName);
  
  const orgRoleName = `ORGANIZATION_${organizationShortTitle}`;

  const acl = new Parse.ACL();
  acl.setRoleReadAccess(Roles.Admin, true);
  acl.setRoleWriteAccess(Roles.Admin, true);
  acl.setRoleReadAccess(orgRoleName, true);
  acl.setRoleWriteAccess(orgRoleName, false);
  if (!isOrgAdmin) {    
    const orgAdminRoleName = `${organizationShortTitle}_ADMIN`;
    acl.setRoleReadAccess(orgAdminRoleName, true);
    acl.setRoleWriteAccess(orgAdminRoleName, true);
  }
  user.setACL(acl);

  const userResponse = await user.save();
  return userResponse;
};

export const UpdateUser = async (
  userModel: UserBaseModel
): Promise<Parse.User | undefined> => {
  const parseUser = new Parse.Query(Parse.User).limit(10000);
  const query = parseUser.equalTo("objectId", userModel.id);

  const user = await query.first();

  if (!user) return;

  user.set("username", userModel.email);
  user.set("email", userModel.email);
  user.set("firstName", userModel.firstName);
  user.set("lastName", userModel.lastName);

  const userResponse = user.save();
  return userResponse;
};

export const getNumberOfUsersByOrganization = async (
  organization?: any
): Promise<UserNumberModel | undefined> => {
  if (!organization) return;

  const adminRoleName = `${organization.attributes.shortTitle}_ADMIN`;
  const clientRoleName = `${organization.attributes.shortTitle}_CLIENT`;
  const employeeRoleName = `${organization.attributes.shortTitle}_EMPLOYEE`;

  const adminQuery = new Parse.Query(Parse.Role)
    .equalTo("name", adminRoleName)
    .limit(10000);
  const clientQuery = new Parse.Query(Parse.Role)
    .equalTo("name", clientRoleName)
    .limit(10000);
  const employeeQuery = new Parse.Query(Parse.Role)
    .equalTo("name", employeeRoleName)
    .limit(10000);

  const query = Parse.Query.or(adminQuery, clientQuery, employeeQuery);
  const roles = await query.find();

  if (!roles) return;

  let numberOfUsers: number = 0;

  await Promise.all(
    roles.map(async (role: any) => {
      const usersQuery = role.relation("users");
      const users = await usersQuery.query().find();

      if (users && users.length > 0) {
        numberOfUsers += users.length;
      }
    })
  );

  return {
    currentUserNumber: numberOfUsers,
    maxUserNumber: organization.attributes.maxNumberOfUsers,
  };
};
