import React, { ReactElement } from "react";
import Parse from "parse";
import { Route, Switch } from "react-router-dom";
import { Clients } from "../../routes/client";
import { Contacts } from "../../routes/contact";
import { Employees } from "../../routes/employees";
import { FundingTypes } from "../../routes/fundingTypes";
import { Home } from "../../routes/home";
import { Login } from "../../routes/login";
import { Organizations } from "../../routes/organization";
import { Administrators } from "../../routes/organizationAdministrators";
import { Projects } from "../../routes/project";
import { ProjectActivities } from "../../routes/projectActivities";
import { ProjectCostContracts } from "../../routes/projectCostContracts";
import { ProjectCosts } from "../../routes/projectCosts";
import { ProjectDocuments } from "../../routes/projectDocuments";
import { ProjectPayments } from "../../routes/projectPayments";
import { ProjectUsers } from "../../routes/projectUsers";
import { SuccessfullPasswordReset } from "../../routes/successfullPasswordReset/SuccessfullPasswordReset";
import { Tags } from "../../routes/tags";
import { TaskDetails } from "../../routes/taskDetails";
import { Tasks } from "../../routes/tasks";
import { TemplatesAlarms } from "../../routes/templatesAlarms";
import { TemplatesDocuments } from "../../routes/templatesDocuments";
import { TemplatesProjects } from "../../routes/templatesProjects";
import { TemplatesProjectDocuments } from "../../routes/templatesProjects/documents";
import { TemplatesProjectTasks } from "../../routes/templatesProjects/tasks";
import { TemplatesTasks } from "../../routes/templatesTasks";
import { WeeklyTasks } from "../../routes/weeklyTasks";
import {
  roleListAdminAndAdminOrganization,
  roleListOnlyAdmin,
} from "../../settings/Constants";
import {
  CLIENTS_ROUTE,
  CLIENT_CONTACTS_ROUTE,
  CLIENT_PROJECTS_ROUTE,
  CLIENT_PROJECTS_USERS_ROUTE,
  EMPLOYEES_ROUTE,
  FUNDING_TYPES_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  ORGANIZATIONS_ROUTE,
  ORGANIZATION_ADMINISTRATORS_ROUTE,
  PASSWORD_RESET_ROUTE,
  PROJECTS_ROUTE,
  PROJECT_DOCUMENTS_FOLDER_ROUTE,
  PROJECT_DOCUMENTS_ROUTE,
  PROJECT_PROJECT_ACTIVITIES_ROUTE,
  PROJECT_PROJECT_COSTS_ROUTE,
  PROJECT_PROJECT_COST_CONTRACTS_ROUTE,
  PROJECT_PROJECT_PAYMENTS_ROUTE,
  PROJECT_TASKS_ROUTE,
  TAGS_ROUTE,
  TASKS_BY_STATUS_ROUTE,
  TASKS_ROUTE,
  TASK_DETAILS_ROUTE,
  TEMPLATES_DOCUMENTS_ROUTE,
  TEMPLATES_PROJECTS_ROUTE,
  TEMPLATES_PROJECT_DOCUMENTS_ROUTE,
  TEMPLATES_PROJECT_TASKS_ROUTE,
  TEMPLATES_TASKS_ALARM_ROUTE,
  TEMPLATES_TASKS_ROUTE,
} from "../../settings/Routes";
import { useAuthDataContext } from "../authDataProvider";
import { PrivateRoute } from "../privateRoute";

export const Router: React.FC = (): ReactElement => {
  const { language, initialLoader } = useAuthDataContext();
  const currentUser = Parse.User.current();

  return (currentUser && language && initialLoader) || !currentUser ? (
    <Switch>
      <Route path={LOGIN_ROUTE} exact component={Login} />

      <Route
        path={PASSWORD_RESET_ROUTE}
        exact
        component={SuccessfullPasswordReset}
      />

      <PrivateRoute
        path={ORGANIZATION_ADMINISTRATORS_ROUTE}
        exact
        component={Administrators}
      />

      <PrivateRoute
        path={ORGANIZATIONS_ROUTE}
        exact
        component={Organizations}
      />

      <PrivateRoute path={TASKS_BY_STATUS_ROUTE} exact component={Home} />

      <PrivateRoute path={HOME_ROUTE} exact component={WeeklyTasks} />

      <PrivateRoute path={TASKS_ROUTE} exact component={Tasks} />

      <PrivateRoute path={PROJECTS_ROUTE} exact component={Projects} />
      <PrivateRoute path={PROJECT_TASKS_ROUTE} exact component={Tasks} />
      <PrivateRoute
        path={PROJECT_PROJECT_ACTIVITIES_ROUTE}
        exact
        component={ProjectActivities}
      />
      <PrivateRoute
        path={PROJECT_PROJECT_COSTS_ROUTE}
        exact
        component={ProjectCosts}
      />
      <PrivateRoute
        path={PROJECT_PROJECT_COST_CONTRACTS_ROUTE}
        exact
        component={ProjectCostContracts}
      />
      <PrivateRoute
        path={PROJECT_PROJECT_PAYMENTS_ROUTE}
        exact
        component={ProjectPayments}
      />
      <PrivateRoute
        path={PROJECT_DOCUMENTS_ROUTE}
        exact
        component={ProjectDocuments}
      />
      <PrivateRoute
        path={PROJECT_DOCUMENTS_FOLDER_ROUTE}
        exact
        component={ProjectDocuments}
      />

      <PrivateRoute path={TASK_DETAILS_ROUTE} exact component={TaskDetails} />

      <PrivateRoute
        path={CLIENTS_ROUTE}
        exact
        component={Clients}
        roles={roleListAdminAndAdminOrganization}
      />
      <PrivateRoute
        path={CLIENT_CONTACTS_ROUTE}
        exact
        component={Contacts}
        roles={roleListAdminAndAdminOrganization}
      />
      <PrivateRoute
        path={CLIENT_PROJECTS_ROUTE}
        exact
        component={Projects}
        roles={roleListAdminAndAdminOrganization}
      />
      <PrivateRoute
        path={CLIENT_PROJECTS_USERS_ROUTE}
        exact
        component={ProjectUsers}
        roles={roleListAdminAndAdminOrganization}
      />

      <PrivateRoute
        path={TEMPLATES_PROJECTS_ROUTE}
        exact
        component={TemplatesProjects}
        roles={roleListOnlyAdmin}
      />
      <PrivateRoute
        path={TEMPLATES_PROJECT_TASKS_ROUTE}
        exact
        component={TemplatesProjectTasks}
        roles={roleListOnlyAdmin}
      />
      <PrivateRoute
        path={TEMPLATES_PROJECT_DOCUMENTS_ROUTE}
        exact
        component={TemplatesProjectDocuments}
        roles={roleListOnlyAdmin}
      />

      <PrivateRoute
        path={TEMPLATES_DOCUMENTS_ROUTE}
        exact
        component={TemplatesDocuments}
        roles={roleListOnlyAdmin}
      />

      <PrivateRoute
        path={TEMPLATES_TASKS_ROUTE}
        exact
        component={TemplatesTasks}
        roles={roleListOnlyAdmin}
      />
      <PrivateRoute
        path={TEMPLATES_TASKS_ALARM_ROUTE}
        exact
        component={TemplatesAlarms}
        roles={roleListOnlyAdmin}
      />

      <PrivateRoute
        path={EMPLOYEES_ROUTE}
        exact
        component={Employees}
        roles={roleListAdminAndAdminOrganization}
      />

      <PrivateRoute
        path={TAGS_ROUTE}
        exact
        component={Tags}
        roles={roleListOnlyAdmin}
      />

      <PrivateRoute
        path={FUNDING_TYPES_ROUTE}
        exact
        component={FundingTypes}
        roles={roleListOnlyAdmin}
      />
    </Switch>
  ) : (
    <></>
  );
};
