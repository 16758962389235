import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-react-popups";
import * as React from "react";
import { useState } from "react";
import { Col, Form } from "react-bootstrap";
import Parse from "parse";
import { OrganizationBaseModel } from "../../models/OrganizationBaseModel";
import { translations } from "../../settings/translation";
import { Language } from "../../settings/Enums";

interface Props {
  data: OrganizationBaseModel;
  language?: Language;
}

export const OrganizationDialogFormTemplate: React.FC<Props> = (
  props: Props
): React.ReactElement => {
  const { data, language } = props;
  const [uniqueField, setUniqueField] = useState<string>("");
  const [isErrorDialogVisible, setIsErrorDialogVisible] =
    useState<boolean>(false);

  const checkShortTitle = async (event: any): Promise<void> => {
    if (!(event && event.target && event.target.value)) return;
    const element = event.target;

    const organization = Parse.Object.extend("Organization");
    const organizationQuery = new Parse.Query(organization).limit(10000);
    const results = await organizationQuery
      .equalTo("shortTitle", element.value)
      .find();

    if (results && results.length > 0) {
      const result = results[0];
      if (
        result &&
        result.id &&
        result.attributes?.shortTitle &&
        result.id !== data.objectId
      ) {
        setUniqueField(result.attributes?.shortTitle);
        setIsErrorDialogVisible(true);
        element.value = "";
        return;
      }
    }
    setUniqueField("");
    setIsErrorDialogVisible(false);
  };

  const checkShortTitleRegex = async (event: any): Promise<void> => {
    if (!(event && event.target && event.target.value)) return;
    const element = event.target;

    const filteredText = element.value
      .replace(/[^0-9A-Z_]/g, "")
      .replace(" ", "");
    element.value = filteredText;
  };

  const buttons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: "OK",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => setIsErrorDialogVisible(false),
    },
  ];

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="titleValidation">
          <Form.Label>{translations[language || Language.Hr].title}</Form.Label>
          <Form.Control
            type="text"
            name="title"
            defaultValue={data.title}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="shortTitleValidation">
          <Form.Label>
            {translations[language || Language.Hr].shortTitle}
            <div style={{ display: "inline", marginLeft: 5, fontSize: 10 }}>
              (
              {
                translations[language || Language.Hr]
                  .organizationShortTitleFormat
              }
              )
            </div>
          </Form.Label>
          <Form.Control
            type="text"
            name="shortTitle"
            defaultValue={data.shortTitle}
            onBlur={(event: any) => checkShortTitle(event)}
            autoComplete="off"
            onChange={(event: any) => checkShortTitleRegex(event)}
            readOnly={data.objectId !== undefined}
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="maxNumberOfUsersValidation">
          <Form.Label>
            {translations[language || Language.Hr].maxUsersNumber}
          </Form.Label>
          <Form.Control
            type="number"
            name="maxNumberOfUsers"
            defaultValue={data.maxNumberOfUsers}
            min={1}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="maxNumberOfProjectsValidation">
          <Form.Label>
            {translations[language || Language.Hr].maxProjectNumber}
          </Form.Label>
          <Form.Control
            type="number"
            name="maxNumberOfProjects"
            defaultValue={data.maxNumberOfProjects}
            min={1}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <DialogComponent
        isModal={true}
        width="500px"
        className="validationErrorDialog"
        header={translations[language || Language.Hr].error}
        allowDragging={true}
        buttons={buttons}
        visible={isErrorDialogVisible}
      >
        <div className="dialog-content">
          <p style={{ marginBottom: 20 }}>
            {translations[language || Language.Hr].organizationAlreadyExists}{" "}
            {<b style={{ color: "red" }}>{uniqueField}</b>}
          </p>
        </div>
      </DialogComponent>
    </div>
  );
};
