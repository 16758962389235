import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import Parse from "parse";
import React, { ReactElement, useEffect, useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import croatianFlag from "../../data/croatian-flag.png";
import englishFlag from "../../data/english-flag.png";
import logoTempoSavjetovanje from "../../data/ts_logo.png";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import {
  roleListAdminAndAdminOrganization,
  roleListOnlyAdmin,
} from "../../settings/Constants";
import { Language } from "../../settings/Enums";
import {
  CLIENTS_ROUTE,
  EMPLOYEES_ROUTE,
  FUNDING_TYPES_ROUTE,
  HOME_ROUTE,
  ORGANIZATIONS_ROUTE,
  PROJECTS_ROUTE,
  TAGS_ROUTE,
  TASKS_BY_STATUS_ROUTE,
  TASKS_ROUTE,
  TEMPLATES_DOCUMENTS_ROUTE,
  TEMPLATES_PROJECTS_ROUTE,
  TEMPLATES_TASKS_ROUTE,
} from "../../settings/Routes";
import { translations } from "../../settings/translation";
import { useAuthDataContext } from "../authDataProvider";
import "./NavBar.css";

export const NavBar: React.FC = (): ReactElement => {
  const [currentUsername, setCurrentUsername] = useState<string>("");
  const {
    userRoles,
    organizations,
    selectedOrganization,
    setSelectedOrganization,
    language,
    setLanguage,
  } = useAuthDataContext();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    string | undefined
  >();
  const [organizationDropdown, setOrganizationDropdown] = useState<
    { [key: string]: Object }[]
  >([]);

  useEffect(() => {
    initializeState();
  }, [currentUsername]);

  useEffect(() => {
    fetchOrganizations();
  }, [organizations]);

  const fetchOrganizations = async (): Promise<void> => {
    const dropdownItems: { [key: string]: Object }[] = [];

    if (organizations && organizations.length > 0) {
      organizations.forEach((org): void => {
        if (org && org.id && org.attributes) {
          dropdownItems.push({
            text: org.attributes.title,
            value: org.id,
          });
        }
      });

      setSelectedOrganizationId(selectedOrganization?.id);
      setOrganizationDropdown(dropdownItems);
    }
  };

  const initializeState = (): void => {
    const currentUser = Parse.User.current();
    if (currentUser) {
      setCurrentUsername(currentUser?.attributes.username);
    } else {
      setCurrentUsername("");
    }
  };

  const logout = (): void => {
    Parse.User.logOut();
    window.location.reload();
  };

  const fields: object = { text: "text", value: "value" };

  return (
    <Navbar
      bg="primary"
      variant="dark"
      expand="md"
      className={
        "navbar bg-primary justify-content-center align-content-center"
      }
    >
      <Navbar.Collapse id="basic-navbar-nav">
        <Navbar.Brand>
          <Link to={HOME_ROUTE} className="nav-link">
            <span style={{ color: "#03d2d6" }}>TEMPO</span>
            <span style={{ color: "#ffffff" }}>
              {translations[language || Language.Hr].projects.toLowerCase()}
            </span>
          </Link>
        </Navbar.Brand>
        {currentUsername && userRoles && (
          <Nav className="mr-auto">
            {((organizationDropdown.length > 1 && selectedOrganizationId) ||
              isAnyCommonString(
                userRoles,
                roleListOnlyAdmin,
                selectedOrganization?.attributes?.shortTitle
              )) && (
              <DropDownListComponent
                fields={fields}
                dataSource={organizationDropdown}
                sortOrder={"Ascending"}
                value={selectedOrganizationId}
                placeholder={
                  translations[language || Language.Hr].chooseOrganization
                }
                select={(item) => {
                  if (setSelectedOrganization && organizations) {
                    setSelectedOrganizationId(item?.itemData.value);
                    setSelectedOrganization(
                      organizations.find(
                        (o): boolean => o.id === item?.itemData.value
                      )
                    );
                  }
                }}
              />
            )}
            {isAnyCommonString(
              userRoles,
              roleListOnlyAdmin,
              selectedOrganization?.attributes?.shortTitle
            ) && (
              <Link to={ORGANIZATIONS_ROUTE} className="nav-link">
                {translations[language || Language.Hr].organizations}
              </Link>
            )}
            {isAnyCommonString(
              userRoles,
              roleListAdminAndAdminOrganization,
              selectedOrganization?.attributes?.shortTitle
            ) && (
              <Link to={CLIENTS_ROUTE} className="nav-link">
                {translations[language || Language.Hr].clients}
              </Link>
            )}
            <Nav className="mr-auto">
              <Link to={PROJECTS_ROUTE} className="nav-link">
                {translations[language || Language.Hr].projects}
              </Link>
            </Nav>
            <NavDropdown
              title={translations[language || Language.Hr].tasks}
              id="tasks"
              className="text-light"
            >
              <NavDropdown.Item>
                <Link to={TASKS_ROUTE} className="text-dark nav-link">
                  {translations[language || Language.Hr].byProjects}
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to={HOME_ROUTE} className="text-dark nav-link">
                  {translations[language || Language.Hr].byWeeks}
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to={TASKS_BY_STATUS_ROUTE} className="text-dark nav-link">
                  {translations[language || Language.Hr].byStatuses}
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            {isAnyCommonString(
              userRoles,
              roleListOnlyAdmin,
              selectedOrganization?.attributes?.shortTitle
            ) && (
              <NavDropdown
                title={translations[language || Language.Hr].templates}
                id="templates"
                className="text-light"
              >
                <NavDropdown.Item>
                  <Link
                    to={TEMPLATES_PROJECTS_ROUTE}
                    className="text-dark nav-link"
                  >
                    {translations[language || Language.Hr].projects}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to={TEMPLATES_DOCUMENTS_ROUTE}
                    className="text-dark nav-link"
                  >
                    {translations[language || Language.Hr].documents}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to={TEMPLATES_TASKS_ROUTE}
                    className="text-dark nav-link"
                  >
                    {translations[language || Language.Hr].tasks}
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
            )}
            {isAnyCommonString(
              userRoles,
              roleListOnlyAdmin,
              selectedOrganization?.attributes?.shortTitle
            ) && (
              <NavDropdown
                title={translations[language || Language.Hr].codeBooks}
                id="codes"
                className="text-light"
              >
                <NavDropdown.Item>
                  <Link to={TAGS_ROUTE} className="text-dark nav-link">
                    {translations[language || Language.Hr].tags}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to={FUNDING_TYPES_ROUTE} className="text-dark nav-link">
                    {translations[language || Language.Hr].financingCategories}
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
            )}
            {isAnyCommonString(
              userRoles,
              roleListAdminAndAdminOrganization,
              selectedOrganization?.attributes?.shortTitle
            ) && (
              <Link to={EMPLOYEES_ROUTE} className="nav-link">
                {translations[language || Language.Hr].employees}
              </Link>
            )}
          </Nav>
        )}
        <Nav className="logos">
          <img src={logoTempoSavjetovanje} />
        </Nav>
        {language && (
          <NavDropdown
            title={
              <img
                src={language === Language.En ? englishFlag : croatianFlag}
                alt="language"
              />
            }
            id="tasks"
            className="text-light flag-language-select"
          >
            <NavDropdown.Item>
              <div
                onClick={(): void => {
                  if (setLanguage) {
                    setLanguage(Language.Hr);
                  }
                }}
              >
                <img src={croatianFlag} alt="croatian-flag" />{" "}
                {translations[language || Language.Hr].croatian}
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <div
                onClick={(): void => {
                  if (setLanguage) {
                    setLanguage(Language.En);
                  }
                }}
              >
                <img src={englishFlag} alt="english-flag" />{" "}
                {translations[language || Language.Hr].english}
              </div>
            </NavDropdown.Item>
          </NavDropdown>
        )}
        {currentUsername && (
          <Nav>
            <Nav.Link onClick={() => logout()}>
              {translations[language || Language.Hr].logout} ({currentUsername})
            </Nav.Link>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};
