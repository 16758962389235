import React, { ReactElement, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { DataGrid } from "../../components/dataGrid";
import { BreadCrumbsNavigation } from "../../components/breadCrumbsNavigation";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { AlarmTemplateBaseModel } from "../../models/AlarmTemplateBaseModel";
import { Language, ParseClassName } from "../../settings/Enums";
import {
  GetAlarmTemplatesByTaskTemplateId,
  saveAlarmTemplate,
} from "../../services/AlarmTemplateServices";
import { useAuthDataContext } from "../../components/authDataProvider";
import { translations } from "../../settings/translation";

interface Props {}

export const TemplatesAlarms: React.FC<Props> = (
  props: Props
): ReactElement => {
  const { language } = useAuthDataContext();
  const [templateAlarms, setTemplateAlarms] = useState<
    AlarmTemplateBaseModel[]
  >([]);

  const getTaskTemplateIdFromRoute = (): string => {
    const url = window.location.pathname;
    const urlParts = url.split("/");

    if (
      urlParts &&
      urlParts.length === 5 &&
      urlParts[1] === "templates" &&
      urlParts[2] === "tasks" &&
      urlParts[4] === "alarms"
    ) {
      return urlParts[3];
    }
    return "";
  };

  const taskTemplateId = getTaskTemplateIdFromRoute();

  useEffect(() => {
    fetchAlarmTemplate();
  }, []);

  const fetchAlarmTemplate = async (): Promise<void> => {
    const results = await GetAlarmTemplatesByTaskTemplateId(taskTemplateId, language);

    setTemplateAlarms(results);
  };

  const createUpdateAlarmTemplate = async (
    alarmTemplate: AlarmTemplateBaseModel
  ) => {
    const response = await saveAlarmTemplate(alarmTemplate, taskTemplateId);

    if (response) {
      await fetchAlarmTemplate();
    }
  };

  const columns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "timeQuantity",
      headerText: translations[language || Language.Hr].number,
      validationRules: {
        required: true,
      },
      type: "number",
    },
    {
      field: "timeUnitDisplayTitle",
      headerText: translations[language || Language.Hr].timeUnit,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "timeUnit",
      headerText: translations[language || Language.Hr].timeUnit,
      validationRules: {
        required: true,
      },
      visible: false,
    },
    {
      field: "isBeforeTaskMilestoneDisplayTitle",
      headerText: translations[language || Language.Hr].beforeAfter,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "isBeforeTaskMilestone",
      headerText: translations[language || Language.Hr].beforeAfter,
      validationRules: {
        required: true,
      },
      visible: false,
      type: "boolean",
    },
    {
      field: "taskMilestoneDisplayTitle",
      headerText: translations[language || Language.Hr].referentDate,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "taskMilestone",
      headerText: translations[language || Language.Hr].referentDate,
      validationRules: {
        required: true,
      },
      visible: false,
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "taskMilestoneDisplayTitle", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    mode: "Dialog",
  };

  return (
    <section id="alarmTemplate">
      <BreadCrumbsNavigation
        breadCrumbTemplates={[
          {
            url: "tasks",
            title: translations[language || Language.Hr].tasks,
          },
        ]}
      />
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={templateAlarms}
            columnProperties={columns}
            useDialogTemplate={ParseClassName.AlarmTemplate}
            updateItem={createUpdateAlarmTemplate}
            createItem={createUpdateAlarmTemplate}
            fetchDataAfterCatchError={fetchAlarmTemplate}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
    </section>
  );
};
