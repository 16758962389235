import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-react-popups";
import Parse from "parse";
import * as React from "react";
import { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { TagBaseModel } from "../../models/TagBaseModel";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  data: TagBaseModel;
  language?: Language;
}

export const TagDialogFormTemplate: React.FC<Props> = (
  props: Props
): React.ReactElement => {
  const { data, language } = props;
  const [uniqueField, setUniqueField] = useState<string>("");
  const [isErrorDialogVisible, setIsErrorDialogVisible] =
    useState<boolean>(false);

  const checkTitle = async (event: any): Promise<void> => {
    if (!(event && event.target && event.target.value)) return;
    const element = event.target;

    const tag = Parse.Object.extend("Tag");
    const tagQuery = new Parse.Query(tag).limit(10000);
    const results = await tagQuery.equalTo("title", element.value).find();

    if (results && results.length > 0) {
      const result = results[0];
      if (
        result &&
        result.id &&
        result.attributes?.title &&
        result.id !== data.id
      ) {
        setUniqueField(result.attributes?.title);
        setIsErrorDialogVisible(true);
        element.value = "";
        return;
      }
    }
    setUniqueField("");
    setIsErrorDialogVisible(false);
  };

  const buttons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].ok,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => setIsErrorDialogVisible(false),
    },
  ];

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="titleValidation">
          <Form.Label>{translations[language || Language.Hr].title}</Form.Label>
          <Form.Control
            type="text"
            name="title"
            defaultValue={data.title}
            onBlur={(event: any) => checkTitle(event)}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <DialogComponent
        isModal={true}
        width="500px"
        className="validationErrorDialog"
        header={translations[language || Language.Hr].error}
        allowDragging={true}
        buttons={buttons}
        visible={isErrorDialogVisible}
      >
        <div className="dialog-content">
          <p style={{ marginBottom: 20 }}>
            <strong> {translations[language || Language.Hr].tag} </strong>{" "}
            {translations[language || Language.Hr].alreadyExists}:{" "}
            {<b style={{ color: "red" }}>{uniqueField}</b>}
          </p>
        </div>
      </DialogComponent>
    </div>
  );
};
