import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthDataContext } from "../../components/authDataProvider";
import { FileManager } from "../../components/fileManager";
import NewFolderDialog from "../../components/newFolderDialog";
import TemplateFolderDetails from "../../components/templateFolderDetails";
import { getCustomTemplateMenu } from "../../helpers/toolbarOptions/ToolbarOptionsHelper";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

export const TemplatesDocuments: React.FC = () => {
  const newFolderRef = useRef(null);
  const detailsRef = useRef(null);
  const { userRoles, selectedOrganization, language } = useAuthDataContext();

  const toolbarMenuCustomAdmin: string[] = [
    translations[language || Language.Hr].newFolder,
  ];
  const toolbarMenuCustomDefault: string[] = [];

  const toolbarMenuCustom: string[] = getCustomTemplateMenu(
    toolbarMenuCustomAdmin,
    toolbarMenuCustomDefault,
    userRoles,
    selectedOrganization
  );

  const fileOpen = (event: any): void => {
    if (newFolderRef && newFolderRef.current) {
      (newFolderRef.current as any).updateSelectedFolderId(
        event.fileDetails.objectId
      );
    }
    if (detailsRef && detailsRef.current) {
      (detailsRef.current as any).fetchProjectFileTemplates(
        event.fileDetails.objectId
      );
    }
  };

  const toolbarClick = (event: any): void => {
    if (
      event?.item?.properties?.text ===
      translations[language || Language.Hr].newFolder
    ) {
      openNewFolderModal(true);
    }
  };

  const openNewFolderModal = (newState: boolean): void => {
    if (newFolderRef && newFolderRef.current) {
      (newFolderRef.current as any).handleModalState(newState);
    }
  };

  return (
    <section id="templatesDocuments">
      <Row className={"box-form"}>
        <Col xs={8}>
          <FileManager
            id="templatesDocuments"
            isTemplate={true}
            toolbarClick={toolbarClick}
            toolbarMenuCustom={toolbarMenuCustom}
            fileOpen={fileOpen}
          />
          <NewFolderDialog
            language={language || Language.Hr}
            ref={newFolderRef}
            isTemplate={true}
            selectedOrganization={selectedOrganization}
          />
        </Col>
        <Col xs={4}>
          <TemplateFolderDetails
            language={language || Language.Hr}
            ref={detailsRef}
            userRoles={userRoles}
            selectedOrganization={selectedOrganization}
          />
        </Col>
      </Row>
    </section>
  );
};
