import React, { ReactElement, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import {
  DialogComponent,
  ButtonPropsModel,
} from "@syncfusion/ej2-react-popups";
import { DataGrid } from "../../../components/dataGrid";
import { FileManager } from "../../../components/fileManager";
import { BreadCrumbsNavigation } from "../../../components/breadCrumbsNavigation";
import { DataGridColumnModel } from "../../../models/DataGridColumnModel";
import { DocumentTemplateBaseModel } from "../../../models/DocumentTemplateBaseModel";
import { DataGridToolbarOption } from "../../../models/DataGridToolbarOption";
import {
  ParseClassName,
  FileSelectActionType,
  Language,
} from "../../../settings/Enums";
import {
  GetProjectTemplateDocuments,
  AddDocumentToProjectTemplateRelation,
  RemoveDocumentToProjectTemplateRelation,
} from "../../../services/ProjectTemplateServices";
import { translations } from "../../../settings/translation";
import { useAuthDataContext } from "../../../components/authDataProvider";

interface Props {}

export const TemplatesProjectDocuments: React.FC<Props> = (
  props: Props
): ReactElement => {
  const { language } = useAuthDataContext();
  const [templateDocuments, setTemplateDocuments] = useState<
    DocumentTemplateBaseModel[]
  >([]);
  const [isAddDocumentDialogDisplayed, setIsAddDocumentDialogDisplayed] =
    useState<boolean>(false);
  let selectedTemplates: string[] = [];

  const getProjectIdFromRoute = (): string => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 5 &&
      urlParts[1] === "templates" &&
      urlParts[2] === "projects" &&
      urlParts[4] === "documents"
    ) {
      return urlParts[3];
    }
    return "";
  };

  const projectId = getProjectIdFromRoute();

  useEffect(() => {
    fetchDocumentTemplates();
  }, []);

  const fetchDocumentTemplates = async (): Promise<void> => {
    const results = await GetProjectTemplateDocuments(projectId, language);

    setTemplateDocuments(results);
  };

  const addDocumentToProjectTemplate = async () => {
    const result = await AddDocumentToProjectTemplateRelation(
      selectedTemplates,
      projectId
    );

    if (result) {
      fetchDocumentTemplates();
      setIsAddDocumentDialogDisplayed(false);
    }
  };

  const deleteDocumentFromProjectTemplate = async (
    id: string
  ): Promise<void> => {
    if (!id) return;

    await RemoveDocumentToProjectTemplateRelation(id, projectId);

    fetchDocumentTemplates();
  };

  const openAddDocumentDialog = async (): Promise<void> => {
    setIsAddDocumentDialogDisplayed(true);
    selectedTemplates = [];
  };

  const templateSelect = (event: any): void => {
    if (
      event.action === FileSelectActionType.Select &&
      event.fileDetails &&
      event.fileDetails.objectId
    ) {
      selectedTemplates.push(event.fileDetails.objectId);
    } else if (
      event.action === FileSelectActionType.Unselect &&
      event.fileDetails &&
      event.fileDetails.objectId
    ) {
      const index = selectedTemplates.indexOf(event.fileDetails.objectId);
      if (index !== -1) {
        selectedTemplates.splice(index, 1);
      }
    }
  };

  const columns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].title,
      validationRules: {
        required: true,
      },
      allowEditing: false,
    },
    {
      field: "description",
      headerText: translations[language || Language.Hr].description,
      validationRules: {
        required: true,
      },
      allowEditing: false,
    },
    {
      field: "isFileDisplayTitle",
      headerText: translations[language || Language.Hr].file,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "isFile",
      headerText: translations[language || Language.Hr].file,
      validationRules: {
        required: true,
      },
      visible: false,
      allowEditing: false,
    },
    {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 200,
      template: (props): ReactElement => {
        return (
          <div
            className="e-btn"
            onClick={(): Promise<void> =>
              deleteDocumentFromProjectTemplate(props.id)
            }
          >
            {translations[language || Language.Hr].delete}
          </div>
        );
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "title", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: false,
    allowAdding: false,
  };

  const buttons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].save,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => addDocumentToProjectTemplate(),
    },
    {
      buttonModel: {
        content: translations[language || Language.Hr].cancel,
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => setIsAddDocumentDialogDisplayed(false),
    },
  ];

  const addDocumentToolbarOption: DataGridToolbarOption = {
    text: translations[language || Language.Hr].addDocument,
    id: "customToolbarOption",
    tooltipText:
      translations[language || Language.Hr].addDocumentTemplateToProject,
    prefixIcon: "e-add",
  };

  return (
    <section id="documentTemplate">
      <BreadCrumbsNavigation
        breadCrumbTemplates={[
          {
            url: "projects",
            title: translations[language || Language.Hr].projectTemplates,
          },
        ]}
      />
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={templateDocuments}
            columnProperties={columns}
            useDialogTemplate={ParseClassName.DocumentTemplate}
            customToolbarOption={addDocumentToolbarOption}
            handleCustomToolbarOption={openAddDocumentDialog}
            showOnlyCustomToolbarOption
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
      <DialogComponent
        isModal={true}
        width="80%"
        minHeight="90vh"
        visible={isAddDocumentDialogDisplayed}
        header={translations[language || Language.Hr].chooseTemplate}
        close={() => setIsAddDocumentDialogDisplayed(false)}
        showCloseIcon={true}
        closeOnEscape={true}
        buttons={buttons}
      >
        <div className="dialog-content" style={{ padding: "15px" }}>
          {isAddDocumentDialogDisplayed && (
            <FileManager
              id="applyTemplateManager"
              isTemplate={true}
              isTemplatePicker={true}
              fileSelect={(event): void => templateSelect(event)}
            />
          )}
        </div>
      </DialogComponent>
    </section>
  );
};
