import { PdfTrueTypeFont } from "@syncfusion/ej2-pdf-export";
import {
  Aggregate,
  AggregateColumnDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  ExcelExport,
  Filter,
  FilterSettingsModel,
  Grid,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  PdfExport,
  PdfExportProperties,
  Resize,
  Sort,
  SortSettingsModel,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { openSansFont } from "../../fonts/openSanse64";
import { AdminDialogFormTemplate } from "../../helpers/dialogTemplates/AdminDialogFormTemplate";
import { AlarmDialogFormTemplate } from "../../helpers/dialogTemplates/AlarmDialogTemplate";
import { AlarmTemplateDialogFormTemplate } from "../../helpers/dialogTemplates/AlarmTemplateDialogFormTemplate";
import { ClientDialogFormTemplate } from "../../helpers/dialogTemplates/ClientDialogTemplate";
import { ContactDialogFormTemplate } from "../../helpers/dialogTemplates/ContactDialogTemplate";
import { FundingTypeDialogFormTemplate } from "../../helpers/dialogTemplates/FundingTypeDialogTemplate";
import { OrganizationDialogFormTemplate } from "../../helpers/dialogTemplates/OrganizationDialogTemplate";
import { PersonDialogFormTemplate } from "../../helpers/dialogTemplates/PersonDialogFormTemplate";
import { ProjectActivityDialogFormTemplate } from "../../helpers/dialogTemplates/ProjectActivityDialogTemplate";
import { ProjectCostContractDialogFormTemplate } from "../../helpers/dialogTemplates/ProjectCostContractDialogTemplate";
import { ProjectCostDialogFormTemplate } from "../../helpers/dialogTemplates/ProjectCostDialogTemplate";
import { ProjectDialogFormTemplate } from "../../helpers/dialogTemplates/ProjectDialogTemplate";
import { ProjectPaymentDialogFormTemplate } from "../../helpers/dialogTemplates/ProjectPaymentDialogTemplate";
import { ProjectTemplateDialogFormTemplate } from "../../helpers/dialogTemplates/ProjectTemplateDialogFormTemplate";
import { TagDialogFormTemplate } from "../../helpers/dialogTemplates/TagDialogTemplate";
import { TaskTemplateDialogFormTemplate } from "../../helpers/dialogTemplates/TaskTemplateDialogFormTemplate";
import { AlarmBaseModel } from "../../models/AlarmBaseModel";
import { AlarmTemplateBaseModel } from "../../models/AlarmTemplateBaseModel";
import { ClientBaseModel } from "../../models/ClientBaseModel";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { DataGridToolbarOption } from "../../models/DataGridToolbarOption";
import { FooterAggregateSettingModel } from "../../models/FooterAggregateSettingModel";
import { FundingTypeBaseModel } from "../../models/FundingTypeBaseModel";
import { OrganizationBaseModel } from "../../models/OrganizationBaseModel";
import { PersonBaseModel } from "../../models/PersonBaseModel";
import { ProjectActivityBaseModel } from "../../models/ProjectActivityBaseModel";
import { ProjectBaseModel } from "../../models/ProjectBaseModel";
import { ProjectCostBaseModel } from "../../models/ProjectCostBaseModel";
import { ProjectCostContractBaseModel } from "../../models/ProjectCostContractBaseModel";
import { ProjectPaymentBaseModel } from "../../models/ProjectPaymentBaseModel";
import { ProjectTemplateBaseModel } from "../../models/ProjectTemplateBaseModel";
import { TagBaseModel } from "../../models/TagBaseModel";
import { TaskTemplateBaseModel } from "../../models/TaskTemplateBaseModel";
import { UserBaseModel } from "../../models/UserBaseModel";
import {
  DataGridActionType,
  DataGridRequestType,
  Language,
  ParseClassName,
} from "../../settings/Enums";
import { translations } from "../../settings/translation";
import { useAuthDataContext } from "../authDataProvider";
import CustomSpinner from "../custom-spinner";
import ErrorMessageDialog from "../errorMessageDialog";

interface Props {
  data: any[];
  columnProperties: DataGridColumnModel[];
  footerAggregateSettings?: FooterAggregateSettingModel[];
  sortSettings: SortSettingsModel;
  editSettings: EditSettingsModel;
  useDialogTemplate?: string;
  updateItem?: (data: any) => Promise<void>;
  createItem?: (data: any) => Promise<void>;
  fetchDataAfterCatchError?: () => Promise<void>;
  customToolbarOption?: DataGridToolbarOption;
  handleCustomToolbarOption?: () => Promise<void>;
  showOnlyCustomToolbarOption?: boolean;
  allowPaging: boolean;
  autoFitColumns?: boolean;
}

let grid: Grid | null = null;

export const DataGrid: React.FC<Props> = (props: Props): ReactElement => {
  const [columns, setColumns] = useState<ReactElement[]>([]);
  const [scrollHeight, setScrollHeight] = useState<number>(600);
  const [footerAggregates, setFooterAggregates] = useState<ReactElement[]>([]);
  const { language } = useAuthDataContext();
  const customSpinnerRef = useRef(null);
  const errorDialogRef = useRef(null);

  useEffect(() => {
    setColumns(getColumns(props.columnProperties));
    setFooterAggregates(getFooterAggregates(props.footerAggregateSettings));
  }, [props.columnProperties, props.footerAggregateSettings]);

  useEffect(() => {
    const tableToolbarClass = document.getElementsByClassName(
      "e-toolbar-items e-tbar-pos"
    );
    const tableHeaderClass = document.getElementsByClassName(
      "e-gridheader e-lib e-droppable"
    );
    const footerClass = document.getElementsByClassName(
      "e-gridpager e-control e-pager e-lib"
    );
    if (
      tableToolbarClass?.length > 0 &&
      tableHeaderClass?.length > 0 &&
      footerClass?.length > 0
    ) {
      const dimensions = tableToolbarClass[0].getBoundingClientRect();
      
      setScrollHeight(
        window.innerHeight -
          dimensions.top -
          tableHeaderClass[0].clientHeight -
          footerClass[0].clientHeight -
          60
      );
    }
  }, [columns]);

  const getColumns = (
    columnProperties: DataGridColumnModel[]
  ): ReactElement[] => {
    return columnProperties.map((property) => (
      <ColumnDirective
        field={property.field}
        visible={property.visible}
        headerText={property.headerText}
        isPrimaryKey={property.isPrimaryKey}
        validationRules={property.validationRules}
        width={property.width}
        allowSorting={property.allowSorting}
        allowEditing={property.allowEditing}
        // Here default value for undefined is not true, so we need to use this fix
        allowFiltering={
          property.allowFiltering === undefined ? true : property.allowFiltering
        }
        template={property.template}
        format={property.format}
        textAlign={
          property.format === "P2" || property.format === "N2"
            ? "Right"
            : "Left"
        }
        type={property.type}
        filter={property.filter ?? "Menu"}
      />
    ));
  };

  const getFooterAggregates = (
    footerAggregateSettings?: FooterAggregateSettingModel[]
  ): ReactElement[] => {
    return footerAggregateSettings && footerAggregateSettings.length > 0
      ? footerAggregateSettings.map((property) => (
          <AggregateColumnDirective
            field={property.column}
            type={property.type}
            format="N2"
            footerTemplate={getFooterTemplate}
          />
        ))
      : [];
  };

  const getFooterTemplate = (props?: any): any => {
    return <span>{props.Sum}</span>;
  };

  const toolbarClickHandler = (args: any): void => {
    if (
      args.item.id === "customToolbarOption" &&
      props.handleCustomToolbarOption
    ) {
      props.handleCustomToolbarOption();
    }

    if (grid && args.item.id) {
      const idParts = args.item.id.split("_");
      if (
        idParts[0] === "grid" &&
        idParts[idParts.length - 1] === "pdfexport"
      ) {
        const pdfExportProperties: PdfExportProperties = {
          theme: {
            caption: { font: new PdfTrueTypeFont(openSansFont, 10) },
            header: { font: new PdfTrueTypeFont(openSansFont, 12) },
            record: { font: new PdfTrueTypeFont(openSansFont, 9) },
          },
        };
        grid.pdfExport(pdfExportProperties);
      }
      if (
        idParts[0] === "grid" &&
        idParts[idParts.length - 1] === "excelexport"
      ) {
        grid.excelExport();
      }
    }
  };

  const actionBegin = async (args: any): Promise<void> => {
    const { updateItem, createItem, fetchDataAfterCatchError } = props;

    if (grid && args) {
      const { requestType, data, action } = args;
      const spinner = customSpinnerRef && customSpinnerRef.current;

      if (
        (action === DataGridActionType.Edit ||
          action === DataGridActionType.Add) &&
        data &&
        requestType !== DataGridRequestType.Cancel
      ) {
        if (spinner) {
          (spinner as any).handleSpinnerState(true);
        }
        try {
          if (action === DataGridActionType.Edit && updateItem) {
            await updateItem(data);
          } else if (createItem) {
            await createItem(data);
          }
        } catch (error) {
          const errorMessage = errorDialogRef && errorDialogRef.current;
          if (errorMessage) {
            (errorMessage as any).handleDialogVisibilityState(
              true,
              typeof (error as any)?.message === "string"
                ? (error as any).message
                : translations[language || Language.Hr].unknownError
            );
          }
          if (fetchDataAfterCatchError) {
            fetchDataAfterCatchError();
          }
        }
      } else if (requestType === DataGridRequestType.Cancel) {
        args.cancel = false;
      }
    }
  };

  const actionComplete = async (args: any): Promise<void> => {
    if (grid && args) {
      const { requestType, dialog } = args;
      if (
        (requestType === DataGridRequestType.BeginEdit ||
          requestType === DataGridRequestType.Add) &&
        dialog
      ) {
        dialog.header = dialogHeader(requestType);
      }
      if (requestType === DataGridRequestType.Save) {
        const spinner = customSpinnerRef && customSpinnerRef.current;
        if (spinner) {
          (spinner as any).handleSpinnerState(false);
        }
      }
    }
  };

  const dialogHeader = (requestType: string): string => {
    const actionName =
      requestType === DataGridRequestType.BeginEdit
        ? translations[language || Language.Hr].edit
        : translations[language || Language.Hr].new;
    switch (props.useDialogTemplate) {
      case ParseClassName.Contact:
        return `${actionName} - ${
          translations[language || Language.Hr].contact
        }`;
      case ParseClassName.Organization:
        return `${actionName} - ${
          translations[language || Language.Hr].organization
        }`;
      case ParseClassName.Project:
        return `${actionName} - ${
          translations[language || Language.Hr].project
        }`;
      case ParseClassName.ProjectActivity:
        return `${actionName} - ${
          translations[language || Language.Hr].activity
        }`;
      case ParseClassName.ProjectCost:
        return `${actionName} - ${translations[language || Language.Hr].cost}`;
      case ParseClassName.ProjectCostContract:
        return `${actionName} - ${
          translations[language || Language.Hr].contract
        }`;
      case ParseClassName.ProjectPayment:
        return `${actionName} - ${
          translations[language || Language.Hr].payment
        }`;
      case ParseClassName.Client:
        return `${actionName} - ${
          translations[language || Language.Hr].client
        }`;
      case ParseClassName.Person:
        return `${actionName} - ${
          translations[language || Language.Hr].employee
        }`;
      case ParseClassName.Administrator:
        return `${actionName} - ${
          translations[language || Language.Hr].administrator
        }`;
      case ParseClassName.Alarm:
        return `${actionName} - ${translations[language || Language.Hr].alarm}`;
      case ParseClassName.TaskTemplate:
        return `${actionName} - ${
          translations[language || Language.Hr].taskTemplate
        }`;
      case ParseClassName.AlarmTemplate:
        return `${actionName} - ${
          translations[language || Language.Hr].alarmTemplate
        }`;
      case ParseClassName.ProjectTemplate:
        return `${actionName} - ${
          translations[language || Language.Hr].projectTemplate
        }`;
      case ParseClassName.Tag:
        return `${actionName} - ${translations[language || Language.Hr].tag}`;
      case ParseClassName.FundingType:
        return `${actionName} - ${
          translations[language || Language.Hr].financingCategory
        }`;
      default:
        return actionName;
    }
  };

  const dialogTemplate = (
    dialogData:
      | PersonBaseModel
      | ProjectBaseModel
      | ProjectCostBaseModel
      | ProjectPaymentBaseModel
      | ProjectCostContractBaseModel
      | ClientBaseModel
      | AlarmBaseModel
      | TaskTemplateBaseModel
      | AlarmTemplateBaseModel
      | ProjectTemplateBaseModel
      | TagBaseModel
      | FundingTypeBaseModel
      | ProjectActivityBaseModel
      | OrganizationBaseModel
      | UserBaseModel
  ): ReactElement | undefined => {
    const a = [dialogData, grid];

    switch (props.useDialogTemplate) {
      case ParseClassName.Contact:
        return (
          <ContactDialogFormTemplate
            {...a}
            data={dialogData as PersonBaseModel}
            language={language}
          />
        );
      case ParseClassName.Organization:
        return (
          <OrganizationDialogFormTemplate
            {...a}
            data={dialogData as OrganizationBaseModel}
            language={language}
          />
        );
      case ParseClassName.Project:
        return (
          <ProjectDialogFormTemplate
            {...a}
            data={dialogData as ProjectBaseModel}
            language={language}
          />
        );
      case ParseClassName.ProjectActivity:
        return (
          <ProjectActivityDialogFormTemplate
            {...a}
            data={dialogData as ProjectActivityBaseModel}
            language={language}
          />
        );
      case ParseClassName.ProjectCost:
        return (
          <ProjectCostDialogFormTemplate
            {...a}
            data={dialogData as ProjectCostBaseModel}
            language={language}
          />
        );
      case ParseClassName.ProjectCostContract:
        return (
          <ProjectCostContractDialogFormTemplate
            {...a}
            data={dialogData as ProjectCostContractBaseModel}
            language={language}
          />
        );
      case ParseClassName.ProjectPayment:
        return (
          <ProjectPaymentDialogFormTemplate
            {...a}
            data={dialogData as ProjectPaymentBaseModel}
            language={language}
          />
        );
      case ParseClassName.Client:
        return (
          <ClientDialogFormTemplate
            {...a}
            language={language}
            data={dialogData as ClientBaseModel}
          />
        );
      case ParseClassName.Person:
        return (
          <PersonDialogFormTemplate
            {...a}
            data={dialogData as PersonBaseModel}
            language={language}
          />
        );
      case ParseClassName.Administrator:
        return (
          <AdminDialogFormTemplate {...a} data={dialogData as UserBaseModel} />
        );
      case ParseClassName.Alarm:
        return (
          <AlarmDialogFormTemplate
            {...a}
            data={dialogData as AlarmBaseModel}
            language={language}
          />
        );
      case ParseClassName.TaskTemplate:
        return (
          <TaskTemplateDialogFormTemplate
            {...a}
            data={dialogData as TaskTemplateBaseModel}
            language={language}
          />
        );
      case ParseClassName.AlarmTemplate:
        return (
          <AlarmTemplateDialogFormTemplate
            {...a}
            data={dialogData as AlarmTemplateBaseModel}
            language={language}
          />
        );
      case ParseClassName.ProjectTemplate:
        return (
          <ProjectTemplateDialogFormTemplate
            {...a}
            data={dialogData as ProjectTemplateBaseModel}
            language={language}
          />
        );
      case ParseClassName.Tag:
        return (
          <TagDialogFormTemplate
            {...a}
            data={dialogData as TagBaseModel}
            language={language}
          />
        );
      case ParseClassName.FundingType:
        return (
          <FundingTypeDialogFormTemplate
            {...a}
            data={dialogData as FundingTypeBaseModel}
            language={language}
          />
        );
      default:
        return undefined;
    }
  };

  const toolbarOptions: (string | DataGridToolbarOption)[] =
    props.showOnlyCustomToolbarOption ? [] : ["Add", "Edit"];
  if (props.customToolbarOption) {
    toolbarOptions.push(props.customToolbarOption);
  }
  toolbarOptions.push("Print");
  toolbarOptions.push("PdfExport");
  toolbarOptions.push("ExcelExport");
  toolbarOptions.push("ColumnChooser");
  const dialogEditSettings =
    props.editSettings.mode === "Dialog"
      ? {
          params: {
            width: "700px",
          },
        }
      : {};
  const editSettings: EditSettingsModel = {
    ...props.editSettings,
    dialog: dialogEditSettings,
    template: dialogTemplate,
  };

  const filterSettings: FilterSettingsModel = {
    type: "Menu",
  };

  const pageSettings: PageSettingsModel = {
    pageCount: 5,
    pageSizes: [10, 20, 50, 100],
    pageSize: 50,
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <CustomSpinner
          ref={customSpinnerRef}
          language={language || Language.Hr}
          spinnerClass="custom-spinner-wrapper"
        />
        <GridComponent
          dataSource={props.data}
          toolbar={toolbarOptions.length > 0 ? toolbarOptions : undefined}
          toolbarClick={toolbarClickHandler}
          allowPaging={props.allowPaging}
          allowSorting={true}
          height={scrollHeight}
          allowResizing={true}
          showColumnChooser={true}
          allowPdfExport={true}
          allowExcelExport={true}
          sortSettings={props.sortSettings}
          editSettings={editSettings}
          allowFiltering={true}
          filterSettings={filterSettings}
          pageSettings={pageSettings}
          actionBegin={(args) => actionBegin(args)}
          actionComplete={actionComplete}
          ref={(g) => (grid = g)}
          dataBound={() => {
            if (grid && props.autoFitColumns) {
              grid?.autoFitColumns();
            }
          }}
        >
          <ColumnsDirective>{columns}</ColumnsDirective>
          {footerAggregates && footerAggregates.length > 0 && (
            <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  {footerAggregates}
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
          )}
          <Inject
            services={[
              Page,
              Toolbar,
              Edit,
              Sort,
              Filter,
              Resize,
              ColumnChooser,
              Aggregate,
              PdfExport,
              ExcelExport,
            ]}
          />
        </GridComponent>
        <ErrorMessageDialog
          language={language || Language.Hr}
          ref={errorDialogRef}
        />
      </div>
    </div>
  );
};
