export const HOME_ROUTE = '/';

export const TASKS_BY_STATUS_ROUTE = '/tasks-by-status';

export const LOGIN_ROUTE = '/login';

export const PASSWORD_RESET_ROUTE = '/password-reset';

export const ORGANIZATIONS_ROUTE = '/organizations';

export const ORGANIZATION_ADMINISTRATORS_ROUTE = '/organizations/:organizationId/administrators';

export const CLIENTS_ROUTE = '/clients';

export const CLIENT_CONTACTS_ROUTE = '/clients/:clientId/contacts';

export const CLIENT_PROJECTS_ROUTE = '/clients/:clientId/projects';

export const CLIENT_PROJECTS_USERS_ROUTE = '/clients/:clientId/projects/:projectId/users';

export const TEMPLATES_PROJECTS_ROUTE = '/templates/projects';

export const TEMPLATES_PROJECT_TASKS_ROUTE = '/templates/projects/:projectId/tasks';

export const TEMPLATES_PROJECT_DOCUMENTS_ROUTE = '/templates/projects/:projectId/documents';

export const TEMPLATES_DOCUMENTS_ROUTE = '/templates/documents';

export const TEMPLATES_TASKS_ROUTE = '/templates/tasks';

export const TEMPLATES_TASKS_ALARM_ROUTE = '/templates/tasks/:taskId/alarms';

export const EMPLOYEES_ROUTE = '/employees';

export const TASK_DETAILS_ROUTE = '/tasks/:taskId';

export const PROJECTS_ROUTE = '/projects';

export const PROJECT_TASKS_ROUTE = '/projects/:projectId/tasks';

export const PROJECT_PROJECT_ACTIVITIES_ROUTE = '/projects/:projectId/activities';

export const PROJECT_PROJECT_COSTS_ROUTE = '/projects/:projectId/activities/:activityId/costs';

export const PROJECT_PROJECT_COST_CONTRACTS_ROUTE = '/projects/:projectId/activities/:activityId/costs/:costId/contracts';

export const PROJECT_PROJECT_PAYMENTS_ROUTE = '/projects/:projectId/activities/:activityId/costs/:costId/contracts/:contractId/payments';

export const PROJECT_DOCUMENTS_ROUTE = '/projects/:projectId/documents';

export const PROJECT_DOCUMENTS_FOLDER_ROUTE = '/projects/:projectId/documents/:folderId';

export const TAGS_ROUTE = '/tags';

export const FUNDING_TYPES_ROUTE = '/funding-types';

export const TASKS_ROUTE = '/tasks';