import React, { ReactElement, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { BreadCrumbsBaseModel } from "../../models/BreadCrumbBaseModel";
import "./styles.css";

interface Props {
  breadCrumbTemplates?: BreadCrumbsBaseModel[];
}

export const BreadCrumbsNavigation: React.FC<Props> = (
  props: Props
): ReactElement => {
  const [breadCrumbs, setBreadCrumbs] = useState<ReactElement[]>([]);
  const history = useHistory();
  const { breadCrumbTemplates } = props;

  useEffect(() => {
    generateBreadCrumbs();
  }, [breadCrumbTemplates]);

  const generateBreadCrumbs = (): void => {
    const url = window.location.pathname;

    const localBreadCrumbs: ReactElement[] = [];

    breadCrumbTemplates?.forEach((template: BreadCrumbsBaseModel): void => {
      const index = url.indexOf(template.url);
      const breadCrumbUrl = url.substring(0, index + template.url.length);
      localBreadCrumbs.push(
        <Button
          onClick={(): void => history.push(breadCrumbUrl)}
          className="bread-crumbs-navigation-button"
        >
          {localBreadCrumbs.length > 0 ? " > " : "> "}
          {template.title.toUpperCase()}
        </Button>
      );
    });

    setBreadCrumbs(localBreadCrumbs);
  };

  return (
    <Row>
      <div className="bread-crumbs-navigation-wrapper">{breadCrumbs}</div>
    </Row>
  );
};
