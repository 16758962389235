import React, { ReactElement, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import {
  DialogComponent,
  ButtonPropsModel,
} from "@syncfusion/ej2-react-popups";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DataGrid } from "../../../components/dataGrid";
import { BreadCrumbsNavigation } from "../../../components/breadCrumbsNavigation";
import { DataGridColumnModel } from "../../../models/DataGridColumnModel";
import { TaskTemplateBaseModel } from "../../../models/TaskTemplateBaseModel";
import { DataGridToolbarOption } from "../../../models/DataGridToolbarOption";
import { Language, ParseClassName } from "../../../settings/Enums";
import { GetTaskTemplatesDropdown } from "../../../services/TaskTemplateServices";
import {
  GetProjectTemplateTasks,
  AddTaskToProjectTemplateRelation,
  RemoveTaskToProjectTemplateRelation,
} from "../../../services/ProjectTemplateServices";
import { useAuthDataContext } from "../../../components/authDataProvider";
import { translations } from "../../../settings/translation";

interface Props {}

export const TemplatesProjectTasks: React.FC<Props> = (
  props: Props
): ReactElement => {
  const { language } = useAuthDataContext();
  const [templateTasks, setTemplateTasks] = useState<TaskTemplateBaseModel[]>(
    []
  );
  const [isAddTaskDialogDisplayed, setIsAddTaskDialogDisplayed] =
    useState<boolean>(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string>();
  const [newTasks, setNewTasks] = useState<{ [key: string]: Object }[]>([]);

  const getProjectIdFromRoute = (): string => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 5 &&
      urlParts[1] === "templates" &&
      urlParts[2] === "projects" &&
      urlParts[4] === "tasks"
    ) {
      return urlParts[3];
    }
    return "";
  };

  const projectId = getProjectIdFromRoute();

  useEffect(() => {
    fetchTaskTemplates();
    fetchTaskTemplatesDropdown();
  }, []);

  const fetchTaskTemplates = async (): Promise<void> => {
    const results = await GetProjectTemplateTasks(projectId, language);

    setTemplateTasks(results);
  };

  const fetchTaskTemplatesDropdown = async (): Promise<void> => {
    const results = await GetTaskTemplatesDropdown();

    setNewTasks(results);
  };

  const addTaskToProjectTemplate = async (id?: string) => {
    if (!id) return;

    const result = await AddTaskToProjectTemplateRelation(id, projectId);

    if (result) {
      fetchTaskTemplates();
      setIsAddTaskDialogDisplayed(false);
    }
  };

  const deleteTaskFromProjectTemplate = async (id: string): Promise<void> => {
    if (!id) return;

    await RemoveTaskToProjectTemplateRelation(id, projectId);

    fetchTaskTemplates();
  };

  const openAddTaskDialog = async (): Promise<void> => {
    setSelectedTaskId(undefined);
    setIsAddTaskDialogDisplayed(true);
  };

  const columns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].title,
      validationRules: {
        required: true,
      },
      allowEditing: false,
    },
    {
      field: "description",
      headerText: translations[language || Language.Hr].description,
      validationRules: {
        required: true,
      },
      allowEditing: false,
    },
    {
      field: "timeQuantity",
      headerText: translations[language || Language.Hr].number,
      validationRules: {
        required: true,
      },
      allowEditing: false,
    },
    {
      field: "timeUnitDisplayTitle",
      headerText: translations[language || Language.Hr].timeUnit,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "timeUnit",
      headerText: translations[language || Language.Hr].timeUnit,
      validationRules: {
        required: true,
      },
      visible: false,
      allowEditing: false,
    },
    {
      field: "isAfterProjectMilestoneDisplayTitle",
      headerText: translations[language || Language.Hr].beforeAfter,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "isAfterProjectMilestone",
      headerText: translations[language || Language.Hr].beforeAfter,
      validationRules: {
        required: true,
      },
      visible: false,
      allowEditing: false,
    },
    {
      field: "projectMilestoneDisplayTitle",
      headerText: translations[language || Language.Hr].referentDate,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
    {
      field: "projectMilestone",
      headerText: translations[language || Language.Hr].referentDate,
      validationRules: {
        required: true,
      },
      visible: false,
      allowEditing: false,
    },
    {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 200,
      template: (props): ReactElement => {
        return (
          <div
            className="e-btn"
            onClick={(): Promise<void> =>
              deleteTaskFromProjectTemplate(props.id)
            }
          >
            {translations[language || Language.Hr].delete}
          </div>
        );
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "title", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: false,
    allowAdding: false,
  };

  const buttons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].save,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => addTaskToProjectTemplate(selectedTaskId),
    },
    {
      buttonModel: {
        content: translations[language || Language.Hr].cancel,
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => setIsAddTaskDialogDisplayed(false),
    },
  ];

  const addTaskToolbarOption: DataGridToolbarOption = {
    text: translations[language || Language.Hr].addTask,
    id: "customToolbarOption",
    tooltipText: translations[language || Language.Hr].addTaskTemplateToProject,
    prefixIcon: "e-add",
  };

  const fields: object = { text: "text", value: "value" };

  return (
    <section id="taskTemplate">
      <BreadCrumbsNavigation
        breadCrumbTemplates={[
          {
            url: "projects",
            title: translations[language || Language.Hr].projectTemplates,
          },
        ]}
      />
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={templateTasks}
            columnProperties={columns}
            useDialogTemplate={ParseClassName.TaskTemplate}
            customToolbarOption={addTaskToolbarOption}
            handleCustomToolbarOption={openAddTaskDialog}
            showOnlyCustomToolbarOption
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
      <DialogComponent
        isModal={true}
        width="700px"
        close={() => setIsAddTaskDialogDisplayed(false)}
        header={translations[language || Language.Hr].chooseTaskTemplateToAdd}
        visible={isAddTaskDialogDisplayed}
        showCloseIcon={true}
        buttons={buttons}
      >
        <div className="dialog-content" style={{ padding: "15px" }}>
          {isAddTaskDialogDisplayed && (
            <DropDownListComponent
              fields={fields}
              dataSource={newTasks}
              sortOrder={"Ascending"}
              value={selectedTaskId}
              placeholder={
                translations[language || Language.Hr].chooseTaskTemplate
              }
              select={(item) => setSelectedTaskId(item?.itemData.value)}
            />
          )}
        </div>
      </DialogComponent>
    </section>
  );
};
