import Parse from "parse";
import { FundingTypeBaseModel } from "../../models/FundingTypeBaseModel";
import { ProjectCostBaseModel } from "../../models/ProjectCostBaseModel";
import { GetFirstObjectById } from "../../services/GenericServices";
import { Language, Roles } from "../../settings/Enums";
import { translations } from "../../settings/translation";

export const fetchCostsByProjectActivityId = async (projectActivity?: any, language?: Language): Promise<ProjectCostBaseModel[]> => {
    if (!projectActivity) return [];

    const projectCostList: ProjectCostBaseModel[] = [];

    const projectCost = Parse.Object.extend("ProjectCost");
    const projectCostListQuery = new Parse.Query(projectCost).equalTo('projectActivity', projectActivity).limit(10000);

    const results = await projectCostListQuery.include("fundingType").include("ProjectActivity").find();

    if (results && results.length > 0) {
        await Promise.all(
            results.map(async (result) => {
                if (result.attributes && result.id) {
                    projectCostList.push({
                        id: result.id,
                        title: result.attributes.title,
                        code: result.attributes.code,
                        unitPrice: result.attributes.unitPrice || 0,
                        numberOfUnits: result.attributes.numberOfUnits,
                        subventionRate: result.attributes.subventionRate || 0,
                        subventionAmount: result.attributes.subventionAmount || 0,
                        vatRate: result.attributes.vatRate || 0,
                        vatAmount: result.attributes.vatAmount || 0,
                        realTotalNetPrice: result.attributes.realTotalNetPrice || 0,
                        realTotalVatPrice: result.attributes.realTotalVatPrice || 0,
                        isVatEligibleCost: result.attributes.isVatEligibleCost || 0,
                        isVatEligibleCostDisplay: result.attributes.isVatEligibleCost ? translations[language || Language.Hr].yes : translations[language || Language.Hr].no,
                        projectActivity: result.attributes.projectActivity,
                        projectActivityId: result.attributes.projectActivity?.id,
                        projectActivityTitle: result.attributes.projectActivity?.attributes?.title,
                        fundingType: result.attributes.fundingType,
                        fundingTypeId: result.attributes.fundingType?.id,
                        fundingTypeTitle: result.attributes.fundingType?.attributes?.title,
                        totalCostContract: result.attributes.totalCostContract || 0,
                        totalPayment: result.attributes.totalPayment || 0,
                        sumDifference: result.attributes.isVatEligibleCost
                            ? ((result.attributes.realTotalVatPrice || 0) - (result.attributes.totalCostContract || 0))
                            : ((result.attributes.realTotalNetPrice || 0) - (result.attributes.totalCostContract || 0))
                    });
                }
            })
        );
    }

    return projectCostList;
};

export const setPropertyToProjectCost = async (projectCostValue: ProjectCostBaseModel, projectActivity: any, selectedOrganization?: any): Promise<any> => {
    if (!selectedOrganization) return;

    const projectCostObject = Parse.Object.extend("ProjectCost");
	const projectCost = new projectCostObject();

	const realTotalNetPrice = projectCostValue.realTotalNetPrice ? Number(projectCostValue.realTotalNetPrice) : 0;
	const realTotalVatPrice = projectCostValue.isVatEligibleCost && projectCostValue.realTotalVatPrice ? Number(projectCostValue.realTotalVatPrice) : realTotalNetPrice;

    projectCost.set("id", projectCostValue.id);
    projectCost.set("title", projectCostValue.title);
    projectCost.set("code", projectCostValue.code);
    projectCost.set("unitPrice", projectCostValue.unitPrice ? Number(projectCostValue.unitPrice) : 0);
    projectCost.set("numberOfUnits", projectCostValue.numberOfUnits ? Number(projectCostValue.numberOfUnits) : 0);
    projectCost.set("subventionRate", projectCostValue.subventionRate ? projectCostValue.subventionRate / 100 : 0);
    projectCost.set("subventionAmount", projectCostValue.subventionAmount ? Number(projectCostValue.subventionAmount) : 0);
    projectCost.set("vatRate", projectCostValue.isVatEligibleCost && projectCostValue.vatRate ? projectCostValue.vatRate / 100 : 0);
    projectCost.set("vatAmount", projectCostValue.isVatEligibleCost && projectCostValue.vatAmount ? Number(projectCostValue.vatAmount) : 0);
    projectCost.set("isVatEligibleCost", projectCostValue.isVatEligibleCost);
    projectCost.set("realTotalNetPrice", realTotalNetPrice);
    projectCost.set("realTotalVatPrice", realTotalVatPrice);
    projectCost.set("projectActivity", projectActivity);

    if (!projectCostValue.id) {
        const acl = new Parse.ACL();

        const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;
        const orgEmployeeRoleName = `${selectedOrganization.attributes.shortTitle}_EMPLOYEE`;

        acl.setRoleReadAccess(Roles.Admin, true);
        acl.setRoleWriteAccess(Roles.Admin, true);
        acl.setRoleReadAccess(orgEmployeeRoleName, true);
        acl.setRoleWriteAccess(orgEmployeeRoleName, true);
        acl.setRoleReadAccess(orgAdminRoleName, true);
        acl.setRoleWriteAccess(orgAdminRoleName, true);

        if (projectActivity?.attributes?.project?.attributes?.shortCode) {
            const projectRoleName = `PROJECT_${projectActivity.attributes.project.attributes.shortCode}`;

            acl.setRoleReadAccess(projectRoleName, true);
            acl.setRoleWriteAccess(projectRoleName, false);
        }
    
        projectCost.setACL(acl);
    }
    
    if (projectCostValue.fundingTypeId) {
        const fundingType = await GetFirstObjectById("FundingType", projectCostValue.fundingTypeId)
        if (fundingType) {
            projectCost.set("fundingType", fundingType);
        }
    }

    return projectCost;
};

export const fetchFundingTypes = async (): Promise<FundingTypeBaseModel[]> => {
    const fundingTypes: FundingTypeBaseModel[] = [];
    const fundingType = Parse.Object.extend("FundingType");
    const fundingTypeQuery = new Parse.Query(fundingType).limit(10000);

    const results = await fundingTypeQuery.find();

    if (results && results.length > 0) {
        await Promise.all(
            results.map(async (result) => {
                if (result.attributes && result.id) {

                    fundingTypes.push({
                        id: result.id,
                        title: result.attributes.title,
                    });
                }
            })
        );
    }

    return fundingTypes;
}

export const mapFundingTypesToDropdown = (fundingTypes: FundingTypeBaseModel[]): { [key: string]: Object }[] => {
    const dropdownItems: { [key: string]: Object }[] = [];
    fundingTypes.map((fundingType: FundingTypeBaseModel) => {
        if (!fundingType.id || !fundingType.title) return;
        dropdownItems.push({
            value: fundingType.id,
            text: fundingType.title,
        });
    });
    return dropdownItems;
};
