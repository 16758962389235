import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  EditSettingsModel,
  SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import {
  ButtonPropsModel, DialogComponent
} from "@syncfusion/ej2-react-popups";
import Parse, { User } from "parse";
import React, { Attributes, ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthDataContext } from "../../components/authDataProvider";
import { BreadCrumbsNavigation } from "../../components/breadCrumbsNavigation";
import { DataGrid } from "../../components/dataGrid";
import { fetchProjectsUsersHelper } from "../../helpers/fetching/FetchingHelper";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { DataGridToolbarOption } from "../../models/DataGridToolbarOption";
import { UserBaseModel } from "../../models/UserBaseModel";
import {
  GetAllObjectsById, GetFirstObjectById
} from "../../services/GenericServices";
import { GetPersonByUserId } from "../../services/PersonService";
import {
  GetRoleById,
  GetRoleQueryRelationByName,
  GetRoleUsers
} from "../../services/RoleServices";
import { GetFirstUserByPropertyName } from "../../services/UserServices";
import { Language, ParseClassName } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {}

export const ProjectUsers: React.FC<Props> = (props: Props): ReactElement => {
  const { selectedOrganization } = useAuthDataContext();
  const [isAddUserDialogDisplayed, setIsAddUserDialogDisplayed] =
    useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [newUsers, setNewUsers] = useState<{ [key: string]: Object }[]>([]);
  const [users, setUsers] = useState<UserBaseModel[]>([]);
  const { language } = useAuthDataContext();

  const getProjectIdFromRoute = (): string => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 6 &&
      urlParts[1] === "clients" &&
      urlParts[3] === "projects" &&
      urlParts[5] === "users"
    ) {
      return urlParts[4];
    }
    return "";
  };

  const getClientIdFromRoute = (): string => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 6 &&
      urlParts[1] === "clients" &&
      urlParts[3] === "projects" &&
      urlParts[5] === "users"
    ) {
      return urlParts[2];
    }
    return "";
  };

  useEffect(() => {
    fetchProjectsUsers();
    fetchClientContactsAndEmployess();
  }, []);

  const projectId = getProjectIdFromRoute();
  const clientId = getClientIdFromRoute();

  const fetchProjectsUsers = async (): Promise<void> => {
    const result = await fetchProjectsUsersHelper(projectId);

    setUsers(result || []);
  };

  const fetchClientContactsAndEmployess = async (): Promise<void> => {
    if (clientId === "" || !selectedOrganization) return;

    const dropdownItems: { [key: string]: Object }[] = [];

    const clients = await GetAllObjectsById("Client", clientId);

    if (clients && clients?.length > 0) {
      await Promise.all(
        clients.map(async (clientDetails) => {
          const clientAttributes = clientDetails.attributes;
          if (!clientAttributes || !clientAttributes?.name) return;

          const clientContactsRelationQuery =
            clientDetails.relation("contacts");
          const clientContacts = await clientContactsRelationQuery
            .query()
            .find();

          if (!clientContacts || clientContacts?.length === 0) return;

          clientContacts.map((clientContact) => {
            const contactAttributes = clientContact.attributes;
            if (
              !clientContact.id ||
              !contactAttributes ||
              !contactAttributes?.email ||
              !contactAttributes?.firstName ||
              !contactAttributes?.lastName ||
              !contactAttributes?.parseUser
            )
              return;

            dropdownItems.push({
              groupBy: translations[language || Language.Hr].contacts,
              text: `${contactAttributes.firstName} ${contactAttributes.lastName} (${contactAttributes.email})`,
              value: clientContact.id,
            });
          });
        })
      );
    }

    const employees = await GetRoleUsers(
      `${selectedOrganization.attributes.shortTitle}_EMPLOYEE`
    );

    if (employees && employees?.length > 0) {
      await Promise.all(
        employees.map(async (employee) => {
          const person = await GetPersonByUserId(employee.id);
          if (!person) return;

          dropdownItems.push({
            groupBy: translations[language || Language.Hr].employees,
            text: `${person.ime} ${person.lastName} (${person.elektronickaPosta})`,
            value: person.id,
          });
        })
      );
    }

    setNewUsers(dropdownItems);
  };

  const addUserToRole = async (id?: string) => {
    if (!id) return;

    const person = await GetFirstObjectById("Person", id);
    if (
      !(
        person &&
        person.id &&
        person.attributes.parseUser &&
        person.attributes.parseUser.id
      )
    )
      return;

    const user = await GetFirstUserByPropertyName(
      person.attributes.parseUser.id
    );
    if (!user) return;

    const roleResult = await getRoleRelation();

    if (!(roleResult && roleResult.attributes)) return;

    roleResult.getUsers().add(user as User<Attributes>);
    const result = await roleResult.save();

    if (result) {
      fetchProjectsUsers();
    }

    setIsAddUserDialogDisplayed(false);
  };

  const openAddUserDialog = async (): Promise<void> => {
    setSelectedUserId(undefined);
    setIsAddUserDialogDisplayed(true);
  };

  const getRoleRelation = async () => {
    if (projectId === "") return;

    const projectResult = await GetFirstObjectById("Project", projectId);

    if (
      projectResult &&
      projectResult.attributes &&
      projectResult.attributes.role &&
      projectResult.attributes.role.id
    ) {
      return await GetRoleById(projectResult.attributes.role.id);
    }
  };

  const deleteUserFromProject = async (id: string): Promise<void> => {
    const roleResult = await getRoleRelation();

    if (!(roleResult && roleResult.attributes)) return;

    const roleUsers = await GetRoleQueryRelationByName(roleResult, "users");
    const user = await GetFirstUserByPropertyName(id);

    if (!(roleUsers && user)) return;

    roleUsers.remove(user as Parse.User<Attributes>);
    await roleResult.save();

    fetchProjectsUsers();
  };

  const columns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "email",
      headerText: translations[language || Language.Hr].email,
      allowEditing: false,
    },
    {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 200,
      template: (props): ReactElement => {
        return (
          <div>
            <div
              className="e-btn"
              onClick={(): Promise<void> => deleteUserFromProject(props.id)}
            >
              {translations[language || Language.Hr].delete}
            </div>
          </div>
        );
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "email", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: false,
    allowAdding: false,
  };

  const addUserToolbarOption: DataGridToolbarOption = {
    text: translations[language || Language.Hr].addUser,
    id: "customToolbarOption",
    tooltipText: translations[language || Language.Hr].addUserToProject,
    prefixIcon: "e-add",
  };

  const buttons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].save,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => addUserToRole(selectedUserId),
    },
    {
      buttonModel: {
        content: translations[language || Language.Hr].cancel,
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => setIsAddUserDialogDisplayed(false),
    },
  ];

  const fields: object = { groupBy: "groupBy", text: "text", value: "value" };

  return (
    <section id="projectUsers">
      <BreadCrumbsNavigation
        breadCrumbTemplates={[
          {
            url: "clients",
            title: translations[language || Language.Hr].clients,
          },
          {
            url: "projects",
            title: translations[language || Language.Hr].projects,
          },
        ]}
      />
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={users}
            columnProperties={columns}
            useDialogTemplate={ParseClassName.Project}
            customToolbarOption={addUserToolbarOption}
            handleCustomToolbarOption={openAddUserDialog}
            showOnlyCustomToolbarOption
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
      <DialogComponent
        isModal={true}
        width="700px"
        close={() => setIsAddUserDialogDisplayed(false)}
        header={translations[language || Language.Hr].chooseUserToAdd}
        visible={isAddUserDialogDisplayed}
        showCloseIcon={true}
        buttons={buttons}
      >
        <div className="dialog-content" style={{ padding: "15px" }}>
          {/* this is needed since syncfusion DropDownListComponent won't deselect value
                                even if selectedExistingContactId === undefined */}
          {isAddUserDialogDisplayed && (
            <DropDownListComponent
              fields={fields}
              dataSource={newUsers}
              sortOrder={"Ascending"}
              value={selectedUserId}
              placeholder={translations[language || Language.Hr].chooseUser}
              select={(item) => setSelectedUserId(item?.itemData.value)}
            />
          )}
        </div>
      </DialogComponent>
    </section>
  );
};
