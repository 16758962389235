import Parse from "parse";
import { OrganizationBaseModel, OrganizationCreateHelperModel } from "../../models/OrganizationBaseModel";
import { UserBaseModel } from "../../models/UserBaseModel";
import { GetRoleUsers } from "../../services/RoleServices";
import { Roles } from "../../settings/Enums";

export const fetchAdminOrganizationListByOrganizationId = async (
  organization?: any
): Promise<UserBaseModel[]> => {
  const adminOrganizationList: UserBaseModel[] = [];

  if (!organization) return [];

  const organizationAdminRoleName = `${organization.attributes.shortTitle}_ADMIN`;
  const results = await GetRoleUsers(organizationAdminRoleName);

  if (!(results && results.length > 0)) return [];

  const object = Parse.Object.extend("Person");
  const objectQuery = new Parse.Query(object)
    .containedIn("parseUser", results)
    .limit(10000);

  const personResults = await objectQuery.find();

  personResults.forEach((person) => {
    if (person && person.attributes && person.id) {
      adminOrganizationList.push({
        id: person.attributes.parseUser.id,
        email: person.attributes.email,
        username: person.attributes.parseUser.attributes.username,
        firstName: person.attributes.firstName,
        lastName: person.attributes.lastName,
        phone: person.attributes.phone,
      });
    }
  });

  return adminOrganizationList;
};

export const createOrganizationAndRelatedRoleObjects = async (title: string, newOrganization: OrganizationBaseModel): Promise<OrganizationCreateHelperModel | undefined> => {
  const roles = await saveOrganizationRelatedRolesObjects(title);

  if (!roles) return;

  const roleName = `ORGANIZATION_${title}`;
  const adminRoleName = `${title}_ADMIN`;

  const roleACL = new Parse.ACL();
  roleACL.setRoleReadAccess(Roles.Admin, true);
  roleACL.setRoleWriteAccess(Roles.Admin, true);
  roleACL.setRoleReadAccess(adminRoleName, true);
  roleACL.setRoleWriteAccess(adminRoleName, true);
  roleACL.setRoleReadAccess(roleName, true);
  roleACL.setRoleWriteAccess(roleName, false);
  const role = new Parse.Role(roleName, roleACL);
  const roleRelation = role.relation("roles");
  roleRelation.add(roles);

  const organizationObject = Parse.Object.extend("Organization");
  const organization = new organizationObject();

  organization.set("title", newOrganization.title);
  organization.set("shortTitle", newOrganization.shortTitle);
  organization.set(
    "maxNumberOfProjects",
    parseInt(newOrganization.maxNumberOfProjects.toString())
  );
  organization.set(
    "maxNumberOfUsers",
    parseInt(newOrganization.maxNumberOfUsers.toString())
  );

  const organizationACL = new Parse.ACL();
  organizationACL.setRoleReadAccess(Roles.Admin, true);
  organizationACL.setRoleWriteAccess(Roles.Admin, true);
  organizationACL.setRoleReadAccess(roleName, true);
  organizationACL.setRoleWriteAccess(roleName, false);
  organization.setACL(organizationACL);

  return {
    organization,
    role,
    relatedRoles: roles,
  }
}

export const saveOrganizationRelatedRolesObjects = async (
  title: string
): Promise<Parse.Role<Partial<Parse.Attributes>>[] | undefined> => {
  const roles: Parse.Role<Partial<Parse.Attributes>>[] = [];

  const adminRoleName = `${title.toUpperCase()}_ADMIN`;
  const adminRoleACL = new Parse.ACL();
  adminRoleACL.setRoleReadAccess(Roles.Admin, true);
  adminRoleACL.setRoleWriteAccess(Roles.Admin, true);
  adminRoleACL.setRoleReadAccess(adminRoleName, true);
  adminRoleACL.setRoleWriteAccess(adminRoleName, false);
  roles.push(new Parse.Role(adminRoleName, adminRoleACL));

  const clientRoleName = `${title.toUpperCase()}_CLIENT`;
  const clientRoleACL = new Parse.ACL();
  clientRoleACL.setRoleReadAccess(Roles.Admin, true);
  clientRoleACL.setRoleWriteAccess(Roles.Admin, true);
  clientRoleACL.setRoleReadAccess(adminRoleName, true);
  clientRoleACL.setRoleWriteAccess(adminRoleName, true);
  clientRoleACL.setRoleReadAccess(clientRoleName, true);
  clientRoleACL.setRoleWriteAccess(
    clientRoleName,
    false
  );
  roles.push(new Parse.Role(clientRoleName, clientRoleACL));

  const employeeRoleName = `${title.toUpperCase()}_EMPLOYEE`;
  const employeeRoleACL = new Parse.ACL();
  employeeRoleACL.setRoleReadAccess(Roles.Admin, true);
  employeeRoleACL.setRoleWriteAccess(Roles.Admin, true);
  employeeRoleACL.setRoleReadAccess(adminRoleName, true);
  employeeRoleACL.setRoleWriteAccess(
    adminRoleName,
    true
  );
  employeeRoleACL.setRoleReadAccess(
    employeeRoleName,
    true
  );
  employeeRoleACL.setRoleWriteAccess(
    employeeRoleName,
    false
  );
  roles.push(new Parse.Role(employeeRoleName, employeeRoleACL));

  const results = await Parse.Object.saveAll(roles);
  if (!results) return;

  const addToGeneralRolesResult = await addOrganizationRolesToGeneralRoles(
    results
  );

  if (!addToGeneralRolesResult) {
    Parse.Object.destroyAll(results);
    return;
  }

  return results;
};

export const addOrganizationRolesToGeneralRoles = async (
  inputRoles: Parse.Role<Partial<Parse.Attributes>>[]
): Promise<Parse.Role<Partial<Parse.Attributes>>[]> => {
  if (inputRoles.length !== 3) return [];
  const roles: Parse.Role<Partial<Parse.Attributes>>[] = [];

  for (let i = 0; i < 3; i++) {
    const role = new Parse.Query(Parse.Role).limit(10000);
    const roleQuery = role.equalTo("name", getGeneralRoleTitle(i));
    const result = await roleQuery.first();
    if (!result) return [];

    const relation = result.relation("roles");
    relation.add(inputRoles[i]);
    roles.push(result);
  }

  return await Parse.Object.saveAll(roles);
};

const getGeneralRoleTitle = (index: number): string => {
  switch (index) {
    case 0:
      return Roles.AdminOrganization;
    case 1:
      return Roles.Client;
    default:
      return Roles.Employee;
  }
};
