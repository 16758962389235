import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import Parse from "parse";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuthDataContext } from "../../components/authDataProvider";
import { DataGrid } from "../../components/dataGrid";
import { ClientBaseModel } from "../../models/ClientBaseModel";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { Language, ParseClassName, Roles } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {}

export const Clients: React.FC<Props> = (props: Props): ReactElement => {
  const [clients, setClients] = useState<ClientBaseModel[]>([]);
  const { selectedOrganization, language } = useAuthDataContext();
  const history = useHistory();

  useEffect(() => {
    fetchClients();
  }, [selectedOrganization]);

  const fetchClients = async (): Promise<void> => {
    if (!selectedOrganization) return;

    const clientsList: ClientBaseModel[] = [];

    const client = Parse.Object.extend("Client");
    const clientListQuery = new Parse.Query(client)
      .equalTo("organization", selectedOrganization)
      .limit(10000);

    const results = await clientListQuery.find();
    if (results && results.length > 0) {
      results.forEach((result) => {
        if (
          result.attributes &&
          result.id &&
          result.attributes.name &&
          result.attributes.oib
        ) {
          clientsList.push({
            id: result.id,
            name: result.attributes.name,
            oib: result.attributes.oib,
          });
        }
      });
    }
    setClients(clientsList);
  };

  const updateClient = async (updatedClient: ClientBaseModel) => {
    const clientObject = Parse.Object.extend("Client");
    const client = new clientObject();

    client.set("id", updatedClient.id);
    client.set("name", updatedClient.name);
    client.set("oib", updatedClient.oib);

    const response = await client.save();

    if (response) {
      await fetchClients();
    }
  };

  const createClient = async (newClient: ClientBaseModel): Promise<void> => {
    if (!selectedOrganization) return;
    const clientObject = Parse.Object.extend("Client");
    const client = new clientObject();

    client.set("name", newClient.name);
    client.set("oib", newClient.oib);
    client.set("organization", selectedOrganization);

    const orgRoleName = `ORGANIZATION_${selectedOrganization.attributes.shortTitle}`;
    const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;

    const clientAcl = new Parse.ACL();
    clientAcl.setRoleReadAccess(Roles.Admin, true);
    clientAcl.setRoleWriteAccess(Roles.Admin, true);
    clientAcl.setRoleReadAccess(orgRoleName, true);
    clientAcl.setRoleWriteAccess(orgRoleName, false);
    clientAcl.setRoleReadAccess(orgAdminRoleName, true);
    clientAcl.setRoleWriteAccess(orgAdminRoleName, true);

    client.setACL(clientAcl);

    const response = await client.save();

    if (response) {
      await fetchClients();
    }
  };

  const columnProperties: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "name",
      headerText: translations[language || Language.Hr].fullName,
      validationRules: {
        required: true,
      },
    },
    {
      field: "oib",
      headerText: translations[language || Language.Hr].pin,
      validationRules: {
        required: true,
        number: true,
        maxLength: 11,
        minLength: 11,
      },
    },
    {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 200,
      template: (props): ReactElement => {
        return (
          <div>
            <div
              className="e-btn"
              onClick={(): void =>
                history.push(`/clients/${props.id}/contacts`)
              }
            >
              {translations[language || Language.Hr].contacts}
            </div>
            <div
              className="e-btn"
              style={{ marginLeft: 10 }}
              onClick={(): void =>
                history.push(`/clients/${props.id}/projects`)
              }
            >
              {translations[language || Language.Hr].projects}
            </div>
          </div>
        );
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "name", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    mode: "Dialog",
  };

  return (
    <section id="client">
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={clients}
            columnProperties={columnProperties}
            updateItem={updateClient}
            createItem={createClient}
            fetchDataAfterCatchError={fetchClients}
            useDialogTemplate={ParseClassName.Client}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
    </section>
  );
};
