import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { ProjectCostBaseModel } from "../../models/ProjectCostBaseModel";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import {
  fetchFundingTypes,
  mapFundingTypesToDropdown,
} from "../fetching/ActivityCostsServiceHelper";

interface Props {
  data: ProjectCostBaseModel;
  language?: Language;
}

export const ProjectCostDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const { data, language } = props;
  const [fundingTypes, setFundingTypes] = useState<{ [key: string]: Object }[]>(
    []
  );
  const [unitPrice, setUnitPrice] = useState<number>(data.unitPrice || 0);
  const [numberOfUnits, setNumberOfUnits] = useState<number>(
    data.numberOfUnits || 0
  );
  const [subventionRate, setSubventionRate] = useState<number | undefined>(
    (data.subventionRate || 0) * 100
  );
  const [vatRate, setVateRate] = useState<number | undefined>(
    (data.vatRate || 0) * 100
  );
  const [fundingTypeId, setFundingTypeId] = useState<string | undefined>(
    data.fundingTypeId
  );
  const [isVatEligibleCost, setIsVatEligibleCost] = useState<boolean>(
    data.isVatEligibleCost
  );

  useEffect(() => {
    getFundingTypes();
  }, []);

  const getFundingTypes = async (): Promise<void> => {
    const types = await fetchFundingTypes();
    const mappedTypes = mapFundingTypesToDropdown(types);
    setFundingTypes(mappedTypes);
  };

  const fields: object = { text: "text", value: "value" };

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="titleValidation">
          <Form.Label>{translations[language || Language.Hr].title}</Form.Label>
          <Form.Control
            type="text"
            name="title"
            defaultValue={data.title}
            autoComplete="off"
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="codeValidation">
          <Form.Label>{translations[language || Language.Hr].code}</Form.Label>
          <Form.Control
            type="text"
            name="code"
            defaultValue={data.code}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="unitPriceValidation">
          <Form.Label>
            {translations[language || Language.Hr].unitPrice}
          </Form.Label>
          <Form.Control
            type="number"
            name="unitPrice"
            defaultValue={data.unitPrice}
            onBlur={(e: React.FocusEvent<HTMLInputElement>): void =>
              setUnitPrice(e?.target?.value ? parseFloat(e.target.value) : 0)
            }
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="numberOfUnitsValidation">
          <Form.Label>
            {translations[language || Language.Hr].quantity}
          </Form.Label>
          <Form.Control
            type="number"
            name="numberOfUnits"
            defaultValue={data.numberOfUnits}
            onBlur={(e: React.FocusEvent<HTMLInputElement>): void =>
              setNumberOfUnits(
                e?.target?.value ? parseFloat(e.target.value) : 0
              )
            }
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="realTotalNetPriceValidation">
          <Form.Label>
            {translations[language || Language.Hr].overallAcceptable}
          </Form.Label>
          <Form.Control
            type="number"
            name="realTotalNetPrice"
            value={(unitPrice * numberOfUnits).toFixed(2)}
            disabled
            autoComplete="off"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="subventionRateValidation">
          <Form.Label>
            {translations[language || Language.Hr].coFinancingRate}
          </Form.Label>
          <Form.Control
            type="number"
            name="subventionRate"
            min={0}
            max={100}
            //leave this way beacuse of handling 0 as legit value
            value={subventionRate === undefined ? "" : subventionRate}
            onChange={(e: React.FocusEvent<HTMLInputElement>): void =>
              setSubventionRate(parseFloat(e.target.value))
            }
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="fundingTypeIdValidation">
          <Form.Label>
            {translations[language || Language.Hr].financingCategory}
          </Form.Label>
          <DropDownListComponent
            fields={fields}
            dataSource={fundingTypes}
            value={fundingTypeId}
            placeholder={
              translations[language || Language.Hr].chooseFinancingCategory
            }
            select={(value) => setFundingTypeId(value?.itemData?.value)}
          />
          {/* Ovo je za sad quickfix da imamo validaciju na komponenti koja nije defaultno od react forme control */}
          <Form.Control
            type="text"
            name="fundingTypeId"
            value={fundingTypeId?.toString() || ""}
            hidden
            autoComplete="off"
            required
          />
          <Form.Control.Feedback type="invalid">
            {translations[language || Language.Hr].requiredField}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="subventionAmountValidation">
          <Form.Label>
            {translations[language || Language.Hr].amountOfCoFinancing}
          </Form.Label>
          <Form.Control
            type="number"
            name="subventionAmount"
            value={(
              (unitPrice *
                numberOfUnits *
                (1 + (vatRate || 0) / 100) *
                (subventionRate || 0)) /
              100
            ).toFixed(2)}
            disabled
            autoComplete="off"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="isVatEligibleCostValidation">
          <Form.Label>
            {translations[language || Language.Hr].vatEligibleCost}
          </Form.Label>
          <Form.Check
            type="checkbox"
            name="isVatEligibleCost"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setIsVatEligibleCost(e.target.checked);
              if (e.target.checked === false) {
                setVateRate(0);
              } else {
                setVateRate(undefined);
              }
            }}
            checked={isVatEligibleCost}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row
        style={{ visibility: isVatEligibleCost ? "visible" : "hidden" }}
      >
        <Form.Group as={Col} controlId="vatRateValidation">
          <Form.Label>
            {translations[language || Language.Hr].vatRate}
          </Form.Label>
          <Form.Control
            type="number"
            name="vatRate"
            min={0}
            max={100}
            //leave this way beacuse of handling 0 as legit value
            value={vatRate === undefined ? "" : vatRate}
            onChange={(e: React.FocusEvent<HTMLInputElement>): void =>
              setVateRate(parseFloat(e.target.value))
            }
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="vatAmountValidation">
          <Form.Label>
            {translations[language || Language.Hr].vatAmount}
          </Form.Label>
          <Form.Control
            type="number"
            name="vatAmount"
            value={((unitPrice * numberOfUnits * (vatRate || 0)) / 100).toFixed(
              2
            )}
            disabled
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="realTotalVatPriceValidation">
          <Form.Label>
            {translations[language || Language.Hr].inTotalWithVAT}
          </Form.Label>
          <Form.Control
            type="number"
            name="realTotalVatPrice"
            value={(
              unitPrice *
              numberOfUnits *
              (1 + (vatRate || 0) / 100)
            ).toFixed(2)}
            disabled
            autoComplete="off"
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
