import Parse from "parse";
import React from "react";
import { Button } from "react-bootstrap";
import {
  updateIsCreatedWithDefaultTaskTemplateProperty,
  updateIsEditableForClientTemplateProperty
} from "../../helpers/fetching/ProjectFileTemplateFetchingHelper";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import { refreshFileManager } from "../../helpers/refreshing/RefreshingHelper";
import { roleListAdminAndEmployee } from "../../settings/Constants";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import "./styles.css";

interface Props {
  userRoles?: string[];
  selectedOrganization?: any;
  language: Language;
}

interface State {
  isLoading: boolean;
  isNotEditableFolder: boolean;
  folderId?: string;
  isEditableForClient: boolean;
  isCreatedWithDefaultTask: boolean;
}

class TemplateFolderDetails extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      isNotEditableFolder: true,
      isEditableForClient: false,
      isCreatedWithDefaultTask: false,
    };
  }

  // do not delete this, it is used with ref
  private fetchProjectFileTemplates = async (
    folderIdFromRef?: string
  ): Promise<void> => {
    this.setState({ isLoading: true });

    if (folderIdFromRef && folderIdFromRef !== "") {
      const projectFile = Parse.Object.extend("ProjectFileTemplate");
      const objectQuery = new Parse.Query(projectFile)
        .equalTo("objectId", folderIdFromRef)
        .limit(10000);

      const result = await objectQuery.first();
      if (result && result.attributes && result.id) {
        this.setState({
          isEditableForClient: result.attributes.isEditableForClient,
          isCreatedWithDefaultTask: result.attributes.isCreatedWithDefaultTask,
          isNotEditableFolder: false,
        });
      } else {
        this.setState({
          isEditableForClient: false,
          isCreatedWithDefaultTask: false,
          isNotEditableFolder: true,
        });
      }
    } else {
      this.setState({
        isEditableForClient: false,
        isCreatedWithDefaultTask: false,
        isNotEditableFolder: true,
      });
    }
    this.setState({
      folderId: folderIdFromRef,
      isLoading: false,
    });
  };

  private updateIsEditableForClient = async (
    isEditable: boolean
  ): Promise<void> => {
    const { folderId } = this.state;
    this.setState({ isEditableForClient: isEditable });
    updateIsEditableForClientTemplateProperty(isEditable, folderId);
    refreshFileManager(true);
  };

  private updateIsCreatedWithDefaultTask = async (
    isCreatedWithDefTask: boolean
  ): Promise<void> => {
    const { folderId } = this.state;
    this.setState({ isCreatedWithDefaultTask: isCreatedWithDefTask });
    updateIsCreatedWithDefaultTaskTemplateProperty(
      isCreatedWithDefTask,
      folderId
    );
    refreshFileManager(true);
  };

  public render(): any {
    const {
      isNotEditableFolder,
      isEditableForClient,
      isCreatedWithDefaultTask,
    } = this.state;
    const { userRoles, selectedOrganization, language } = this.props;

    return (
      <div>
        {!isNotEditableFolder && (
          <div className="main-template-folder-details-wrapper">
            {userRoles &&
              isAnyCommonString(
                userRoles,
                roleListAdminAndEmployee,
                selectedOrganization?.attributes?.shortTitle
              ) && (
                <div>
                  <div className="template-folder-wrapper">
                    <div className="template-folder-header">
                      <div className="title-header-wrapper">
                        <div
                          className={`details-template-folder-background-${!!isEditableForClient}`}
                        />
                        {isEditableForClient
                          ? translations[language || Language.Hr]
                              .clientCanEditFolders
                          : translations[language || Language.Hr]
                              .clientCannotEditFolders}
                      </div>
                      <Button
                        className="add-button-template-folder"
                        onClick={(): Promise<void> =>
                          this.updateIsEditableForClient(!isEditableForClient)
                        }
                      >
                        {isEditableForClient
                          ? translations[language || Language.Hr].forbid
                          : translations[language || Language.Hr].allow}
                      </Button>
                    </div>
                  </div>
                  <div className="template-folder-wrapper">
                    <div className="template-folder-header">
                      <div className="title-header-wrapper">
                        <div className="outer-icon-header">
                          <div
                            className={`header-icon e-icons background-task-icon-${!!isCreatedWithDefaultTask}`}
                          />
                        </div>
                        {isCreatedWithDefaultTask
                          ? translations[language || Language.Hr]
                              .folderIsCreatedAutomaticallyTask
                          : translations[language || Language.Hr]
                              .folderIsNotCreatedAutomaticallyTask}
                      </div>
                      <Button
                        className="add-button-template-folder"
                        onClick={(): Promise<void> =>
                          this.updateIsCreatedWithDefaultTask(
                            !isCreatedWithDefaultTask
                          )
                        }
                      >
                        {isCreatedWithDefaultTask
                          ? translations[language || Language.Hr].forbid
                          : translations[language || Language.Hr].allow}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}
        {isNotEditableFolder && (
          <div>
            {translations[language || Language.Hr].notEditableFolderNote}
          </div>
        )}
      </div>
    );
  }
}

export default TemplateFolderDetails;
