import { GetFirstObjectById } from "../../services/GenericServices";
import { roleListAdminAndAdminOrganizationAndEmployee, roleListAdminAndEmployee } from "../../settings/Constants";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import { isAnyCommonString } from "../filtering/FilteringHelper";

export const checkIsAllEditableSelectedFoldersInFileDetails = (event: any): boolean => {
    const fileDetails = event?.fileDetails || [];
    if (fileDetails.length > 0) {
        let isEditable = true;
        fileDetails.forEach((fd: any) => {
            if (!fd?.isEditableForClient) {
                isEditable = false;
            }
        });
        return isEditable;
    }
    return false;
};

export const checkIsEditableSelectedFolderInFileDetails = (event: any): boolean => {
    const fileDetails = event?.fileDetails || [];
    if (fileDetails.length > 0) {
        return fileDetails[0].isEditableForClient || false;
    }
    return false;
};

export const checkIsEditableFolder = async (event: any, folderId: string): Promise<boolean> => {
    const result = await GetFirstObjectById("ProjectFile", folderId);
    return result?.attributes?.isEditableForClient || false;
};

export const deleteFileManagerHelper = (event: any, callbackFunction: (modalState: boolean, messageText: string) => void, userRoles?: string[], language?: Language, organizationName?: string): void => {
    const isAdminOrEmployee =
        userRoles && isAnyCommonString(userRoles, roleListAdminAndEmployee, organizationName);
    if (!isAdminOrEmployee) {
        const isAllowedToEdit = checkIsAllEditableSelectedFoldersInFileDetails(
            event
        );
        if (!isAllowedToEdit) {
            event.cancel = true;
            callbackFunction(true, translations[language || Language.Hr].noRightsToDeleteFile);
        }
    }
}

export const renameFileManagerHelper = (event: any, callbackFunction: (modalState: boolean, messageText: string) => void, userRoles?: string[], language?: Language, organizationName?: string): void => {
    const isAdminOrEmployee =
        userRoles && isAnyCommonString(userRoles, roleListAdminAndEmployee, organizationName);
    if (!isAdminOrEmployee) {
        const isAllowedToEdit = checkIsEditableSelectedFolderInFileDetails(
            event
        );
        if (!isAllowedToEdit) {
            event.cancel = true;
            callbackFunction(true, translations[language || Language.Hr].noRightsToRenameFile);
        }
    }
}

export const newFolderFileManagerHelper = async (event: any, selectedFolderId: string, userRoles?: string[], organizationName?: string): Promise<boolean> => {
    const isAdminOrEmployee =
        userRoles && isAnyCommonString(userRoles, roleListAdminAndAdminOrganizationAndEmployee, organizationName);

    if (isAdminOrEmployee) {
        return true;
    }
    const isAllowedToEdit = await checkIsEditableFolder(
        event,
        selectedFolderId
    );
    return !!isAllowedToEdit;
}