import { setCulture } from "@syncfusion/ej2-base";
import Parse from "parse";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAsync } from "react-use";
import { getPersonByUser } from "../../helpers/fetching/UserServiceHelper";
import { GetUserRoles } from "../../services/RoleServices";
import { Language } from "../../settings/Enums";

type ContextProps = {
  initialLoader: boolean;
  userRoles: string[];
  language?: Language;
  organizations: any[];
  selectedOrganization?: any;
  setLanguage: (newValue: Language) => void;
  setSelectedOrganization: (newValue: any) => void;
  fetchDropdownOrganizations: () => Promise<void>;
};

export const AuthDataContext = createContext<Partial<ContextProps>>({});

const initialAuthData: ContextProps = {
  initialLoader: false,
  userRoles: [],
  organizations: [],
  setSelectedOrganization: (newValue: any) => {
    throw new Error("Function not implemented.");
  },
  fetchDropdownOrganizations: () => {
    throw new Error("Function not implemented.");
  },
  setLanguage: (newValue: Language) => {
    throw new Error("Function not implemented.");
  },
};

const AuthDataProvider = (props: any) => {
  const [authData, setAuthData] = useState<ContextProps>(initialAuthData);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<
    any | undefined
  >();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();
  const [initialLoader, setInitialLoader] = useState<boolean>(false);
  const currentUser = Parse.User.current();
  const userRoles = useAsync(GetUserRoles);

  const fetchOrganizations = async (): Promise<void> => {
    const object = Parse.Object.extend("Organization");
    const objectQuery = new Parse.Query(object).limit(10000);

    if (authData.userRoles.length === 0) {
      mapUserRoles();
      return;
    }

    const person = await getPersonByUser(currentUser);

    const localOrganizations = await objectQuery.find();

    if (!(localOrganizations && localOrganizations?.length > 0)) return;

    const defOrg = person
      ? localOrganizations.find(
          (l): boolean =>
            l.id === person.attributes?.lastSelectedOrganization?.id
        )
      : undefined;

    setSelectedOrganization(defOrg || localOrganizations[0]);
    setOrganizations(localOrganizations);
    setInitialLoader(true);
  };

  useEffect(() => {
    fetchInitialLanguage();
  }, []);

  useEffect(() => {
    if (authData.userRoles.length > 0) {
      fetchOrganizations();
    }
  }, [authData.userRoles]);

  useEffect(() => {
    setAuthData({
      ...authData,
      selectedOrganization,
    });
  }, [selectedOrganization]);

  useEffect(() => {
    setAuthData({
      ...authData,
      organizations,
    });
  }, [organizations]);

  useEffect(() => {
    mapUserRoles();
  }, [userRoles]);

  useEffect(() => {
    setAuthData({
      ...authData,
      initialLoader,
    });;
  }, [initialLoader]);

  const mapUserRoles = (): void => {
    if (!userRoles.value) return;

    const userRolesNames = userRoles.value?.map((userRole: any) => {
      return userRole.attributes.name;
    });
    setAuthData({
      userRoles: userRolesNames,
      selectedOrganization,
      initialLoader,
      organizations,
      language: selectedLanguage,
      setLanguage: updateLanguage,
      setSelectedOrganization: changeOrganization,
      fetchDropdownOrganizations: fetchOrganizations,
    });
  };

  const changeOrganization = (newValue: any): void => {
    setSelectedOrganization(newValue);
    updateUserLastOrganization(newValue);
  };

  const updateUserLastOrganization = async (newValue: any): Promise<void> => {
    const person = await getPersonByUser(currentUser);
    if (!person) return;

    person.set("lastSelectedOrganization", newValue);
    await person.save();
  };

  const fetchInitialLanguage = (): void => {
    const defaultLanguage = localStorage.getItem("language");

    if (defaultLanguage) {
      setSelectedLanguage(defaultLanguage as Language);
      setCulture(defaultLanguage);
    } else {
      const newLang = Language.Hr;
      setSelectedLanguage(newLang);
      localStorage.setItem("language", newLang);
      setCulture(newLang);
    }
  };

  const updateLanguage = (newValue: Language): void => {
    localStorage.setItem("language", newValue);
    setSelectedLanguage(newValue);
    window.location.reload();
  };

  return <AuthDataContext.Provider value={authData} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
