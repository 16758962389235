import Parse from "parse";
import { GetFirstUserByPropertyName } from "./UserServices";
import { TaskFormBaseModel } from "../models/TaskBaseModel";
import { ProjectNumberModel } from "../models/ProjectBaseModel";
import { GetFirstObjectById } from "./GenericServices";
import { GetTaskStates } from "./TaskStatusServices";

export const setPropertyToTask = async (
  taskModel: TaskFormBaseModel,
  projectFileId?: string
) => {
  const taskObject = Parse.Object.extend("Task");
  const task = new taskObject();
  task.set("id", taskModel.id);

  const results = await GetTaskStates();

  // Setting task state pointer
  if (results && results.length > 0) {
    const taskState = results.find(
      (r): boolean =>
        r.attributes.title === taskModel.taskStateId ||
        r.id === taskModel.taskStateId
    );
    task.set("taskState", taskState);
  }

  const assigneesRelation = task.relation("assignees");
  const oldAsignees = await assigneesRelation.query().find();
  if (oldAsignees && oldAsignees.length > 0) {
    oldAsignees.forEach((assignee: any) => {
      assigneesRelation.remove(assignee);
    });
  }
  // Setting task assignees relation
  await Promise.all(
    taskModel.assignees.map(async (assignee) => {
      const usersResponse = await GetFirstUserByPropertyName(
        assignee,
        "username"
      );
      if (usersResponse) {
        assigneesRelation.add(usersResponse);
      }
    })
  );

  task.set("availableSince", taskModel.availableSince);
  task.set("deadline", taskModel.deadline);
  task.set("title", taskModel.title);
  task.set("description", taskModel.description);
  task.set("project", taskModel.project);
  task.set("isScheduledToNotify", taskModel.isScheduledToNotify);

  if (projectFileId) {
    const projectFile = await GetFirstObjectById("ProjectFile", projectFileId);
    if (projectFile) {
      task.set("projectFile", projectFile);
    }
  } else {
    task.set("projectFile", taskModel.projectFile);
  }

  // TODO: this is not yet implemented
  // task.set('projectActivity, taskModel.projectActivity);

  return task;
};

export const getNumberOfProjectsByOrganization = async (
  organization?: any
): Promise<ProjectNumberModel | undefined> => {
  if (!organization) return;

  const clientObject = Parse.Object.extend("Client");
  const clientObjectQuery = new Parse.Query(clientObject)
    .equalTo("organization", organization)
    .limit(10000);

  const clients = await clientObjectQuery.find();

  if (!clients) return;

  const object = Parse.Object.extend("Project");
  const objectQuery = new Parse.Query(object)
    .containedIn("client", clients)
    .limit(10000);

  const projects = await objectQuery.find();

  if (!projects) return;

  return {
    currentProjectNumber: projects.length,
    maxProjectNumber: organization.attributes.maxNumberOfProjects,
  };
};
