import Parse from 'parse';

export const updateTotalCostInProjectAndProjectActivity = async (projectActivityId?: string): Promise<void> => {
	if (!projectActivityId) return;

	const projectActivityObject = Parse.Object.extend('ProjectActivity');
	const projectActivityQuery = new Parse.Query(projectActivityObject).limit(10000);
	const projectActivity = await projectActivityQuery.get(projectActivityId);

	if (!projectActivity) return;

	const projectCostObject = Parse.Object.extend('ProjectCost');
	const projectCostQuery = new Parse.Query(projectCostObject).equalTo('projectActivity', projectActivity).limit(10000);
	const projectCosts = await projectCostQuery.find();

	let costSum = 0;
	let subventionSum = 0;

	if (projectCosts && projectCosts.length > 0) {
		await Promise.all(
			projectCosts.map(async (projectCost) => {
				if (projectCost.id && projectCost.attributes) {
					costSum += projectCost.attributes.isVatEligibleCost ? projectCost.attributes.realTotalVatPrice || 0 : projectCost.attributes.realTotalNetPrice || 0;
					subventionSum += projectCost.attributes.subventionAmount || 0;
				}
			})
		);
	}

	projectActivity.set('totalCost', costSum);
	projectActivity.set('totalSubvention', subventionSum);
	const projectActivityResult = await projectActivity.save();

	if (!(projectActivityResult && projectActivityResult.attributes?.project?.id)) return;

	const projectObject = Parse.Object.extend('Project');
	const projectQuery = new Parse.Query(projectObject).equalTo('objectId', projectActivityResult.attributes.project.id).limit(10000);
	const project = await projectQuery.first();

	if (!project) return;

	const allProjectActivityObject = Parse.Object.extend('ProjectActivity');
	const allProjectActivityQuery = new Parse.Query(allProjectActivityObject).equalTo('project', project).limit(10000);
	const allProjectActivities = await allProjectActivityQuery.find();

	let paymentSumLevelTwo = 0;
	let subventionSumLevelTwo = 0;

	if (allProjectActivities && allProjectActivities.length > 0) {
		await Promise.all(
			allProjectActivities.map(async (allProjectActivity) => {
				if (allProjectActivity.id && allProjectActivity.attributes) {
					paymentSumLevelTwo += allProjectActivity.attributes.totalCost || 0;
					subventionSumLevelTwo += allProjectActivity.attributes.totalSubvention || 0;
				}
			})
		);
	}

	project.set('totalCost', paymentSumLevelTwo);
	project.set('totalSubvention', subventionSumLevelTwo);
	await project.save();
}

export const updateTotalCostContractInProjectAndProjectActivityAndProjectCost = async (projectCostId?: string): Promise<void> => {
	if (!projectCostId) return;

	const projectCostObject = Parse.Object.extend('ProjectCost');
	const projectCostQuery = new Parse.Query(projectCostObject).limit(10000);
	const projectCost = await projectCostQuery.get(projectCostId);

	if (!projectCost) return;

	const projectCostContractObject = Parse.Object.extend('ProjectCostContract');
	const projectCostContractQuery = new Parse.Query(projectCostContractObject).equalTo('projectCost', projectCost).limit(10000);
	const projectCostContracts = await projectCostContractQuery.find();

	let costContractSum = 0;

	if (projectCostContracts && projectCostContracts.length > 0) {
		await Promise.all(
			projectCostContracts.map(async (projectCostContract) => {
				if (projectCostContract.id && projectCostContract.attributes && projectCostContract.attributes.realTotalNetPrice && !projectCost.attributes.isVatEligibleCost) {
					costContractSum += projectCostContract.attributes.realTotalNetPrice;
				} else if (projectCostContract.id && projectCostContract.attributes && projectCostContract.attributes.realTotalVatPrice && projectCost.attributes.isVatEligibleCost) {
					costContractSum += projectCostContract.attributes.realTotalVatPrice;
				}
			})
		);
	}

	projectCost.set('totalCostContract', costContractSum);
	const projectCostResult = await projectCost.save();

	if (!(projectCostResult && projectCostResult.attributes?.projectActivity?.id)) return;

	const projectActivityObject = Parse.Object.extend('ProjectActivity');
	const projectActivityQuery = new Parse.Query(projectActivityObject).equalTo('objectId', projectCostResult.attributes.projectActivity.id).limit(10000);
	const projectActivity = await projectActivityQuery.first();

	if (!projectActivity) return;

	const allProjectCostObject = Parse.Object.extend('ProjectCost');
	const allProjectCostQuery = new Parse.Query(allProjectCostObject).equalTo('projectActivity', projectActivity).limit(10000);
	const allProjectCosts = await allProjectCostQuery.find();

	let paymentSumLevelTwo = 0;

	if (allProjectCosts && allProjectCosts.length > 0) {
		await Promise.all(
			allProjectCosts.map(async (allProjectCost) => {
				if (allProjectCost.id && allProjectCost.attributes && allProjectCost.attributes.totalCostContract) {
					paymentSumLevelTwo += allProjectCost.attributes.totalCostContract;
				}
			})
		);
	}

	projectActivity.set('totalCostContract', paymentSumLevelTwo);
	const projectActivityResult = await projectActivity.save();

	if (!(projectActivityResult && projectActivityResult.attributes?.project?.id)) return;

	const projectObject = Parse.Object.extend('Project');
	const projectQuery = new Parse.Query(projectObject).equalTo('objectId', projectActivityResult.attributes.project.id).limit(10000);
	const project = await projectQuery.first();

	if (!project) return;

	const allProjectActivityObject = Parse.Object.extend('ProjectActivity');
	const allProjectActivityQuery = new Parse.Query(allProjectActivityObject).equalTo('project', project).limit(10000);
	const allProjectActivities = await allProjectActivityQuery.find();

	let paymentSumLevelThree = 0;

	if (allProjectActivities && allProjectActivities.length > 0) {
		await Promise.all(
			allProjectActivities.map(async (allProjectActivity) => {
				if (allProjectActivity.id && allProjectActivity.attributes && allProjectActivity.attributes.totalCostContract) {
					paymentSumLevelThree += allProjectActivity.attributes.totalCostContract;
				}
			})
		);
	}

	project.set('totalCostContract', paymentSumLevelThree);
	await project.save();
}

export const updateTotalPaymentInProjectAndProjectActivityAndProjectCostAndProjectCostContract = async (projectCostContractId?: string): Promise<void> => {
	if (!projectCostContractId) return;

	const projectCostContractObject = Parse.Object.extend('ProjectCostContract');
	const projectCostContractQuery = new Parse.Query(projectCostContractObject).limit(10000);
	const projectCostContract = await projectCostContractQuery.get(projectCostContractId);

	if (!projectCostContract) return;

	const isVatEligibleCost = await isVatEligibleCostGetFromProjectCostContract(projectCostContract);

	const projectPaymentObject = Parse.Object.extend('ProjectPayment');
	const projectPaymentQuery = new Parse.Query(projectPaymentObject).equalTo('projectCostContract', projectCostContract).limit(10000);
	const projectPayments = await projectPaymentQuery.find();

	let paymentSum = 0;

	if (projectPayments && projectPayments.length > 0) {
		await Promise.all(
			projectPayments.map(async (projectPayment) => {
				if (projectPayment.id && projectPayment.attributes && projectPayment.attributes.realTotalNetPrice && !isVatEligibleCost) {
					paymentSum += projectPayment.attributes.realTotalNetPrice;
				} else if (projectPayment.id && projectPayment.attributes && projectPayment.attributes.realTotalVatPrice && isVatEligibleCost) {
					paymentSum += projectPayment.attributes.realTotalVatPrice;
				}
			})
		);
	}

	projectCostContract.set('totalPayment', paymentSum);
	const projectCostContractResult = await projectCostContract.save();

	if (!(projectCostContractResult && projectCostContractResult.attributes?.projectCost?.id)) return;

	const projectCostObject = Parse.Object.extend('ProjectCost');
	const projectCostQuery = new Parse.Query(projectCostObject).equalTo('objectId', projectCostContractResult.attributes.projectCost.id).limit(10000);
	const projectCost = await projectCostQuery.first();

	if (!projectCost) return;

	const allProjectCostContractsObject = Parse.Object.extend('ProjectCostContract');
	const allProjectCostContractsQuery = new Parse.Query(allProjectCostContractsObject).equalTo('projectCost', projectCost).limit(10000);
	const allProjectCostContracts = await allProjectCostContractsQuery.find();

	let paymentSumLevelTwo = 0;

	if (allProjectCostContracts && allProjectCostContracts.length > 0) {
		await Promise.all(
			allProjectCostContracts.map(async (allProjectCostContract) => {
				if (allProjectCostContract.id && allProjectCostContract.attributes && allProjectCostContract.attributes.totalPayment) {
					paymentSumLevelTwo += allProjectCostContract.attributes.totalPayment;
				}
			})
		);
	}

	projectCost.set('totalPayment', paymentSumLevelTwo);
	const projectCostResult = await projectCost.save();

	if (!(projectCostResult && projectCostResult.attributes?.projectActivity?.id)) return;

	const projectActivityObject = Parse.Object.extend('ProjectActivity');
	const projectActivityQuery = new Parse.Query(projectActivityObject).equalTo('objectId', projectCostResult.attributes.projectActivity.id).limit(10000);
	const projectActivity = await projectActivityQuery.first();

	if (!projectActivity) return;

	const allProjectCostObject = Parse.Object.extend('ProjectCost');
	const allProjectCostQuery = new Parse.Query(allProjectCostObject).equalTo('projectActivity', projectActivity).limit(10000);
	const allProjectCosts = await allProjectCostQuery.find();

	let paymentSumLevelThree = 0;

	if (allProjectCosts && allProjectCosts.length > 0) {
		await Promise.all(
			allProjectCosts.map(async (allProjectCost) => {
				if (allProjectCost.id && allProjectCost.attributes && allProjectCost.attributes.totalPayment) {
					paymentSumLevelThree += allProjectCost.attributes.totalPayment;
				}
			})
		);
	}

	projectActivity.set('totalPayment', paymentSumLevelThree);
	const projectActivityResult = await projectActivity.save();

	if (!(projectActivityResult && projectActivityResult.attributes?.project?.id)) return;

	const projectObject = Parse.Object.extend('Project');
	const projectQuery = new Parse.Query(projectObject).equalTo('objectId', projectActivityResult.attributes.project.id).limit(10000);
	const project = await projectQuery.first();

	if (!project) return;

	const allProjectActivityObject = Parse.Object.extend('ProjectActivity');
	const allProjectActivityQuery = new Parse.Query(allProjectActivityObject).equalTo('project', project).limit(10000);
	const allProjectActivities = await allProjectActivityQuery.find();

	let paymentSumLevelFour = 0;

	if (allProjectActivities && allProjectActivities.length > 0) {
		await Promise.all(
			allProjectActivities.map(async (allProjectActivity) => {
				if (allProjectActivity.id && allProjectActivity.attributes && allProjectActivity.attributes.totalPayment) {
					paymentSumLevelFour += allProjectActivity.attributes.totalPayment;
				}
			})
		);
	}

	project.set('totalPayment', paymentSumLevelFour);
	await project.save();
}

const isVatEligibleCostGetFromProjectCostContract = async (projectCostContract: any): Promise<boolean> => {
	const projectCostObject = Parse.Object.extend('ProjectCost');
	const projectCostQuery = new Parse.Query(projectCostObject).equalTo('objectId', projectCostContract.attributes.projectCost.id).limit(10000);
	const projectCost = await projectCostQuery.first();

	return projectCost?.attributes?.isVatEligibleCost || false;
}
