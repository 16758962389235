import { Roles } from "../../settings/Enums";

export const isAnyCommonString = (userRoles: string[], requiredRoles: string[], selectedOrganizationTitle?: string): boolean => {
    
    for (let i = 0; i < requiredRoles.length; i++) {

        for (let j = 0; j < userRoles.length; j++) {
            
            let requiredRole = requiredRoles[i];
            let userRole = userRoles[j];
            
            if (requiredRole === userRole) {
                return true;
            }

            if (selectedOrganizationTitle) {

                if (requiredRole === Roles.AdminOrganization) {
                    if (userRole === `${selectedOrganizationTitle}_ADMIN`) {
                        return true;
                    }
                }
    
                if (requiredRole === Roles.Employee) {
                    if (userRole === `${selectedOrganizationTitle}_EMPLOYEE`) {
                        return true;
                    }
                }

                if (requiredRole === Roles.Client) {
                    if (userRole === `${selectedOrganizationTitle}_CLIENT`) {
                        return true;
                    }
                }

            }

        }
    }
    
    return false;
    
    // return requiredRoles.some((item: string) => {
    //     if (item === Roles.Admin) {
    //         return userRoles.includes(item)
    //     } else if (selectedOrganizationTitle) {
    //         return !!userRoles.find((s: string): boolean => {
    //             switch (s) {
    //                 case Roles.AdminOrganization:
    //                     return `${selectedOrganizationTitle}_ADMIN` === item;
    //                 case Roles.Client:
    //                     return `${selectedOrganizationTitle}_CLIENT` === item;
    //                 case Roles.Employee:
    //                     return `${selectedOrganizationTitle}_EMPLOYEE` === item;
    //                 default:
    //                     return false;
    //             }
    //         });
    //     }
    //     return false;
    // });
}

// export const isAnyCommonString = (firstArray: string[], secondArray: string[], selectedOrganizationTitle?: string): boolean => {
//     return firstArray.some((item: string) => {
//         if (item === Roles.Admin) {
//             return secondArray.includes(item)
//         } else if (selectedOrganizationTitle) {
//             return !!secondArray.find((s: string): boolean => {
//                 switch (s) {
//                     case Roles.AdminOrganization:
//                         return `${selectedOrganizationTitle}_ADMIN` === item;
//                     case Roles.Client:
//                         return `${selectedOrganizationTitle}_CLIENT` === item;
//                     case Roles.Employee:
//                         return `${selectedOrganizationTitle}_EMPLOYEE` === item;
//                     default:
//                         return false;
//                 }
//             });
//         }
//         return false;
//     });
// }
