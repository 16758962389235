import {
  EditSettingsModel,
  SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthDataContext } from "../../components/authDataProvider";
import { BreadCrumbsNavigation } from "../../components/breadCrumbsNavigation";
import { DataGrid } from "../../components/dataGrid";
import {
  fetchPaymentsByProjectCostContractId,
  setPropertyToProjectPayment
} from "../../helpers/fetching/CostPaymentServiceHelper";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import { updateTotalPaymentInProjectAndProjectActivityAndProjectCostAndProjectCostContract } from "../../helpers/updateParent/UpdateParentHelper";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { ProjectPaymentBaseModel } from "../../models/ProjectPaymentBaseModel";
import { GetFirstObjectById } from "../../services/GenericServices";
import { roleListAdminAndEmployee } from "../../settings/Constants";
import { Language, ParseClassName } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {}

export const ProjectPayments: React.FC<Props> = (): ReactElement => {
  const [projectPayments, setProjectPayments] = useState<
    ProjectPaymentBaseModel[]
  >([]);
  const [projectCostContract, setProjectCostContract] = useState<
    any | undefined
  >(undefined);
  const { userRoles, selectedOrganization, language } = useAuthDataContext();

  useEffect(() => {
    getProjectCostContractIdFromRoute();
  }, []);

  const getProjectCostContractIdFromRoute = async (): Promise<void> => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 10 &&
      urlParts[1] === "projects" &&
      urlParts[3] === "activities" &&
      urlParts[5] === "costs" &&
      urlParts[7] === "contracts" &&
      urlParts[9] === "payments"
    ) {
      const projectCostContractIdFromUrl = urlParts[8];

      const projectCostContractResult = await GetFirstObjectById(
        "ProjectCostContract",
        projectCostContractIdFromUrl,
        "projectCost.projectActivity.project.role"
      );

      const projectPayments = await fetchPaymentsByProjectCostContractId(
        projectCostContractResult
      );
      setProjectCostContract(projectCostContractResult);
      setProjectPayments(projectPayments);
    }
  };

  const editSettings: EditSettingsModel = {
    allowEditing:
      userRoles &&
      isAnyCommonString(
        userRoles,
        roleListAdminAndEmployee,
        selectedOrganization?.attributes?.shortTitle
      )
        ? true
        : false,
    allowAdding:
      userRoles &&
      isAnyCommonString(
        userRoles,
        roleListAdminAndEmployee,
        selectedOrganization?.attributes?.shortTitle
      )
        ? true
        : false,
    mode: "Dialog",
  };

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "invoiceDate", direction: "Descending" }],
  };

  const columns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "realTotalNetPrice",
      headerText: translations[language || Language.Hr].amountWithoutVat,
      validationRules: {
        required: true,
      },
      format: "N2",
    },
    {
      field: "vatAmount",
      headerText: translations[language || Language.Hr].VAT,
      format: "N2",
    },
    {
      field: "realTotalVatPrice",
      headerText: translations[language || Language.Hr].inTotal,
      format: "N2",
    },
    {
      field: "subventionAmount",
      headerText: translations[language || Language.Hr].subvention,
      format: "N2",
    },
    {
      field: "invoiceDate",
      headerText: translations[language || Language.Hr].invoiceDate,
      type: "date",
      format: "dd.MM.yyyy.",
      filter: {
        type: "Menu",
        params: {
          format: "dd.MM.yyyy.",
        },
      },
      validationRules: {
        required: true,
      },
    },
    {
      field: "paymentDate",
      headerText: translations[language || Language.Hr].paymentDate,
      type: "date",
      format: "dd.MM.yyyy.",
      filter: {
        type: "Menu",
        params: {
          format: "dd.MM.yyyy.",
        },
      },
      validationRules: {
        required: true,
      },
    },
  ];

  const createOrUpdateProjectPayment = async (
    newProjectPayment: ProjectPaymentBaseModel
  ): Promise<void> => {
    const projectPayment = setPropertyToProjectPayment(
      newProjectPayment,
      projectCostContract,
      selectedOrganization
    );
    const response = await projectPayment.save();

    if (response) {
      const projectPayments = await fetchPaymentsByProjectCostContractId(
        projectCostContract
      );
      setProjectPayments(projectPayments);

      await updateTotalPaymentInProjectAndProjectActivityAndProjectCostAndProjectCostContract(
        projectCostContract?.id
      );
    }
  };

  return (
    <section id="payment">
      <BreadCrumbsNavigation
        breadCrumbTemplates={[
          {
            url: "projects",
            title: translations[language || Language.Hr].projects,
          },
          {
            url: "activities",
            title: translations[language || Language.Hr].activities,
          },
          {
            url: "costs",
            title: translations[language || Language.Hr].costs,
          },
          {
            url: "contracts",
            title: translations[language || Language.Hr].contracts,
          },
        ]}
      />
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={projectPayments}
            columnProperties={columns}
            useDialogTemplate={ParseClassName.ProjectPayment}
            createItem={createOrUpdateProjectPayment}
            updateItem={createOrUpdateProjectPayment}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
    </section>
  );
};
