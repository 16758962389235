import React, { ReactElement, useState, useEffect } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { GetProjectTemplates } from "../../services/ProjectTemplateServices";
import { useAuthDataContext } from "../authDataProvider";
import { translations } from "../../settings/translation";
import { Language } from "../../settings/Enums";

interface Props {
  updateSelectedProjectTemplate: (value?: string) => void;
  value?: string;
}

export const ProjectTemplatePicker: React.FC<Props> = (
  props: Props
): ReactElement => {
  const [projectTemplates, setProjectTemplates] = useState<
    { [key: string]: Object }[]
  >([]);
  const { language } = useAuthDataContext();

  useEffect(() => {
    fetchProjectTemplates();
  }, []);

  const fetchProjectTemplates = async (): Promise<void> => {
    const projectTemplates = await GetProjectTemplates();

    if (!projectTemplates) return;

    const mappedProjectTemplates: { [key: string]: Object }[] = [];

    projectTemplates.forEach((projectTemplate) => {
      mappedProjectTemplates.push({
        text: projectTemplate.title,
        value: projectTemplate.id,
      });
    });

    setProjectTemplates(mappedProjectTemplates);
  };

  const onChange = (value?: string): void => {
    const { updateSelectedProjectTemplate } = props;

    updateSelectedProjectTemplate(value);
  };

  const fields: object = { text: "text", value: "value" };

  return (
    <DropDownListComponent
      name="projectTemplate"
      fields={fields}
      dataSource={projectTemplates}
      sortOrder={"Ascending"}
      value={props.value}
      showClearButton={true}
      placeholder={translations[language || Language.Hr].chooseTemplate}
      change={(item) =>
        onChange(
          item && item.itemData && item.itemData.value
            ? item.itemData.value
            : undefined
        )
      }
    />
  );
};
