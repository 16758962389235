import React from "react";
import Parse from "parse";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-react-popups";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  projectShortCode?: string;
  language: Language;
}

interface State {
  isDialogVisible: boolean;
  selectedTags?: string[];
  tagOptions: { [key: string]: Object }[];
  tagOptionsForSearch: any[];
}

const API_HOST = process.env.REACT_APP_API_HOST;
const SERVERLESS_API_HOST = process.env.REACT_APP_SERVERLESS_API_HOST;

class DownloadByTagsDialog extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isDialogVisible: false,
      tagOptions: [],
      tagOptionsForSearch: [],
    };
  }

  public componentDidMount() {
    this.fetchAllTags();
  }

  private fetchAllTags = async (): Promise<void> => {
    const dropdownItems: { [key: string]: Object }[] = [];
    const dropdownItemsForSearch: any[] = [];

    const object = Parse.Object.extend("Tag");
    const query = new Parse.Query(object).limit(10000);

    const result = await query.find();

    if (!(result && result.length > 0)) return;

    result.forEach((tag) => {
      if (tag.id && tag.attributes) {
        dropdownItems.push({
          value: tag.id,
          text: `${tag.attributes.title}`,
        });
        dropdownItemsForSearch.push(tag);
      }
    });

    this.setState({
      tagOptions: dropdownItems,
      tagOptionsForSearch: dropdownItemsForSearch,
    });
  };

  // do not delete this, this is used as reference
  private handleModalState = (newState: boolean): void => {
    this.setState({
      isDialogVisible: newState,
    });
  };

  private downloadByTags = async (): Promise<void> => {
    const { projectShortCode } = this.props;
    const { selectedTags } = this.state;
    const sessionToken = Parse.User.current()?.get("sessionToken");

    // NOTE: default API endpoint is hosted at Heroku but HTTP request timeout at Heroku Load Balancer is limited
    // to 30 seconds, because od that this intensive job is moved to Google Cloud Run with timeout of 300s
    const endpointWithSessionToken = `${SERVERLESS_API_HOST}/filemanager/download-by-tags?sessionToken=${sessionToken}`;
    const url = `${endpointWithSessionToken}${
      projectShortCode ? `&projectShortCode=${projectShortCode}` : ""
    }`;

    const selectedTagsString = selectedTags?.join("&tags=");
    const urlWithTags = `${url}${
      selectedTagsString ? `&tags=${selectedTagsString}` : ""
    }`;

    window.open(urlWithTags, "_parent");
  };

  public render(): any {
    const { isDialogVisible, selectedTags, tagOptions } = this.state;
    const { language } = this.props;

    const buttons: ButtonPropsModel[] = [
      {
        buttonModel: {
          content: translations[language || Language.Hr].download,
          cssClass: "e-flat",
          isPrimary: true,
          disabled: !(selectedTags && selectedTags.length > 0),
        },
        click: () => this.downloadByTags(),
      },
      {
        buttonModel: {
          content: translations[language || Language.Hr].cancel,
          cssClass: "e-flat",
          isPrimary: false,
        },
        click: () =>
          this.setState({ isDialogVisible: false, selectedTags: undefined }),
      },
    ];

    return (
      <div>
        {isDialogVisible && (
          <DialogComponent
            isModal={true}
            width="700px"
            close={(): void => this.setState({ isDialogVisible: false })}
            header={translations[language || Language.Hr].downloadByTags}
            visible={isDialogVisible}
            showCloseIcon={true}
            buttons={buttons}
          >
            <div className="dialog-content" style={{ padding: "15px" }}>
              <MultiSelectComponent
                dataSource={tagOptions}
                value={selectedTags}
                placeholder={translations[language || Language.Hr].chooseTags}
                change={(args) =>
                  this.setState({
                    selectedTags: args && (args.value as string[]),
                  })
                }
                closePopupOnSelect={false}
                mode="Box"
                name="tags"
              >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
            </div>
          </DialogComponent>
        )}
      </div>
    );
  }
}

export default DownloadByTagsDialog;
