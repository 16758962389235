import Parse from "parse";
import { GetRelationByName } from "../../services/GenericServices";
import { Roles } from "../../settings/Enums";

export const saveProjectFileTags = async (
  selectedTags: string[] | undefined,
  tagOptionsForSearch: any[],
  folderId: string
): Promise<any> => {
  const projectFile = Parse.Object.extend("ProjectFile");
  const projectFileQuery = new Parse.Query(projectFile).limit(10000);
  const projectFileDetails = await projectFileQuery.get(folderId);
  const relation = projectFileDetails.relation("tags");
  const tags = await GetRelationByName(projectFileDetails, "tags");

  if (selectedTags && selectedTags.length > 0) {
    // Add new tags to relation
    selectedTags.forEach((selectedTag): void => {
      const oldValue = tags.find((t: any): boolean =>
        t && t.attributes && t.attributes.title === selectedTag ? true : false
      );
      if (!oldValue) {
        const findNewValue = tagOptionsForSearch.find((t: any): boolean =>
          t && t.id && t.attributes && t.attributes.title === selectedTag
            ? true
            : false
        );
        if (findNewValue) {
          relation.add(findNewValue);
        }
      }
    });

    // Remove old tags that is deselected from relation
    tags.forEach((tag: any): void => {
      if (tag && tag.attributes) {
        const stillInList = selectedTags.find(
          (selectedTag): boolean => selectedTag === tag.attributes.title
        );
        if (!stillInList) {
          relation.remove(tag);
        }
      }
    });
  } else {
    // If all tags are deselected
    tags.forEach((tag: any): void => {
      relation.remove(tag);
    });
  }

  return await projectFileDetails.save();
};

export const saveProjectFileTask = async (
  selectedTask: string | undefined,
  taskOptionsForSearch: any[],
  folderId: string
): Promise<any> => {
  const taskToSave = taskOptionsForSearch.find((t: any): boolean =>
    t && t.id === selectedTask ? true : false
  );

  if (taskToSave) {
    const projectFile = Parse.Object.extend("ProjectFile");
    const projectFileQuery = new Parse.Query(projectFile).limit(10000);
    const projectFileDetails = await projectFileQuery.get(folderId);
    taskToSave.set("projectFile", projectFileDetails);
    const newTask = await taskToSave.save();
    projectFileDetails.set("task", newTask);
    return await projectFileDetails.save();
  }
};

export const removeProjectFileTask = async (folderId: string): Promise<any> => {
  const projectFile = Parse.Object.extend("ProjectFile");
  const projectFileQuery = new Parse.Query(projectFile).limit(10000);
  const projectFileDetails = await projectFileQuery.get(folderId);
  if (
    projectFileDetails &&
    projectFileDetails.attributes &&
    projectFileDetails.attributes.task &&
    projectFileDetails.attributes.task.id
  ) {
    await removeTaskProjectFile(projectFileDetails.attributes.task.id);
  }
  projectFileDetails.set("task", null);
  return await projectFileDetails.save();
};

export const removeTaskProjectFile = async (taskId: string): Promise<void> => {
  const task = Parse.Object.extend("Task");
  const taskQuery = new Parse.Query(task).limit(10000);
  const taskDetails = await taskQuery.get(taskId);
  taskDetails.set("projectFile", null);
  await taskDetails.save();
};

export const saveProjectFileTaskAfterCreate = async (
  selectedTask: any,
  folderId: string
): Promise<any> => {
  if (selectedTask) {
    const projectFile = Parse.Object.extend("ProjectFile");
    const projectFileQuery = new Parse.Query(projectFile).limit(10000);
    const projectFileDetails = await projectFileQuery.get(folderId);
    projectFileDetails.set("task", selectedTask);
    return await projectFileDetails.save();
  }
};

export const updateIsEditableForClientProperty = async (
  isEditableForClient: boolean,
  folderId?: string,
  selectedOrganization?: any
): Promise<any> => {
  if (!(folderId && selectedOrganization)) return;
  const projectFile = Parse.Object.extend("ProjectFile");
  const projectFileQuery = new Parse.Query(projectFile)
    .include("Project")
    .limit(10000);
  const projectFileDetails = await projectFileQuery.get(folderId);

  if (!projectFileDetails?.attributes?.project?.attributes?.shortCode) return;

  projectFileDetails.set("isEditableForClient", isEditableForClient);
  const projectRole =
    "PROJECT_" + projectFileDetails.attributes.project.attributes.shortCode;
  const acl = new Parse.ACL();
  const orgEmployeeRoleName = `${selectedOrganization.attributes.shortTitle}_EMPLOYEE`;
  acl.setRoleReadAccess(projectRole, true);
  acl.setRoleWriteAccess(
    isEditableForClient ? projectRole : orgEmployeeRoleName,
    true
  );
  acl.setRoleReadAccess(Roles.Admin, true);
  acl.setRoleWriteAccess(Roles.Admin, true);

  const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;

  acl.setRoleReadAccess(orgAdminRoleName, true);
  acl.setRoleWriteAccess(orgAdminRoleName, true);

  projectFileDetails.setACL(acl);
  projectFileDetails.save();
};
