import { EmitType } from "@syncfusion/ej2-base";
import {
  DetailsView,
  FileManagerComponent,
  FileOpenEventArgs,
  FileSelectEventArgs,
  Inject,
  MenuClickEventArgs,
  MenuOpenEventArgs,
  NavigationPane,
  Toolbar,
  ToolbarClickEventArgs,
  ToolbarCreateEventArgs,
} from "@syncfusion/ej2-react-filemanager";
import Parse from "parse";
import React, { ReactElement } from "react";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import {
  getFileManagerFileMenu,
  getFileManagerFolderMenu,
  getFileManagerLayoutMenu,
  getFileManagerToolbar,
} from "../../helpers/toolbarOptions/ToolbarOptionsHelper";
import { roleListAdminAndEmployee } from "../../settings/Constants";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import { useAuthDataContext } from "../authDataProvider";
import "./FileManager.css";

interface Props {
  id: string;
  isTemplate?: boolean;
  isTemplatePicker?: boolean;
  projectShortCode?: string;
  toolbarClick?: EmitType<ToolbarClickEventArgs>;
  toolbarCreate?: EmitType<ToolbarCreateEventArgs>;
  menuClick?: EmitType<MenuClickEventArgs>;
  menuOpen?: EmitType<MenuOpenEventArgs>;
  fileSelect?: EmitType<FileSelectEventArgs>;
  fileOpen?: EmitType<FileOpenEventArgs>;
  toolbarMenuCustom?: string[];
  fileMenuCustom?: string[];
  folderMenuCustom?: string[];
  layoutMenuCustom?: string[];
  path?: string;
}

const API_HOST = process.env.REACT_APP_API_HOST;
const apiHostUrl = `${API_HOST}/filemanager`;

export const FileManager: React.FC<Props> = (props: Props): ReactElement => {
  const {
    id,
    isTemplate,
    isTemplatePicker,
    projectShortCode,
    toolbarClick,
    toolbarCreate,
    menuClick,
    menuOpen,
    fileSelect,
    fileOpen,
    toolbarMenuCustom,
    fileMenuCustom,
    folderMenuCustom,
    layoutMenuCustom,
    path,
  } = props;
  const { userRoles, selectedOrganization, language } = useAuthDataContext();

  const toolbarMenus = getFileManagerToolbar(
    isTemplatePicker,
    isTemplate,
    toolbarMenuCustom,
    userRoles,
    selectedOrganization?.attributes?.shortTitle,
  );

  const fileMenus = getFileManagerFileMenu(
    isTemplatePicker,
    fileMenuCustom,
    userRoles,
    selectedOrganization?.attributes?.shortTitle,
  );

  const folderMenus = getFileManagerFolderMenu(
    isTemplatePicker,
    folderMenuCustom,
    userRoles,
    selectedOrganization?.attributes?.shortTitle,
  );

  const layoutMenus = getFileManagerLayoutMenu(
    layoutMenuCustom,
    userRoles,
    selectedOrganization?.attributes?.shortTitle,
  );

  const installationId = localStorage.getItem(
    "Parse/tempoProjectsApp/installationId"
  );
  const sessionToken = Parse.User.current()?.get("sessionToken");
  let queryString = `?installationId=${installationId}&sessionToken=${sessionToken}`;
  if (projectShortCode) {
    queryString = `${queryString}&projectShortCode=${projectShortCode}&language=${language}`;
  }

  const fileUrl = isTemplate ? "templates" : "actions";

  const hideError = (args: any): void => {
    if (
      args &&
      args.popupModule &&
      args.popupModule.content &&
      typeof args.popupModule.content === "string" &&
      args.popupModule.content.includes(
        "The destination folder is the subfolder of the source folder."
      )
    ) {
      args.cancel = true;
    }
  };

  return (
    <FileManagerComponent
      id={id}
      view={"Details"}
      allowDragAndDrop={
        userRoles &&
        isAnyCommonString(
          userRoles,
          roleListAdminAndEmployee,
          selectedOrganization?.attributes?.shortTitle
        )
      }
      ajaxSettings={{
        url: `${apiHostUrl}/${fileUrl}${queryString}`,
        uploadUrl: isTemplate
          ? undefined
          : `${apiHostUrl}/upload${queryString}`,
        downloadUrl: isTemplate
          ? undefined
          : `${apiHostUrl}/download${queryString}`,
      }}
      toolbarSettings={{ items: toolbarMenus }}
      toolbarClick={toolbarClick}
      toolbarCreate={toolbarCreate}
      fileSelect={fileSelect}
      fileOpen={fileOpen}
      showThumbnail={false}
      contextMenuSettings={{
        file: fileMenus,
        folder: folderMenus,
        layout: layoutMenus,
      }}
      detailsViewSettings={{
        columns: isTemplate
          ? [
              {
                field: "extension",
                headerText: '<div class="custom-icon-header">.</div>',
                width: "46",
                template:
                  '<div class="table-content-custom custom-icon-${extension}"><div class="is-editable-${isEditableForClient}">.</div>.</div>',
              },
              {
                field: "name",
                headerText: translations[language || Language.Hr].name,
                minWidth: 120,
                width: "auto",
                customAttributes: { class: "e-fe-grid-name" },
              },
              {
                field: "_fm_modified",
                headerText: translations[language || Language.Hr].modified,
                minWidth: 50,
                width: "190",
                format: "dd.MM.yyyy. HH:mm",
              },
              {
                field: "isCreatedWithDefaultTask",
                headerText: translations[language || Language.Hr].task,
                minWidth: 50,
                width: "100",
                template:
                  '<div class="outer-icon-wrapper"><div class="header-icon e-icons background-task-table-icon-${isCreatedWithDefaultTask}" /></div>',
              },
            ]
          : [
              {
                field: "extension",
                headerText: '<div class="custom-icon-header">.</div>',
                width: "46",
                template:
                  '<div class="table-content-custom custom-icon-${extension}"><div class="is-editable-${isEditableForClient}">.</div>.</div>',
              },
              {
                field: "name",
                headerText: translations[language || Language.Hr].name,
                minWidth: 120,
                width: "auto",
                customAttributes: { class: "e-fe-grid-name" },
              },
              {
                field: "_fm_modified",
                headerText: translations[language || Language.Hr].modified,
                minWidth: 50,
                width: "190",
                format: "dd.MM.yyyy. HH:mm",
              },
              {
                field: "size",
                headerText: translations[language || Language.Hr].size,
                minWidth: 50,
                width: "110",
                template:
                  '<div class="${size}">${Number(size/1024).toFixed(2)} KB</div>',
              },
              {
                field: "taskStateName",
                headerText: translations[language || Language.Hr].status,
                minWidth: 50,
                width: "150",
                template:
                  '<div class="task-status-${taskStateId}">${taskStateName}</div>',
              },
            ],
      }}
      menuClick={menuClick}
      menuOpen={menuOpen}
      beforePopupOpen={(args): void => hideError(args)}
      path={path}
    >
      <Inject services={[NavigationPane, DetailsView, Toolbar]} />
    </FileManagerComponent>
  );
};
