import React, { ReactElement } from "react";
import { Col, Form } from "react-bootstrap";
import { ProjectTemplateBaseModel } from "../../models/ProjectTemplateBaseModel";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  data: ProjectTemplateBaseModel;
  language?: Language;
}

export const ProjectTemplateDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const { data, language } = props;

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="titleValidation">
          <Form.Label>{translations[language || Language.Hr].title}</Form.Label>
          <Form.Control
            type="text"
            name="title"
            defaultValue={data.title}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
