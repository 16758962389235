import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  EditSettingsModel,
  SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import {
  ButtonPropsModel,
  DialogComponent
} from "@syncfusion/ej2-react-popups";
import Parse, { Attributes } from "parse";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthDataContext } from "../../components/authDataProvider";
import { BreadCrumbsNavigation } from "../../components/breadCrumbsNavigation";
import { DataGrid } from "../../components/dataGrid";
import { fetchAdminOrganizationListByOrganizationId } from "../../helpers/fetching/OrganizationServiceHelper";
import { getPersonByUser } from "../../helpers/fetching/UserServiceHelper";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { DataGridToolbarOption } from "../../models/DataGridToolbarOption";
import { PersonBaseModel } from "../../models/PersonBaseModel";
import { UserBaseModel, UserNumberModel } from "../../models/UserBaseModel";
import {
  GetAllObjectsByTableName,
  GetFirstObjectById,
  GetRelationByName
} from "../../services/GenericServices";
import { CreatePerson, UpdatePersonByUser } from "../../services/PersonService";
import {
  AssaignUserToRole,
  GetRoleByPropertyName,
  GetRoleQueryRelationByName
} from "../../services/RoleServices";
import {
  CreateUser,
  GetFirstUserByPropertyName,
  getNumberOfUsersByOrganization,
  UpdateUser
} from "../../services/UserServices";
import { Language, ParseClassName, Roles } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {}

export const Administrators: React.FC<Props> = (props: Props): ReactElement => {
  const [
    isAddAdministratorDialogDisplayed,
    setIsAddAdministratorDialogDisplayed,
  ] = useState<boolean>(false);
  const { language } = useAuthDataContext();
  const [selectedAdministratorId, setSelectedAdministratorId] =
    useState<string>();
  const [newAdministrators, setNewAdministrators] = useState<
    { [key: string]: Object }[]
  >([]);
  const [administrators, setAdministrators] = useState<UserBaseModel[]>([]);
  const [organization, setOrganization] = useState<any | undefined>();
  const [numberOfUsers, setNumberOfUsers] = useState<
    UserNumberModel | undefined
  >();

  useEffect(() => {
    getOrganizationIdFromRoute();
  }, []);

  useEffect(() => {
    fetchAdministrators();
  }, [organization]);

  const getUsersCount = async (org: any): Promise<void> => {
    const numbers = await getNumberOfUsersByOrganization(org);
    setNumberOfUsers(numbers);
  };

  const getOrganizationIdFromRoute = async (): Promise<void> => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 4 &&
      urlParts[1] === "organizations" &&
      urlParts[3] === "administrators"
    ) {
      const orgId = urlParts[2];
      fetchOrganization(orgId);
    }
  };

  const fetchOrganization = async (orgId?: string): Promise<void> => {
    if (orgId) {
      const selectedOrganization = await GetFirstObjectById(
        "Organization",
        orgId
      );
      if (selectedOrganization) {
        setOrganization(selectedOrganization);
        getUsersCount(selectedOrganization);
      }
    }
  };

  const fetchAdministrators = async (org?: any): Promise<void> => {
    if (!organization && !org) return;

    const result = await fetchAdminOrganizationListByOrganizationId(
      organization || org
    );

    setAdministrators(result);
    if (newAdministrators.length === 0) {
      fetchExistingUsers(result);
    }
  };

  const fetchExistingUsers = async (
    newAttributes: UserBaseModel[]
  ): Promise<void> => {
    const dropdownItems: { [key: string]: Object }[] = [];
    const persons = await GetAllObjectsByTableName("Person", "parseUser");

    if (!(persons && persons?.length > 0)) return;

    const adminQuery = new Parse.Query(Parse.Role)
      .equalTo("name", Roles.Admin)
      .limit(10000);
    const adminRole = await adminQuery.first();

    if (!adminRole) return;

    const adminUsers = await GetRelationByName(adminRole, "users");

    await Promise.all(
      persons.map(async (person) => {
        if (
          person &&
          person.attributes &&
          person.attributes.parseUser &&
          person.id &&
          !newAttributes.find(
            (a: UserBaseModel): boolean =>
              a.id === person.attributes.parseUser.id
          ) &&
          !adminUsers?.find(
            (adminUser: Parse.User): boolean =>
              adminUser.id === person.attributes.parseUser.id
          )
        ) {
          dropdownItems.push({
            text: `${person.attributes.firstName} ${person.attributes.lastName} (${person.attributes.email})`,
            value: person.attributes.parseUser.id,
          });
        }
      })
    );

    setNewAdministrators(dropdownItems);
  };

  const createAdministrator = async (
    administratorModel: UserBaseModel
  ): Promise<void> => {
    if (!organization) return;

    const userResponse = await CreateUser(
      administratorModel,
      organization.attributes.shortTitle,
      true
    );

    if (!userResponse) return;

    const personModel = {
      ime: administratorModel.firstName || "",
      lastName: administratorModel.lastName || "",
      phone: administratorModel.phone || "",
      elektronickaPosta: administratorModel.email,
      parseUser: userResponse,
      lozinka: administratorModel.password,
    };

    const orgRoleName = `ORGANIZATION_${organization.attributes.shortTitle}`;
    const orgAdminRoleName = `${organization.attributes.shortTitle}_ADMIN`;

    const personAcl = new Parse.ACL();
    personAcl.setRoleReadAccess(Roles.Admin, true);
    personAcl.setRoleWriteAccess(Roles.Admin, true);
    personAcl.setReadAccess(userResponse, true);
    personAcl.setWriteAccess(userResponse, true);
    personAcl.setRoleReadAccess(orgRoleName, true);
    personAcl.setRoleWriteAccess(orgRoleName, false);

    await CreatePerson(personModel as PersonBaseModel, personAcl);

    await AssaignUserToRole(userResponse, orgAdminRoleName);

    if (numberOfUsers) {
      setNumberOfUsers({
        ...numberOfUsers,
        currentUserNumber: numberOfUsers.currentUserNumber + 1,
      });
    }

    await fetchAdministrators();
  };

  const updateAdministrator = async (
    administratorModel: UserBaseModel
  ): Promise<any> => {
    const userResponse = await UpdateUser(administratorModel);

    if (!userResponse) return;

    const personModel = {
      ime: administratorModel.firstName || "",
      lastName: administratorModel.lastName || "",
      phone: administratorModel.phone || "",
      elektronickaPosta: administratorModel.email,
      parseUser: userResponse,
      lozinka: administratorModel.password,
    };

    await UpdatePersonByUser(personModel as PersonBaseModel);

    await fetchAdministrators();
  };

  const openAddAdministratorDialog = async (): Promise<void> => {
    setSelectedAdministratorId(undefined);
    setIsAddAdministratorDialogDisplayed(true);
  };

  const addAdministratorToOrganization = async (
    newAdminId?: string
  ): Promise<void> => {
    if (!(newAdminId && organization)) return;

    const user = await GetFirstUserByPropertyName(newAdminId);
    if (!user) return;
    const person = await getPersonByUser(user);
    if (!person) return;

    const orgRoleName = `ORGANIZATION_${organization.attributes.shortTitle}`;
    const orgAdminRoleName = `${organization.attributes.shortTitle}_ADMIN`;

    const userACL = user.getACL();
    if (!userACL) return;
    const personACL = person.getACL();
    if (!personACL) return;

    userACL.setRoleReadAccess(orgRoleName, true);
    user.setACL(userACL);
    personACL.setRoleReadAccess(orgRoleName, true);
    person.setACL(userACL);

    const userAndPersonResult = await Parse.Object.saveAll([user, person]);
    if (!userAndPersonResult) return;

    const result = await AssaignUserToRole(user, orgAdminRoleName);

    if (numberOfUsers) {
      setNumberOfUsers({
        ...numberOfUsers,
        currentUserNumber: numberOfUsers.currentUserNumber + 1,
      });
    }

    if (result) {
      fetchAdministrators();
    }
    setIsAddAdministratorDialogDisplayed(false);
  };

  const deleteAdministratorFromOrganization = async (
    adminIdToDelete?: string
  ): Promise<void> => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      !(
        urlParts &&
        urlParts.length === 4 &&
        urlParts[1] === "organizations" &&
        urlParts[3] === "administrators"
      )
    )
      return;
    const orgId = urlParts[2];
    const localOrganization = await GetFirstObjectById("Organization", orgId);
    if (!localOrganization) return;

    const organizationRoleName = `ORGANIZATION_${localOrganization.attributes.shortTitle}`;
    const organizationAdminRoleName = `${localOrganization.attributes.shortTitle}_ADMIN`;
    if (!adminIdToDelete) return;

    const roleResult = await GetRoleByPropertyName(
      "name",
      organizationAdminRoleName
    );
    if (!(roleResult && roleResult.attributes)) return;
    const roleUsers = await GetRoleQueryRelationByName(roleResult, "users");
    const user = await GetFirstUserByPropertyName(adminIdToDelete);
    const person = await getPersonByUser(user);
    if (!(roleUsers && user && person)) return;
    const userACL = user.getACL();
    const personACL = person.getACL();
    if (!(userACL && personACL)) return;
    userACL.setRoleReadAccess(organizationRoleName, false);
    personACL.setRoleReadAccess(organizationRoleName, false);
    user.setACL(userACL);
    person.setACL(personACL);
    roleUsers.remove(user as Parse.User<Attributes>);
    const result = await Parse.Object.saveAll([user, person, roleResult]);

    if (result) {
      fetchAdministrators(localOrganization);
      if (numberOfUsers) {
        setNumberOfUsers({
          ...numberOfUsers,
          currentUserNumber: numberOfUsers.currentUserNumber - 1,
        });
      }
    }
  };

  const columnProperties: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "firstName",
      headerText: translations[language || Language.Hr].name,
      validationRules: {
        required: true,
      },
    },
    {
      field: "lastName",
      headerText: translations[language || Language.Hr].surname,
      validationRules: {
        required: true,
      },
    },
    {
      field: "email",
      headerText: translations[language || Language.Hr].email,
      validationRules: {
        required: true,
      },
    },
    {
      field: "phone",
      headerText: translations[language || Language.Hr].phone,
      validationRules: {
        required: true,
      },
    },
    {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 100,
      template: (props): ReactElement => {
        return (
          <div>
            <div
              className="e-btn"
              onClick={(): Promise<void> =>
                deleteAdministratorFromOrganization(props.id)
              }
            >
              {translations[language || Language.Hr].delete}
            </div>
          </div>
        );
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "ime", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding:
      numberOfUsers &&
      numberOfUsers.currentUserNumber < numberOfUsers.maxUserNumber,
    mode: "Dialog",
  };

  const addExistingAdministratorToolbarOption: DataGridToolbarOption = {
    text: translations[language || Language.Hr].addExisting,
    id: "customToolbarOption",
    tooltipText:
      translations[language || Language.Hr].addExistingUserToOrganization,
    prefixIcon: "e-add",
    disabled: !(
      numberOfUsers &&
      numberOfUsers.currentUserNumber < numberOfUsers.maxUserNumber
    ),
  };

  const buttons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].save,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => addAdministratorToOrganization(selectedAdministratorId),
    },
    {
      buttonModel: {
        content: translations[language || Language.Hr].cancel,
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => setIsAddAdministratorDialogDisplayed(false),
    },
  ];

  const fields: object = { text: "text", value: "value" };

  return (
    <section id="adminOrganizationList">
      <BreadCrumbsNavigation
        breadCrumbTemplates={[
          {
            url: "organizations",
            title: translations[language || Language.Hr].organizations,
          },
        ]}
      />
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={administrators}
            columnProperties={columnProperties}
            useDialogTemplate={ParseClassName.Administrator}
            customToolbarOption={addExistingAdministratorToolbarOption}
            handleCustomToolbarOption={openAddAdministratorDialog}
            updateItem={updateAdministrator}
            createItem={createAdministrator}
            fetchDataAfterCatchError={fetchAdministrators}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
      <DialogComponent
        isModal={true}
        width="700px"
        close={() => setIsAddAdministratorDialogDisplayed(false)}
        header={translations[language || Language.Hr].chooseAdministratorToAdd}
        visible={isAddAdministratorDialogDisplayed}
        showCloseIcon={true}
        buttons={buttons}
      >
        <div className="dialog-content" style={{ padding: "15px" }}>
          {/* this is needed since syncfusion DropDownListComponent won't deselect value
                                even if selectedExistingContactId === undefined */}
          {isAddAdministratorDialogDisplayed && (
            <DropDownListComponent
              fields={fields}
              dataSource={newAdministrators}
              sortOrder={"Ascending"}
              value={selectedAdministratorId}
              placeholder={
                translations[language || Language.Hr].chooseAdministrator
              }
              select={(item) =>
                setSelectedAdministratorId(item?.itemData.value)
              }
            />
          )}
        </div>
      </DialogComponent>
    </section>
  );
};
