import React, { ReactElement, useState, useEffect } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { GetTaskTemplates } from "../../services/TaskTemplateServices";
import { useAuthDataContext } from "../authDataProvider";
import { translations } from "../../settings/translation";
import { Language } from "../../settings/Enums";

interface Props {
  updateSelectedTaskTemplate: (value: string) => void;
  value?: string;
}

export const TaskTemplatePicker: React.FC<Props> = (
  props: Props
): ReactElement => {
  const [taskTemplates, setTaskTemplates] = useState<
    { [key: string]: Object }[]
  >([]);
  const { language } = useAuthDataContext();

  useEffect(() => {
    fetchTaskTemplates();
  }, []);

  const fetchTaskTemplates = async (): Promise<void> => {
    const taskTemplates = await GetTaskTemplates(language);

    if (!taskTemplates) return;

    const mappedTaskTemplates: { [key: string]: Object }[] = [];

    taskTemplates.forEach((taskTemplate) => {
      mappedTaskTemplates.push({
        text: taskTemplate.title,
        value: taskTemplate.id,
      });
    });

    setTaskTemplates(mappedTaskTemplates);
  };

  const onSelect = (value?: string): void => {
    const { updateSelectedTaskTemplate } = props;

    if (!value) return;
    updateSelectedTaskTemplate(value);
  };

  const fields: object = { text: "text", value: "value" };

  return (
    <div style={{ padding: "15px" }}>
      <DropDownListComponent
        name="taskTemplate"
        fields={fields}
        dataSource={taskTemplates}
        sortOrder={"Ascending"}
        value={props.value}
        placeholder={translations[language || Language.Hr].chooseTemplate}
        select={(item) => onSelect(item?.itemData.value)}
      />
    </div>
  );
};
