import Parse from "parse";
import { AlarmBaseModel } from "../models/AlarmBaseModel";
import { AlarmStatus, Language, Roles, TimeUnit } from "../settings/Enums";
import { GetAlarmTemplatesByTaskTemplateId } from "./AlarmTemplateServices";
import { GetFirstObjectById } from "./GenericServices";

export const GetAlarmsByTask = async (task: any) => {
  const object = Parse.Object.extend("Alarm");
  const objectQuery = new Parse.Query(object)
    .equalTo("task", task)
    .include("alarmState")
    .limit(10000);

  const results = await objectQuery.find();

  if (!results) return [];

  const alarms: AlarmBaseModel[] = [];

  results.forEach((alarm) => {
    alarms.push({
      id: alarm.id,
      deliveryTime: alarm.attributes.deliveryTime,
      task: alarm.attributes.task,
      alarmState: alarm.attributes.alarmState,
      alarmStateDisplayTitle: alarm.attributes.alarmState.attributes.title,
    });
  });

  return alarms;
};

export const saveAlarm = async (
  newAlarm: any,
  selectedOrganization?: any
): Promise<any> => {
  const alarmObject = Parse.Object.extend("Alarm");
  const alarm = new alarmObject();

  const resultState = await GetFirstObjectById(
    "AlarmState",
    newAlarm.alarmState
  );

  if (!resultState) return;

  alarm.set("id", newAlarm.id);
  alarm.set("task", newAlarm.task);
  alarm.set("alarmState", resultState);
  alarm.set("deliveryTime", newAlarm.deliveryTime);

  const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;
  const orgEmployeeRoleName = `${selectedOrganization.attributes.shortTitle}_EMPLOYEE`;
  const projectRoleName = `PROJECT_${newAlarm.task?.attributes.project.attributes.shortCode}`;

  const acl = new Parse.ACL();
  acl.setRoleReadAccess(Roles.Admin, true);
  acl.setRoleWriteAccess(Roles.Admin, true);
  acl.setRoleReadAccess(orgEmployeeRoleName, true);
  acl.setRoleWriteAccess(orgEmployeeRoleName, true);
  acl.setRoleReadAccess(orgAdminRoleName, true);
  acl.setRoleWriteAccess(orgAdminRoleName, true);
  acl.setRoleReadAccess(projectRoleName, true);
  acl.setRoleWriteAccess(projectRoleName, false);

  alarm.setACL(acl);

  return await alarm.save();
};

export const createAlarmsFromTaskTemplate = async (
  task: any,
  taskTemplateId: string,
  selectedOrganization?: any,
  language?: Language
): Promise<any> => {
  const alarmTemplates = await GetAlarmTemplatesByTaskTemplateId(
    taskTemplateId,
    language
  );

  if (!alarmTemplates) return;

  alarmTemplates.forEach((alarmTemplate) => {
    const deliveryTime: Date = new Date(task.attributes.deadline);

    switch (alarmTemplate.timeUnit.id) {
      case TimeUnit.Day:
        deliveryTime.setDate(
          alarmTemplate.isBeforeTaskMilestone
            ? deliveryTime.getDate() - alarmTemplate.timeQuantity
            : deliveryTime.getDate() + alarmTemplate.timeQuantity
        );
        break;
      case TimeUnit.Month:
        deliveryTime.setMonth(
          alarmTemplate.isBeforeTaskMilestone
            ? deliveryTime.getMonth() - alarmTemplate.timeQuantity
            : deliveryTime.getMonth() + alarmTemplate.timeQuantity
        );
        break;
      case TimeUnit.Year:
        deliveryTime.setFullYear(
          alarmTemplate.isBeforeTaskMilestone
            ? deliveryTime.getFullYear() - alarmTemplate.timeQuantity
            : deliveryTime.getFullYear() + alarmTemplate.timeQuantity
        );
        break;
      default:
        break;
    }

    const newAlarm = {
      deliveryTime: deliveryTime,
      alarmState: AlarmStatus.Scheduled,
      task: task,
    };
    saveAlarm(newAlarm, selectedOrganization);
  });
};
