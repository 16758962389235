import React, { ReactElement, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Parse from "parse";
import moment from "moment";
import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { DataGrid } from "../../components/dataGrid";
import { ProjectPicker } from "../../components/projectPicker";
import { BreadCrumbsNavigation } from "../../components/breadCrumbsNavigation";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { TaskStateBaseModel } from "../../models/TaskStateBaseModel";
import { TaskBaseModel } from "../../models/TaskBaseModel";
import { Language, ParseClassName } from "../../settings/Enums";
import { GetRelationByName } from "../../services/GenericServices";
import { sortTasksByDeadline } from "../../helpers/sorting/SortingHelper";
import { GetTaskStates } from "../../services/TaskStatusServices";
import { translations } from "../../settings/translation";
import { useAuthDataContext } from "../../components/authDataProvider";

interface Props {}

export const Tasks: React.FC<Props> = (props: Props): ReactElement => {
  const [tasks, setTasks] = useState<TaskBaseModel[]>([]);
  const [taskStates, setTaskStates] = useState<TaskStateBaseModel[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<
    string | undefined
  >(undefined);
  const { language } = useAuthDataContext();

  useEffect(() => {
    fetchTasks();
    fetchTaskStates();
  }, [selectedProjectId]);

  const getProjectIdFromRoute = (): string | undefined => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 4 &&
      urlParts[1] === "projects" &&
      urlParts[3] === "tasks"
    ) {
      return urlParts[2];
    }
  };

  const selectedProjectIdFromRoute = getProjectIdFromRoute();

  const editSettings: EditSettingsModel = {
    allowEditing: false,
    allowAdding: false,
  };

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "title", direction: "Ascending" }],
  };

  const columns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].title,
    },
    {
      field: "description",
      headerText: translations[language || Language.Hr].description,
    },
    {
      field: language && language !== Language.Hr ? "taskStateTranslationEn" : "taskStateTranslation",
      headerText: translations[language || Language.Hr].status,
    },
    {
      field: "availableSince",
      headerText: translations[language || Language.Hr].availableFrom,
      type: "date",
      format: "dd.MM.yyyy.",
      filter: {
        type: "Menu",
        params: {
          format: "dd.MM.yyyy.",
        },
      },
    },
    {
      field: "deadline",
      headerText: translations[language || Language.Hr].deadline,
      type: "date",
      format: "dd.MM.yyyy.",
      filter: {
        type: "Menu",
        params: {
          format: "dd.MM.yyyy.",
        },
      },
    },
    {
      field: "assigneesString",
      headerText: translations[language || Language.Hr].responsiblePersons,
    },
  ];

  const fetchTasks = async (): Promise<void> => {
    if (!(selectedProjectId || selectedProjectIdFromRoute)) return;

    const tasksList: TaskBaseModel[] = [];
    const localTaskState =
      taskStates.length > 0 ? taskStates : await fetchTaskStates();

    const task = Parse.Object.extend("Task");
    const taskListQuery = new Parse.Query(task).limit(10000);

    const project = Parse.Object.extend("Project");
    const projectQuery = new Parse.Query(project)
      .equalTo("objectId", selectedProjectId || selectedProjectIdFromRoute)
      .limit(10000);
    const projectResult = await projectQuery.first();

    const results = projectResult
      ? await taskListQuery
          .equalTo("project", projectResult)
          .include("Project")
          .find()
      : await taskListQuery.include("Project").find();

    if (results && results.length > 0) {
      await Promise.all(
        results.map(async (result) => {
          if (result.attributes && result.id) {
            const assignees = await GetRelationByName(result, "assignees");
            const assigneesMails: string[] = [];

            if (assignees) {
              assignees.forEach((assignee: any) => {
                if (assignee && assignee.attributes) {
                  assigneesMails.push(
                    assignee.attributes.email || assignee.attributes.username
                  );
                }
              });
            }

            const taskState = localTaskState.find(
              (tS: TaskStateBaseModel): boolean =>
                tS.id === result.attributes.taskState.id
            );

            tasksList.push({
              id: result.id,
              projectActivity: result.attributes.projectActivity,
              assignees: assigneesMails,
              assigneesString: assigneesMails.join(","),
              taskState: result.attributes.taskState,
              taskStateTranslation: taskState?.title,
              taskStateTranslationEn: taskState?.title_en,
              availableSince: result.attributes.availableSince,
              title: result.attributes.title,
              deadline: result.attributes.deadline,
              project: result.attributes.project,
              description: result.attributes.description,
              projectId: result.attributes.project.id,
              taskStateId: result.attributes.taskState.id,
              className:
                new Date() >= result.attributes.deadline
                  ? "highlighted-card"
                  : "",
              deadlineCroFormat: moment(result.attributes.deadline).format(
                "DD.MM.YYYY."
              ),
              projectShortCode: result.attributes.project.attributes.shortCode,
              projectShortTitle:
                result.attributes.project.attributes.shortTitle,
              projectFile: result.attributes.projectFile,
            });
          }
        })
      );
    }
    const sortedTasks = sortTasksByDeadline(tasksList);

    setTasks(sortedTasks);
  };

  const fetchTaskStates = async (): Promise<TaskStateBaseModel[]> => {
    const taskStateList: TaskStateBaseModel[] = [];
    const results = await GetTaskStates();

    if (results && results.length > 0) {
      results.forEach((result) => {
        if (result.attributes && result.id && result.attributes.title) {
          taskStateList.push({
            id: result.id,
            title: result.attributes.title,
            title_en: result.attributes.title_en,
          });
        }
      });
    }
    setTaskStates(taskStateList);

    return taskStateList;
  };

  const updateSelectedProjectId = (projectId?: string): void => {
    setSelectedProjectId(projectId);
  };

  return (
    <section id="task">
      {selectedProjectIdFromRoute && (
        <BreadCrumbsNavigation
          breadCrumbTemplates={[
            {
              url: "projects",
              title: translations[language || Language.Hr].projects,
            },
          ]}
        />
      )}
      {!selectedProjectIdFromRoute && (
        <Row style={{ marginBottom: 25 }}>
          <Col xl={4} lg={4} md={3} sm={3} xs={3}>
            <ProjectPicker updateSelectedProject={updateSelectedProjectId} />
          </Col>
        </Row>
      )}
      {selectedProjectIdFromRoute || selectedProjectId ? (
        <Row className={"box-form"}>
          <Col>
            <DataGrid
              data={tasks}
              columnProperties={columns}
              useDialogTemplate={ParseClassName.Task}
              showOnlyCustomToolbarOption={true}
              sortSettings={sortSettings}
              editSettings={editSettings}
              allowPaging={true}
            />
          </Col>
        </Row>
      ) : (
        <div>{translations[language || Language.Hr].chooseProjectToShowTasks}</div>
      )}
    </section>
  );
};
