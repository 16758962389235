import React, { ReactElement, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import Parse from "parse";
import { DataGrid } from "../../components/dataGrid";
import { FundingTypeBaseModel } from "../../models/FundingTypeBaseModel";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { Language, ParseClassName } from "../../settings/Enums";
import { useAuthDataContext } from "../../components/authDataProvider";
import { translations } from "../../settings/translation";

interface Props {}

export const FundingTypes: React.FC<Props> = (props: Props): ReactElement => {
  const [fundingTypes, setFundingTypes] = useState<FundingTypeBaseModel[]>([]);
  const { language } = useAuthDataContext();

  useEffect(() => {
    fetchFundingTypes();
  }, []);

  const fetchFundingTypes = async (): Promise<void> => {
    const fundingTypesList: FundingTypeBaseModel[] = [];

    const fundingType = Parse.Object.extend("FundingType");
    const fundingTypeListQuery = new Parse.Query(fundingType).limit(10000);

    const results = await fundingTypeListQuery.find();
    if (results && results.length > 0) {
      results.forEach((result) => {
        if (result.attributes && result.id && result.attributes.title) {
          fundingTypesList.push({
            id: result.id,
            title: result.attributes.title,
          });
        }
      });
    }
    setFundingTypes(fundingTypesList);
  };

  const updateFundingType = async (
    updatedFundingType: FundingTypeBaseModel
  ) => {
    const fundingTypeObject = Parse.Object.extend("FundingType");
    const fundingType = new fundingTypeObject();

    fundingType.set("id", updatedFundingType.id);
    fundingType.set("title", updatedFundingType.title);

    const response = await fundingType.save();

    if (response) {
      await fetchFundingTypes();
    }
  };

  const createFundingType = async (
    newFundingType: FundingTypeBaseModel
  ): Promise<void> => {
    const fundingTypeObject = Parse.Object.extend("FundingType");
    const fundingType = new fundingTypeObject();

    fundingType.set("title", newFundingType.title);

    const response = await fundingType.save();

    if (response) {
      await fetchFundingTypes();
    }
  };

  const columnProperties: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].title,
      validationRules: {
        required: true,
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "title", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    mode: "Dialog",
  };

  return (
    <section id="fundingType">
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={fundingTypes}
            columnProperties={columnProperties}
            updateItem={updateFundingType}
            createItem={createFundingType}
            fetchDataAfterCatchError={fetchFundingTypes}
            useDialogTemplate={ParseClassName.FundingType}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
    </section>
  );
};
