import Parse from "parse";
import { AlarmTemplateBaseModel } from "../models/AlarmTemplateBaseModel";
import { Language } from "../settings/Enums";
import { translations } from "../settings/translation";
import { GetFirstObjectById } from "./GenericServices";

export const GetAlarmTemplatesByTaskTemplateId = async (
  taskTemplateId: string,
  language?: Language
): Promise<AlarmTemplateBaseModel[]> => {
  const object = Parse.Object.extend("AlarmTemplate");
  const query = new Parse.Query(object)
    .include("timeUnit")
    .include("taskMilestone")
    .include("taskTemplate")
    .equalTo(
      "taskTemplate",
      new Parse.Object("TaskTemplate", { id: taskTemplateId })
    )
    .limit(10000);

  const results = await query.find();

  if (!(results && results.length > 0)) return [];

  const alarmTemplates: AlarmTemplateBaseModel[] = [];

  results.forEach((template) => {
    alarmTemplates.push({
      id: template.id,
      isBeforeTaskMilestone: template.attributes.isBeforeTaskMilestone,
      isBeforeTaskMilestoneDisplayTitle: template.attributes
        .isBeforeTaskMilestone
        ? translations[language || Language.Hr].before
        : translations[language || Language.Hr].after,
      timeUnit: template.attributes.timeUnit,
      timeUnitDisplayTitle:
        language && language !== Language.Hr
          ? template.attributes.timeUnit.attributes.title_en
          : template.attributes.timeUnit.attributes.title,
      taskMilestone: template.attributes.taskMilestone,
      taskMilestoneDisplayTitle:
        language && language !== Language.Hr
          ? template.attributes.taskMilestone.attributes.title_en
          : template.attributes.taskMilestone.attributes.title,
      timeQuantity: template.attributes.timeQuantity,
      taskTemplate: template.attributes.taskTemplate,
      taskTemplateDisplayTitle:
        template.attributes.taskTemplate.attributes.title,
    });
  });

  return alarmTemplates;
};

export const saveAlarmTemplate = async (
  newAlarmTemplate: any,
  taskTemplateId: string
): Promise<any> => {
  const alarmTemplateObject = Parse.Object.extend("AlarmTemplate");
  const alarmTemplate = new alarmTemplateObject();

  const resultTaskMilestone = await GetFirstObjectById(
    "TaskMilestone",
    newAlarmTemplate.taskMilestone
  );
  const resultTimeUnit = await GetFirstObjectById(
    "TimeUnit",
    newAlarmTemplate.timeUnit
  );
  const resultTaskTemplate = await GetFirstObjectById(
    "TaskTemplate",
    taskTemplateId
  );

  if (!(resultTaskMilestone && resultTimeUnit && resultTaskTemplate)) return;

  alarmTemplate.set("id", newAlarmTemplate.id);
  alarmTemplate.set("timeUnit", resultTimeUnit);
  alarmTemplate.set("taskMilestone", resultTaskMilestone);
  alarmTemplate.set("taskTemplate", resultTaskTemplate);
  alarmTemplate.set(
    "isBeforeTaskMilestone",
    newAlarmTemplate.isBeforeTaskMilestone
  );
  alarmTemplate.set("timeQuantity", newAlarmTemplate.timeQuantity);

  return await alarmTemplate.save();
};
