import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import {
  ButtonPropsModel,
  DialogComponent,
} from "@syncfusion/ej2-react-popups";
import Parse from "parse";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthDataContext } from "../../components/authDataProvider";
import { BreadCrumbsNavigation } from "../../components/breadCrumbsNavigation";
import { DataGrid } from "../../components/dataGrid";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { DataGridToolbarOption } from "../../models/DataGridToolbarOption";
import { PersonBaseModel } from "../../models/PersonBaseModel";
import { UserBaseModel, UserNumberModel } from "../../models/UserBaseModel";
import { AssaignUserToRole } from "../../services/RoleServices";
import {
  CreateUser,
  UpdateUser,
  getNumberOfUsersByOrganization,
} from "../../services/UserServices";
import { Language, ParseClassName, Roles } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {}

export const Contacts: React.FC<Props> = (props: Props): ReactElement => {
  const { selectedOrganization } = useAuthDataContext();
  const [contacts, setContacts] = useState<PersonBaseModel[]>([]);
  const [clientId, setClientId] = useState<string>("");
  const [
    isAddExistingContactDialogDisplayed,
    setIsAddExistingContactDialogDisplayed,
  ] = useState<boolean>(false);
  const { language } = useAuthDataContext();
  const [existingContacts, setExistingContacts] = useState<
    { [key: string]: Object }[]
  >([]);
  const [selectedExistingContactId, setSelectedExistingContact] =
    useState<string>();
  const [numberOfUsers, setNumberOfUsers] = useState<
    UserNumberModel | undefined
  >();

  useEffect(() => {
    getClientIdFromRoute();
  }, []);

  const getClientIdFromRoute = () => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 4 &&
      urlParts[1] === "clients" &&
      urlParts[3] === "contacts"
    ) {
      setClientId(urlParts[2]);
    }
  };

  useEffect(() => {
    fetchClientContacts();
    fetchOtherClientsContacts();
  }, [clientId]);

  useEffect(() => {
    getUsersCount();
  }, [selectedOrganization]);

  const getUsersCount = async (): Promise<void> => {
    const numbers = await getNumberOfUsersByOrganization(selectedOrganization);
    setNumberOfUsers(numbers);
  };

  const fetchClientContacts = async (): Promise<void> => {
    if (clientId === "") return;

    const contactsList: PersonBaseModel[] = [];

    const client = Parse.Object.extend("Client");
    const clientListQuery = new Parse.Query(client).limit(10000);
    const clientDetails = await clientListQuery.get(clientId);
    const relation = clientDetails.relation("contacts");
    const results = await relation.query().find();

    if (results && results.length > 0) {
      results.forEach((result) => {
        if (
          result.attributes &&
          result.id &&
          result.attributes.firstName &&
          result.attributes.lastName &&
          result.attributes.email &&
          result.attributes.phone
        ) {
          contactsList.push({
            id: result.id,
            ime: result.attributes.firstName,
            lastName: result.attributes.lastName,
            phone: result.attributes.phone,
            elektronickaPosta: result.attributes.email,
            parseUser: result.attributes.parseUser,
            isAplicationUserDisplayTitle: result.attributes.parseUser
              ? translations[language || Language.Hr].yes
              : translations[language || Language.Hr].no,
          });
        }
      });
    }
    setContacts(contactsList);
  };

  const fetchOtherClientsContacts = async (): Promise<void> => {
    if (clientId === "") return;

    const dropdownItems: { [key: string]: Object }[] = [];

    const client = Parse.Object.extend("Client");
    const clientListQuery = new Parse.Query(client).limit(10000);
    clientListQuery.notEqualTo("objectId", clientId);
    const clients = await clientListQuery.find();

    if (!clients || clients?.length === 0) return;

    await Promise.all(
      clients.map(async (clientDetails) => {
        const clientAttributes = clientDetails.attributes;
        if (!clientAttributes || !clientAttributes?.name) return;

        const clientContactsRelationQuery = clientDetails.relation("contacts");
        const clientContacts = await clientContactsRelationQuery.query().find();

        if (!clientContacts || clientContacts?.length === 0) return;

        clientContacts.map((clientContact) => {
          const contactAttributes = clientContact.attributes;
          if (
            !clientContact.id ||
            !contactAttributes ||
            !contactAttributes?.email ||
            !contactAttributes?.firstName ||
            !contactAttributes?.lastName
          )
            return;

          dropdownItems.push({
            text: `${contactAttributes.firstName} ${contactAttributes.lastName} (${contactAttributes.email})`,
            value: clientContact.id,
            groupBy: clientAttributes.name,
          });
        });
      })
    );

    setExistingContacts(dropdownItems);
  };

  const updateContact = async (updatedPerson: PersonBaseModel) => {
    const personObject = Parse.Object.extend("Person");
    const person = new personObject();

    person.set("id", updatedPerson.id);
    person.set("firstName", updatedPerson.ime);
    person.set("lastName", updatedPerson.lastName);
    person.set("phone", updatedPerson.phone);
    person.set("email", updatedPerson.elektronickaPosta);

    if (updatedPerson.isAplicationUser) {
      if (!updatedPerson.parseUser) {
        const userResponse = await createClient(updatedPerson);

        if (userResponse) {
          person.set("parseUser", userResponse);
        }
      } else {
        updateClient(updatedPerson);
      }
      // TODO: check this password update
      // } else {
      //     const isSuccesfullyUpdatedPassword = await updateUserPassword(updatedPerson);
      // }
    } else if (!updatedPerson.isAplicationUser && updatedPerson.parseUser) {
      person.set("parseUser", null);
      deleteUser(updatedPerson);
    }

    await person.save();

    await fetchClientContacts();
  };

  const createContact = async (newPerson: PersonBaseModel): Promise<void> => {
    if (!selectedOrganization) return;

    const personObject = Parse.Object.extend("Person");
    const person = new personObject();

    person.set("firstName", newPerson.ime);
    person.set("lastName", newPerson.lastName);
    person.set("phone", newPerson.phone);
    person.set("email", newPerson.elektronickaPosta);

    const orgRoleName = `ORGANIZATION_${selectedOrganization.attributes.shortTitle}`;
    const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;

    const personAcl = new Parse.ACL();
    personAcl.setRoleReadAccess(Roles.Admin, true);
    personAcl.setRoleWriteAccess(Roles.Admin, true);
    personAcl.setRoleReadAccess(orgRoleName, true);
    personAcl.setRoleWriteAccess(orgRoleName, false);
    personAcl.setRoleReadAccess(orgAdminRoleName, true);
    personAcl.setRoleWriteAccess(orgAdminRoleName, true);

    if (newPerson.isAplicationUser && newPerson.lozinka) {
      const userResponse = await createClient(newPerson);

      if (userResponse) {
        person.set("parseUser", userResponse);
        personAcl.setReadAccess(userResponse, true);
        personAcl.setWriteAccess(userResponse, true);
      }
    }

    person.setACL(personAcl);

    const response = await person.save();
    await updateClientWithContacts(response);

    if (response) {
      await fetchClientContacts();
    }
  };

  const createClient = async (person: PersonBaseModel): Promise<any> => {
    if (person.isAplicationUser && person.lozinka && selectedOrganization) {
      const userModel: UserBaseModel = {
        id: "",
        email: person.elektronickaPosta,
        username: person.elektronickaPosta,
        password: person.lozinka,
        firstName: person.ime,
        lastName: person.lastName,
      };
      const userResponse = await CreateUser(
        userModel,
        selectedOrganization.attributes.shortTitle,
        false
      );

      if (userResponse && userResponse.id) {
        if (numberOfUsers) {
          setNumberOfUsers({
            ...numberOfUsers,
            currentUserNumber: numberOfUsers.currentUserNumber + 1,
          });
        }
        AssaignUserToRole(
          userResponse,
          `${selectedOrganization.attributes.shortTitle}_CLIENT`
        );
        return userResponse;
      }
    }
  };

  const updateClient = async (person: PersonBaseModel): Promise<void> => {
    if (!person.parseUser) return;

    const userModel: UserBaseModel = {
      id: person.parseUser.id,
      email: person.elektronickaPosta,
      username: person.elektronickaPosta,
      firstName: person.ime,
      lastName: person.lastName,
    };

    await UpdateUser(userModel);
  };

  const deleteUser = async (person: PersonBaseModel): Promise<void> => {
    if (!person.parseUser) return;

    const users = new Parse.Query(Parse.User).limit(10000);
    const usersQuery = users.equalTo("objectId", person.parseUser.id);
    const user = await usersQuery.first();

    if (!user) return;

    user.destroy();

    if (numberOfUsers) {
      setNumberOfUsers({
        ...numberOfUsers,
        currentUserNumber: numberOfUsers.currentUserNumber - 1,
      });
    }
  };

  const updateUserPassword = async (person: PersonBaseModel): Promise<any> => {
    if (person.parseUser) {
      const users = new Parse.Query(Parse.User).limit(10000);
      const usersQuery = users.equalTo("objectId", person.parseUser.id);
      const result = await usersQuery.first();

      if (result) {
        result.set("password", person.lozinka);

        const saveResult = await result.save();

        if (saveResult) {
          return true;
        }
      }
      return false;
    }
  };

  const updateClientWithContacts = async (newRelation?: any): Promise<void> => {
    if (!clientId || !newRelation) return;

    const client = Parse.Object.extend("Client");
    const clientListQuery = new Parse.Query(client).limit(10000);
    const clientDetails = await clientListQuery.get(clientId);
    const relation = clientDetails.relation("contacts");
    relation.add(newRelation);
    await clientDetails.save();
  };

  const addExistingContactToClient = async (contactId?: string) => {
    if (!contactId) return;
    const person = Parse.Object.extend("Person");
    const personListQuery = new Parse.Query(person).limit(10000);
    const personDetails = await personListQuery.get(contactId);

    if (personDetails && personDetails.attributes.parseUser && numberOfUsers) {
      setNumberOfUsers({
        ...numberOfUsers,
        currentUserNumber: numberOfUsers.currentUserNumber + 1,
      });
    }

    await updateClientWithContacts(personDetails);

    fetchClientContacts();

    setIsAddExistingContactDialogDisplayed(false);
  };

  const columnProperties: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "ime",
      headerText: translations[language || Language.Hr].name,
      validationRules: {
        required: true,
      },
    },
    {
      field: "lastName",
      headerText: translations[language || Language.Hr].surname,
      validationRules: {
        required: true,
      },
    },
    {
      field: "elektronickaPosta",
      headerText: translations[language || Language.Hr].email,
      validationRules: {
        required: true,
      },
    },
    {
      field: "phone",
      headerText: translations[language || Language.Hr].phone,
      validationRules: {
        required: true,
      },
    },
    {
      field: "isAplicationUserDisplayTitle",
      headerText: translations[language || Language.Hr].user,
      allowEditing: false,
      filter: {
        type: "CheckBox",
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "ime", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding:
      numberOfUsers &&
      numberOfUsers.currentUserNumber < numberOfUsers.maxUserNumber,
    mode: "Dialog",
  };

  const addExistingContactToolbarOption: DataGridToolbarOption = {
    text: translations[language || Language.Hr].addExisting,
    id: "customToolbarOption",
    tooltipText: translations[language || Language.Hr].addExistingContact,
    prefixIcon: "e-add",
    disabled: !(
      numberOfUsers &&
      numberOfUsers.currentUserNumber < numberOfUsers.maxUserNumber
    ),
  };

  const openAddExistingContactDialog = async (): Promise<void> => {
    setSelectedExistingContact(undefined);
    setIsAddExistingContactDialogDisplayed(true);
  };

  const buttons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].save,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => addExistingContactToClient(selectedExistingContactId),
    },
    {
      buttonModel: {
        content: translations[language || Language.Hr].cancel,
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => setIsAddExistingContactDialogDisplayed(false),
    },
  ];

  const fields: object = { groupBy: "groupBy", text: "text", value: "value" };

  return (
    <section id="contact">
      <BreadCrumbsNavigation
        breadCrumbTemplates={[
          {
            url: "clients",
            title: translations[language || Language.Hr].clients,
          },
        ]}
      />
      {!(numberOfUsers &&
        numberOfUsers.currentUserNumber < numberOfUsers.maxUserNumber) && (
          <Row className={"box-form"} style={{ fontSize: 10, color: "red" }}>
            <Col>{translations[language || Language.Hr].maxUsersUsed}</Col>
          </Row>
        )}
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={contacts}
            columnProperties={columnProperties}
            useDialogTemplate={ParseClassName.Contact}
            updateItem={updateContact}
            createItem={createContact}
            fetchDataAfterCatchError={fetchClientContacts}
            customToolbarOption={addExistingContactToolbarOption}
            handleCustomToolbarOption={openAddExistingContactDialog}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
      <DialogComponent
        isModal={true}
        width="700px"
        close={() => setIsAddExistingContactDialogDisplayed(false)}
        header={translations[language || Language.Hr].selectExistingContact}
        visible={isAddExistingContactDialogDisplayed}
        showCloseIcon={true}
        buttons={buttons}
      >
        <div className="dialog-content" style={{ padding: "15px" }}>
          {/* this is needed since syncfusion DropDownListComponent won't deselect value
                                even if selectedExistingContactId === undefined */}
          {isAddExistingContactDialogDisplayed && (
            <DropDownListComponent
              fields={fields}
              dataSource={existingContacts}
              sortOrder={"Ascending"}
              value={selectedExistingContactId}
              placeholder={translations[language || Language.Hr].chooseContact}
              select={(item) =>
                setSelectedExistingContact(item?.itemData.value)
              }
            />
          )}
        </div>
      </DialogComponent>
    </section>
  );
};
