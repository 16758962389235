import Parse from "parse";

export const updateIsEditableForClientTemplateProperty = async (isEditableForClient: boolean, templateFolderId?: string,): Promise<any> => {
    if (!templateFolderId) return;
    const projectFileTemplate = Parse.Object.extend('ProjectFileTemplate');
    const projectFileTemplateQuery = new Parse.Query(projectFileTemplate).limit(10000);
    const projectFileTemplateDetails = await projectFileTemplateQuery.get(templateFolderId);

    projectFileTemplateDetails.set('isEditableForClient', isEditableForClient);
    projectFileTemplateDetails.save();
}

export const updateIsCreatedWithDefaultTaskTemplateProperty = async (isCreatedWithDefaultTask: boolean, templateFolderId?: string,): Promise<any> => {
    if (!templateFolderId) return;
    const projectFileTemplate = Parse.Object.extend('ProjectFileTemplate');
    const projectFileTemplateQuery = new Parse.Query(projectFileTemplate).limit(10000);
    const projectFileTemplateDetails = await projectFileTemplateQuery.get(templateFolderId);

    projectFileTemplateDetails.set('isCreatedWithDefaultTask', isCreatedWithDefaultTask);
    projectFileTemplateDetails.save();
}