import React, { useState, ReactElement } from "react";
import Parse from "parse";
import { Col, Form } from "react-bootstrap";
import { PersonBaseModel } from "../../models/PersonBaseModel";
import { Language, PasswordType } from "../../settings/Enums";
import {
  DialogComponent,
  ButtonPropsModel,
} from "@syncfusion/ej2-react-popups";
import { translations } from "../../settings/translation";

interface Props {
  data: PersonBaseModel;
  language?: Language;
}

export const ContactDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const { data, language } = props;
  const [isPasswordDisplayed, setIsPasswordDisplayed] = useState<boolean>(
    !!data.parseUser
  );
  const [passwordType, setPasswordType] = useState<string>(PasswordType.Text);
  const [uniqueField, setUniqueField] = useState<string>("");
  const [isErrorDialogVisible, setIsErrorDialogVisible] =
    useState<boolean>(false);

  const checkMail = async (event: any): Promise<void> => {
    if (!(event && event.target && event.target.value)) return;
    const element = event.target;

    const person = Parse.Object.extend("Person");
    const personQuery = new Parse.Query(person).limit(10000);
    const results = await personQuery.equalTo("email", element.value).find();

    if (results && results.length > 0) {
      const result = results[0];
      if (
        result &&
        result.id &&
        result.attributes?.email &&
        result.id !== data.id
      ) {
        setUniqueField(result.attributes?.email);
        setIsErrorDialogVisible(true);
        element.value = "";
        return;
      }
    }
    setUniqueField("");
    setIsErrorDialogVisible(false);
  };

  const onChange = (args: React.ChangeEvent) => {
    const target = args.target as HTMLInputElement;
    if (target.name === "isAplicationUser") {
      setIsPasswordDisplayed(target.checked);
    }
  };

  const handleClick = (passwordType: string): void => {
    setPasswordType(
      passwordType === "text" ? PasswordType.Password : PasswordType.Text
    );
  };

  const buttons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].ok,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => setIsErrorDialogVisible(false),
    },
  ];

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="imeValidation">
          <Form.Label>{translations[language || Language.Hr].name}</Form.Label>
          <Form.Control
            type="text"
            name="ime"
            defaultValue={data.ime}
            autoComplete="off"
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="lastNameValidation">
          <Form.Label>
            {translations[language || Language.Hr].surname}
          </Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            defaultValue={data.lastName}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="elektronickaPostaValidation">
          <Form.Label>{translations[language || Language.Hr].email}</Form.Label>
          <Form.Control
            type="email"
            name="elektronickaPosta"
            defaultValue={data.elektronickaPosta}
            onBlur={(event: any) => checkMail(event)}
            autoComplete="off"
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="phoneValidation">
          <Form.Label>{translations[language || Language.Hr].phone}</Form.Label>
          <Form.Control
            type="text"
            name="phone"
            defaultValue={data.phone}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          controlId="isAplicationUserValidation"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: "18px",
          }}
        >
          <Form.Check
            label={translations[language || Language.Hr].applicationUser}
            type="checkbox"
            name="isAplicationUser"
            onChange={onChange}
            defaultChecked={!!data.parseUser}
          />
        </Form.Group>
        {
          // this should stay like this instead of using JS because of validation
        }
        <Form.Group
          as={Col}
          controlId="lozinkaValidation"
          style={{
            display: isPasswordDisplayed && !data.parseUser ? "block" : "none",
          }}
        >
          <Form.Label>
            {translations[language || Language.Hr].password}
          </Form.Label>
          <Form.Control
            type={passwordType}
            name="lozinka"
            defaultValue={data.lozinka}
            autoComplete="new-password"
            required={isPasswordDisplayed && !data.parseUser}
          />
          <Form.Text
            onClick={() => handleClick(passwordType)}
            style={{ cursor: "pointer" }}
            muted
          >
            {passwordType === PasswordType.Text
              ? translations[language || Language.Hr].hidePassword
              : translations[language || Language.Hr].showPassword}
          </Form.Text>
        </Form.Group>
      </Form.Row>
      <DialogComponent
        isModal={true}
        width="500px"
        className="validationErrorDialog"
        header={translations[language || Language.Hr].error}
        allowDragging={true}
        buttons={buttons}
        visible={isErrorDialogVisible}
      >
        <div className="dialog-content">
          <p style={{ marginBottom: 20 }}>
            <strong> {translations[language || Language.Hr].email} </strong>{" "}
            {translations[language || Language.Hr].alreadyExists}:{" "}
            {<b style={{ color: "red" }}>{uniqueField}</b>}
          </p>
        </div>
      </DialogComponent>
    </div>
  );
};
