import { Roles } from "./Enums";

export const toolbarSettings = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'OrderedList', 'UnorderedList', 'CreateLink',
        'FontColor', 'BackgroundColor']
};

export const roleListOnlyAdmin = [Roles.Admin];
export const roleListAdminAndAdminOrganization = [Roles.Admin, Roles.AdminOrganization];
export const roleListAdminAndEmployee = [Roles.Admin, Roles.Employee];
export const roleListAdminAndAdminOrganizationAndEmployee = [Roles.Admin, Roles.Employee, Roles.AdminOrganization];
