import {
  EditSettingsModel,
  SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import Parse from "parse";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthDataContext } from "../../components/authDataProvider";
import { DataGrid } from "../../components/dataGrid";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { PersonBaseModel } from "../../models/PersonBaseModel";
import { UserBaseModel, UserNumberModel } from "../../models/UserBaseModel";
import {
  CreatePerson,
  GetPersonByUserId,
  UpdatePerson
} from "../../services/PersonService";
import { AssaignUserToRole, GetRoleUsers } from "../../services/RoleServices";
import {
  CreateUser,
  getNumberOfUsersByOrganization,
  UpdateUser
} from "../../services/UserServices";
import { Language, ParseClassName, Roles } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {}

export const Employees: React.FC<Props> = (props: Props): ReactElement => {
  const [employees, setEmployees] = useState<PersonBaseModel[]>([]);
  const { selectedOrganization, language } = useAuthDataContext();
  const [numberOfUsers, setNumberOfUsers] = useState<
    UserNumberModel | undefined
  >();

  useEffect(() => {
    fetchEmployees();
  }, [selectedOrganization]);

  useEffect(() => {
    getUsersCount();
  }, [selectedOrganization]);

  const getUsersCount = async (): Promise<void> => {
    const numbers = await getNumberOfUsersByOrganization(selectedOrganization);
    setNumberOfUsers(numbers);
  };

  const fetchEmployees = async (): Promise<void> => {
    if (!selectedOrganization) return;

    const roleUsers = await GetRoleUsers(
      `${selectedOrganization.attributes.shortTitle}_EMPLOYEE`
    );

    const persons: PersonBaseModel[] = [];

    if (!(roleUsers && roleUsers.length > 0)) return;

    await Promise.all(
      roleUsers.map(async (user) => {
        const person = await GetPersonByUserId(user.id);
        if (!person) return;
        persons.push(person);
      })
    );

    setEmployees(persons);
  };

  const createEmployee = async (
    personModel: PersonBaseModel
  ): Promise<void> => {
    if (!selectedOrganization) return;
    const userModel: UserBaseModel = {
      id: "",
      email: personModel.elektronickaPosta,
      username: personModel.elektronickaPosta,
      password: personModel.lozinka,
      firstName: personModel.ime,
      lastName: personModel.lastName,
    };
    const userResponse = await CreateUser(
      userModel,
      selectedOrganization.attributes.shortTitle,
      false
    );

    if (!userResponse) return;

    personModel.parseUser = userResponse;

    const orgRoleName = `ORGANIZATION_${selectedOrganization.attributes.shortTitle}`;
    const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;

    const personAcl = new Parse.ACL();
    personAcl.setRoleReadAccess(Roles.Admin, true);
    personAcl.setRoleWriteAccess(Roles.Admin, true);
    personAcl.setReadAccess(userResponse, true);
    personAcl.setWriteAccess(userResponse, true);
    personAcl.setRoleReadAccess(orgRoleName, true);
    personAcl.setRoleWriteAccess(orgRoleName, false);
    personAcl.setRoleReadAccess(orgAdminRoleName, true);
    personAcl.setRoleWriteAccess(orgAdminRoleName, true);

    await CreatePerson(personModel, personAcl);

    await AssaignUserToRole(
      userResponse,
      `${selectedOrganization.attributes.shortTitle}_EMPLOYEE`
    );

    await fetchEmployees();

    if (numberOfUsers) {
      setNumberOfUsers({
        ...numberOfUsers,
        currentUserNumber: numberOfUsers.currentUserNumber + 1,
      });
    }
  };

  const updateEmployee = async (personModel: PersonBaseModel): Promise<any> => {
    if (!personModel.parseUser) return;
    const userModel: UserBaseModel = {
      id: personModel.parseUser.id,
      email: personModel.elektronickaPosta,
      username: personModel.elektronickaPosta,
      firstName: personModel.ime,
      lastName: personModel.lastName,
    };
    const userResponse = await UpdateUser(userModel);

    if (!userResponse) return;

    await UpdatePerson(personModel);

    await fetchEmployees();
  };

  const columnProperties: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "ime",
      headerText: translations[language || Language.Hr].name,
      validationRules: {
        required: true,
      },
    },
    {
      field: "lastName",
      headerText: translations[language || Language.Hr].surname,
      validationRules: {
        required: true,
      },
    },
    {
      field: "elektronickaPosta",
      headerText: translations[language || Language.Hr].email,
      validationRules: {
        required: true,
      },
    },
    {
      field: "phone",
      headerText: translations[language || Language.Hr].phone,
      validationRules: {
        required: true,
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "ime", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding:
      numberOfUsers &&
      numberOfUsers.currentUserNumber < numberOfUsers.maxUserNumber,
    mode: "Dialog",
  };

  return (
    <section id="employees">
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={employees}
            columnProperties={columnProperties}
            useDialogTemplate={ParseClassName.Person}
            updateItem={updateEmployee}
            createItem={createEmployee}
            fetchDataAfterCatchError={fetchEmployees}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
    </section>
  );
};
