import * as React from "react";
import { Col, Form } from "react-bootstrap";
import { useAuthDataContext } from "../../components/authDataProvider";
import { ClientBaseModel } from "../../models/ClientBaseModel";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  data: ClientBaseModel;
  language?: Language;
}

export const ClientDialogFormTemplate: React.FC<Props> = (
  props: Props
): React.ReactElement => {
  const { data, language } = props;

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="nameValidation">
          <Form.Label>{translations[language || Language.Hr].fullName}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            defaultValue={data.name}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="oibValidation">
          <Form.Label>{translations[language || Language.Hr].pin}</Form.Label>
          <Form.Control
            type="text"
            name="oib"
            defaultValue={data.oib}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
