import React, { ReactElement, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Parse from "parse";
import {
  EditSettingsModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { DataGrid } from "../../components/dataGrid";
import { useAuthDataContext } from "../../components/authDataProvider";
import { OrganizationBaseModel } from "../../models/OrganizationBaseModel";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { Language, ParseClassName } from "../../settings/Enums";
import { createOrganizationAndRelatedRoleObjects } from "../../helpers/fetching/OrganizationServiceHelper";
import { translations } from "../../settings/translation";

interface Props {}

export const Organizations: React.FC<Props> = (props: Props): ReactElement => {
  const [organizations, setOrganizations] = useState<OrganizationBaseModel[]>(
    []
  );
  const { language, fetchDropdownOrganizations } = useAuthDataContext();
  const history = useHistory();

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async (): Promise<void> => {
    const organizationsList: OrganizationBaseModel[] = [];

    const organization = Parse.Object.extend("Organization");
    const organizationListQuery = new Parse.Query(organization)
      .include("role")
      .limit(10000);

    const results = await organizationListQuery.find();
    if (results && results.length > 0) {
      results.forEach((result) => {
        if (
          result.attributes &&
          result.id &&
          result.attributes.title &&
          result.attributes.shortTitle &&
          result.attributes.maxNumberOfProjects &&
          result.attributes.maxNumberOfUsers
        ) {
          organizationsList.push({
            objectId: result.id,
            title: result.attributes.title,
            shortTitle: result.attributes.shortTitle,
            role: result.attributes.role,
            maxNumberOfProjects: result.attributes.maxNumberOfProjects,
            maxNumberOfUsers: result.attributes.maxNumberOfUsers,
          });
        }
      });
    }
    setOrganizations(organizationsList);
  };

  const updateOrganization = async (
    updatedOrganization: OrganizationBaseModel
  ) => {
    const organizationObject = Parse.Object.extend("Organization");
    const organization = new organizationObject();

    organization.set("id", updatedOrganization.objectId);
    organization.set("title", updatedOrganization.title);
    organization.set("shortTitle", updatedOrganization.shortTitle);
    organization.set(
      "maxNumberOfProjects",
      parseInt(updatedOrganization.maxNumberOfProjects.toString())
    );
    organization.set(
      "maxNumberOfUsers",
      parseInt(updatedOrganization.maxNumberOfUsers.toString())
    );
    organization.set("role", updatedOrganization.role);

    const response = await organization.save();

    if (response) {
      await fetchOrganizations();
    }
  };

  const createOrganization = async (
    newOrganization: OrganizationBaseModel
  ): Promise<void> => {
    const organizationName = newOrganization.shortTitle.toUpperCase();
    const createdOrganizationAndRelatedRoles =
      await createOrganizationAndRelatedRoleObjects(
        organizationName,
        newOrganization
      );

    if (!createdOrganizationAndRelatedRoles) return;

    const response = await createdOrganizationAndRelatedRoles.organization.save(
      { role: createdOrganizationAndRelatedRoles.role }
    );

    if (response) {
      await fetchOrganizations();
      if (fetchDropdownOrganizations) {
        await fetchDropdownOrganizations();
      }
    } else {
      Parse.Object.destroyAll(createdOrganizationAndRelatedRoles.relatedRoles);
    }
  };

  const columnProperties: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].fullName,
      validationRules: {
        required: true,
      },
    },
    {
      field: "shortTitle",
      headerText: translations[language || Language.Hr].shortName,
      validationRules: {
        required: true,
      },
    },
    {
      field: "maxNumberOfUsers",
      headerText: translations[language || Language.Hr].maxUsersCount,
      validationRules: {
        required: true,
        number: true,
      },
    },
    {
      field: "maxNumberOfProjects",
      headerText: translations[language || Language.Hr].maxProjectsCount,
    validationRules: {
        required: true,
        number: true,
      },
    },
    {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 100,
      template: (props): ReactElement => {
        return (
          <div
            className="e-btn"
            onClick={(): void =>
              history.push(`/organizations/${props.objectId}/administrators`)
            }
          >
            {translations[language || Language.Hr].administrators}
          </div>
        );
      },
    },
  ];

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "name", direction: "Ascending" }],
  };

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    mode: "Dialog",
  };

  return (
    <section id="organization">
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={organizations}
            columnProperties={columnProperties}
            updateItem={updateOrganization}
            createItem={createOrganization}
            fetchDataAfterCatchError={fetchOrganizations}
            useDialogTemplate={ParseClassName.Organization}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
          />
        </Col>
      </Row>
    </section>
  );
};
