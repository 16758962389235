import Parse from "parse";
import { TaskTemplateBaseModel } from "../models/TaskTemplateBaseModel";
import { Language } from "../settings/Enums";
import { translations } from "../settings/translation";
import { GetFirstObjectById } from "./GenericServices";

export const GetTaskTemplates = async (
  language?: Language
): Promise<TaskTemplateBaseModel[]> => {
  const object = Parse.Object.extend("TaskTemplate");
  const query = new Parse.Query(object)
    .include("timeUnit")
    .include("projectMilestone")
    .limit(10000);

  const results = await query.find();

  if (!(results && results.length > 0)) return [];

  const taskTemplates: TaskTemplateBaseModel[] = [];

  results.forEach((template) => {
    taskTemplates.push({
      id: template.id,
      isAfterProjectMilestone: template.attributes.isAfterProjectMilestone,
      isAfterProjectMilestoneDisplayTitle: template.attributes
        .isAfterProjectMilestone
        ? translations[language || Language.Hr].after
        : translations[language || Language.Hr].before,
      timeUnit: template.attributes.timeUnit,
      timeUnitDisplayTitle:
        language && language !== Language.Hr
          ? template.attributes.timeUnit.attributes.title_en
          : template.attributes.timeUnit.attributes.title,
      projectMilestone: template.attributes.projectMilestone,
      projectMilestoneDisplayTitle:
        language && language !== Language.Hr
          ? template.attributes.projectMilestone.attributes.title_en
          : template.attributes.projectMilestone.attributes.title,
      title: template.attributes.title,
      timeQuantity: template.attributes.timeQuantity,
      description: template.attributes.description,
    });
  });

  return taskTemplates;
};

export const GetTaskTemplatesDropdown = async (): Promise<
  { [key: string]: Object }[]
> => {
  const object = Parse.Object.extend("TaskTemplate");
  const query = new Parse.Query(object)
    .include("timeUnit")
    .include("projectMilestone")
    .limit(10000);

  const results = await query.find();

  if (!(results && results.length > 0)) return [];

  const dropdownItems: { [key: string]: Object }[] = [];

  results.forEach((template) => {
    dropdownItems.push({
      text: template.attributes.title,
      value: template.id,
    });
  });

  return dropdownItems;
};

export const GetTaskTemplateById = async (
  id: string,
  language?: Language
): Promise<TaskTemplateBaseModel | undefined> => {
  const object = Parse.Object.extend("TaskTemplate");
  const query = new Parse.Query(object)
    .include("timeUnit")
    .include("projectMilestone")
    .equalTo("objectId", id)
    .limit(10000);

  const result = await query.first();

  if (!result) return;

  return {
    id: result.id,
    isAfterProjectMilestone: result.attributes.isAfterProjectMilestone,
    isAfterProjectMilestoneDisplayTitle: result.attributes
      .isAfterProjectMilestone
      ? translations[language || Language.Hr].after
      : translations[language || Language.Hr].before,
    timeUnit: result.attributes.timeUnit,
    timeUnitDisplayTitle:
      language && language !== Language.Hr
        ? result.attributes.timeUnit.attributes.title_en
        : result.attributes.timeUnit.attributes.title,
    projectMilestone: result.attributes.projectMilestone,
    projectMilestoneDisplayTitle:
      language && language !== Language.Hr
        ? result.attributes.projectMilestone.attributes.title_en
        : result.attributes.projectMilestone.attributes.title,
    title: result.attributes.title,
    timeQuantity: result.attributes.timeQuantity,
    description: result.attributes.description,
  };
};

export const saveTaskTemplate = async (newTaskTemplate: any): Promise<any> => {
  const taskTemplateObject = Parse.Object.extend("TaskTemplate");
  const taskTemplate = new taskTemplateObject();

  const resultProjectMilestone = await GetFirstObjectById(
    "ProjectMilestone",
    newTaskTemplate.projectMilestone
  );
  const resultTimeUnit = await GetFirstObjectById(
    "TimeUnit",
    newTaskTemplate.timeUnit
  );

  if (!(resultProjectMilestone && resultTimeUnit)) return;

  taskTemplate.set("id", newTaskTemplate.id);
  taskTemplate.set("timeUnit", resultTimeUnit);
  taskTemplate.set("projectMilestone", resultProjectMilestone);
  taskTemplate.set(
    "isAfterProjectMilestone",
    newTaskTemplate.isAfterProjectMilestone
  );
  taskTemplate.set("title", newTaskTemplate.title);
  taskTemplate.set("description", newTaskTemplate.description);
  taskTemplate.set("timeQuantity", newTaskTemplate.timeQuantity);

  return await taskTemplate.save();
};
