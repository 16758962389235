export enum ParseClassName {
    Client = 'client',
    Contact = 'contact',
    Project = 'project',
    ProjectActivity = 'projectActivity',
    ProjectCost = 'projectCost',
    ProjectCostContract = 'projectCostContract',
    ProjectPayment = 'projectPayment',
    FundingType = 'fundingType',
    Task = 'task',
    Person = 'Person',
    Administrator = 'administrator',
    Alarm = 'alarm',
    Tag = 'tag',
    TaskTemplate = 'taskTemplate',
    AlarmTemplate = 'alarmTemplate',
    ProjectTemplate = 'projectTemplate',
    DocumentTemplate = 'documentTemplate',
    Organization = 'organization',
}

export enum DataGridRequestType {
    BeginEdit = 'beginEdit',
    Add = 'add',
    Cancel = 'cancel',
    Save = 'save',
}

export enum DataGridActionType {
    Add = 'add',
    Edit = 'edit',
}

export enum FileSelectActionType {
    Select = 'select',
    Unselect = 'unselect',
}

export enum PasswordType {
    Password = 'password',
    Text = 'text',
}

export enum TaskColumnNames {
    Title = 'title',
    Desciption = 'description',
    AvailableSince = 'availableSince',
    Deadline = 'deadline',
    TaskStatus = 'taskStatus',
    Assignees = 'assignees',
    RTE = 'rte',
}

export enum TaskStatus {
    ToDo = 'TODO',
    ToDoClient = 'TODO_CLIENT',
    InProgress = 'IN_PROGRESS',
    AtClient = 'AT_CLIENT',
    Review = 'REVIEW',
    Done = 'DONE',
}

export enum TimeUnit {
    Day = 'DAY',
    Month = 'MONTH',
    Year = 'YEAR',
}

export enum AlarmStatus {
    Scheduled = 'SCHEDULED',
}

export enum Roles {
    Admin = 'ADMIN',
    AdminOrganization = "ADMIN_ORGANIZATION",
    Employee = 'EMPLOYEE',
    Client = 'CLIENT',
}

export enum Language {
    En = "en",
    Hr = "hr",
}