import React, { ReactElement, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { GetAlarmStates } from "../../services/AlarmStateServices";
import { AlarmBaseModel } from "../../models/AlarmBaseModel";
import { translations } from "../../settings/translation";
import { Language } from "../../settings/Enums";

interface Props {
  data: AlarmBaseModel;
  language?: Language;
}

export const AlarmDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const [alarmStates, setAlarmStates] = useState<{ [key: string]: Object }[]>(
    []
  );
  const [selectedAlarmState, setSelectedAlarmState] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    fetchAlarmStates();
  }, []);

  const fetchAlarmStates = async (): Promise<void> => {
    const alarmStates = await GetAlarmStates();

    if (!alarmStates) return;

    const mappedAlarmStates: { [key: string]: Object }[] = [];

    alarmStates.forEach((alarmState) => {
      mappedAlarmStates.push({
        text: language && language !== Language.Hr ? alarmState.title_en: alarmState.title,
        value: alarmState.id,
      });
    });

    setAlarmStates(mappedAlarmStates);

    setSelectedAlarmState(
      data.alarmState && data.alarmState.id
        ? data.alarmState.id
        : alarmStates[0].id
    );
  };

  const { data, language } = props;
  const fields: object = { text: "text", value: "value" };

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="alarmStateValidation">
          <Form.Label>
            {translations[language || Language.Hr].status}
          </Form.Label>
          <DropDownListComponent
            name="alarmState"
            fields={fields}
            dataSource={alarmStates}
            value={selectedAlarmState}
            readonly={!data.id}
            select={(item) => setSelectedAlarmState(item?.itemData.value)}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="deliveryTimeValidation">
          <Form.Label>
            {translations[language || Language.Hr].notificationTime}
          </Form.Label>
          <DateTimePickerComponent
            name="deliveryTime"
            placeholder={
              translations[language || Language.Hr].enterNotificationTime
            }
            value={data.deliveryTime}
            format="dd.MM.yyyy HH:mm"
            timeFormat="HH:mm"
            step={15}
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
