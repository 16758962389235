import {
  EditSettingsModel,
  SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuthDataContext } from "../../components/authDataProvider";
import { BreadCrumbsNavigation } from "../../components/breadCrumbsNavigation";
import { DataGrid } from "../../components/dataGrid";
import {
  fetchProjectActivitiesByProjectId,
  setPropertyToProjectActivity
} from "../../helpers/fetching/ProjectActivitiesServiceHelper";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import { DataGridColumnModel } from "../../models/DataGridColumnModel";
import { ProjectActivityBaseModel } from "../../models/ProjectActivityBaseModel";
import { GetFirstObjectById } from "../../services/GenericServices";
import { roleListAdminAndEmployee } from "../../settings/Constants";
import { Language, ParseClassName } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {}

export const ProjectActivities: React.FC<Props> = (): ReactElement => {
  const [projectActivities, setProjectActivities] = useState<
    ProjectActivityBaseModel[]
  >([]);
  const [project, setProject] = useState<any | undefined>(undefined);
  const history = useHistory();
  const { userRoles, selectedOrganization, language } = useAuthDataContext();

  useEffect(() => {
    getProjectIdFromRoute();
  }, []);

  let projectId: string;

  const getProjectIdFromRoute = async (): Promise<void> => {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    if (
      urlParts &&
      urlParts.length === 4 &&
      urlParts[1] === "projects" &&
      urlParts[3] === "activities"
    ) {
      projectId = urlParts[2];

      const project = await GetFirstObjectById("Project", projectId, "role");
      const projectActivities = await fetchProjectActivitiesByProjectId(
        project
      );
      setProject(project);
      setProjectActivities(projectActivities);
    }
  };

  const editSettings: EditSettingsModel = {
    allowEditing:
      userRoles &&
      isAnyCommonString(
        userRoles,
        roleListAdminAndEmployee,
        selectedOrganization?.attributes?.shortTitle
      )
        ? true
        : false,
    allowAdding:
      userRoles &&
      isAnyCommonString(
        userRoles,
        roleListAdminAndEmployee,
        selectedOrganization?.attributes?.shortTitle
      )
        ? true
        : false,
    mode: "Dialog",
  };

  const sortSettings: SortSettingsModel = {
    columns: [{ field: "title", direction: "Ascending" }],
  };

  const columns: DataGridColumnModel[] = [
    {
      field: "id",
      visible: false,
      isPrimaryKey: true,
      allowEditing: false,
    },
    {
      field: "title",
      headerText: translations[language || Language.Hr].title,
      validationRules: {
        required: true,
      },
    },
    {
      field: "description",
      headerText: translations[language || Language.Hr].description,
      visible: false,
    },
    {
      field: "code",
      headerText: translations[language || Language.Hr].code,
      validationRules: {
        required: true,
      },
    },
    {
      field: "startDate",
      headerText: translations[language || Language.Hr].startDate,
      type: "date",
      format: "dd.MM.yyyy.",
      filter: {
        type: "Menu",
        params: {
          format: "dd.MM.yyyy.",
        },
      },
      validationRules: {
        required: true,
      },
    },
    {
      field: "endDate",
      headerText: translations[language || Language.Hr].endDate,
      type: "date",
      format: "dd.MM.yyyy.",
      filter: {
        type: "Menu",
        params: {
          format: "dd.MM.yyyy.",
        },
      },
      validationRules: {
        required: true,
      },
    },
    {
      field: "totalCost",
      headerText: translations[language || Language.Hr].granted,
      format: "N2",
    },
    {
      field: "totalCostContract",
      headerText: translations[language || Language.Hr].agreed,
      format: "N2",
    },
    {
      field: "sumDifference",
      headerText: translations[language || Language.Hr].difference,
      format: "N2",
    },
    {
      field: "totalSubvention",
      headerText: translations[language || Language.Hr].subvention,
      format: "N2",
    },
    {
      field: "totalPayment",
      headerText: translations[language || Language.Hr].paid,
      format: "N2",
    },
    {
      field: "details",
      headerText: translations[language || Language.Hr].details,
      allowEditing: false,
      allowSorting: false,
      allowFiltering: false,
      width: 100,
      template: (props): ReactElement => {
        return (
          <div>
            {projectId && (
              <div
                className="e-btn"
                onClick={(): void =>
                  history.push(
                    `/projects/${projectId}/activities/${props.id}/costs`
                  )
                }
              >
                {translations[language || Language.Hr].costs}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const createOrUpdateProjectActivity = async (
    newProjectActivity: ProjectActivityBaseModel
  ): Promise<void> => {
    const projectActivity = setPropertyToProjectActivity(
      newProjectActivity,
      project,
      selectedOrganization
    );
    const response = await projectActivity.save();

    if (response) {
      const projectActivities = await fetchProjectActivitiesByProjectId(
        project
      );
      setProjectActivities(projectActivities);
    }
  };

  return (
    <section id="activity">
      <BreadCrumbsNavigation
        breadCrumbTemplates={[
          {
            url: "projects",
            title: translations[language || Language.Hr].projects,
          },
        ]}
      />
      <Row className={"box-form"}>
        <Col>
          <DataGrid
            data={projectActivities}
            columnProperties={columns}
            useDialogTemplate={ParseClassName.ProjectActivity}
            createItem={createOrUpdateProjectActivity}
            updateItem={createOrUpdateProjectActivity}
            sortSettings={sortSettings}
            editSettings={editSettings}
            allowPaging={true}
            autoFitColumns={true}
          />
        </Col>
      </Row>
    </section>
  );
};
