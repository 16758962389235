import React, { ReactElement, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import Parse from "parse";
import { Kanban } from "../../components/kanban";
import { useAuthDataContext } from "../../components/authDataProvider";
import { KanbanColumnModel } from "../../models/KanbanColumnModel";
import { TaskBaseModel } from "../../models/TaskBaseModel";
import { GetRelationByName } from "../../services/GenericServices";
import { GetTaskStates } from "../../services/TaskStatusServices";
import { Language, TaskStatus } from "../../settings/Enums";
import { sortTasksByDeadline } from "../../helpers/sorting/SortingHelper";
import "./Home.css";

interface Props { }

const initialDeadlineDays: number = 30;

export const Home: React.FC<Props> = (props: Props): ReactElement => {
  const { selectedOrganization, language } = useAuthDataContext();
  const [tasks, setTasks] = useState<TaskBaseModel[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<
    string | undefined
  >(undefined);
  const [deadlineDaysFromToday, setDeadlineDaysFromToday] = useState<number>(
    initialDeadlineDays
  );
  const [kanbanColumns, setKanbanColumns] = useState<KanbanColumnModel[]>([]);

  useEffect(() => {
    fetchTaskStates();
  }, [language]);

  useEffect(() => {
    fetchTasks(deadlineDaysFromToday);
  }, [selectedProjectId]);

  const fetchTasks = async (days: number, projectId?: string): Promise<void> => {
    if (!selectedOrganization) return;
    const tasksList: TaskBaseModel[] = [];
    const startDate = new Date();
    const endDate = new Date();

    startDate.setDate(startDate.getDate() - 1);
    endDate.setDate(endDate.getDate() + days);

    const taskStates = await GetTaskStates();
    const doneStatus: any[] = [];

    if (!(taskStates && taskStates.length > 0)) return;

    taskStates.forEach((taskState) => {
      if (taskState.id && taskState.id === TaskStatus.Done) {
        doneStatus.push(taskState);
      }
    });

    if (doneStatus.length === 0) return;

    const task = Parse.Object.extend("Task");
    let projectResult;
    if (projectId || selectedProjectId) {
      const project = Parse.Object.extend("Project");
      const projectQuery = new Parse.Query(project).equalTo("objectId", projectId || selectedProjectId).limit(10000);
      projectResult = await projectQuery.first();
    }

    const taskListQueryBetweenDeadlineDates = new Parse.Query(task).limit(10000)
      .greaterThanOrEqualTo("deadline", startDate)
      .lessThanOrEqualTo("deadline", endDate);
    const taskListQueryPassedDeadline = new Parse.Query(task).limit(10000)
      .lessThanOrEqualTo("deadline", new Date())
      .notEqualTo("taskState", doneStatus[0]);

    const taskListQueries = Parse.Query.or(
      taskListQueryBetweenDeadlineDates,
      taskListQueryPassedDeadline
    ).limit(10000);

    const results = projectResult
      ? await taskListQueries.equalTo('project', projectResult).include("project.client.organization").find()
      : await taskListQueries.include("project.client.organization").find();

    if (results && results.length > 0) {
      await Promise.all(
        results.map(async (result) => {
          if (result.attributes && result.id && result.attributes.project.attributes.client.attributes.organization.id === selectedOrganization.id) {
            const assignees = await GetRelationByName(result, "assignees");
            const assigneesMails: string[] = [];
            let concatenatedAssignees = "";

            if (assignees) {
              assignees.forEach((assignee: any) => {
                if (assignee && assignee.attributes) {
                  assigneesMails.push(
                    assignee.attributes.email || assignee.attributes.username
                  );
                }
              });

              concatenatedAssignees = assigneesMails.join(', ');
            }

            tasksList.push({
              id: result.id,
              projectActivity: result.attributes.projectActivity,
              assignees: assigneesMails,
              assigneesString: concatenatedAssignees,
              taskState: result.attributes.taskState,
              availableSince: result.attributes.availableSince,
              title: result.attributes.title,
              deadline: result.attributes.deadline,
              project: result.attributes.project,
              description: result.attributes.description,
              projectId: result.attributes.project.id,
              taskStateId: result.attributes.taskState.id,
              className:
                new Date() >= result.attributes.deadline
                  ? "highlighted-card"
                  : "",
              deadlineCroFormat: moment(result.attributes.deadline).format(
                "DD.MM.YYYY."
              ),
              projectShortCode: result.attributes.project.attributes.shortCode,
              projectShortTitle:
                result.attributes.project.attributes.shortTitle,
              projectFile: result.attributes.projectFile,
            });
          }
        })
      );
    }

    const sortedTasks = sortTasksByDeadline(tasksList);

    setTasks(sortedTasks);
  };

  const fetchTaskStates = async (): Promise<void> => {
    const columnsList: KanbanColumnModel[] = [];

    const results = await GetTaskStates();

    if (results && results.length > 0) {
      results.forEach((result) => {
        if (result.attributes && result.id && result.attributes.title) {
          columnsList.push({
            keyField: result.id,
            headerText: language && language !== Language.Hr ? result.attributes[`title_${language}`] : result.attributes.title,
          });
        }
      });
    }
    setKanbanColumns(columnsList);
  };

  const updateDeadlineDays = (days: number): void => {
    setDeadlineDaysFromToday(days);
    fetchTasks(days);
  };

  const updateSelectedProject = (projectId?: string): void => {
    setTasks([]);
    setSelectedProjectId(projectId);
  };

  return (
    <section id="home">
      <Row className={"box-form"}>
        <Col>
          <Kanban
            data={tasks}
            columnProperties={kanbanColumns}
            deadlineDays={deadlineDaysFromToday}
            projectId={selectedProjectId}
            fetchTasks={fetchTasks}
            updateDeadlineDays={updateDeadlineDays}
            updateProject={updateSelectedProject}
          />
        </Col>
      </Row>
    </section>
  );
};
