import Parse from "parse";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { useAuthDataContext } from "../../components/authDataProvider";
import DownloadByTagsDialog from "../../components/downloadByTagsDialog";
import ErrorMessageDialog from "../../components/errorMessageDialog";
import { FileManager } from "../../components/fileManager";
import FolderDetails from "../../components/folderDetails";
import NewFolderDialog from "../../components/newFolderDialog";
import ProjectTemplateDialog from "../../components/projectTemplateDialog";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import {
  deleteFileManagerHelper,
  newFolderFileManagerHelper,
  renameFileManagerHelper
} from "../../helpers/toolbarOptions/FileManagerPermissionHelper";
import {
  getCustomFolderMenu,
  getCustomLayoutMenu,
  getCustomTemplateMenu
} from "../../helpers/toolbarOptions/ToolbarOptionsHelper";
import { GetFirstObjectById } from "../../services/GenericServices";
import { getFolderPath } from "../../services/ProjectDocumentServices";
import { roleListAdminAndEmployee } from "../../settings/Constants";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface OwnProps {}

interface ProjectDocumentProps {
  clientId?: string;
  projectId: string;
  folderId: string;
}

type Props = OwnProps & RouteComponentProps<ProjectDocumentProps>;

const API_HOST = process.env.REACT_APP_API_HOST;

export const ProjectDocuments: React.FC<Props> = (props: Props) => {
  const {
    match: { params },
  } = props;

  const [selectedProject, setSelectedProject] = useState<any>(undefined);
  const [selectedProjectId, setSelectedProjectId] = useState<any>(undefined);
  const [folderPath, setFolderPath] = useState<string | undefined>(undefined);
  let selectedFolderId: string = params.folderId || "";
  const detailsRef = useRef(null);
  const templateRef = useRef(null);
  const downloadByTagsRef = useRef(null);
  const newFolderRef = useRef(null);
  const errorRef = useRef(null);
  const { userRoles, selectedOrganization, language } = useAuthDataContext();

  useEffect((): void => {
    fetchProject(params.projectId);
    fetchFolderPath(params.folderId);
  }, [params.projectId]);

  const fetchProject = async (projectId: string): Promise<void> => {
    const result = await GetFirstObjectById("Project", projectId);
    setSelectedProject(result?.attributes);
    setSelectedProjectId(result?.id);
  };

  const fetchFolderPath = async (folderId: string): Promise<void> => {
    const result = await getFolderPath(folderId);
    // hack just to make sure folder path is set every time
    setTimeout(function () {
      setFolderPath(result);
    }, 250);
  };

  const toolbarClick = async (event: any): Promise<void> => {
    if (
      event?.item?.properties?.text ===
      translations[language || Language.Hr].template
    ) {
      updateSelectedFolderId(selectedFolderId);
      updateSelectedTemplates([]);
      openModal(true);
    } else if (
      event?.item?.properties?.text ===
      translations[language || Language.Hr].downloadByTags
    ) {
      openDownloadByTagsModal(true);
    } else if (
      event?.item?.properties?.text ===
      translations[language || Language.Hr].newFolder
    ) {
      const isAllowed = await newFolderFileManagerHelper(
        event,
        selectedFolderId,
        userRoles,
        selectedOrganization?.attributes?.shortTitle
      );
      if (isAllowed) {
        updateSelectedFolderId(selectedFolderId);
        openNewFolderModal(true);
      } else {
        openErrorModal(
          true,
          translations[language || Language.Hr].noCreateFolderAccessMessage
        );
      }
    } else if (
      event?.item?.properties?.text ===
      translations[language || Language.Hr].rename
    ) {
      renameFileManagerHelper(
        event,
        openErrorModal,
        userRoles,
        language,
        selectedOrganization?.attributes?.shortTitle
      );
    } else if (
      event?.item?.properties?.text ===
      translations[language || Language.Hr].delete
    ) {
      deleteFileManagerHelper(
        event,
        openErrorModal,
        userRoles,
        language,
        selectedOrganization?.attributes?.shortTitle
      );
    }
  };

  const menuClick = (event: any): void => {
    if (
      event?.item?.properties?.text ===
      translations[language || Language.Hr].template
    ) {
      updateSelectedFolderId(
        event.fileDetails && event.fileDetails.length > 0
          ? event.fileDetails[0].objectId
          : selectedFolderId
      );
      updateSelectedTemplates([]);
      openModal(true);
    } else if (
      event?.item?.properties?.text ===
      translations[language || Language.Hr].downloadByTags
    ) {
      openDownloadByTagsModal(true);
    } else if (
      event?.item?.properties?.text ===
      translations[language || Language.Hr].rename
    ) {
      renameFileManagerHelper(
        event,
        openErrorModal,
        userRoles,
        language,
        selectedOrganization?.attributes?.shortTitle
      );
    } else if (
      event?.item?.properties?.text ===
      translations[language || Language.Hr].delete
    ) {
      deleteFileManagerHelper(
        event,
        openErrorModal,
        userRoles,
        language,
        selectedOrganization?.attributes?.shortTitle
      );
    }
  };

  const fileOpen = (event: any): void => {
    if (event.fileDetails.isFile) {
      event.cancel = true;
      const sessionToken = Parse.User.current()?.get("sessionToken");
      window.open(
        `${API_HOST}/filemanager/preview/${event.fileDetails.objectId}?sessionToken=${sessionToken}`,
        "_blank"
      );
    } else {
      selectedFolderId = event.fileDetails.objectId;
      let url = `/projects/${params.projectId}/documents/${selectedFolderId}`;
      window.history.replaceState(null, "", url);
      if (detailsRef && detailsRef.current) {
        (detailsRef.current as any).fetchProjectFiles(selectedFolderId);
      }
    }
  };

  const toolbarMenuCustomAdmin: string[] = [
    translations[language || Language.Hr].newFolder,
    translations[language || Language.Hr].template,
    translations[language || Language.Hr].downloadByTags,
  ];
  const toolbarMenuCustomDefault: string[] = [
    translations[language || Language.Hr].newFolder,
    translations[language || Language.Hr].downloadByTags,
  ];

  const toolbarMenuCustom: string[] = getCustomTemplateMenu(
    toolbarMenuCustomAdmin,
    toolbarMenuCustomDefault,
    userRoles,
    selectedOrganization?.attributes?.shortTitle,
  );

  const folderMenuCustom: string[] = getCustomFolderMenu(
    userRoles,
    language,
    selectedOrganization?.attributes?.shortTitle,
  );

  const layoutMenuCustom: string[] = getCustomLayoutMenu(
    userRoles,
    language,
    selectedOrganization?.attributes?.shortTitle,
  );

  const openModal = (newState: boolean): void => {
    if (templateRef && templateRef.current) {
      (templateRef.current as any).handleModalState(newState);
    }
  };

  const updateSelectedFolderId = (folderId?: string): void => {
    if (templateRef && templateRef.current) {
      (templateRef.current as any).updateSelectedFolderId(folderId);
    }
    if (newFolderRef && newFolderRef.current) {
      (newFolderRef.current as any).updateSelectedFolderId(folderId);
    }
  };

  const updateSelectedTemplates = (newTemplates: string[]): void => {
    if (templateRef && templateRef.current) {
      (templateRef.current as any).updateSelectedTemplates(newTemplates);
    }
  };

  const openDownloadByTagsModal = (newState: boolean): void => {
    if (downloadByTagsRef && downloadByTagsRef.current) {
      (downloadByTagsRef.current as any).handleModalState(newState);
    }
  };

  const openNewFolderModal = (newState: boolean): void => {
    if (newFolderRef && newFolderRef.current) {
      (newFolderRef.current as any).handleModalState(newState);
    }
  };

  const openErrorModal = (newState: boolean, errorMessage: string): void => {
    if (errorRef && errorRef.current) {
      (errorRef.current as any).handleDialogVisibilityState(
        newState,
        errorMessage
      );
    }
  };

  return (
    <section id="projectDocumentHome">
      <Row className={"box-form"}>
        <Col>
          {selectedProject && folderPath && (
            <Row>
              <Col xs={6}>
                <FileManager
                  id="fileManager"
                  projectShortCode={selectedProject?.shortCode}
                  toolbarClick={toolbarClick}
                  menuClick={menuClick}
                  toolbarMenuCustom={toolbarMenuCustom}
                  folderMenuCustom={folderMenuCustom}
                  layoutMenuCustom={layoutMenuCustom}
                  fileOpen={fileOpen}
                  path={folderPath}
                />
                <div style={{ fontSize: "10px" }}>
                  {translations[language || Language.Hr].automaticRenameNote}
                </div>
                {userRoles &&
                  isAnyCommonString(
                    userRoles,
                    roleListAdminAndEmployee,
                    selectedOrganization?.attributes?.shortTitle
                  ) && (
                    <div style={{ fontSize: "10px" }}>
                      {
                        translations[language || Language.Hr]
                          .renameAfterAutomaticRename
                      }
                    </div>
                  )}
              </Col>
              <Col xs={6}>
                {selectedProjectId && (
                  <FolderDetails
                    language={language || Language.Hr}
                    folderId={selectedFolderId}
                    ref={detailsRef}
                    userRoles={userRoles}
                    selectedProjectId={selectedProjectId}
                    wrappedComponentRef={detailsRef}
                    selectedOrganization={selectedOrganization}
                  />
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <ProjectTemplateDialog
        language={language || Language.Hr}
        selectedProject={selectedProject}
        ref={templateRef}
      />
      <DownloadByTagsDialog
        language={language || Language.Hr}
        ref={downloadByTagsRef}
        projectShortCode={selectedProject?.shortCode}
      />
      <NewFolderDialog
        language={language || Language.Hr}
        ref={newFolderRef}
        projectShortCode={selectedProject?.shortCode}
        userRoles={userRoles}
        selectedOrganization={selectedOrganization}
      />
      <ErrorMessageDialog language={language || Language.Hr} ref={errorRef} />
    </section>
  );
};
