import { roleListAdminAndAdminOrganizationAndEmployee, roleListAdminAndEmployee } from "../../settings/Constants";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import { isAnyCommonString } from "../filtering/FilteringHelper";

// #region Toolbar

const toolbarMenuDefault: string[] = [
    "Refresh",
    "SortBy",
    "Selection",
    // "View",
    // "Details",
];

const adminEmployeeToolbarMenuTemplatePicker: string[] = ["Rename", "Delete"];
const toolbarMenuTemplatePicker: string[] = ["Rename", "Delete"];

const toolbarMenuOnlyForFiles: string[] = ["Upload", "Download"];

export const getFileManagerToolbar = (isTemplatePicker?: boolean, isTemplate?: boolean, toolbarMenuCustom?: string[], userRoles?: string[], organizationName?: string): string[] => {
    const isAdminOrEmployee = userRoles && isAnyCommonString(userRoles, roleListAdminAndEmployee, organizationName);

    return toolbarMenuDefault.concat(
        isTemplatePicker ? [] : (isAdminOrEmployee ? adminEmployeeToolbarMenuTemplatePicker : toolbarMenuTemplatePicker),
        isTemplate ? [] : toolbarMenuOnlyForFiles,
        toolbarMenuCustom || [],
    );
}

export const getCustomTemplateMenu = (toolbarMenuCustomAdmin: string[], toolbarMenuCustomDefault: string[], userRoles?: string[], organizationName?: string): string[] => {
    const isAdminOrEmployee = userRoles && isAnyCommonString(userRoles, roleListAdminAndAdminOrganizationAndEmployee, organizationName);

    return isAdminOrEmployee ? toolbarMenuCustomAdmin : toolbarMenuCustomDefault;
}

// #endregion Toolbar

// #region FileMenu

const fileMenuAdmin: string[] = [
    "Download",
    "|",
    "Rename",
    "Delete",
    // "|",
    // "Details",
];

const fileMenuDefault: string[] = [
    "Download",
    "|",
    "Rename",
    "Delete",
    // "|",
    // "Details",
];

export const getFileManagerFileMenu = (isTemplatePicker?: boolean, fileMenuCustom?: string[], userRoles?: string[], organizationName?: string): string[] => {
    const isAdminOrEmployee = userRoles && isAnyCommonString(userRoles, roleListAdminAndEmployee, organizationName);
    const selectedFileMenu = isAdminOrEmployee ? fileMenuAdmin : fileMenuDefault;

    return isTemplatePicker ? [] : selectedFileMenu.concat(fileMenuCustom || []);
}

// #endregion FileMenu

// #region FolderMenu 

const folderMenuAdmin: string[] = [
    "Download",
    "|",
    "Rename",
    "Delete",
    // "|",
    // "Details",
];

const folderMenuDefault: string[] = [
    "Download",
    "|",
    "Rename",
    "Delete",
    // "|",
    // "Details",
];

const getFolderMenuCustomAdmin = (language?: Language): string[] => [translations[language || Language.Hr].template];

const folderMenuCustomDefault: string[] = [];

export const getFileManagerFolderMenu = (isTemplatePicker?: boolean, folderMenuCustom?: string[], userRoles?: string[], organizationName?: string): string[] => {
    const isAdminOrEmployee = userRoles && isAnyCommonString(userRoles, roleListAdminAndAdminOrganizationAndEmployee, organizationName);
    const selectedFolderMenu = isAdminOrEmployee ? folderMenuAdmin : folderMenuDefault;

    return isTemplatePicker ? [] : selectedFolderMenu.concat(folderMenuCustom || []);
}

export const getCustomFolderMenu = (userRoles?: string[], language?: Language, organizationName?: string): string[] => {
    const isAdminOrEmployee = userRoles && isAnyCommonString(userRoles, roleListAdminAndAdminOrganizationAndEmployee, organizationName);

    return isAdminOrEmployee ? getFolderMenuCustomAdmin(language) : folderMenuCustomDefault;
}

// #endregion FolderMenu 

// #region LayoutMenu

const layoutMenuAdmin: string[] = [
    "Refresh",
    "SortBy",
    // "View",
    "|",
    // "Details",
    "SelectAll",
];

const layoutMenuDefault: string[] = [
    "Refresh",
    "SortBy",
    // "View",
    "|",
    // "Details",
    "SelectAll",
];

const getLayoutMenuCustomAdmin = (language?: Language): string[] => [translations[language || Language.Hr].template, translations[language || Language.Hr].downloadByTags];

const getLayoutMenuCustomDefault = (language?: Language): string[] => [translations[language || Language.Hr].downloadByTags];

export const getFileManagerLayoutMenu = (layoutMenuCustom?: string[], userRoles?: string[], organizationName?: string): string[] => {
    const isAdminOrEmployee = userRoles && isAnyCommonString(userRoles, roleListAdminAndAdminOrganizationAndEmployee, organizationName);
    const selectedLayoutMenu = isAdminOrEmployee ? layoutMenuAdmin : layoutMenuDefault;

    return selectedLayoutMenu.concat(layoutMenuCustom || []);
}

export const getCustomLayoutMenu = (userRoles?: string[], language?: Language, organizationName?: string): string[] => {
    const isAdminOrEmployee = userRoles && isAnyCommonString(userRoles, roleListAdminAndAdminOrganizationAndEmployee, organizationName);

    return isAdminOrEmployee ? getLayoutMenuCustomAdmin(language) : getLayoutMenuCustomDefault(language);
}

// #endregion LayoutMenu