import Parse from "parse";
import { GetFirstObjectById } from "./GenericServices";

const API_HOST = process.env.REACT_APP_API_HOST;
const applyTemplateUrl = `${API_HOST}/filemanager/apply`;

export const applyProjectDocumentTemplate = async (
  templateIds: string[],
  folderId?: string,
  shortCode?: string
): Promise<boolean> => {
  if (!templateIds || templateIds.length === 0) return false;

  const sessionToken = Parse.User.current()?.get("sessionToken");
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  templateIds.forEach((templateId) => {
    const req = JSON.stringify({
      projectShortCode: shortCode,
      sessionToken: sessionToken,
      projectFileTemplateObjectId: templateId,
      projectFileObjectId: folderId,
    });

    const requestInfo: RequestInit = {
      headers: headers,
      method: "POST",
      body: req,
      redirect: "follow",
    };
    fetch(`${applyTemplateUrl}`, requestInfo);
  });

  return true;
};

export const getFolderPath = async (folderId?: string): Promise<string> => {
  if (!folderId) return "/";

  let path = "/";
  const folder = await GetFirstObjectById("ProjectFile", folderId);
  if (!folder) return path;

  path += folder.attributes.title;
  if (folder.attributes.parent) {
    path = await getFolderPath(folder.attributes.parent.id) + path;
  }

  return path;
};
