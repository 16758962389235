import Parse from "parse";
import { ProjectPaymentBaseModel } from "../../models/ProjectPaymentBaseModel";
import { Roles } from "../../settings/Enums";

export const fetchPaymentsByProjectCostContractId = async (projectCostContract?: any): Promise<ProjectPaymentBaseModel[]> => {
    if (!projectCostContract) return [];

    const projectPaymentList: ProjectPaymentBaseModel[] = [];

    const projectPayment = Parse.Object.extend("ProjectPayment");
    const projectPaymentListQuery = new Parse.Query(projectPayment).equalTo('projectCostContract', projectCostContract).limit(10000);

    const results = await projectPaymentListQuery.include("projectCostContract.projectCost").find();

    if (results && results.length > 0) {
        await Promise.all(
            results.map(async (result) => {
                if (result.attributes && result.id) {

                    projectPaymentList.push({
                        id: result.id,
                        realTotalNetPrice: result.attributes.realTotalNetPrice || 0,
                        realTotalVatPrice: result.attributes.realTotalVatPrice || 0,
                        vatAmount: result.attributes.vatAmount || 0,
                        subventionAmount: result.attributes.subventionAmount || 0,
                        invoiceDate: result.attributes.invoiceDate,
                        paymentDate: result.attributes.paymentDate,
                        projectCostContract: result.attributes.projectCostContract,
                        projectCostContractId: result.attributes.projectCostContract?.id,
                        projectCostContractTitle: result.attributes.projectCostContract?.attributes?.title,
                    });
                }
            })
        );
    }

    return projectPaymentList;
};

export const setPropertyToProjectPayment = (projectPaymentValue: ProjectPaymentBaseModel, projectCostContract: any, selectedOrganization?: any) => {
    if (!selectedOrganization) return;

    const projectPaymentObject = Parse.Object.extend("ProjectPayment");
    const projectPayment = new projectPaymentObject();

    const projectCost = projectCostContract?.attributes?.projectCost;
    const isVatEligibleCost = projectCost?.attributes?.isVatEligibleCost;
    const subventionRate = projectCost?.attributes?.subventionRate || 0;
    const projectPaymentEligibleAmount = isVatEligibleCost ? Number(projectPaymentValue.realTotalVatPrice) : Number(projectPaymentValue.realTotalNetPrice);
    const subventionAmount = Number((subventionRate * projectPaymentEligibleAmount).toFixed(2));

    projectPayment.set("id", projectPaymentValue.id);
    projectPayment.set("realTotalNetPrice", projectPaymentValue.realTotalNetPrice ? Number(projectPaymentValue.realTotalNetPrice) : 0);
    projectPayment.set("realTotalVatPrice", projectPaymentValue.realTotalVatPrice ? Number(projectPaymentValue.realTotalVatPrice) : 0);
    projectPayment.set("vatAmount", projectPaymentValue.vatAmount ? Number(projectPaymentValue.vatAmount) : 0);
    projectPayment.set("subventionAmount", subventionAmount);
    projectPayment.set("paymentDate", projectPaymentValue.paymentDate);
    projectPayment.set("invoiceDate", projectPaymentValue.invoiceDate);
    projectPayment.set("projectCostContract", projectCostContract);

    if (!projectPaymentValue.id) {
        const acl = new Parse.ACL();

        const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;
        const orgEmployeeRoleName = `${selectedOrganization.attributes.shortTitle}_EMPLOYEE`;

        acl.setRoleReadAccess(Roles.Admin, true);
        acl.setRoleWriteAccess(Roles.Admin, true);
        acl.setRoleReadAccess(orgEmployeeRoleName, true);
        acl.setRoleWriteAccess(orgEmployeeRoleName, true);
        acl.setRoleReadAccess(orgAdminRoleName, true);
        acl.setRoleWriteAccess(orgAdminRoleName, true);

        if (projectCostContract?.attributes?.projectCost?.attributes?.projectActivity?.attributes?.project?.attributes?.shortCode) {
            const projectRoleName = `PROJECT_${projectCostContract.attributes.projectCost.attributes.projectActivity.attributes.project.attributes.shortCode}`;

            acl.setRoleReadAccess(projectRoleName, true);
            acl.setRoleWriteAccess(projectRoleName, false);
        }

        projectPayment.setACL(acl);
    }

    return projectPayment;
};
