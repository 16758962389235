import moment from "moment";
import Parse from "parse";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthDataContext } from "../../components/authDataProvider";
import { WeeklyKanban } from "../../components/weeklyKanban";
import { fetchDaysOfTheWeekHelper } from "../../helpers/fetching/FetchingHelper";
import { sortTasksByDeadline } from "../../helpers/sorting/SortingHelper";
import { KanbanColumnModel } from "../../models/KanbanColumnModel";
import { TaskBaseModel } from "../../models/TaskBaseModel";
import { GetRelationByName } from "../../services/GenericServices";
import { GetTaskStates } from "../../services/TaskStatusServices";
import { Language } from "../../settings/Enums";
import "./WeeklyTasks.css";

interface Props {}

const initialSelectedDate: Date = new Date();

export const WeeklyTasks: React.FC<Props> = (props: Props): ReactElement => {
  const { selectedOrganization, language } = useAuthDataContext();
  const [tasks, setTasks] = useState<TaskBaseModel[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<
    string | undefined
  >(undefined);
  const [selectedDate, setSelectedDate] = useState<Date>(initialSelectedDate);
  const [kanbanColumns, setKanbanColumns] = useState<KanbanColumnModel[]>([]);
  const [taskStatuses, setTaskStatuses] = useState<KanbanColumnModel[]>([]);

  useEffect(() => {
    fetchDaysOfTheWeek();
    fetchTaskStates();
  }, [language]);

  useEffect(() => {
    fetchTasks(selectedDate);
  }, [selectedProjectId]);

  const fetchTasks = async (date: Date, projectId?: string): Promise<void> => {
    if (!selectedOrganization) return;
    const tasksList: TaskBaseModel[] = [];
    const startDate = moment(date).startOf("week").toDate();
    const endDate = moment(date).endOf("week").toDate();

    if (date.getDay() === 0) {
      startDate.setDate(startDate.getDate() - 6);
      endDate.setDate(endDate.getDate() - 6);
    } else {
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);
    }

    startDate.setHours(0, 0, 0);
    endDate.setHours(23, 59, 59);

    const task = Parse.Object.extend("Task");
    let projectResult;
    if (projectId || selectedProjectId) {
      const project = Parse.Object.extend("Project");
      const projectQuery = new Parse.Query(project)
        .equalTo("objectId", projectId || selectedProjectId)
        .limit(10000);
      projectResult = await projectQuery.first();
    }

    const taskListQueryBetweenDeadlineDates = new Parse.Query(task)
      .limit(10000)
      .greaterThanOrEqualTo("deadline", startDate)
      .lessThanOrEqualTo("deadline", endDate);

    const results = projectResult
      ? await taskListQueryBetweenDeadlineDates
          .equalTo("project", projectResult)
          .include("project.client.organization")
          .find()
      : await taskListQueryBetweenDeadlineDates
          .include("project.client.organization")
          .find();

    if (results && results.length > 0) {
      await Promise.all(
        results.map(async (result) => {
          if (
            result.attributes &&
            result.id &&
            result.attributes.project.attributes.client.attributes.organization
              .id === selectedOrganization.id
          ) {
            const assignees = await GetRelationByName(result, "assignees");
            const assigneesMails: string[] = [];
            let concatenatedAssignees = "";

            if (assignees) {
              assignees.forEach((assignee: any) => {
                if (assignee && assignee.attributes) {
                  assigneesMails.push(
                    assignee.attributes.email || assignee.attributes.username
                  );
                }
              });

              concatenatedAssignees = assigneesMails.join(", ");
            }

            tasksList.push({
              id: result.id,
              projectActivity: result.attributes.projectActivity,
              assignees: assigneesMails,
              assigneesString: concatenatedAssignees,
              taskState: result.attributes.taskState,
              availableSince: result.attributes.availableSince,
              title: result.attributes.title,
              deadline: result.attributes.deadline,
              deadlineDayOfTheWeek: result.attributes.deadline
                ? result.attributes.deadline.getDay().toString()
                : undefined,
              project: result.attributes.project,
              description: result.attributes.description,
              projectId: result.attributes.project.id,
              taskStateId: result.attributes.taskState.id,
              className:
                new Date() >= result.attributes.deadline
                  ? "highlighted-card"
                  : "",
              deadlineCroFormat: moment(result.attributes.deadline).format(
                "DD.MM.YYYY."
              ),
              projectShortCode: result.attributes.project.attributes.shortCode,
              projectShortTitle:
                result.attributes.project.attributes.shortTitle,
              projectFile: result.attributes.projectFile,
            });
          }
        })
      );
    }

    const sortedTasks = sortTasksByDeadline(tasksList);

    setTasks(sortedTasks);
  };

  const fetchDaysOfTheWeek = (): void => {
    const results = fetchDaysOfTheWeekHelper(language);

    setKanbanColumns(results);
  };

  const fetchTaskStates = async (): Promise<void> => {
    const columnsList: KanbanColumnModel[] = [];

    const results = await GetTaskStates();

    if (results && results.length > 0) {
      results.forEach((result) => {
        if (result.attributes && result.id && result.attributes.title) {
          columnsList.push({
            keyField: result.id,
            headerText: language && language !== Language.Hr ? result.attributes[`title_${language}`] : result.attributes.title,
          });
        }
      });
    }
    setTaskStatuses(columnsList);
  };

  const updateSelectedDate = (date: Date): void => {
    setSelectedDate(date);
    fetchTasks(date);
  };

  const updateSelectedProject = (projectId?: string): void => {
    setTasks([]);
    setSelectedProjectId(projectId);
  };

  return (
    <section id="weekly-tasks">
      <Row className={"box-form"}>
        <Col>
          <WeeklyKanban
            data={tasks}
            columnProperties={kanbanColumns}
            taskStatuses={taskStatuses}
            selectedDate={selectedDate}
            projectId={selectedProjectId}
            fetchTasks={fetchTasks}
            updateSelectedDate={updateSelectedDate}
            updateProject={updateSelectedProject}
          />
        </Col>
      </Row>
    </section>
  );
};
