import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  ColumnDirective,
  ColumnsDirective,
  KanbanComponent
} from "@syncfusion/ej2-react-kanban";
import {
  ButtonPropsModel,
  createSpinner,
  DialogComponent,
  hideSpinner,
  showSpinner
} from "@syncfusion/ej2-react-popups";
import moment from "moment";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { KanbanDialogFormTemplate } from "../../helpers/dialogTemplates/KanbanDialogTemplate";
import KanbanReadDialogFormTemplate from "../../helpers/dialogTemplates/KanbanReadDialogTemplate";
import { saveTask } from "../../helpers/fetching/TaskFetchingHelper";
import { isAnyCommonString } from "../../helpers/filtering/FilteringHelper";
import { KanbanColumnModel } from "../../models/KanbanColumnModel";
import { TaskBaseModel, TaskFormBaseModel } from "../../models/TaskBaseModel";
import {
  GetFirstObjectById,
  GetFirstObjectByPropertyName
} from "../../services/GenericServices";
import { calculateDeadline } from "../../services/TaskServices";
import { GetTaskTemplateById } from "../../services/TaskTemplateServices";
import { roleListAdminAndAdminOrganizationAndEmployee } from "../../settings/Constants";
import { Language, TaskStatus } from "../../settings/Enums";
import { TASKS_BY_STATUS_ROUTE } from "../../settings/Routes";
import { translations } from "../../settings/translation";
import { useAuthDataContext } from "../authDataProvider";
import CustomSpinner from "../custom-spinner";
import { ProjectPicker } from "../projectPicker";
import { TaskTemplatePicker } from "../taskTemplatePicker";
import "./WeeklyKanban.css";

interface Props {
  columnProperties: KanbanColumnModel[];
  taskStatuses: KanbanColumnModel[];
  data: TaskBaseModel[];
  selectedDate: Date;
  projectId?: string;
  fetchTasks: (date: Date) => Promise<void>;
  updateSelectedDate: (date: Date) => void;
  updateProject: (projectId?: string) => void;
}

export const WeeklyKanban: React.FC<Props> = (props: Props): ReactElement => {
  const [selectedCard, setSelectedCard] = useState<TaskBaseModel | undefined>(
    undefined
  );
  const [scrollHeight, setScrollHeight] = useState<number>(650);
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState<
    string | undefined
  >(undefined);
  const [isEditDialogVisible, setIsEditDialogVisible] =
    useState<boolean>(false);
  const [isReadDialogVisible, setIsReadDialogVisible] =
    useState<boolean>(false);
  const [isScheduledToNotify, setIsScheduledToNotify] =
    useState<boolean>(false);
  const [isTaskTemplateDialogVisible, setIsTaskTemplateDialogVisible] =
    useState<boolean>(false);
  const [taskFormData, setTaskFormData] = useState<
    TaskFormBaseModel | undefined
  >(undefined);
  const [isValidatingForm, setIsValidatingForm] = useState<boolean>(false);
  const customSpinnerRef = useRef(null);
  const { userRoles, selectedOrganization, language } = useAuthDataContext();
  const { projectId, data } = props;

  const templateTaskButton = {
    buttonModel: {
      content: translations[language || Language.Hr].template,
      cssClass: "e-flat float-left",
    },
    click: () => openTaskTemplateDialog(),
  };

  const removeTemplateTaskButton = {
    buttonModel: {
      content: translations[language || Language.Hr].removeTemplate,
      cssClass: "e-flat e-danger float-left",
    },
    click: () => removeSelectedTaskTemplate(),
  };

  const defaultEditCardButtons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].save,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => onSubmitClick(false),
    },
    {
      buttonModel: {
        content: translations[language || Language.Hr].saveAndSend,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => onSubmitClick(true),
    },
    {
      buttonModel: {
        content: translations[language || Language.Hr].cancel,
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () =>
        selectedCard?.id ? openReadDialog(selectedCard) : closeDialog(),
    },
  ];
  const [editCardButtons, setEditCardButtons] = useState<ButtonPropsModel[]>(
    defaultEditCardButtons
  );

  const columns: ReactElement[] = [];
  const history = useHistory();

  useEffect(() => {
    const toolbarClass = document.getElementsByClassName("e-swimlane");

    if (toolbarClass?.length > 0) {
      const dimensions = toolbarClass[0].getBoundingClientRect();

      setScrollHeight(window.innerHeight - dimensions.top - 20);
    }
  }, [columns]);

  const spinnerElement = document.getElementById("editDialogComponent");
  if (spinnerElement) {
    createSpinner({
      target: spinnerElement,
      cssClass: "spinner",
    });
  }

  const kanbanHeaderTemplate = () => {
    return (
      '<div class="header-template-wrap">' +
      '<div class="header-icon e-icons ${keyField}"></div>' +
      '<div class="header-text">${headerText}</div>' +
      "</div>"
    );
  };

  props.columnProperties.forEach((property) => {
    columns.push(
      <ColumnDirective
        headerText={property.headerText}
        keyField={property.keyField}
        showAddButton={
          userRoles &&
          isAnyCommonString(
            userRoles,
            roleListAdminAndAdminOrganizationAndEmployee,
            selectedOrganization?.attributes?.shortTitle
          ) &&
          data?.length > 0
        }
        allowToggle={true}
        template={kanbanHeaderTemplate()}
      />
    );
  });

  const onCardClick = (params: any) => {
    if (params && params.data && !params.cancel) {
      openReadDialog(params.data);
    }
  };

  const dialogOpenAct = async (args: any) => {
    args.cancel = true;
    if (!(args && args.data && args.requestType === "Add")) return;

    let project;
    if (args.data.projectShortCode) {
      project = await GetFirstObjectByPropertyName(
        "Project",
        "shortCode",
        args.data.projectShortCode
      );
    } else if (projectId) {
      project = await GetFirstObjectByPropertyName(
        "Project",
        "objectId",
        projectId
      );
      args.data.projectShortCode = project?.attributes.shortCode;
    }

    args.data.title = undefined;
    args.data.project = project;
    args.data.projectId = project?.id;
    args.data.projectShortTitle = project?.attributes.shortTitle;
    args.data.assignees = [];
    args.data.taskStateId = TaskStatus.ToDo;

    const date = moment(props.selectedDate).startOf("week").toDate();
    if (args.data.deadlineDayOfTheWeek !== "0") {
      date.setDate(
        date.getDate() + parseInt(args.data.deadlineDayOfTheWeek, 10)
      );
    } else {
      date.setDate(date.getDate() + 7);
    }
    args.data.deadline = date;

    openEditDialog(args.data);
  };

  const dialogAddNewOpenAct = async (projectId: string): Promise<void> => {
    const projectObject = await GetFirstObjectById("Project", projectId);

    const data = {
      assignees: [],
      taskStateId: TaskStatus.ToDo,
      projectId: projectId,
      project: projectObject,
      projectShortCode: projectObject?.attributes.shortCode,
      projectShortTitle: projectObject?.attributes.shortTitle,
      deadline: props.selectedDate,
    };

    openEditDialog(data);
  };

  const openEditDialog = (data: any) => {
    setEditCardButtons([templateTaskButton, ...defaultEditCardButtons]);
    setSelectedCard(data);
    setTaskFormData({ ...data });
    setIsReadDialogVisible(false);
    setIsEditDialogVisible(true);
  };

  const openReadDialog = (data: any) => {
    setSelectedCard(data);
    setTaskFormData({ ...data });
    setIsEditDialogVisible(false);
    setIsReadDialogVisible(true);
  };

  const openTaskTemplateDialog = () => {
    setIsTaskTemplateDialogVisible(true);
  };

  const closeTaskTemplateDialog = () => {
    setIsTaskTemplateDialogVisible(false);
  };

  const closeDialog = () => {
    if (spinnerElement) {
      hideSpinner(spinnerElement);
    }
    setIsEditDialogVisible(false);
    setIsReadDialogVisible(false);
    setIsTaskTemplateDialogVisible(false);
    setSelectedCard(undefined);
    setTaskFormData(undefined);
  };

  const onPressEdit = () => {
    setEditCardButtons(defaultEditCardButtons);
    setIsEditDialogVisible(true);
    setIsReadDialogVisible(false);
  };

  const onSaveAction = async () => {
    const { selectedDate, fetchTasks } = props;

    if (!taskFormData) return;

    const spinner = customSpinnerRef && customSpinnerRef.current;

    if (spinner) {
      (spinner as any).handleSpinnerState(true);
    }

    const response = await saveTask(
      { ...taskFormData, isScheduledToNotify: isScheduledToNotify },
      selectedTaskTemplate,
      undefined,
      selectedOrganization,
      language
    );

    if (response) {
      fetchTasks(selectedDate);
      closeDialog();
    }
    if (spinner) {
      (spinner as any).handleSpinnerState(false);
    }
  };

  const updateTask = (task: TaskFormBaseModel): void => {
    setTaskFormData(task);
  };

  const updateSelectedTaskTemplate = (value: string): void => {
    setSelectedTaskTemplate(value);
  };

  const removeSelectedTaskTemplate = (): void => {
    setSelectedTaskTemplate(undefined);
    setEditCardButtons([templateTaskButton, ...defaultEditCardButtons]);
  };

  const onDragStop = async (event: any): Promise<void> => {
    const { selectedDate, fetchTasks } = props;

    if (!(event && event.data && event.data.length > 0)) return;

    const newDayOfTheWeek = parseInt(event.data[0].deadlineDayOfTheWeek, 10);
    const newDate = moment(event.data[0].deadline).startOf("week").toDate();
    if (event.data[0].deadline.getDay() === 0) {
      newDate.setDate(newDate.getDate() - 7);
    }

    if (newDayOfTheWeek === 0) {
      newDate.setDate(newDate.getDate() + newDayOfTheWeek + 7);
    } else {
      newDate.setDate(newDate.getDate() + newDayOfTheWeek);
    }

    const data = {
      ...event.data[0],
      deadline: newDate,
    };

    const response = await saveTask(
      data as TaskFormBaseModel,
      undefined,
      undefined,
      selectedOrganization,
      language
    );

    if (response) {
      fetchTasks(selectedDate);
      closeDialog();
    }
  };

  const dateChange = (event: any): void => {
    const { updateSelectedDate, selectedDate } = props;

    if (event && event && event.value && event.value !== selectedDate) {
      updateSelectedDate(event.value);
    }
  };

  const weekChange = (isForward: boolean): void => {
    const { selectedDate, updateSelectedDate } = props;

    const newDate = isForward
      ? moment(selectedDate).add("week", 1).toDate()
      : moment(selectedDate).subtract("week", 1).toDate();
    updateSelectedDate(newDate);
  };

  const updateSelectedProject = (projectId?: string): void => {
    const { updateProject } = props;
    updateProject(projectId);
  };

  const onAddNewClick = (projectId?: string): void => {
    if (projectId) {
      dialogAddNewOpenAct(projectId);
    }
  };

  const onSubmitClick = (locaIsScheduledToNotify: boolean): void => {
    if (spinnerElement) {
      showSpinner(spinnerElement);
    }
    setIsScheduledToNotify(locaIsScheduledToNotify);
    setIsValidatingForm(true);
  };

  const onValidationEnd = (result: boolean): void => {
    setIsValidatingForm(false);
    if (!result) {
      if (spinnerElement) {
        hideSpinner(spinnerElement);
      }
      return;
    }
    onSaveAction();
  };

  const goToProjectDocumentRoute = async (
    projectId?: string
  ): Promise<void> => {
    if (!projectId) return;
    history.push(`/projects/${projectId}/documents`);
  };

  const updateTaskInitialValues = async (
    taskTemplateId?: string
  ): Promise<void> => {
    if (!taskTemplateId) return;

    setEditCardButtons([
      templateTaskButton,
      removeTemplateTaskButton,
      ...defaultEditCardButtons,
    ]);

    const result = await GetTaskTemplateById(taskTemplateId, language);

    if (
      !(
        result &&
        selectedCard &&
        selectedCard.project &&
        selectedCard.project.attributes &&
        taskFormData
      )
    )
      return;

    const deadline: Date = calculateDeadline(
      selectedCard.project.attributes[result.projectMilestone.id],
      result.timeUnit.id,
      result.timeQuantity,
      result.isAfterProjectMilestone
    );

    const newSelectedCard: TaskBaseModel = {
      ...selectedCard,
      description: result.description,
      title: result.title,
      deadline,
    };

    const newSelectedTaskFormData: TaskFormBaseModel = {
      ...taskFormData,
      description: result.description,
      title: result.title,
      deadline,
    };

    setSelectedCard(newSelectedCard);
    setTaskFormData(newSelectedTaskFormData);
    closeTaskTemplateDialog();
  };

  const cardTemplate = (): string => {
    return (
      "<div class='card-content ${className}'>" +
      "<div>" +
      "<b>${title}</b><b class='float-right'>${deadlineCroFormat}</b>" +
      "</div>" +
      "<div>${assigneesString}</div>" +
      "</div>"
    );
  };

  const readCardHeader = (selectedCard: TaskBaseModel): string => {
    return (
      "<span>" +
      selectedCard.title +
      "</span>" +
      "<span class='float-right'>rok " +
      selectedCard.deadlineCroFormat +
      "</span>"
    );
  };

  const cancelReadCardButton: ButtonPropsModel = {
    buttonModel: {
      content: translations[language || Language.Hr].close,
      cssClass: "e-flat",
      isPrimary: false,
    },
    click: () => closeDialog(),
  };

  const readCardButtons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].edit,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => onPressEdit(),
    },
    cancelReadCardButton,
  ];

  const taskTemplateDialogButtons: ButtonPropsModel[] = [
    {
      buttonModel: {
        content: translations[language || Language.Hr].apply,
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => updateTaskInitialValues(selectedTaskTemplate),
    },
    {
      buttonModel: {
        content: translations[language || Language.Hr].cancel,
        cssClass: "e-flat",
        isPrimary: false,
      },
      click: () => closeTaskTemplateDialog(),
    },
  ];

  return (
    <div>
      <CustomSpinner
        language={language || Language.Hr}
        spinnerClass="custom-spinner-wrapper-kanban"
        ref={customSpinnerRef}
      />
      <Row className="align-items-center">
        <Col xl={3} lg={3} md={2} sm={2} xs={2}>
          <ProjectPicker updateSelectedProject={updateSelectedProject} />
        </Col>
        <Col xl={3} lg={3} md={4} sm={4} xs={4}>
          {userRoles &&
            isAnyCommonString(
              userRoles,
              roleListAdminAndAdminOrganizationAndEmployee,
              selectedOrganization?.attributes?.shortTitle
            ) && (
              <button
                disabled={!projectId}
                className="btn btn-primary"
                onClick={() => onAddNewClick(projectId)}
              >
                {translations[language || Language.Hr].addTask}
              </button>
            )}
          <button
            disabled={!projectId}
            className="btn btn-primary"
            style={{ marginLeft: 15 }}
            onClick={() => goToProjectDocumentRoute(projectId)}
          >
            {translations[language || Language.Hr].documents}
          </button>
        </Col>
        <Col xl={4} lg={4} md={4} sm={4} xs={4} className="align-items-center">
          <span
            className="float-right"
            style={{ height: "38px", lineHeight: "38px" }}
          >
            {translations[language || Language.Hr].weekTasksDisplayed}:{" "}
          </span>
          <button
            className="btn btn-primary float-right"
            style={{ marginRight: 25 }}
            onClick={() => history.push(TASKS_BY_STATUS_ROUTE)}
          >
            {translations[language || Language.Hr].overviewByStatus}
          </button>
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={2} style={{ display: "flex" }}>
          <Button
            style={{ marginRight: 5 }}
            onClick={(): void => weekChange(false)}
          >
            {`<`}
          </Button>
          <DatePickerComponent
            name="deadline"
            format="dd.MM.yyyy"
            allowEdit={false}
            showClearButton={false}
            value={props.selectedDate}
            change={(value) => dateChange(value)}
          />
          <Button
            style={{ marginLeft: 5 }}
            onClick={(): void => weekChange(true)}
          >
            {`>`}
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <KanbanComponent
          id="weekly-kanban"
          keyField="deadlineDayOfTheWeek"
          dataSource={props.data}
          height={scrollHeight}
          cardSettings={{
            contentField: "description",
            headerField: "title",
            template: cardTemplate(),
          }}
          cardDoubleClick={onCardClick}
          dialogOpen={dialogOpenAct}
          swimlaneSettings={{
            keyField: "projectShortCode",
            textField: "projectShortTitle",
            showItemCount: false,
            showEmptyRow: true,
          }}
          dragStop={(event) => onDragStop(event)}
        >
          <ColumnsDirective>{columns}</ColumnsDirective>
        </KanbanComponent>
      </Row>
      <DialogComponent
        isModal={true}
        className="kanban-dialog"
        header={
          selectedCard
            ? readCardHeader(selectedCard)
            : translations[language || Language.Hr].taskDetails
        }
        width="700px"
        minHeight="90vh"
        visible={isReadDialogVisible}
        buttons={
          userRoles &&
          isAnyCommonString(
            userRoles,
            roleListAdminAndAdminOrganizationAndEmployee,
            selectedOrganization?.attributes?.shortTitle
          )
            ? readCardButtons
            : [cancelReadCardButton]
        }
      >
        <div className="dialog-content">
          {selectedCard && (
            <KanbanReadDialogFormTemplate
              data={selectedCard}
              isDialog={true}
              showAlarmButton={
                userRoles &&
                isAnyCommonString(
                  userRoles,
                  roleListAdminAndAdminOrganizationAndEmployee,
                  selectedOrganization?.attributes?.shortTitle
                )
              }
              language={language}
              selectedOrganization={selectedOrganization}
            />
          )}
        </div>
      </DialogComponent>
      {userRoles &&
        isAnyCommonString(
          userRoles,
          roleListAdminAndAdminOrganizationAndEmployee,
          selectedOrganization?.attributes?.shortTitle
        ) && (
          <DialogComponent
            id="editDialogComponent"
            isModal={true}
            className="kanban-dialog"
            header={
              selectedCard?.id
                ? translations[language || Language.Hr].editTask
                : translations[language || Language.Hr].newTask
            }
            width="700px"
            visible={isEditDialogVisible}
            buttons={editCardButtons}
          >
            <div className="dialog-content">
              {selectedCard && taskFormData && (
                <KanbanDialogFormTemplate
                  data={selectedCard}
                  formData={taskFormData}
                  taskStatusesList={props.taskStatuses}
                  updateTask={updateTask}
                  startValidation={isValidatingForm}
                  onValidationReset={onValidationEnd}
                />
              )}
            </div>
          </DialogComponent>
        )}
      {/* this is needed since syncfusion DropDownListComponent won't deselect value
                                even if selectedTaskTemplate === undefined */}
      {userRoles &&
        isAnyCommonString(
          userRoles,
          roleListAdminAndAdminOrganizationAndEmployee,
          selectedOrganization?.attributes?.shortTitle
        ) &&
        isTaskTemplateDialogVisible && (
          <DialogComponent
            isModal={true}
            className="kanban-dialog"
            header={translations[language || Language.Hr].chooseTaskTemplate}
            width="700px"
            visible={isTaskTemplateDialogVisible}
            buttons={taskTemplateDialogButtons}
          >
            <div className="dialog-content">
              <TaskTemplatePicker
                updateSelectedTaskTemplate={updateSelectedTaskTemplate}
                value={selectedTaskTemplate}
              />
            </div>
          </DialogComponent>
        )}
    </div>
  );
};
