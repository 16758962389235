import React, { ReactElement, useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TaskTemplateBaseModel } from "../../models/TaskTemplateBaseModel";
import { GetProjectMilestones } from "../../services/ProjectMilestoneServices";
import { GetTimeUnits } from "../../services/TimeUnitServices";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";

interface Props {
  data: TaskTemplateBaseModel;
  language?: Language;
}

export const TaskTemplateDialogFormTemplate: React.FC<Props> = (
  props: Props
): ReactElement => {
  const [projectMilestones, setProjectMilestones] = useState<
    { [key: string]: Object }[]
  >([]);
  const [selectedProjectMilestone, setSelectedProjectMilestone] = useState<
    string | undefined
  >(undefined);
  const [timeUnits, setTimeUnits] = useState<{ [key: string]: Object }[]>([]);
  const [selectedTimeUnit, setSelectedTimeUnit] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    fetchProjectMilestones();
    fetchTimeUnits();
  }, []);

  const fetchProjectMilestones = async (): Promise<void> => {
    const projectMilestones = await GetProjectMilestones();

    if (!projectMilestones) return;

    const mappedProjectMilestones: { [key: string]: Object }[] = [];

    projectMilestones.forEach((projectMilestone) => {
      mappedProjectMilestones.push({
        text:
          language && language !== Language.Hr
            ? projectMilestone.title_en
            : projectMilestone.title,
        value: projectMilestone.id,
      });
    });

    setProjectMilestones(mappedProjectMilestones);

    setSelectedProjectMilestone(
      data.projectMilestone && data.projectMilestone.id
        ? data.projectMilestone.id
        : projectMilestones[0].id
    );
  };

  const fetchTimeUnits = async (): Promise<void> => {
    const timeUnits = await GetTimeUnits();

    if (!timeUnits) return;

    const mappedTimeUnits: { [key: string]: Object }[] = [];

    timeUnits.forEach((timeUnit) => {
      mappedTimeUnits.push({
        text:
          language && language !== Language.Hr
            ? timeUnit.title_en
            : timeUnit.title,
        value: timeUnit.id,
      });
    });

    setTimeUnits(mappedTimeUnits);

    setSelectedTimeUnit(
      data.timeUnit && data.timeUnit.id ? data.timeUnit.id : timeUnits[0].id
    );
  };

  const { data, language } = props;
  const fields: object = { text: "text", value: "value" };

  return (
    <div style={{ padding: "15px" }}>
      <Form.Row>
        <Form.Group as={Col} controlId="titleValidation">
          <Form.Label>{translations[language || Language.Hr].title}</Form.Label>
          <Form.Control
            type="text"
            name="title"
            defaultValue={data.title}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="descriptionValidation">
          <Form.Label>
            {translations[language || Language.Hr].description}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            defaultValue={data.description}
            autoComplete="off"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="timeQuantity">
          <Form.Label>
            {translations[language || Language.Hr].number}
          </Form.Label>
          <Form.Control
            type="number"
            name="timeQuantity"
            defaultValue={data.timeQuantity}
            autoComplete="off"
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="timeUnit">
          <Form.Label>
            {translations[language || Language.Hr].timeUnit}
          </Form.Label>
          <DropDownListComponent
            name="timeUnit"
            fields={fields}
            dataSource={timeUnits}
            value={selectedTimeUnit}
            select={(item) => setSelectedTimeUnit(item?.itemData.value)}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          controlId="isAfterProjectMilestoneValidation"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: "18px",
          }}
        >
          <Form.Check
            label={translations[language || Language.Hr].afterReferentDate}
            type="checkbox"
            name="isAfterProjectMilestone"
            defaultChecked={!!data.isAfterProjectMilestone}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="projectMilestone">
          <Form.Label>
            {translations[language || Language.Hr].referentDate}
          </Form.Label>
          <DropDownListComponent
            name="projectMilestone"
            fields={fields}
            dataSource={projectMilestones}
            value={selectedProjectMilestone}
            select={(item) => setSelectedProjectMilestone(item?.itemData.value)}
          />
        </Form.Group>
      </Form.Row>
    </div>
  );
};
