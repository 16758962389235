import {
    TaskBaseModel,
    TaskActivityBaseModel,
} from "../../models/TaskBaseModel"

export const sortTasksByDeadline = (tasks: TaskBaseModel[], desc?: boolean): TaskBaseModel[] => {
    const sortedTasks = tasks.sort((a,b) => a.deadline.getTime() - b.deadline.getTime());
    if (desc) {
        return sortedTasks.reverse();
    }
    return sortedTasks;
}

export const sortTaskActivitiesByCreationDate = (taskActivities: TaskActivityBaseModel[], desc?: boolean): TaskActivityBaseModel[] => {
    const sortedTaskActivities = taskActivities.sort((a,b) => a.creationDate.getTime() - b.creationDate.getTime());
    if (desc) {
        return sortedTaskActivities.reverse();
    }
    return sortedTaskActivities;
}
