import Parse from "parse";
import { TimeUnit, TaskStatus, Roles, Language } from "../settings/Enums";
import { TaskFormBaseModel } from "../models/TaskBaseModel";
import { createAlarmsFromTaskTemplate } from "./AlarmServices";
import { setPropertyToTask } from "./ProjectServices";
import { GetFirstObjectById } from "./GenericServices";

export const GetTaskById = async (id: string) => {
  const object = Parse.Object.extend("Task");
  const objectQuery = new Parse.Query(object)
    .equalTo("objectId", id)
    .include("project")
    .include("taskState")
    .limit(10000);
  return await objectQuery.first();
};

export const GetTasks = async () => {
  const task = Parse.Object.extend("Task");
  const query = new Parse.Query(task).limit(10000);

  return await query.find();
};

export const GetTasksIncludingPointer = async (pointerName: string) => {
  const task = Parse.Object.extend("Task");
  const query = new Parse.Query(task).limit(10000);
  const includeQuery = query.include(pointerName);

  return await includeQuery.find();
};

export const GetTaskByIdIncludingPointer = async (
  id: string,
  pointerName: string
) => {
  const task = Parse.Object.extend("Task");
  const query = new Parse.Query(task).equalTo("objectId", id).limit(10000);
  const includeQuery = query.include(pointerName);

  return await includeQuery.first();
};

export const createTaskItem = async (
  task: any,
  body: string,
  projectFileId?: string,
  selectedOrganization?: any
): Promise<any> => {
  if (!selectedOrganization) return;
  const taskItemObject = Parse.Object.extend("TaskItem");
  const taskItem = new taskItemObject();

  if (projectFileId) {
    const projectFile = await GetFirstObjectById("ProjectFile", projectFileId);
    if (projectFile) {
      taskItem.set("projectFile", projectFile);
    }
  }

  const acl = new Parse.ACL();
  acl.setRoleReadAccess(Roles.Admin, true);
  acl.setRoleReadAccess(
    task.attributes.project.attributes.role.attributes.name,
    true
  );
  acl.setRoleWriteAccess(Roles.Admin, true);
  acl.setRoleWriteAccess(
    task.attributes.project.attributes.role.attributes.name,
    true
  );

  const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;

  acl.setRoleReadAccess(orgAdminRoleName, true);
  acl.setRoleWriteAccess(orgAdminRoleName, true);

  taskItem.set("task", task);
  taskItem.set("body", body || "<p></p>");
  taskItem.set("createdBy", Parse.User.current());
  taskItem.setACL(acl);

  return await taskItem.save();
};

export const calculateDeadline = (
  initialDate: Date,
  timeUnitId: string,
  timeQuantity: number,
  isAfterProjectMilestone: boolean
): Date => {
  const deadline: Date = new Date(initialDate);

  switch (timeUnitId) {
    case TimeUnit.Day:
      deadline.setDate(
        isAfterProjectMilestone
          ? deadline.getDate() + timeQuantity
          : deadline.getDate() - timeQuantity
      );
      break;
    case TimeUnit.Month:
      deadline.setMonth(
        isAfterProjectMilestone
          ? deadline.getMonth() + timeQuantity
          : deadline.getMonth() - timeQuantity
      );
      break;
    case TimeUnit.Year:
      deadline.setFullYear(
        isAfterProjectMilestone
          ? deadline.getFullYear() + timeQuantity
          : deadline.getFullYear() - timeQuantity
      );
      break;
    default:
      break;
  }

  return deadline;
};

export const createTasksFromTemplate = (
  taskTemplates: any,
  project: any,
  selectedOrganization?: any
): void => {
  taskTemplates.forEach(async (template: any) => {
    if (template.attributes) {
      const deadline: Date = calculateDeadline(
        project.attributes[template.attributes.projectMilestone.id],
        template.attributes.timeUnit.id,
        template.attributes.timeQuantity,
        template.attributes.isAfterProjectMilestone
      );

      const availableSince = new Date(deadline);
      availableSince.setMonth(availableSince.getMonth() - 1);

      const model: TaskFormBaseModel = {
        assignees: [],
        taskStateId: TaskStatus.ToDo,
        availableSince: availableSince,
        deadline: deadline,
        title: template.attributes.title,
        description: template.attributes.description,
        project: project,
        projectId: project.id,
      };

      await saveTask(model, template.id, selectedOrganization);
    }
  });
};

export const saveTask = async (
  taskModel: TaskFormBaseModel,
  taskTemplateId?: string,
  selectedOrganization?: any,
  language?: Language
): Promise<any> => {
  if (!selectedOrganization) return;

  const task = await setPropertyToTask(taskModel);

  if (!taskModel.id) {
    const acl = new Parse.ACL();
    acl.setRoleReadAccess(Roles.Admin, true);
    acl.setRoleReadAccess(
      taskModel.project.attributes.role.attributes.name,
      true
    );
    acl.setRoleWriteAccess(Roles.Admin, true);
    acl.setRoleWriteAccess(
      taskModel.project.attributes.role.attributes.name,
      true
    );

    const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;

    acl.setRoleReadAccess(orgAdminRoleName, true);
    acl.setRoleWriteAccess(orgAdminRoleName, true);
    task.setACL(acl);
  }

  const taskResponse = await task.save();

  if (!taskResponse) return;

  if (taskTemplateId) {
    await createAlarmsFromTaskTemplate(
      taskResponse,
      taskTemplateId,
      selectedOrganization,
      language
    );
  }

  return taskResponse;
};
