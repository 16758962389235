import Parse from "parse";
import { TimeUnitBaseModel } from "../models/TimeUnitBaseModel";

export const GetTimeUnits = async () => {
    const object = Parse.Object.extend('TimeUnit');
    const objectQuery = new Parse.Query(object).limit(10000);

    const results = await objectQuery.find();

    if (!results) return [];

    const timeUnits: TimeUnitBaseModel[] = [];

    results.forEach(timeUnit => {
        timeUnits.push({
            id: timeUnit.id,
            title: timeUnit.attributes.title,
            title_en: timeUnit.attributes.title_en,
        })
    });

    return timeUnits; 
}