import Parse from "parse";
import { TagBaseModel } from "../../models/TagBaseModel";
import { ProjectCostContractBaseModel } from "../../models/ProjectCostContractBaseModel";
import { GetFirstObjectById } from "../../services/GenericServices";
import { Roles } from "../../settings/Enums";

export const fetchContractsByProjectCostId = async (projectCost?: any): Promise<ProjectCostContractBaseModel[]> => {
    if (!projectCost) return [];

    const projectCostContractList: ProjectCostContractBaseModel[] = [];

    const projectCostContract = Parse.Object.extend("ProjectCostContract");
    const projectCostContractListQuery = new Parse.Query(projectCostContract).equalTo('projectCost', projectCost).limit(10000);

    const results = await projectCostContractListQuery.include("tag").include("projectCost").find();

    if (results && results.length > 0) {
        await Promise.all(
            results.map(async (result) => {
                if (result.attributes && result.id) {
                    projectCostContractList.push({
                        id: result.id,
                        contractor: result.attributes.contractor,
                        unitPrice: result.attributes.unitPrice || 0,
                        numberOfUnits: result.attributes.numberOfUnits,
                        vatRate: result.attributes.vatRate || 0,
                        vatAmount: result.attributes.vatAmount || 0,
                        realTotalNetPrice: result.attributes.realTotalNetPrice || 0,
                        realTotalVatPrice: result.attributes.realTotalVatPrice || 0,
                        projectCost: result.attributes.projectCost,
                        projectCostId: result.attributes.projectCost?.id,
                        projectCostTitle: result.attributes.projectCost?.attributes?.title,
                        tag: result.attributes.tag,
                        tagId: result.attributes.tag?.id,
                        tagTitle: result.attributes.tag?.attributes?.title,
                        subventionAmount: result.attributes.subventionAmount || 0,
                        totalPayment: result.attributes.totalPayment || 0,
                    });
                }
            })
        );
    }

    return projectCostContractList;
};

export const setPropertyToProjectCostContract = async (projectCostContractValue: ProjectCostContractBaseModel, projectCost: any, selectedOrganization?: any): Promise<any> => {
    if (!selectedOrganization) return;

    const projectCostContractObject = Parse.Object.extend("ProjectCostContract");
    const projectCostContract = new projectCostContractObject();

    const isVatEligibleCost = projectCost?.attributes?.isVatEligibleCost;
    const subventionRate = projectCost?.attributes?.subventionRate || 0;
    const projectCostContractEligibleAmount = isVatEligibleCost ? Number(projectCostContractValue.realTotalVatPrice) : Number(projectCostContractValue.realTotalNetPrice);
    const subventionAmount = Number((subventionRate * projectCostContractEligibleAmount).toFixed(2));

    projectCostContract.set("id", projectCostContractValue.id);
    projectCostContract.set("contractor", projectCostContractValue.contractor);
    projectCostContract.set("unitPrice", projectCostContractValue.unitPrice ? Number(projectCostContractValue.unitPrice) : 0);
    projectCostContract.set("numberOfUnits", projectCostContractValue.numberOfUnits ? Number(projectCostContractValue.numberOfUnits) : 0);
    projectCostContract.set("vatRate", projectCostContractValue.vatRate ? projectCostContractValue.vatRate / 100 : 0);
    projectCostContract.set("vatAmount", projectCostContractValue.vatAmount ? Number(projectCostContractValue.vatAmount) : 0);
    projectCostContract.set("realTotalNetPrice", projectCostContractValue.realTotalNetPrice ? Number(projectCostContractValue.realTotalNetPrice) : 0);
    projectCostContract.set("realTotalVatPrice", projectCostContractValue.realTotalVatPrice ? Number(projectCostContractValue.realTotalVatPrice) : 0);
    projectCostContract.set("subventionAmount", subventionAmount);
    projectCostContract.set("projectCost", projectCost);

    if (!projectCostContractValue.id) {
        const acl = new Parse.ACL();

        const orgAdminRoleName = `${selectedOrganization.attributes.shortTitle}_ADMIN`;
        const orgEmployeeRoleName = `${selectedOrganization.attributes.shortTitle}_EMPLOYEE`;

        acl.setRoleReadAccess(Roles.Admin, true);
        acl.setRoleWriteAccess(Roles.Admin, true);
        acl.setRoleReadAccess(orgEmployeeRoleName, true);
        acl.setRoleWriteAccess(orgEmployeeRoleName, true);
        acl.setRoleReadAccess(orgAdminRoleName, true);
        acl.setRoleWriteAccess(orgAdminRoleName, true);

        if (projectCost?.attributes?.projectActivity?.attributes?.project?.attributes?.shortCode) {
            const projectRoleName = `PROJECT_${projectCost.attributes.projectActivity.attributes.project.attributes.shortCode}`;

            acl.setRoleReadAccess(projectRoleName, true);
            acl.setRoleWriteAccess(projectRoleName, false);
        }

        projectCostContract.setACL(acl);
    }

    if (projectCostContractValue.tagId) {
        const tag = await GetFirstObjectById("Tag", projectCostContractValue.tagId)
        if (tag) {
            projectCostContract.set("tag", tag);
        }
    }

    return projectCostContract;
};

export const fetchTags = async (): Promise<TagBaseModel[]> => {
    const tags: TagBaseModel[] = [];
    const tag = Parse.Object.extend("Tag");
    const tagQuery = new Parse.Query(tag).limit(10000);

    const results = await tagQuery.find();

    if (results && results.length > 0) {
        await Promise.all(
            results.map(async (result) => {
                if (result.attributes && result.id) {

                    tags.push({
                        id: result.id,
                        title: result.attributes.title,
                    });
                }
            })
        );
    }

    return tags;
}

export const mapTagsToDropdown = (tags: TagBaseModel[]): { [key: string]: Object }[] => {
    const dropdownItems: { [key: string]: Object }[] = [];
    tags.map((tag: TagBaseModel) => {
        if (!tag.id || !tag.title) return;
        dropdownItems.push({
            value: tag.id,
            text: tag.title,
        });
    });
    return dropdownItems;
};

export const getProjectCostContractByTags = async (tagIds?: string[], projectId?: string): Promise<ProjectCostContractBaseModel[]> => {
    if (!(tagIds && tagIds.length > 0 && projectId)) return [];
    const projectCostContractList: ProjectCostContractBaseModel[] = [];

    await Promise.all(
        tagIds.map(async (tagId) => {
            const tagObject = Parse.Object.extend("Tag");
            const tagQuery = new Parse.Query(tagObject);
            const tag = await tagQuery.get(tagId);

            if (tag) {
                const projectCostContract = Parse.Object.extend("ProjectCostContract");
                const projectCostContractListQuery = new Parse.Query(projectCostContract).equalTo('tag', tag).include('projectCost.projectActivity').limit(10000);

                const results = await projectCostContractListQuery.find();

                if (results && results.length > 0) {
                    await Promise.all(
                        results.map(async (result) => {
                            if (result.attributes && result.id && result.attributes.projectCost?.attributes?.projectActivity?.attributes?.project?.id === projectId) {
                                projectCostContractList.push({
                                    id: result.id,
                                    contractor: result.attributes.contractor,
                                    unitPrice: result.attributes.unitPrice || 0,
                                    numberOfUnits: result.attributes.numberOfUnits,
                                    vatRate: result.attributes.vatRate || 0,
                                    vatAmount: result.attributes.vatAmount || 0,
                                    realTotalNetPrice: result.attributes.realTotalNetPrice || 0,
                                    realTotalVatPrice: result.attributes.realTotalVatPrice || 0,
                                    projectCost: result.attributes.projectCost,
                                    projectCostId: result.attributes.projectCost?.id,
                                    projectCostTitle: result.attributes.projectCost?.attributes?.title,
                                    tag: result.attributes.tag,
                                    tagId: result.attributes.tag?.id,
                                    tagTitle: result.attributes.tag?.attributes?.title,
                                    projectCostCode: result.attributes.projectCost?.attributes?.code,
                                    projectActivityCode: result.attributes.projectCost?.attributes?.projectActivity?.attributes?.code,
                                    projectCostRealTotalNetPrice: result.attributes.projectCost?.attributes?.realTotalNetPrice || 0,
                                    subventionAmount: result.attributes.subventionAmount || 0,
                                    totalPayment: result.attributes.totalPayment || 0,
                                });
                            }
                        })
                    );
                }
            }
        })
    )

    return projectCostContractList;
}

export const generateLinkToPaymentsTable = async (projectCostContractId?: string): Promise<string | undefined> => {
    if (!projectCostContractId) return;

    const projectCostContract = Parse.Object.extend("ProjectCostContract");
    const projectCostContractListQuery = new Parse.Query(projectCostContract).equalTo('objectId', projectCostContractId).limit(10000);

    const result = await projectCostContractListQuery.include("projectCost.projectActivity.project").first();

    if (result?.attributes?.projectCost?.id && result?.attributes?.projectCost?.attributes?.projectActivity?.id && result?.attributes?.projectCost?.attributes?.projectActivity?.attributes?.project?.id) {
        return `/projects/${result.attributes.projectCost.attributes.projectActivity.attributes.project.id}/activities/${result.attributes.projectCost.attributes.projectActivity.id}/costs/${result.attributes.projectCost.id}/contracts/${projectCostContractId}/payments`;
    };
}
