import React from "react";
import { Language } from "../../settings/Enums";
import { translations } from "../../settings/translation";
import "./styles.css";

interface Props {
  language: Language;
  spinnerClass?: string;
  backgroundObjectClass?: string;
  backgroundTransparencyClass?: string;
}

interface State {
  isSpinnerVisible: boolean;
}

class CustomSpinner extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
    };
  }

  // do not delete this, this is used as reference
  private handleSpinnerState = (newState: boolean): void => {
    this.setState({
      isSpinnerVisible: newState,
    });
  };

  public render(): any {
    const { isSpinnerVisible } = this.state;
    const {
      language,
      spinnerClass,
      backgroundObjectClass,
      backgroundTransparencyClass,
    } = this.props;

    return (
      <div>
        {isSpinnerVisible && (
          <div className={spinnerClass || ""}>
            <div className={backgroundObjectClass || ""}>
              <div className={backgroundTransparencyClass || ""}>
                <div
                  className="spinner-border"
                  style={{ color: "#1b314c" }}
                  role="status"
                >
                  <span className="sr-only">{`${translations[language].loading}...`}</span>
                </div>
                <h3
                  style={{ color: "#1b314c" }}
                >{`${translations[language].saving}...`}</h3>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CustomSpinner;
