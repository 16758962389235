import Parse from "parse";

export const GetUserRoles = async () => {
  const roles = await new Parse.Query(Parse.Role)
    .equalTo("users", Parse.User.current())
    .limit(10000)
    .find();
  return roles;
};

export const GetRoleById = async (id: string) => {
  const parseRole = new Parse.Query(Parse.Role).limit(10000);
  const query = parseRole.equalTo("objectId", id);
  return await query.first();
};

export const GetRoleByPropertyName = async (name: string, value: any) => {
  const parseRole = new Parse.Query(Parse.Role).limit(10000);
  const query = parseRole.equalTo(name, value);
  return await query.first();
};

export const GetRoleUsers = async (roleName: string) => {
  const parseRole = new Parse.Query(Parse.Role).limit(10000);
  const query = parseRole.equalTo("name", roleName);
  const role = await query.first();
  return role?.relation("users").query().find();
};

export const AssaignUserToRole = async (user: Parse.User, roleName: string) => {
  const parseRole = new Parse.Query(Parse.Role).limit(10000);
  const query = parseRole.equalTo("name", roleName);
  const role = await query.first();
  const usersRelation = role?.relation("users");
  usersRelation?.add(user);
  return await role?.save();
};

export const GetRoleQueryRelationByName = async (
  query: Parse.Role,
  name: string
) => {
  return await query.relation(name);
};

export const GetRoleRelationByName = async (
  query: Parse.Role,
  name: string
) => {
  const relationQuery = query.relation(name);
  return await relationQuery.query().find();
};
