import React, { ReactElement } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Language } from "../../settings/Enums";
import { LOGIN_ROUTE } from "../../settings/Routes";
import { translations } from "../../settings/translation";
import "./styles.css";

export const SuccessfullPasswordReset: React.FC = (): ReactElement => {
  const history = useHistory();
  const language =
    (localStorage.getItem("language") as Language | undefined) || Language.Hr;

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    history.push(LOGIN_ROUTE);
  };

  return (
    <Row className={"box-form"}>
      <Col>
        <Form onSubmit={handleSubmit} className="successfullPasswordResetForm">
          <Form.Group style={{ textAlign: "center" }}>
            <Form.Label className="justify-content-center">
              {translations[language || Language.Hr].resetPasswordSent}
              <br />
              {translations[language || Language.Hr].pleaseCheckMail}
            </Form.Label>
          </Form.Group>
          <Form.Row className="justify-content-center">
            <Button type="submit" className={"btn btn-primary"}>
              {translations[language || Language.Hr].goToLoginScreen}
            </Button>
          </Form.Row>
        </Form>
      </Col>
    </Row>
  );
};
